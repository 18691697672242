import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRolePermissionDirective } from './has-role-permission.directive';

@NgModule({
  declarations: [HasRolePermissionDirective],
  exports:[HasRolePermissionDirective],
  imports: [
    CommonModule
  ]
})
export class DirectiveModule { }
