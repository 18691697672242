import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, RemoveUnderscorePipe } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from '../app/appInterceptor';
import { SharedModulesModule } from '../app/shared-files/shared-modules/shared-modules.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './auth/auth.guard';
import { DocumentComponent } from './pages/pages-init/document/document.component';
import { HomeComponent } from './components/home/home.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {MatChipsModule} from '@angular/material/chips';
import * as moment from "moment";
import { LandingComponent } from './components/landing/landing.component';


import { QuillConfig, QuillModule,QUILL_CONFIG_TOKEN } from "ngx-quill";

import 'quill-mention'
import { LOCALE_ID } from '@angular/core';
import localeIN from '@angular/common/locales/en-IN'
import { registerLocaleData } from '@angular/common'


registerLocaleData(localeIN);
import { NumbervalidationDirective } from './numbervalidation.directive';
import { MAT_HAMMER_OPTIONS, MatInputModule, MatTableModule } from '@angular/material';

import { DirectiveModule } from './directive/directive.module';

import {CredlixBoardModule} from './component/credlix-board/credlix-board.module'
import { AccountAuthorizationComponent, RemoveUnderscore ,} from './auth/account-authorization/account-authorization/account-authorization.component';
import { SupplierDiscountDialogComponent } from './auth/supplier-discount-dialog/supplier-discount-dialog.component';
import { AbmManagementComponent } from './component/credlix-board/abm-management/abm-management.component';
import { ErrorToastComponent } from './shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from './shared-files/success-toast/success-toast.component';
import { InfoToastComponent } from './shared-files/info-toast/info-toast.component';
import { WarningToastComponent } from './shared-files/warning-toast/warning-toast.component';

import { MsalService,MSAL_INSTANCE } from '@azure/msal-angular';
import { PublicClientApplication,IPublicClientApplication } from '@azure/msal-browser';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'dbd4c170-c875-41a4-89e8-729c56adf5a0', // QA client id
      redirectUri: 'https://crm.moglilabs.com/auth/login',
      authority: 'https://login.microsoftonline.com/4273e6e9-aed1-40ab-83a3-85e0d43de705', // Directory (tenant) ID
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    DocumentComponent,
    HomeComponent,
    AccountAuthorizationComponent,
    NumbervalidationDirective,
    SupplierDiscountDialogComponent,
    RemoveUnderscore,
    RemoveUnderscorePipe,
    AbmManagementComponent,
    ErrorToastComponent,
    SuccessToastComponent,
    InfoToastComponent,
    WarningToastComponent],

  imports: [
    BrowserModule,
    DirectiveModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModulesModule,
    FormsModule,
    ReactiveFormsModule,
    CredlixBoardModule,
    //NgxMatDrpModule,
    MatChipsModule,
    MatTableModule,
    MatInputModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    ToastrModule.forRoot({
      timeOut: 6000,
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true
    }),
  //   MsalModule.forRoot(
  //     new PublicClientApplication(msalConfig), 
  //     {
  //     interactionType: InteractionType.Redirect, // 'popup' can be used
  //     authRequest: loginRequest,
  //   },
  //   {
  //     interactionType: InteractionType.Redirect, // Third argument: token acquisition interaction type
  //     protectedResourceMap: new Map([
  //       ['https://graph.microsoft.com/v1.0/me', ['openid']] // MS Graph API    
  //     ]),
  //   }
  // ),
    QuillModule.forRoot()
  ],

  entryComponents:[SupplierDiscountDialogComponent,ErrorToastComponent,SuccessToastComponent,InfoToastComponent,WarningToastComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AppInterceptor,
    multi: true
  },
  { provide: LOCALE_ID, useValue: 'en-IN' },
  {
    provide: MAT_HAMMER_OPTIONS,
    useValue: {
      cssProps: {
        userSelect: true
      }
    },
  },
  MsalService,
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: MsalInterceptor,
  //   multi: true,
  // },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  [AuthGuard]],

  bootstrap: [AppComponent]
})
export class AppModule { }
