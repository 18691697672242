import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabelConstants } from 'src/app/LabelConstants';

@Component({
  selector: 'app-custom-multiple-select',
  templateUrl: './custom-multiple-select.component.html',
  styleUrls: ['./custom-multiple-select.component.scss']
})
export class CustomMultipleSelectComponent implements OnInit {
  isListOfString: boolean = false;
  stringLabelList = [
    'Business Vertical',
    'Tag Type',
    'Industry',
    'Business Unit',
    'Deal Type',
    'L1 Categories',
    'Supplier Type',
    'Brands',
    'Location',
    'Locations',
    'L1 Category',
    'L1 category',
    'L2 Category',
    'L2 category',
    'M & A',
    'M&A',
    'Target Panel',
    'Tag Type',
    'Solution Pitched by Moglix',
    'Executive Watcher',
    'L2 Categories',
    'State',
    'Product Interested In',
    'Life Cycle Stage',
    'Market',
    'Applicabel Industry',
    'Company Type',
    LabelConstants.LeadSourceLabel
  ];
  constructor() { }

  ngOnInit() {
    this.isListOfString = this.stringLabelList.includes(this.label);
    if (!this.selectedValues) {
      this.selectedValues = []
    } 
  }
  @Input() label: string = 'Select'; // Default label is 'Select'
  @Input() valueCountView: boolean = false;
  @Input() selectedValues: string[] = [];
  @Input() selectedHighlight: boolean = true
  @Input() availableOptions: string[] = [];
  @Input() searchControl: string = '';
  @Input() disabled: boolean = false
  @Output() selectionChange = new EventEmitter<string[]>();
  @Output() searchChange = new EventEmitter<string>();

  clearSelection() {
    this.selectedValues = [];
    this.selectionChange.emit(this.selectedValues);
  }

  applySelection() {
    this.selectionChange.emit(this.selectedValues);
  }

  isOptionArray(option: string | string[]): option is string[] {
    return Array.isArray(option);
  }

  onSelectDialogClosed() {
    this.searchControl = '';
    this.searchChange.emit('');
  }

  handlePaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData.getData('text');
    this.searchChange.emit(pastedText)
  }

}
