
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from './pages/pages.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { SettingService } from './settings/setting.service';
import { Pipe, PipeTransform } from '@angular/core';
import { KanbanService } from './component/credlix-board/kanban.service';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ConfirmDialogComponent } from './shared-files/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})


export class AppComponent implements OnInit {
  opened: boolean;
  searchText: string;
  autoSuggestloading = false;
  showCloseIcon: boolean;
  storeSearchResponse: any;
  showListing: boolean;
  title = 'credlix';
  selected = 'option2';
  links = ['All Sales Invoices', 'Customers'];
  activeLink = this.links[0];
  loginDetails: string;
  selectAccount: boolean = false;
  showSideBar: boolean;
  userProfile: string
  hideDashboard: boolean = true;
  showInvoicePage: boolean = false;
  isMenuOpen = true;
  selectedItem = '';
  panelOpenState = false;
  contentMargin = 190;
  accountDetails = [];
  getAccountName: string;
  account_name: any;
  // accountForm: FormGroup;
  sidenavfull: boolean = false;
  AccName: string;
  isCustomer: boolean = false;
  getBusinessID: String;
  isLoading: boolean;
  channleFinancing: boolean = false;
  epfinancing: boolean = false;
  isCredlixUser: boolean = false;
  anchorList: any = [];
  anchorType: any;
  isBuyer: boolean = false;
  userOnBoardData: any;
  onBoardRes: any;
  businessName: string;
  displayName: string;
  roleType: string;
  roleName: string;
  userName: string;
  userEmail: string;
  gstNumber: string;
  anchorDisplayName: string;
  isSidUser: boolean = false;
  selecedManagement :any;
  isCRM:boolean=true;
  isSOB:boolean=false;
  isSRM:boolean=false;
  boardName="Customer Board";
  crmUserId:any;
  sobUserId:any;
  srmUserId:any;
  defaultBusinessId:any;
  defaultBusinessName:any="";
  enableBusinessDropDown:boolean=false;
  userDetails: string;
  idtoken: any;
  accountName: string;
  logObj = {}
  constructor(private router: Router, public authService: AuthService, private activatedRoute: ActivatedRoute, public settingService: SettingService, public pagesService: PagesService,
    private fb: FormBuilder, private kanbanService: KanbanService,private msalService: MsalService,private _snackBar: MatSnackBar, private dialog: MatDialog) {
    console.log('app component constrcutor')
      this.settingService.selectAccount.subscribe((account: any) => {
      if (account == false) {
        this.selectAccount = false;
      }
      else {
        this.selectAccount = true;
      }
    })
    console.log('app component constrcutor account',this.selectAccount)
    this.settingService._GSTINOnBoardData.subscribe(res => {
      this.userOnBoardData = res['status'];
    });
    console.log('app component constrcutor userOnBoardData',this.userOnBoardData)
    //this.redirectToPageBasedOnCondition()
  }
  switchManagement(selecedManagement){
    this.selecedManagement=selecedManagement;
    if(!selecedManagement || selecedManagement==='null'){
      console.log('switchManagement set condition')
      this.selecedManagement=this.defaultBusinessName;
    }
    this.settingService.storeManagementData(this.selecedManagement);
    if(this.selecedManagement==='CRM Management'){
      this.isCRM=true;
      this.boardName="Customer Board";
      this.isSOB=false;
      this.isSRM=false;
      localStorage.setItem('selected_business',"CRM Management");
    }else if (this.selecedManagement==='SOB Management'){
      this.isCRM=false;
      this.isSOB=true;
      this.isSRM=false;
      this.boardName="KAM Board";
      localStorage.setItem('selected_business',"SOB Management");
    }else if(this.selecedManagement==='SRM Management'){
      this.isCRM=false;
      this.isSOB=false;
      this.isSRM=true;
      this.boardName="Supplier";
      localStorage.setItem('selected_business',"SRM Management");
    }
  }
  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.notificationId) {
        this.authService.destroySession();
        this.selectAccount = false;
      }
    })
  }
  ngOnInit() {
    console.log('app component ngOnInit')
    this.msalService.handleRedirectObservable().subscribe({
      next: (authResult) => {
        console.log('app handleRedirectObservable result',authResult)
        if (authResult && authResult.accessToken) {
          ;
          this.authService.getUserByAzureToken(authResult.accessToken,localStorage.getItem('businessId')).subscribe({
            next: (res) => {
            console.log('app success after redirect ')
              this.setUserData(res); // Handle the successful response here
            },
            error: (err) => {
              console.error('Error while fetching user data:', err);
              //this.router.navigate(['/']);
            //window.location.reload();
            },
            complete: () => {
              console.log('getUserByAzureToken request completed');
            }
          });
        }else{
          //this.router.navigate(['/']);
          //window.location.reload();
        }
      },
      error: (error) => {
        console.error('Error handling redirect', error);
      }
    });
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if (getLocalStorage && getLocalStorage['role']) {
      this.businessName = getLocalStorage['role']['businessName'];
      this.displayName = getLocalStorage['role']['role']['displayName'];
      this.gstNumber = getLocalStorage['role']['gstNumber'];
      this.roleType = getLocalStorage['role']['roleType'];
      this.roleName = getLocalStorage['role']['roleName'];
      this.userEmail = getLocalStorage['role']['userEmail'];
      this.userName = getLocalStorage['role']['userName'];
    }
    if (getLocalStorage && getLocalStorage['crmUserId'] != null) {
      this.crmUserId=getLocalStorage['crmUserId'];
      localStorage.setItem('crmUserId',this.crmUserId);
    }
    if (getLocalStorage && getLocalStorage['sobUserId'] != null) {
      this.sobUserId=getLocalStorage['sobUserId'];
      localStorage.setItem('sobUserId',this.sobUserId);
    }
    if (getLocalStorage && getLocalStorage['srmUserId'] != null) {
      this.srmUserId=getLocalStorage['srmUserId'];
      localStorage.setItem('srmUserId',this.srmUserId);
    }
    if (getLocalStorage && getLocalStorage['defaultBusinessId'] != null) {
      this.defaultBusinessId=getLocalStorage['defaultBusinessId'];
      localStorage.setItem('defaultBusinessId',this.defaultBusinessId);
    }
    if (getLocalStorage && getLocalStorage['defaultBusinessName'] != null) {
      this.defaultBusinessName=getLocalStorage['defaultBusinessName'];
      localStorage.setItem('defaultBusinessName',this.defaultBusinessName);
    }
    if (getLocalStorage && getLocalStorage['access_token'] != null) {
      this.selectAccount = true;
    }
    else {
      this.selectAccount = false;
    }
    let businessCount=0;
    if(this.crmUserId){
      businessCount++;
    }
    if(this.sobUserId){
      businessCount++;
    }
    if(this.srmUserId){
      businessCount++;
    }
    if(businessCount>1){
      this.enableBusinessDropDown=true;
    }
    let checkSubmit = localStorage.getItem('isSubmitted');
    if (checkSubmit == 'true') {
      this.pagesService.isSubmitted = true;
    }
    else {
      this.pagesService.isSubmitted = false;
    }
    this.pagesService.isLoading$.subscribe(res => {
      setTimeout(() => {
        this.isLoading = res;
      }, 0);
    })
    window.addEventListener('storage', (event) => {
      if (event.key === 'logged_multiple') {
        if (event.newValue === "true") {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
        else {
          this.router.navigate(['select-account']).then(() => {
            window.location.reload();
          });
        }
      }
      else if (event.key === 'logged_in' || event.key === 'TOKEN') {
        sessionStorage.clear();
        if (this.pagesService.checkMobileScreen()) {
          this.router.navigate(['/sop-management']);
        }
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    }, false)
    this.getAccountName = localStorage.getItem('accountName');
    this.loginDetails = localStorage.getItem('currentUser');
    if (this.authService.isChannleFinancing()) {
      this.channleFinancing = true;
    }
    if (this.authService.isEPfinancing()) {
      this.epfinancing = true;
    }
    if (this.loginDetails && this.loginDetails.trim()) {
      var matches = this.loginDetails.match(/\b(\w)/g);
      if (matches.length > 2) {
        matches.splice(2, matches.length)
      }
      this.userProfile = matches.join('').toUpperCase()
    }
    if (this.authService.isAnchor()) {
      this.isCustomer = true;
    }
    if (this.authService.isBuyer()) {
      this.isBuyer = true;
    }
    if (this.authService.isSIDRoleType()) {
      this.isSidUser = true;
    }
    if (this.authService.isCredlixUser()) {
      this.isCredlixUser = true;
      if (localStorage.getItem('credlixUserID')) {
        let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
        this.anchorList = getLocalStorage['credlixAnchorList'];
        this.anchorList.map(val => {
          if (localStorage.getItem('credlixUserID') == val['id']) {
            this.anchorDisplayName = val['displayName'];
            this.anchorType = val;
            this.epfinancing = val['isEPFinancing'];
            this.channleFinancing = val['isChannleFinancing'];
          }
        })
      }
      this.pagesService.$anchorList.subscribe((res: any) => {
        let anchors = res;
        this.anchorList = anchors.filter(val => {
          return val['displayName'] != 'NUPHI'
        })
        this.anchorList.map((val, i) => {
          if (val['displayName'] == "MOGLIX") {
            this.anchorList.splice(i, 1);
            this.anchorList.unshift(val);
          }
        })
        this.anchorType = this.anchorList[0];
        this.epfinancing = res[0]['isEPFinancing'];
        this.channleFinancing = res[0]['isChannleFinancing'];
        let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"))
        if (res[0]['displayName'] == "MOGLIX") {
          getLocalStorage['moglix'] = true;
        }
        getLocalStorage['credlixAnchorList'] = this.anchorList;
        localStorage.setItem('credlixUserID', this.anchorList[0]['id']);
        localStorage.setItem('USERINFO', JSON.stringify(getLocalStorage));
      })
    }
    this.pagesService.changeColor$.subscribe(res => {
      if (res) {
        let ele = document.getElementById(res);
        if (ele && ele.style) {
          document.getElementById(res).style.display = "inline-block";
          this.changeElementColor(res)
        }
      }
    })
    this.selecedManagement = localStorage.getItem('selected_business');
    this.switchManagement(this.selecedManagement);
  }
  redirectToPageBasedOnCondition(){
    if (this.pagesService.checkMobileScreen()) {
      this.router.navigate(['/sop-management']);
    }
  }
  login() {
    this.router.navigate(['/auth/login']);
  }
  logout() {
    sessionStorage.clear();
    this.authService.destroySession();
    this.router.navigate(['/auth/login'])
      .then(() => {
        window.localStorage.setItem('logged_in', 'false');
        window.location.reload();
      });
  }
  deleteUserAccount() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      height: '200px',
      panelClass: 'custom-dialog-container',
      data: { 
        title: 'Delete Account Confirmation',
        message: 'Are you sure you want to delete your account? This action cannot be undone.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.settingService.deleteUserAccount().subscribe({
          next: (res) => {
            if (res["status"] === 200) {
              this._snackBar.openFromComponent(SuccessToastComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['atom-toast', 'success'],
                  duration: 2000,
                  data: "Account deleted successfully"
              });
              this.authService.destroySession();
              this.router.navigate(['/auth/login']).then(() => {
                console.log('Navigation to login successful.');
              });
            }
          },
          error: (error) => {
            console.error('Error deleting user account:', error);
            // Optionally show a user-friendly error message
          }
        });
      }
    });

    
  }

  
  
  switchAccount() {
    sessionStorage.clear();
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    getLocalStorage['access_token'] = null;
    localStorage.removeItem('credlixUserID');
    localStorage.removeItem('isCustomer');
    localStorage.setItem('TOKEN', getLocalStorage['account_token']);
    this.pagesService.getRolesByAccount().subscribe(res => {
      if (res['status'] == 200) {
        getLocalStorage['user_roles'] = res['result']['user_roles'];
        localStorage.setItem("USERINFO", JSON.stringify(getLocalStorage));
        this.router.navigate(['select-account']).then(() => {
          localStorage.setItem('logged_multiple', 'false');
          window.location.reload();
        });
      }
    });
  }

  changePassword() {
    this.router.navigate(['/auth/change-password'])
  }
  createInvoice() {
    this.hideDashboard = false;
    this.showInvoicePage = true;
  }
  getUserAccounts() {
    this.pagesService.getAccountDetails().subscribe(data => {
      if (data['status'] == 200) {
        this.pagesService.offSpinner();
        this.accountDetails = data['result'].filter(function (el) {
          return el != null;
        });
      }
    })
  }
  sidenav_open() {
    this.sidenavfull == true;
  }
  sidenav_close() {
    this.sidenavfull == false;
  }

  // changeBusinessID(value) {
  //   let selectedBusinessRoles = []
  //   this.getBusinessID = value;
  //   this.authService.storageBusinessID(this.getBusinessID);
  //   for (let i = 0; i < this.accountDetails.length; i++) {
  //     if (value == this.accountDetails[i].id) {
  //       selectedBusinessRoles = this.accountDetails[i].roles
  //       localStorage.setItem('businessGSTN', this.accountDetails[i].gstNo);
  //     }
  //   }
  //   let obj = {
  //     roleId: selectedBusinessRoles[0].id
  //   }
  //   this.pagesService.refreshTokenWithBusinessID(obj).subscribe(res => {
  //     if (res['status'] == 200) {
  //       this.authService.createSession(res['result'].access_token)
  //       this.authService.getUserDetails(res['result']);
  //       window.location.reload();
  //     }
  //   })

  // }

  setAccountName() {
    if (this.accountDetails != null && this.accountDetails.length > 0)
      return this.accountDetails[0].id
  }
  showSuppliers() {
    this.router.navigate(['/supplierLinks'])
  }
  resetGlobaFiler(val) {
    sessionStorage.clear();
    document.getElementById(val).style.display = "inline-block";
    this.changeElementColor(val);
    this.pagesService.getClickEvent('clicked');
  }

  // headerlistData(){
  //   this.pagesService.getHeaderList().subscribe(list=>{
  //     this.authService.storageListHeaders(list['result']);
  //   })
  // }
  permission = []

  navigateToUserManagement() {
    this.router.navigate(['/settings/user-management'])
  }

  changeAnchor(anchorType) {
    this.anchorDisplayName = anchorType['displayName'];
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    if (this.router.url.includes('settings/manualUpload') || anchorType['isChannleFinancing']) {
      this.pagesService.getHeaderList(anchorType['id']).subscribe(list => {
        this.authService.storageListHeaders(list['result']);
      })
    }
    // oldEPFinancing added to handle when credlixUser is routing from vedanta to moglix/accord/twiga
    let oldEPFinancing = this.epfinancing;
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"))
    if (anchorType['displayName'] == "MOGLIX") {
      getLocalStorage['moglix'] = true;
    }
    else {
      getLocalStorage['moglix'] = false;
    }
    //for SID anchor
    if (anchorType['isSid']) {
      getLocalStorage['isSid'] = true;
    }
    else {
      getLocalStorage['isSid'] = false;
    }
    this.epfinancing = anchorType['isEPFinancing'];
    this.channleFinancing = anchorType['isChannleFinancing'];
    getLocalStorage['channleFinancing'] = this.epfinancing;
    getLocalStorage['channleFinancing'] = this.channleFinancing;
    this.pagesService.setChannelFinancing(this.channleFinancing);
    localStorage.setItem('credlixUserID', anchorType['id']);
    localStorage.setItem('USERINFO', JSON.stringify(getLocalStorage));
    if (this.epfinancing) {
      if (!this.router.url.includes('/earlyPayments') && !this.router.url.includes('/supplierProfile')) {
        if (this.router.url.includes('/manualFileUpload')) {
          if (anchorType['displayName'] == "MOGLIX") {
            this.router.navigate(['/settings/manualUpload/manualFileUpload/booking']);
          }
          else {
            this.router.navigate(['/settings/manualUpload/manualFileUpload/home']);
          }
        }
        else if (!oldEPFinancing) {
          this.router.navigate(['/supplierLinks'])
        }
        else {
          this.router.navigate([this.router.url]);
        }
      }
      else {
        this.router.navigate(['/supplierLinks'])
      }
    }
    else if (this.channleFinancing) {
      this.router.navigate(['/channelFinance']);
    }
  }

  navigateToSupplier() {
    console.log('home clciked');
    if(!this.pagesService.checkMobileScreen()){
      this.router.navigate(['/crm-board']);
    }
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    if (this.authService.isBorrower() && this.authService.isSIDRoleType()) {
      this.router.navigate(['/sid/invoiceListing'])
    }
    else if (this.authService.isBuyer() && this.authService.isSIDRoleType()) {
      this.router.navigate(['/sid/listing/buyerSupplier'])
    }
    else if (this.authService.isSIDUSER()) {
      this.router.navigate(['/sid/listing/supplier'])
    }
    if ((this.isCustomer || this.isCredlixUser) && this.epfinancing && !this.authService.isSIDRoleType())
      this.router.navigate(['/supplierLinks'])
    if (!this.isCustomer && this.epfinancing && !this.isCredlixUser && !this.authService.isSIDRoleType())
      this.router.navigate(['/earlyPayments'])
    if ((this.isCustomer || this.isCredlixUser) && this.channleFinancing && !this.authService.isSIDRoleType())
      this.router.navigate(['/channelFinance'])
    if (!this.isCustomer && this.channleFinancing && !this.isCredlixUser && !this.authService.isSIDRoleType())
      this.router.navigate(['/bankLists'])
  }

  //added for changing icons css when routing in internal components
  changeElementColor(val) {
    if (val != "EP_white" && this.epfinancing && document.getElementById('EP_grey')) {
      document.getElementById('EP_grey').style.display = "inline-block";
      document.getElementById('EP_white').style.display = "none";
    }
    else if (val == "EP_white" && this.epfinancing) {
      document.getElementById('EP_grey').style.display = "none";
    }
    if (val != "white_das" && document.getElementById('grey_das')) {
      document.getElementById('grey_das').style.display = "inline-block";
      document.getElementById('white_das').style.display = "none";
    }
    else if (val == "white_das" && document.getElementById('grey_das')) {
      document.getElementById('grey_das').style.display = "none";
    }
    // if (val != "white_doc" && !this.channleFinancing) {
    //   document.getElementById('grey_doc').style.display = "inline-block";
    //   document.getElementById('white_doc').style.display = "none";
    // }
    // else if (val == "white_doc" && !this.channleFinancing) {
    //   document.getElementById('grey_doc').style.display = "none";
    // }

    if (val != "buyer_white" && document.getElementById('buyer_grey')) {
      document.getElementById('buyer_grey').style.display = "inline-block";
      document.getElementById('buyer_white').style.display = "none";
    }
    else if (val == "buyer_white") {
      document.getElementById('buyer_grey').style.display = "none";
    }

    if (val != "supplier_white" && document.getElementById('supplier_grey')) {
      document.getElementById('supplier_grey').style.display = "inline-block";
      document.getElementById('supplier_white').style.display = "none";
    }
    else if (val == "supplier_white") {
      document.getElementById('supplier_grey').style.display = "none";
    }

    if (val != "finance_white" && this.channleFinancing && document.getElementById('finance_grey')) {
      document.getElementById('finance_grey').style.display = "inline-block";
      document.getElementById('finance_white').style.display = "none";
    }
    else if (val == "finance_white") {
      document.getElementById('finance_grey').style.display = "none";
    }
    if ((val != "leads_white" && (this.authService.isLenderLeads() ||
      this.authService.isCredlixUser()) && !this.channleFinancing) &&
      document.getElementById('leads_grey')) {
      document.getElementById('leads_grey').style.display = "inline-block";
      document.getElementById('leads_white').style.display = "none";
    }
    else if (val == "leads_white" && (this.authService.isLenderLeads() || this.authService.isCredlixUser())) {
      document.getElementById('leads_grey').style.display = "none";
    }
    if (val != "white_doc_ci" && this.authService.isCredlixUser() && !this.channleFinancing
      && document.getElementById('grey_doc_ci')) {
      document.getElementById('grey_doc_ci').style.display = "inline-block";
      document.getElementById('white_doc_ci').style.display = "none";
    }
    else if (val == "white_doc_ci" && this.authService.isCredlixUser()) {
      document.getElementById('grey_doc_ci').style.display = "none";
    }
    if ((val != "white_doc_et" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() ||
      this.isCustomer && !this.channleFinancing) && document.getElementById('grey_doc_et'))) {
      document.getElementById('grey_doc_et').style.display = "inline-block";
      document.getElementById('white_doc_et').style.display = "none";
    }
    else if (val == "white_doc_et" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() || this.isCustomer)) {
      document.getElementById('grey_doc_et').style.display = "none";
    }
    if ((val != "white_doc_pt" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() ||
      this.isCustomer && !this.channleFinancing) && document.getElementById('grey_doc_pt'))) {
      document.getElementById('grey_doc_pt').style.display = "inline-block";
      document.getElementById('white_doc_pt').style.display = "none";
    }
    else if (val == "white_doc_pt" && (this.authService.isCredlixUser() || this.isCustomer || this.authService.isSIDUSER())) {
      document.getElementById('grey_doc_pt').style.display = "none";
    }

    if ((val != "white_doc_pr" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() ||
      this.isCustomer && !this.channleFinancing) && document.getElementById('grey_doc_pr'))) {
      document.getElementById('grey_doc_pr').style.display = "inline-block";
      document.getElementById('white_doc_pr').style.display = "none";
    }
    else if (val == "white_doc_pr" && (this.authService.isCredlixUser() || this.isCustomer || this.authService.isSIDUSER())) {
      document.getElementById('grey_doc_pr').style.display = "none";
    }

    if ((val != "white_doc_cb" && (this.authService.isCredlixUser() || this.authService.isSIDUSER() ||
      this.isCustomer && !this.channleFinancing) && document.getElementById('grey_doc_cb'))) {
      document.getElementById('grey_doc_cb').style.display = "inline-block";
      document.getElementById('white_doc_cb').style.display = "none";
    }
    else if (val == "white_doc_cb" && (this.authService.isCredlixUser() || this.isCustomer || this.authService.isSIDUSER())) {
      document.getElementById('grey_doc_cb').style.display = "none";
    }

    if (val != "settings_white" && document.getElementById('settings_grey')) {
      document.getElementById('settings_grey').style.display = "inline-block";
      document.getElementById('settings_white').style.display = "none";
    }
    else {
      document.getElementById('settings_grey').style.display = "none";
    }

  }
  onSearchChange(value) {
    this.autoSuggestloading = true
    this.showCloseIcon = true;

    if (value) {
      if (value.trim().length > 1) {
        let obj = {
          "where": {
            "name": {
              "type": "search",
              "operator": "or",
              "value": [
                  {
                    "field": "name",
                    "value": this.searchText,
                    "sub_type" : "suggest"
                  }
                ]
            }
          }
        };
        setTimeout(function () {
          this.autoSuggestloading = false;
        }.bind(this), 500);
        this.kanbanService.getLaneTask(obj).subscribe(res => {
          this.autoSuggestloading = false;
          this.storeSearchResponse = res['result'];
          this.showListing = true;

        })
      }
    }
    else {
      this.showCloseIcon = false;

    }
  }
  getCardDetails(list) {
    window.open('/#/cardDetails/' + list.id, "_blank");
    this.showListing = false;
  }

  submitSearch () {
    console.log("Submit Global Search " + this.searchText);
  }
  resetSearchText() {
    this.searchText = "";
    this.showCloseIcon = false;
    this.showListing = false;
    this.storeSearchResponse = [];
  }
   setUserData(data: any) {
       console.log('setUserData',data)
      if (data['status'] == 200) {
        let tmpData = data['result'];
        this.authService.offSpinner();
        this.userDetails = data['result']['first_name'] + " " + data['result']['last_name']
        this.authService.getUserDetails((data['result']));
        this.authService.storageUserDetails(this.userDetails);
        if (data['result']['access_token'] != null) {
          this.idtoken = data['result']['access_token'];
        }
        else {
          this.idtoken = data['result']['account_token'];
        }
        this.authService.createSession(this.idtoken);
        this.accountName = this.logObj['email'];
        this.authService.storageAccountName(this.accountName);
        if (tmpData['exim'] != null && tmpData['exim'] == true) {
          window.location.href = `${window.location.protocol}//${window.location.host}/nuphi/login?token=EXIM`;
        } else {
          if (data.result != null && data['result']['access_token'] && data.result.role.role.roleType == 'BORROWER' && 
            data.result.role.masterAccount.isSid == false && data.result.lead == true) {
            
          }
          else if (data.result != null && data['result']['access_token']) {
            console.log('login reloading on getting token',data['result']['access_token'])
            this.router.navigate(['/']).then(() => {
              window.location.reload();
            });
          }
          else if (!data['result']['access_token']) {
            this.router.navigate(['select-account']).then(() => {
              window.localStorage.setItem('logged_multiple', 'false');
              window.location.reload();
            });
          }
        }
      }
  
   }
}
@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/_/g, " ");
  }
}
