<section class="section mb-0">
  <div class="atom-row  m-0">
    <div class="w-p180 mr-10 mb-0">     
        <div class="input-group">
          <!-- <mat-label *ngIf="isDeals">Search Deal</mat-label>
          <mat-label *ngIf="!isDeals && !isSOBOpportunity ">Search By Name</mat-label>
          <mat-label *ngIf="isSOBOpportunity">Search Opportunity</mat-label> -->
          <app-field-autocomplete [fieldData]="inputAutoField" [modelRef]="searchByNameConfig.value" (filterApplied)="onRelationFilterByName(searchByNameConfig);">
          </app-field-autocomplete>
        </div>
    </div>
    <!-- Hunters for isAccount || isContact || isDeals -->
    <div class="w-p113 mr-10 mb-0" *ngIf="teams != null && (isAccount || isContact || isDeals)">
      <div class="input-group">
        <app-custom-multiple-select [label]="huntersLabel" 
                    [selectedValues]="selectedUsers"
                    [availableOptions]="userListSearch" 
                    [searchControl]="searchControlOwner"
                    (selectionChange)="onKAMSelectionChange($event)"
                    (searchChange)="filterOptions($event)">
        </app-custom-multiple-select>
      </div>
    </div>
    <!-- KAM for isSOBAccount || isSOBContact || isSOBDeals || isSOBOpportunity-->
    <div class="w-p140 mr-10 mb-0" *ngIf="(isSOBAccount || isSOBContact || isSOBDeals || isSOBOpportunity)">
      <div class="input-group" *ngIf="teams != null">
        <app-custom-multiple-select [label]="KAMLabel" 
                    [selectedValues]="selectedUsers"
                    [availableOptions]="userListSearch" 
                    [searchControl]="searchControlOwner"
                    (selectionChange)="onKAMSelectionChange($event)"
                    (searchChange)="filterOptions($event)">
        </app-custom-multiple-select>
      </div>
    </div>
    <!-- Teams isDeals || isSOBDeals || isSOBOpportunity-->
    <div class="w-p103 mr-10 mb-0"  *ngIf="isDeals || isSOBDeals || isSOBOpportunity || isSRMSupplier">
      <div fxLayout="column" class="roleadd" *ngIf="teams != null">
        <div class="input-group">
            <app-custom-multiple-select [label]="teamsLabel" 
                    [selectedValues]="selectTeams"
                    [availableOptions]="teamsSearch" 
                    [searchControl]="searchControlRegion"
                    (selectionChange)="onRegionSelectionChange($event)"
                    (searchChange)="filterOptionsTeams($event)">
        </app-custom-multiple-select>
        </div>
      </div>
    </div>
    <!-- Tag Type isDeals -->
    <div class="w-p114 mr-10 mb-0" *ngIf="isDeals">
      <div class="input-group">
        <app-custom-multiple-select [label]="tagTypeLabel" 
                    [selectedValues]="selectedTagType"
                    [availableOptions]="defaultTagTypeSearch" 
                    [searchControl]="searchControlTagType"
                    (selectionChange)="onTagTypeSelectionChange($event)"
                    (searchChange)="filterOptionsTagTypeForDealsListing($event)">
        </app-custom-multiple-select>
      </div>
    </div>
    <!-- Account isDeals -->
    <div class="w-p118 mr-10 mb-0" *ngIf="isDeals">
        <div class="input-group">
           <app-custom-select #cusComComplete [fieldData]="{
            'config' : accountConfig,
            'isFilter' : true
          }" (selectedAccountIdsChange)="handleApplySelections($event)">
        </app-custom-select>
        </div> 
    </div>
    <!-- Industry isDeals Hunting -->
    <div class="w-p110 mr-10 mb-0" *ngIf="isDeals">
      <div class="input-group">
        <app-custom-multiple-select [label]="industryLabel" 
                    [selectedValues]="selectedIndustries"
                    [availableOptions]="defaultIndustriesSearch" 
                    [searchControl]="searchControlIndustry"
                    (selectionChange)="onIndustrySelectionChange($event)"
                    (searchChange)="filterOptionsIndustry($event)">
        </app-custom-multiple-select>    
      </div>
    </div>
    <!-- Account isContact -->
    <div class="w-p118 mr-10 mb-0" *ngIf="isContact">
      <div class="input-group">
         <app-custom-select #cusComComplete [fieldData]="{
          'config' : accountConfig,
          'isFilter' : true
        }" (selectedAccountIdsChange)="handleApplySelections($event, 'account')">
      </app-custom-select>
      </div> 
    </div>

    <!-- Lead Source Contact/ Account Hunting -->
    <div class="w-p135 mr-10 mb-0" *ngIf="isDeals || isContact || isAccount">
      <div class="input-group">
        <app-custom-multiple-select [label]="LeadSourceLabel" 
                    [selectedValues]="selectedLeadSource"
                    [availableOptions]="leadSourceAvailable" 
                    [searchControl]="searchControlLeadSource"
                    (selectionChange)="onLeadSourceSelectionChange($event)"
                    (searchChange)="filterOptionsLeadSource($event)">
        </app-custom-multiple-select>    
      </div> 
    </div>

    <!-- <div class="w-p200 mr-10 mb-0" *ngIf="isSOBOpportunity"> //commented to make generic component
      <div fxLayout="column" class="roleadd">
        <div class="input-group">
          <mat-label>Customer  Name</mat-label>
            <mat-form-field appearance="outline" class="mat-select mat-select-search">
              <mat-select #s1 placeholder="Customer Name" multiple [(ngModel)]="selectedAccountIds" (selectionChange)="onSelectionChange($event)">
                <div class="select-options" (scroll)="onScroll($event)">
                  <mat-option *ngFor="let account of accountIdsListSearch" [value]="account.id"> {{ account.displayName }}</mat-option>
                </div>
                <div class="select-footer">
                  <button   mat-button
          class="btn-basic btn-text-black mr-10"
          color="primary" (click)="clearSelections()">Clear</button>
                  <button  class="btn-primary" mat-raised-button color="warn" (click)="applySelections(); s1.close()">Apply</button>
                </div>
              </mat-select>
            </mat-form-field>
        </div>
      </div>
    </div> -->
        <!-- parent.component.html -->
    <div class="w-p118 mr-10 mb-0"  *ngIf="isSOBOpportunity">
     
        <div class="input-group">
          <!-- <mat-label>Customer Name</mat-label> -->
          <app-custom-select #customSelectRef [fieldData]="{
              'config' : accountConfigOpportunity,
              'isFilter' : true
            }" (selectedAccountIdsChange)="handleApplySelections($event)">
          </app-custom-select>
        </div>
     
    </div>


    <div class="w-p180 mr-10 mb-0" *ngIf="isSOBOpportunity">

        <div class="input-group">
          <!-- <mat-label>Plant Name</mat-label> -->
          <app-field-autocomplete #autocompleteComponent [fieldData]="{
          'config' : plantConfigOpportunity,
          'isFilter' : true
        }" [modelRef]="plantConfigOpportunity.value" (filterApplied)="onPlantRelationFilter(plantConfigOpportunity);">
          </app-field-autocomplete>
        </div>
  
    </div>

    <div class="w-p180 mr-10 mb-0" *ngIf="isSOBContact || isSOBDeals">
    
        <div class="input-group">
          <!-- <mat-label>SOB Account</mat-label> -->
          <app-field-autocomplete #autoComComponent [fieldData]="{
          'config' : sobAccountConfig,
          'isFilter' : true
        }" [modelRef]="sobAccountConfig.value" (filterApplied)="onRelationFilter(sobAccountConfig);">
          </app-field-autocomplete>
        </div>
  
    </div>
    
    <!-- Stages isDeals -->
    <div class="w-p96 mr-10 mb-0" *ngIf="isDeals && selectedLane && selectedLane.laneDTO.id == 'all'">
      <div class="input-group">        
        <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0 app-field-autocomplete selected-highlight" id="inputdefault">
          <mat-select  [panelClass]="'select-panel-class'"  placeholder="{{ stagesLabel }}" #sl6 [(ngModel)]="selectedStages" multiple>
            <div class="select-options">
              <mat-option *ngFor="let bs of defaultStages" [value]="bs.id">{{bs.name}}</mat-option>
            </div>
            <mat-select-trigger>
              {{stagesLabel}} ({{ selectedStages.length }})
            </mat-select-trigger>
            <div class="select-footer">
              <button   mat-button class="btn-basic btn-text-black mr-10" color="primary"
                (click)="selectedStages=[];resetStageFilterFromCache();onApply();sl6.close();">Clear</button>
              <button  class="btn-primary" mat-raised-button color="warn" (click)="onApply();sl6.close();">Apply</button>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    
    <!-- Stages isSOBOpportunity -->
    <div class="w-p96 mr-10 mb-0" *ngIf="isSOBOpportunity && selectedLane && selectedLane.laneDTO.id == 'all'">
      <div class="input-group">
        <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0 app-field-autocomplete selected-highlight" id="inputdefault">
          <mat-select  [panelClass]="'select-panel-class'"  placeholder="{{ stagesLabel }}" #sl6 [(ngModel)]="selectedStages" multiple>
            <div class="select-options">
              <mat-option *ngFor="let bs of defaultOpportunityStages" [value]="bs.id">{{bs.name}}</mat-option>
            </div>
            <mat-select-trigger>
              {{stagesLabel}} ({{ selectedStages.length }})
            </mat-select-trigger>
            <div class="select-footer">
              <button mat-button class="btn-basic btn-text-black mr-10" color="primary" (click)="selectedStages=[];resetStageFilterFromCache();onApply();sl6.close();">Clear</button>
              <button class="btn-primary" mat-raised-button color="warn" (click)="onApply();sl6.close();">Apply</button>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  <!-- Industry isAccount Hunting || SOB -->
  <div class="w-p110 mr-10 mb-0" *ngIf="isAccount || isSOBAccount">
      <div class="input-group">
        <app-custom-multiple-select [label]="industryLabel" 
                    [selectedValues]="selectedIndustries"
                    [availableOptions]="defaultIndustriesSearch" 
                    [searchControl]="searchControlIndustry"
                    (selectionChange)="onIndustrySelectionChange($event)"
                    (searchChange)="filterOptionsIndustry($event)">
        </app-custom-multiple-select>    
      </div>
  </div>

  <!-- Tag Type isAccount -->
  <div class="w-p123 mr-10 mb-0" *ngIf="isAccount">
    <div class="input-group">
      <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0 app-field-autocomplete selected-highlight" id="inputdefault">
        <mat-select  [panelClass]="'select-panel-class'"  placeholder="{{tagTypeLabel}}" #sl5 [(ngModel)]="selectedTagType" multiple>
          <mat-form-field class="mat-input icon"  appearance="outline">
            <i class="ri-search-line"></i>
            <input type = "text" matInput placeholder="Search" [(ngModel)]="searchControlTagType" (keyup) = "filterOptionsTagType($event)">
            
          </mat-form-field>
          <div class="select-options">
            <mat-option *ngFor="let bs of defaultTagTypeSearch" [value]="bs">{{bs}}</mat-option>
          </div>
          <mat-select-trigger>
            {{tagTypeLabel}} ({{ selectedTagType.length }})
          </mat-select-trigger>
          <div class="select-footer">
            <button   mat-button class="btn-basic btn-text-black mr-10" color="primary" (click)="selectedTagType=[];onApply();sl5.close();">Clear</button>
            <button  class="btn-primary" mat-raised-button color="warn" (click)="onApply();sl5.close();">Apply</button>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- Business Vertical isDeals || isSOBOpportunity-->
  <div class="w-p158 mr-10 mb-0" *ngIf="isDeals || isSOBOpportunity">
        <div class="input-group">
          <!-- <mat-label>Business Vertical</mat-label> -->
          <app-custom-multiple-select  [label]="businessVerticalLabel"  
            [selectedValues]="selectedBusinessVertical"
            [availableOptions]="defaultBusinessVerticalSearch" 
            [searchControl]="searchControlBusinessVertical"
            (selectionChange)="onBUSelectionChange($event)"
            (searchChange)="filterOptionsVertical($event)">
          </app-custom-multiple-select>
        </div>
  </div>
  <!-- Deal Type isDeals -->
  <div class="w-p118 mr-10 mb-0" *ngIf="isDeals">
      <div class="input-group">
        <!-- <mat-label>Deal Type</mat-label> -->
        <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0 selected-highlight" id="inputdefault">
          <mat-select  [panelClass]="'select-panel-class'"  placeholder="Deal Type" #sl4 [(ngModel)]="selectedDealTypes" multiple>
            <div class="select-options">
              <mat-option *ngFor="let dt of defaultDealTypes" [value]="dt">{{dt}}</mat-option>
            </div>
            <mat-select-trigger>
              Deal Type ({{ selectedDealTypes.length }})
            </mat-select-trigger>
            <div class="select-footer">
              <button   mat-button
          class="btn-basic btn-text-black mr-10"
          color="primary"
                (click)="selectedDealTypes=[];resetDealTypesInCache();onApply();sl4.close();">Clear</button>
              <button  class="btn-primary" mat-raised-button color="warn" (click)="onApply();sl4.close();">Apply</button>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
  </div>
  <!-- Deal Type isSOBOpportunity -->
  <div class="w-p118 mr-10 mb-0" *ngIf="isSOBOpportunity">
    <div class="input-group">
        <app-custom-multiple-select [label]="dealTypeLabel" 
                    [selectedValues]="selectedDealTypes"
                    [availableOptions]="defaultDealTypesOpportunitySearch" 
                    [searchControl]="searchControlDealType"
                    (selectionChange)="onDealTypeSelectionChange($event)"
                    (searchChange)="filterOptionsDealType($event)">
        </app-custom-multiple-select>
    </div>
  </div>
  <!-- L1 categories isSOBOpportunity-->
  <div class="w-p140 mr-10 mb-0" *ngIf="isSOBOpportunity">
    <div class="input-group">    
      <app-custom-multiple-select [label]="L1CategoriesLabel" 
                      [selectedValues]="selectedL1Categories"
                      [availableOptions]="defaultL1CategoriesListSearch" 
                      [searchControl]="searchControlL1Categories"
                      (selectionChange)="onL1CategoriesSelectionChange($event)"
                      (searchChange)="filterOptionsL1Categories($event)">
      </app-custom-multiple-select>
    </div>
  </div>

  <!-- PO Type isSOBOpportunity -->
  <div class="w-p140 mr-10 mb-0" *ngIf="isSOBOpportunity">
    <div class="input-group">
      <!-- <mat-label>PO Type</mat-label> -->
      <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0 selected-highlight" id="inputdefault">
        <mat-select  [panelClass]="'select-panel-class'"  placeholder="PO Type" #sl4 [(ngModel)]="selectedPoTypes" multiple>
          <div class="select-options">
            <mat-option *ngFor="let dt of defaultPoTypes" [value]="dt">{{dt}}</mat-option>
          </div>
          <div class="select-footer">
            <button   mat-button
          class="btn-basic btn-text-black mr-10"
          color="primary"
              (click)="selectedPoTypes=[];resetPoTypesInCache();onApply();sl4.close();">Clear</button>
            <button  class="btn-primary" mat-raised-button color="warn" (click)="onApply();sl4.close();">Apply</button>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- <mat-slide-toggle *ngIf="isDeals"
    class="example-margin"
    [(ngModel)]="mywatchlist"
    (change)="onToggle($event)">
    My Watchlist
</mat-slide-toggle> -->
  <div class="w-p35 mr-10 mb-0" *ngIf="isDeals">
    <div  (click)="onToggle(mywatchlist)" class="bookmark-ribbon" [ngClass]="{'active':mywatchlist,'':!mywatchlist}">
  <i class="ri-bookmark-fill"   [matTooltip]="Watcher"
  matTooltipClass="new-tooltip" ></i>
   </div>
  </div>






  <!-- isSobVedantaAccount start  -->
   <ng-container *ngIf="isSobVedantaAccount">
    
    <!-- searchControlOwner -->
    <div class="w-p140 mr-10 mb-0">
      <div class="input-group" *ngIf="teams != null">
        <app-custom-multiple-select [label]="designatedASMLabel" 
                    [selectedValues]="selectedUsers"
                    [availableOptions]="userListSearch" 
                    [searchControl]="searchControlOwner"
                    (selectionChange)="onKAMSelectionChange($event)"
                    (searchChange)="filterOptions($event)">
        </app-custom-multiple-select>
      </div>
    </div>

    <!-- searchControlIndustry -->
    <div class="w-p110 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="industryLabel" 
                    [selectedValues]="selectedIndustries"
                    [availableOptions]="defaultIndustriesSearch" 
                    [searchControl]="searchControlIndustry"
                    (selectionChange)="onIndustrySelectionChange($event)"
                    (searchChange)="filterOptionsIndustry($event)">
        </app-custom-multiple-select>    
      </div>
    </div>

    <!-- searchControlRegion -->
    <div class="w-p103 mr-10 mb-0">
      <div fxLayout="column" class="roleadd" *ngIf="teams != null">
        <div class="input-group">
            <app-custom-multiple-select [label]="teamsLabel" 
                    [selectedValues]="selectTeams"
                    [availableOptions]="teamsSearch" 
                    [searchControl]="searchControlRegion"
                    (selectionChange)="onRegionSelectionChange($event)"
                    (searchChange)="filterOptionsTeams($event)">
        </app-custom-multiple-select>
        </div>
      </div>
    </div>

    <!-- searchControlLeadSource -->
    <!-- <div class="w-p135 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="LeadSourceLabel" 
                    [selectedValues]="selectedLeadSource"
                    [availableOptions]="leadSourceAvailable" 
                    [searchControl]="searchControlLeadSource"
                    (selectionChange)="onLeadSourceSelectionChange($event)"
                    (searchChange)="filterOptionsLeadSource($event)">
        </app-custom-multiple-select>    
      </div> 
    </div> -->

    <!-- searchControlCompanyType -->
    <div class="w-p135 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="'Company Type'" 
                    [selectedValues]="selectedCompanyType"
                    [availableOptions]="companyTypeListAvailable" 
                    [searchControl]="searchControlCompanyType"
                    (selectionChange)="onCompanyTypeSelectionChange($event)"
                    (searchChange)="filterOptionsCompanyType($event)">
        </app-custom-multiple-select>    
      </div> 
    </div>

    <!-- Product Interested In -->
    <div class="w-p135 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="'Product Interested In'" 
                    [selectedValues]="selectedProductInterestedIn"
                    [availableOptions]="productInterestedInListAvailable" 
                    [searchControl]="searchControlProductInterestedInList"
                    (selectionChange)="updateSelection('selectedProductInterestedIn', $event)"
                    (searchChange)="filterList($event, 'productInterestedInListAvailable', 'defaultProductInterestedInList')">
        </app-custom-multiple-select>    
      </div> 
    </div>

    <!-- Life Cycle Stage -->
    <div class="w-p135 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="'Life Cycle Stage'" 
                    [selectedValues]="selectedLifeCycleStage"
                    [availableOptions]="lifeCycleStageListAvailable" 
                    [searchControl]="searchControlLifeCycleStage"
                    (selectionChange)="updateSelection('selectedLifeCycleStage', $event)"
                    (searchChange)="filterList($event, 'lifeCycleStageListAvailable', 'defaultLifeCycleStageList')">
        </app-custom-multiple-select>    
      </div> 
    </div>

    <!-- Market -->
    <!-- <div class="w-p135 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="'Market'" 
                    [selectedValues]="selectedMarket"
                    [availableOptions]="marketListAvailable" 
                    [searchControl]="searchControlMarket"
                    (selectionChange)="updateSelection('selectedMarket', $event)"
                    (searchChange)="filterList($event, 'marketListAvailable', 'defaultMarketList')">
        </app-custom-multiple-select>    
      </div> 
    </div> -->

   </ng-container>
  <!-- isSobVedantaAccount end  -->

  <!-- isSobVedantaPlant start  -->
  <ng-container *ngIf="isSobVedantaPlant">
    <!-- searchControlOwner -->
    <div class="w-p140 mr-10 mb-0">
      <div class="input-group" *ngIf="teams != null">
        <app-custom-multiple-select [label]="designatedASMLabel" 
                    [selectedValues]="selectedUsers"
                    [availableOptions]="userListSearch" 
                    [searchControl]="searchControlOwner"
                    (selectionChange)="onKAMSelectionChange($event)"
                    (searchChange)="filterOptions($event)">
        </app-custom-multiple-select>
      </div>
    </div>

    <!-- searchControlIndustry -->
    <div class="w-p150 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="industryLabelPlant" 
                    [selectedValues]="selectedIndustries"
                    [availableOptions]="defaultIndustriesSearch" 
                    [searchControl]="searchControlIndustry"
                    (selectionChange)="onIndustrySelectionChange($event)"
                    (searchChange)="filterOptionsIndustry($event)">
        </app-custom-multiple-select>    
      </div>
    </div>

    <!-- accountConfig -->
    <div class="w-p118 mr-10 mb-0">
      <div class="input-group">
        <app-custom-select #cusComComplete [fieldData]="inputAutoFieldForVedantaAccount" (selectedAccountIdsChange)="handleApplySelections($event)">
        </app-custom-select>
      </div> 
    </div>

    <!-- searchControlRegion -->
    <div class="w-p103 mr-10 mb-0">
      <div fxLayout="column" class="roleadd" *ngIf="teams != null">
        <div class="input-group">
            <app-custom-multiple-select [label]="teamsLabel" 
                    [selectedValues]="selectTeams"
                    [availableOptions]="teamsSearch" 
                    [searchControl]="searchControlRegion"
                    (selectionChange)="onRegionSelectionChange($event)"
                    (searchChange)="filterOptionsTeams($event)">
        </app-custom-multiple-select>
        </div>
      </div>
    </div>

    <!-- searchControlLeadSource -->
    <!-- <div class="w-p135 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="LeadSourceLabel" 
                    [selectedValues]="selectedLeadSource"
                    [availableOptions]="leadSourceAvailable" 
                    [searchControl]="searchControlLeadSource"
                    (selectionChange)="onLeadSourceSelectionChange($event)"
                    (searchChange)="filterOptionsLeadSource($event)">
        </app-custom-multiple-select>    
      </div> 
    </div> -->

    <!-- searchControlCompanyType -->
    <div class="w-p135 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="'Company Type'" 
                    [selectedValues]="selectedCompanyType"
                    [availableOptions]="companyTypeListAvailable" 
                    [searchControl]="searchControlCompanyType"
                    (selectionChange)="onCompanyTypeSelectionChange($event)"
                    (searchChange)="filterOptionsCompanyType($event)">
        </app-custom-multiple-select>    
      </div> 
    </div>

  </ng-container>
  <!-- isSobVedantaPlant end  -->

  <!-- isSobVedantaContact start  -->
  <ng-container *ngIf="isSobVedantaContact">
    
    <!-- accountConfig -->
    <div class="w-p118 mr-10 mb-0">
      <div class="input-group">
        <app-custom-select #cusComComplete [fieldData]="inputAutoFieldForVedantaAccount" (selectedAccountIdsChange)="handleApplySelections($event)">
        </app-custom-select>
      </div> 
    </div>

    <!-- searchControlOwner -->
    <div class="w-p140 mr-10 mb-0">
      <div class="input-group" *ngIf="teams != null">
        <app-custom-multiple-select [label]="designatedASMLabel" 
                    [selectedValues]="selectedUsers"
                    [availableOptions]="userListSearch" 
                    [searchControl]="searchControlOwner"
                    (selectionChange)="onKAMSelectionChange($event)"
                    (searchChange)="filterOptions($event)">
        </app-custom-multiple-select>
      </div>
    </div>

    <!-- searchControlRegion -->
    <div class="w-p103 mr-10 mb-0">
      <div fxLayout="column" class="roleadd" *ngIf="teams != null">
        <div class="input-group">
            <app-custom-multiple-select [label]="teamsLabel" 
                    [selectedValues]="selectTeams"
                    [availableOptions]="teamsSearch" 
                    [searchControl]="searchControlRegion"
                    (selectionChange)="onRegionSelectionChange($event)"
                    (searchChange)="filterOptionsTeams($event)">
        </app-custom-multiple-select>
        </div>
      </div>
    </div>

  </ng-container>
  <!-- isSobVedantaContact end  -->

<!-- isHZLVedantaAccount start  -->
<ng-container *ngIf="isHZLVedantaAccount">
    
  <!-- searchControlOwner -->
  <div class="w-p140 mr-10 mb-0">
    <div class="input-group" *ngIf="teams != null">
      <app-custom-multiple-select [label]="designatedASMLabel" 
                  [selectedValues]="selectedUsers"
                  [availableOptions]="userListSearch" 
                  [searchControl]="searchControlOwner"
                  (selectionChange)="onKAMSelectionChange($event)"
                  (searchChange)="filterOptions($event)">
      </app-custom-multiple-select>
    </div>
  </div>

  <!-- searchControlIndustry -->
  <div class="w-p110 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="industryLabel" 
                  [selectedValues]="selectedIndustries"
                  [availableOptions]="defaultIndustriesSearch" 
                  [searchControl]="searchControlIndustry"
                  (selectionChange)="onIndustrySelectionChange($event)"
                  (searchChange)="filterOptionsIndustry($event)">
      </app-custom-multiple-select>    
    </div>
  </div>

  <!-- searchControlRegion -->
  <div class="w-p103 mr-10 mb-0">
    <div fxLayout="column" class="roleadd" *ngIf="teams != null">
      <div class="input-group">
          <app-custom-multiple-select [label]="teamsLabel" 
                  [selectedValues]="selectTeams"
                  [availableOptions]="teamsSearch" 
                  [searchControl]="searchControlRegion"
                  (selectionChange)="onRegionSelectionChange($event)"
                  (searchChange)="filterOptionsTeams($event)">
      </app-custom-multiple-select>
      </div>
    </div>
  </div>

  <!-- searchControlCompanyType -->
  <div class="w-p135 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="'Company Type'" 
                  [selectedValues]="selectedCompanyType"
                  [availableOptions]="companyTypeListAvailable" 
                  [searchControl]="searchControlCompanyType"
                  (selectionChange)="onCompanyTypeSelectionChange($event)"
                  (searchChange)="filterOptionsCompanyType($event)">
      </app-custom-multiple-select>    
    </div> 
  </div>

  <!-- Product Interested In -->
  <div class="w-p135 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="'Product Interested In'" 
                  [selectedValues]="selectedProductInterestedIn"
                  [availableOptions]="productInterestedInListAvailable" 
                  [searchControl]="searchControlProductInterestedInList"
                  (selectionChange)="updateSelection('selectedProductInterestedIn', $event)"
                  (searchChange)="filterList($event, 'productInterestedInListAvailable', 'defaultProductInterestedInList')">
      </app-custom-multiple-select>    
    </div> 
  </div>

  <!-- Life Cycle Stage -->
  <div class="w-p135 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="'Life Cycle Stage'" 
                  [selectedValues]="selectedLifeCycleStage"
                  [availableOptions]="lifeCycleStageListAvailable" 
                  [searchControl]="searchControlLifeCycleStage"
                  (selectionChange)="updateSelection('selectedLifeCycleStage', $event)"
                  (searchChange)="filterList($event, 'lifeCycleStageListAvailable', 'defaultLifeCycleStageList')">
      </app-custom-multiple-select>    
    </div> 
  </div>
 </ng-container>
<!-- isHZLVedantaAccount end  -->

<!-- isHZLVedantaPlant start  -->
<ng-container *ngIf="isHZLVedantaPlant">
  <!-- searchControlOwner -->
  <div class="w-p140 mr-10 mb-0">
    <div class="input-group" *ngIf="teams != null">
      <app-custom-multiple-select [label]="designatedASMLabel" 
                  [selectedValues]="selectedUsers"
                  [availableOptions]="userListSearch" 
                  [searchControl]="searchControlOwner"
                  (selectionChange)="onKAMSelectionChange($event)"
                  (searchChange)="filterOptions($event)">
      </app-custom-multiple-select>
    </div>
  </div>

  <!-- searchControlIndustry -->
  <div class="w-p150 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="industryLabelPlant" 
                  [selectedValues]="selectedIndustries"
                  [availableOptions]="defaultIndustriesSearch" 
                  [searchControl]="searchControlIndustry"
                  (selectionChange)="onIndustrySelectionChange($event)"
                  (searchChange)="filterOptionsIndustry($event)">
      </app-custom-multiple-select>    
    </div>
  </div>

  <!-- accountConfig -->
  <div class="w-p118 mr-10 mb-0">
    <div class="input-group">
      <app-custom-select #cusComComplete [fieldData]="inputAutoFieldForVedantaAccount" (selectedAccountIdsChange)="handleApplySelections($event)">
      </app-custom-select>
    </div> 
  </div>

  <!-- searchControlRegion -->
  <div class="w-p103 mr-10 mb-0">
    <div fxLayout="column" class="roleadd" *ngIf="teams != null">
      <div class="input-group">
          <app-custom-multiple-select [label]="teamsLabel" 
                  [selectedValues]="selectTeams"
                  [availableOptions]="teamsSearch" 
                  [searchControl]="searchControlRegion"
                  (selectionChange)="onRegionSelectionChange($event)"
                  (searchChange)="filterOptionsTeams($event)">
      </app-custom-multiple-select>
      </div>
    </div>
  </div>

  <!-- searchControlCompanyType -->
  <div class="w-p135 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="'Company Type'" 
                  [selectedValues]="selectedCompanyType"
                  [availableOptions]="companyTypeListAvailable" 
                  [searchControl]="searchControlCompanyType"
                  (selectionChange)="onCompanyTypeSelectionChange($event)"
                  (searchChange)="filterOptionsCompanyType($event)">
      </app-custom-multiple-select>    
    </div> 
  </div>
</ng-container>
<!-- isHZLVedantaPlant end  -->

<!-- isHZLVedantaContact start  -->
<ng-container *ngIf="isHZLVedantaContact">
    
  <!-- accountConfig -->
  <div class="w-p118 mr-10 mb-0">
    <div class="input-group">
      <app-custom-select #cusComComplete [fieldData]="inputAutoFieldForVedantaAccount" (selectedAccountIdsChange)="handleApplySelections($event)">
      </app-custom-select>
    </div> 
  </div>

  <!-- searchControlOwner -->
  <div class="w-p140 mr-10 mb-0">
    <div class="input-group" *ngIf="teams != null">
      <app-custom-multiple-select [label]="designatedASMLabel" 
                  [selectedValues]="selectedUsers"
                  [availableOptions]="userListSearch" 
                  [searchControl]="searchControlOwner"
                  (selectionChange)="onKAMSelectionChange($event)"
                  (searchChange)="filterOptions($event)">
      </app-custom-multiple-select>
    </div>
  </div>

  <!-- searchControlRegion -->
  <div class="w-p103 mr-10 mb-0">
    <div fxLayout="column" class="roleadd" *ngIf="teams != null">
      <div class="input-group">
          <app-custom-multiple-select [label]="teamsLabel" 
                  [selectedValues]="selectTeams"
                  [availableOptions]="teamsSearch" 
                  [searchControl]="searchControlRegion"
                  (selectionChange)="onRegionSelectionChange($event)"
                  (searchChange)="filterOptionsTeams($event)">
      </app-custom-multiple-select>
      </div>
    </div>
  </div>

</ng-container>
<!-- isHZLVedantaContact end  -->

  <!--isSRMSupplier Start-->
   <ng-container *ngIf="isSRMSupplier">
    <div class="w-p140 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="supplierTypeLabel" 
                      [selectedValues]="selectedSupplierType"
                      [availableOptions]="defaultSupplierTypeListSearch" 
                      [searchControl]="searchControlSupplierType"
                      (selectionChange)="getSelectionChangeSupplierType($event)"
                      (searchChange)="getFilterOptionsSupplierType($event)">
          </app-custom-multiple-select>
      </div>
    </div>

    <!-- Supplier Location -->
    <div class="w-p118 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="locationLabel" 
                      [selectedValues]="selectedLocation"
                      [availableOptions]="defaultLocationListSearch" 
                      [searchControl]="searchControlLocation"
                      (selectionChange)="getSelectionChangeLocation($event)"
                      (searchChange)="getFilterOptionsLocation($event)">
          </app-custom-multiple-select>
      </div>
    </div>
    
    <!-- Supplier Brands -->
    <div class="w-p118 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="brandsLabel" 
                      [selectedValues]="selectedBrand"
                      [availableOptions]="defaultBrandListSearch" 
                      [searchControl]="searchControlBrand"
                      (selectionChange)="getSelectionChangeBrand($event)"
                      (searchChange)="getFilterOptionsBrand($event)">
          </app-custom-multiple-select>
      </div>
    </div>
    
    <!-- Supplier L1 Category -->
    <div class="w-p140 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="L1CategoriesLabel" 
                      [selectedValues]="selectedL1Categories"
                      [availableOptions]="defaultL1CategoriesListSearch" 
                      [searchControl]="searchControlL1Categories"
                      (selectionChange)="onL1CategoriesSelectionChange($event)"
                      (searchChange)="filterOptionsL1Categories($event)">
          </app-custom-multiple-select>
      </div>
    </div>

    <!-- Supplier L2 Category -->
    <div class="w-p140 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="L2CategoryLabel" 
                      [selectedValues]="selectedL2Categories"
                      [availableOptions]="defaultL2CategoriesListSearch" 
                      [searchControl]="searchControlL2Categories"
                      (selectionChange)="getSelectionChangeL2Categories($event)"
                      (searchChange)="getFilterOptionsL2Categories($event)">
          </app-custom-multiple-select>
      </div>
    </div>

    <!-- Supplier M & A -->
    <div class="w-p118 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="MALabel" 
                          [selectedValues]="selectedMA"
                          [availableOptions]="defaultMAListSearch" 
                          [searchControl]="searchControlMA"
                          (selectionChange)="getSelectionChangeMA($event)"
                          (searchChange)="getFilterOptionsMA($event)">
          </app-custom-multiple-select>
      </div>
    </div>

    <!-- Supplier Moglix POC -->
    <div class="w-p140 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="MoglixPOCLabel" 
                      [selectedValues]="selectedUsers"
                      [availableOptions]="userListSearch" 
                      [searchControl]="searchControlOwner"
                      (selectionChange)="onKAMSelectionChange($event)"
                      (searchChange)="filterOptions($event)">
          </app-custom-multiple-select>
      </div>
    </div>

    <!-- Supplier Target Panel -->
    <div class="w-p140 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="TargetPanelLabel" 
                      [selectedValues]="selectedTargetPanel"
                      [availableOptions]="defaultTargetpanelListSearch" 
                      [searchControl]="searchControlTargetpanel"
                      (selectionChange)="getSelectionChangeTargetPanel($event)"
                      (searchChange)="getFilterOptionsTargetPanel($event)">
          </app-custom-multiple-select>
      </div>
    </div>

    <!-- Supplier Stages -->
    <div class="w-p118 mr-10 mb-0">
      <div class="input-group">
          <app-custom-multiple-select [label]="stagesLabel" 
                      [selectedValues]="selectedSRMStages"
                      [availableOptions]="defaultSRMStagesSearch" 
                      [searchControl]="''" 
                      (selectionChange)="getSelectionChangeStages($event)"
                      (searchChange)="getFilterOptionsStages($event)">
          </app-custom-multiple-select>
      </div>
    </div>

   </ng-container>
  <!--isSRMSupplier End-->

    <!-- Closing Date isDeals || isSOBOpportunity-->
    <div class="w-p182 mr-10 mb-0" *ngIf="isDeals || isSOBOpportunity">
      <div class="input-group">
          <app-date-picker-custom [selectDefault]="true" [resetDates]="resetDates" [inputData]="inputData" [placeholder]="'Closing Date'"
          (filterApplied)="onApplyDates($event)"></app-date-picker-custom>
      </div>
    </div>
  
    <!-- Created Date -->
    <!-- <div class="w-p182 mr-10 mb-0" *ngIf="!isSRMSupplier">
          <div class="input-group">
            <app-date-picker-custom [selectDefault]="false" [resetDates]="resetDates" [inputData]="inputData" [placeholder]="'Created Date'"
              (filterApplied)="onApplyDates($event)"></app-date-picker-custom>
          </div>
    </div> -->

  <div class="mr-10 mb-0 flex items-center">
    <button (click)="clearAll();" mat-button class="clearAll-text"  color="primary">Clear All</button>
  </div>
  </div>
</section>
