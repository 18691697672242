import { Component, OnInit, Inject, ElementRef, ViewChild, HostBinding, AfterViewInit, AfterContentInit, ChangeDetectorRef, AfterContentChecked, ChangeDetectionStrategy, NgZone, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { KanbanService } from '../../../kanban.service';
import { SettingService } from 'src/app/settings/setting.service';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { COMMA, ENTER, L } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as lodash from 'lodash';
import * as moment from "moment";
import { LaneMoveComponent } from '../../lanes-list/lane-move/lane-move.component';
import { UploadMappingComponent } from 'src/app/settings/upload-mapping/upload-mapping/upload-mapping.component';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { isEmpty } from "lodash"
import { CommentsTabComponent } from '../card-details/comments-tab/comments-tab.component';
import { LaneTaskUsersTabComponent } from '../card-details/lane-task-users-tab/lane-task-users-tab.component';
import { LaneTaskDocumentsTabComponent } from '../card-details/lane-task-documents-tab/lane-task-documents-tab.component';
import { HistoryTabComponent } from '../card-details/history-tab/history-tab.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { LaneMovementComponent } from '../lane-movement/lane-movement.component';
import { NavigationService } from '../../navigation-service.service';
import 'quill-mention';
import { QuillEditorComponent } from 'ngx-quill'
import {
  animate, state, style, transition, trigger
} from '@angular/animations';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ContactInfoComponent } from '../contact-info/contact-info.component';
import { AccountInfoComponent } from '../account-info/account-info.component';
import { CreateLaneTaskComponent } from '../create-lane-task/create-lane-task.component';
import { Constants } from 'src/app/Constants';
import { MatSelect } from '@angular/material';
import { RfqWonConfirmationComponent } from '../rfq-won-confirmation/rfq-won-confirmation.component';

import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatSnackBar } from '@angular/material';
import { FormArrayExampleComponent } from '../../../form-array-example/form-array-example.component';
import { FieldAutocompleteComponent } from '../field-autocomplete/field-autocomplete.component';
import { LabelConstants } from 'src/app/LabelConstants';



export interface PeriodicElement {
  name: string;
  reqName: string;
  totalReq: string;
  approved?: string;
  notApproved: string;
  followupRequested: string;
  pending: string;
  smeOwner: string;
  position: number;
  weight: number;
  symbol: string;
  partial: number;
}


@Component({
  selector: 'app-lane-card-details',
  templateUrl: './lane-card-details.component.html',
  styleUrls: ['./lane-card-details.component.scss']
})
export class LaneCardDetailsComponent implements OnInit {
  currentUser: string;
  laneTaskIdByUrl: any;
  optionalLanes = ["C0", "C1"];
  laneTaskIDDetail: any;
  currentSelectedLane: any;
  previouslySelected: any;
  laneArrayKeys: any;
  allDataLaneArrayKeys: any;
  boardId: string;
  accountId: any;
  isRepeatedRFQFrequency: boolean = false;
  isDealTypeNewCategories: boolean = false;
  isNewCategories: boolean = false;
  subCategoriesOfNewCategories: any;
  customFieldOptional = false;
  errorField: any = {};
  saveError: any = {};
  formDetails: FormGroup;
  currentTabId: string;
  leadName: string;
  laneTaskConfig: any;
  currentSelectedLaneId: any;
  selectedBoardLane: any;
  boardLane: any;
  isEditableAccess: any;
  selectedLanePosition: any;
  selectedlane: any;
  breakpoint: any;
  isMasterAdmin: boolean = false;
  _: any = lodash;
  content = ''
  matContent = ''
  console = console;
  contacts: any;
  laneId: any;
  contactBoardLane: any;
  lanesWithSubdivisionLanes: any;
  subdivisionLanes: any[] = [];
  laneTaskConfigs: any;
  isLoading: boolean = true;
  contactDetails: any[] = [];
  pageIndex = -1;
  pageSize = 200;
  pageFrom = 0;
  plantNameId: any;
  accountNameId: any;
  boardIdForCRMContacts: string = Constants.boardIdForCRMContacts;
  boardIdForSobContacts: string = Constants.boardIdForSobContacts;
  boardIdForSobPlant: string = Constants.boardIdForSobPlant;
  boardIdForCRMDeals: string = Constants.boardIdForCRMDeals;
  boardIdForCRMAccount: string = Constants.boardIdForCRMAccount;
  boardIdForSobOpportunity: string = Constants.boardIdForSobOpportunity;
  boardIdForSobAccount = Constants.boardIdForSobAccount;
  boardIdForVedantaAccount=Constants.boardIdForVedantaAccount;
  serviceAbilitySubLaneId = Constants.serviceAbilitySubLaneId;
  isCountryIndia: boolean = false;
  @ViewChild('sl2') sl2: MatSelect;
  selectedPlantIds: string[] = [];
  searchControl = '';
  isW1Checked: boolean = false;
  customerId: string = '';
  plantId: string = '';
  isRfqWonConfirmation: boolean = false;
  rfqDTOs: any[] = [];
  rfqCapturedText: any = '';
  disableW1checkBox: boolean = false;
  m1: any;
  m2: any;
  m3: any;
  m4: any;
  m5: any;
  m6: any;
  projectionData = [];
  projectionTimeline: any;
  isHidden = true;
  industryList: any = [];
  businessVerticalList: any = [];
  regionTeamList: any = [];
  dealTypeList: any = [];
  leadSourceList: any = [];
  newCategoryList: any = [];
  roleNameList: any = [];
  roleTypeList: any = [];
  industryListSearch: any = [];
  businessVerticalListSearch: any = [];
  regionTeamListSearch: any = [];
  dealTypeListSearch: any = [];
  leadSourceListSearch: any = [];
  newCategoryListSearch: any = [];
  l2CategoryListSearch: any = [];
  roleNameListSearch: any = [];
  roleTypeListSearch: any = [];
  searchControlIndustry: string = "";
  searchControlBusinessVertical = "";
  searchControlRegionTeam = "";
  searchControlDealType = "";
  searchControlLeadSource = "";
  searchControlL1Category = "";
  searchControlL2Category = "";
  searchControlRoleName = "";
  searchControlRoleType = "";
  dealType = '';
  kamHandoverLaneId: string = Constants.kamHandoverLaneId;
  subdivisionLaneConfig: any[] = [];
  selectedTabIndex: any;
  currentSubLaneId: any;
  remainingLaneConfig: any[];
  remainingFinalConfigList: any;
  serviceabilityTableDataSource: any;
  sobUserId: string;
  plants: any;
  plantOptions: any;
  selectedPlantsForMeeting: any;
  searchControlPlants: "";
  plantTableDataSource: any;
  strengthLevel: string;
  percentage: number = 0;
  minDateForNextAction: Date = new Date();
  accountContactList: any = [];
  accountContactListSearch: any = [];
  nextActionDate: any = null;
  laneTaskIdDetails: any;
  kamHandoverAllowed = false;
  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;
  modules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor
        insertItem(item)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user')
      },
      source: (searchTerm, renderList) => {
        const values = ["Akash Yadav"];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm)
        } else {
          const matches = []

          values.forEach((entry) => {
            // if (entry.userName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
            //   matches.push(entry)
            // }
          })
          renderList(matches, searchTerm)
        }
      }
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link', 'image', 'video']                         // link and image, video
    ]
  }
  restOfColumns: string[] = ['GMV_JFM23', 'GMV_AMJ23', 'GM_JFM23', 'GM_AMJ23',];
  dataColumns: string[] = ['category', 'categoryAnnualSpend', 'GMAnalysis', 'SOB', 'GMV_JFM23', 'GMV_AMJ23', 'GM_JFM23', 'GM_AMJ23',];
  displayedColumnsForFinancials: string[] = ['Years', 'Sales', 'CM', 'OTIF', 'Rejection', 'KeyConcernAreas'];
  dataSource = null;
  spans = [];
  data = [
    {
      "categoryName": "Electricals",
      "categoryAnnualSpend": '—',
      "GMAnalysis": '—',
      "SOB": '—',
      "GMV_JFM23": '—',
      "GMV_AMJ23": '—',
      "GM_JFM23": '—',
      "GM_AMJ23": '—',
    },
    {
      "categoryName": "Safety",
      "categoryAnnualSpend": '—',
      "GMAnalysis": '—',
      "SOB": '—',
      "GMV_JFM23": '—',
      "GMV_AMJ23": '—',
      "GM_JFM23": '—',
      "GM_AMJ23": '—',
    },
    {
      "categoryName": "Pumps & Motors",
      "categoryAnnualSpend": '—',
      "GMAnalysis": '—',
      "SOB": '—',
      "GMV_JFM23": '—',
      "GMV_AMJ23": '—',
      "GM_JFM23": '—',
      "GM_AMJ23": '—',
    },
    {
      "categoryName": "LED & Lighting",
      "categoryAnnualSpend": '—',
      "GMAnalysis": '—',
      "SOB": '—',
      "GMV_JFM23": '—',
      "GMV_AMJ23": '—',
      "GM_JFM23": '—',
      "GM_AMJ23": '—',
    }
  ]
  file: any;
  isProgressVisible = false;
  financialsTableDataSource: Array<any> = [];
  financialsDisplayColumns: Array<string> = ['Years', 'Sales', 'CM', 'OTIF', 'Rejection', 'KeyConcernAreas'];
  moglixBusinessHistoryTableDataSource: Array<any> = [];
  moglixBusinessHistoryDisplayColumns: Array<string> = ['Years', 'Sales', 'CM', 'OTIF', 'Rejection', 'KeyConcernAreas'];
  @ViewChildren(FormArrayExampleComponent) formArrayComponent!: QueryList<FormArrayExampleComponent>;
  @ViewChildren(FieldAutocompleteComponent) fieldAutoComponent!: QueryList<FieldAutocompleteComponent>;
  fileForFields: any = {};
  tabActive = false;

  columnWidth = '33.33%';

  accountContactCeoList: any = [];
  accountContactCeoListSearch: any = [];
  selectedCEOIds: any = [];
  userList: any = [];
  userListSearch: any = [];

  constructor(private _snackBar: MatSnackBar, private formBuilder: FormBuilder, public toasterService: ToastrService, public dialog: MatDialog, private cd: ChangeDetectorRef, private activatedRoute: ActivatedRoute, public router: Router, public settingServ: SettingService, public _kService: KanbanService,
    private pagesService: PagesService, private authService: AuthService, private _ngZone: NgZone, public navigation: NavigationService) {

  }
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  ngOnInit() {
    this.dealType = ''
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if (getSessionStorage && getSessionStorage['role']) {
      this.currentUser = getSessionStorage['role']['userName'];
      this.isMasterAdmin = getSessionStorage['role']['roleName'] === "MASTER_ADMIN";
    }
    this.sobUserId = localStorage.getItem('sobUserId');
    this.activatedRoute.params.subscribe(params => {
      this.laneTaskIdByUrl = params.id;
      this.getLaneDetailsById();
      this.fetchTeams();
    });
    this.cacheSpan('fldName', d => d.fldName, this.data);
    this.dataSource = this.data;


  }
  ngAfterViewChecked() {
    this.cd.detectChanges();
  }
  columnWidthCheck() {

    if (this.boardId === Constants.boardIdForCRMDeals || this.boardId === Constants.boardIdForSobOpportunity || this.boardId === Constants.boardIdForSrmSupplier) {
      this.columnWidth = '33.33%';
    }
    else {
      this.columnWidth = '50%';
    }

  }
  getLaneTaskIdDetails() {
    return this.laneTaskIdDetails;
  }
  setLaneTaskIdDetails(laneTaskIdDetails: any) {
    this.laneTaskIdDetails = laneTaskIdDetails;
  }
  getLaneTaskConfig() {
    return this.laneTaskConfigs
  }
  setLaneTaskConfig(laneTaskConfigValue: any) {
    this.laneTaskConfigs = laneTaskConfigValue;
  }

  shouldDisplayLabel(formItem): boolean {
    const field = formItem.config.field;
    switch (field) {
      case 'repeat-rf-qs-frequency':
        return this.isRepeatedRFQFrequency;
      case 'new-categories':
        return this.isDealTypeNewCategories;
      case 'new-category-list':
        return this.isNewCategories;
      case 'event-name':
      case 'event-date':
        return formItem.config.optional === true;
      default:
        return field !== 'repeat-rf-qs-frequency' &&
          field !== 'new-categories' &&
          field !== 'new-category-list' &&
          field !== 'event-name' &&
          field !== 'event-date';
    }
  }
  isRequired(formItem): boolean {
    return formItem.config.optional && this.checkValidity(formItem.config);
  }

  shouldDisplayLi(formItem): boolean {
    const field = formItem.config.field;
    const optional = formItem.config.optional;
    const accessibleLanes = formItem.config.accessibleLanes;
    const currentLaneIncluded = !accessibleLanes || accessibleLanes.includes(this.currentSelectedLaneId);

    if (field === 'event-date' || field === 'event-name') {
      return optional;
    }

    return currentLaneIncluded;
  }

  getColumnWidth(formItem): string {
    const type = formItem.config.type;
    return (type === 'table' || type === 'form') ? '100%' : this.columnWidth;
  }

  checkValidity(tmp) {
    if (this.customFieldOptional && (tmp.field == "realistic-deal-size-cr" || tmp.field == "business-potential-in-cr" || tmp.field == "problem-statement" || tmp.field == "contact")) {
      if (tmp.field === "contact" && this.laneId !== Constants.C0) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  formatPaymentType(value) {
    return this._.startCase(value);
  }
  getContactBoardLane() {
    let contactBoardId;
    if (this.boardId === Constants.boardIdForCRMDeals) {
      contactBoardId = Constants.boardIdForCRMContacts;
    } else if (this.boardId === Constants.boardIdForSobOpportunity) {
      contactBoardId = Constants.boardIdForSobContacts;
    }
    this.fetchConactBoardlane(contactBoardId);
  }
  changeStatus(event, currentSelected) {
    //   var that = this;
    //  setTimeout(function() {
    //   console.log(event.source)
    //   that.currentSelectedLane = that.previouslySelected
    //   console.log("Event, ", that.currentSelectedLane, event.source);
    //  }, 2000);

    let obj = {
      "id": this.laneTaskIDDetail.id,
      "laneId": this.currentTabId,
      "toLaneID": event.value
    }
    this._kService.assignLaneTaskMovement(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.currentTabId = event.value
        //this.currentSelectedLane = fromLane;
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 6000,
          data: "Data Saved"
        });

        // this.toasterService.success("Data Saved");
        // this.getLaneDetailsById(true);
      }
    }, (error) => {
      if (error) {
        this.currentTabId = event.value;
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Required Fields are not present"
        });

        // this.toasterService.error("Required Fields are not present");
        //  this.currentSelectedLane = fromLane;
      }
    });

  }
  getOptionList(list, field) {
    if (!list) {
      return [];
    }
    if (field !== 'revenue-range') {
      list.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    }
    return list;
  }
  updateLaneTaskStatus(toLane, fromLane) {
    let toLaneId = toLane.laneId;
    let toLaneConfig = toLane.config;

    let isValid = false;
    for (let i = 0; i < toLaneConfig.length; i++) {
      if (i > 0 && !isValid) {
        break;
      }
      let tmpConf = toLaneConfig[i];
      if (tmpConf && tmpConf.optional) {
        let dataConf = this.laneArrayKeys.filter((te) => {
          return tmpConf.field == te.subKeys;
        })
        if (dataConf && dataConf.length && dataConf[0].config.value && dataConf[0].config.value.toString().trim() != "") {
          isValid = true
        } else {
          this.errorField = tmpConf;
        }
      }
      console.log(this.errorField);
      if (isValid) {

      } else {
        this.currentTabId = fromLane.id;
        if (this.errorField) {
          this._snackBar.openFromComponent(ErrorToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'error'],
            duration: 6000,
            data: `Required Field ${this.errorField.displayName}`
          });

          // this.toasterService.error(`Required Field ${this.errorField.displayName} is epmty!`);
        } else {
          this._snackBar.openFromComponent(ErrorToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'error'],
            duration: 6000,
            data: "Required Fields are not present"
          });

          // this.toasterService.error("Required Fields are not present");
        }
      }

      //  updateData.push(this.laneArrayKeys[i].config);

    }
    console.log("To Lane :: " + toLane)
  }
  onResize(event) {
    //this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
    this.setBreakPoint();
  }
  setBreakPoint() {
    if ((window.innerWidth <= 400)) {
      this.breakpoint = 3
    } else if ((window.innerWidth > 400) && (window.innerWidth <= 768)) {
      this.breakpoint = 3
    } else if ((window.innerWidth > 768) && (window.innerWidth <= 1390)) {
      this.breakpoint = 3;
    } else if ((window.innerWidth > 1390)) {
      this.breakpoint = 3;
    }
  }
  validateEmail(formItem) {
    if (!formItem.value.toString()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      formItem.error = true;
    } else {
      formItem.error = false;
    }
    return formItem;
  };
  valideateWithDebounce(formItem, allowedLength) {
    this._.debounce(this.validateLength(formItem, allowedLength), 100);
  }
  validateLength(formItem, allowedLength) {
    if (formItem.config.value == null || formItem.config.value.toString().trim().length != allowedLength) {
      formItem.config.error = true;
    } else {
      formItem.config.error = false;
    }
  }
  formatDisplayDate(date) {
    if (!date) {
      return "";
    }
    let formatDate = new Date(date);
    return formatDate.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
  }
  validateLaneTask(laneTask) {
    let kp = [];
    let error = false;
    let errorMessage = null;
    let name = "Test " + laneTask.name;
    let currentFieldLaneMandatory = this.optionalLanes.indexOf(this.laneTaskIDDetail.lane.displayName) != -1;
    this.customFieldOptional = currentFieldLaneMandatory;

    this.nextActionDate = this.laneTaskIDDetail.nextActionDate;
    if (this.boardId === this.boardIdForCRMDeals && !Constants.nonActiveDealIds.includes(this.laneTaskIDDetail.lane.id)) {
      if (this.nextActionDate != undefined || this.nextActionDate != null) {
        const inputDateObj = new Date(this.nextActionDate);
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1); // Subtract one day
        if (inputDateObj < currentDate) {
          this.showActionItems();
          return {
            "message": 'Next "Action Date" is less than the current date',
            "isValid": false
          };
        }
      } else {
        return {
          "message": 'Update the "Next Action Item" first to proceed further',
          "isValid": false
        };
      }
    }
    this.console.log("this.laneArrayKeys", this.laneArrayKeys)
    for (let i = 0; i < this.laneArrayKeys.length; i++) {
      let tmp = this.laneArrayKeys[i].config;

      tmp.error = null;
      if (tmp.display && (!tmp.accessibleLanes || tmp.accessibleLanes.includes(this.laneTaskIdByUrl))) {

        if (tmp.optional && tmp.type == "string") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "number") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "multiSelect") {
          if (tmp.values == null || tmp.values.length < 1) {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.type == "currency") {
          if (tmp.optional && this.checkValidity(tmp) && (!tmp.value || tmp.value.toString().trim() == "")) {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
          if (tmp.field == "realistic-deal-size-cr" && tmp.value && this.boardId !== this.boardIdForSobOpportunity) {
            let ind = this.laneArrayKeys.findIndex((element) => {
              return element.config.field == "business-potential-in-cr";
            })
            if (ind != -1) {
              let totalPotential = this.laneArrayKeys[ind].config
              if (totalPotential.value) {
                let bussPotential = parseFloat(totalPotential.value);
                let dealSize = parseFloat(tmp.value);
                if (dealSize > bussPotential) {
                  tmp.error = tmp.displayName + " : can not be greater than " + totalPotential.displayName;
                }
              }
            }
          }
        }
        else if (tmp.optional && tmp.type == "textarea") {
          if (this.checkValidity(tmp) && (!tmp.value || tmp.value.toString().trim() == "")) {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "belongsTo") {
          if (tmp.field == "contact" && (this.laneTaskIDDetail.lane.id != Constants.C0) && (this.laneTaskIDDetail.lane.id != Constants.S0)) {
            if (tmp.optional && tmp.values.length < 1) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          }
          else if (tmp.field == "plant" && this.boardId == Constants.boardIdForSobContacts) {
            if (!tmp.values || (tmp.values && tmp.values.length === 0)) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          }
          else if (tmp.field == "plant-name" && this.boardId == Constants.boardIdForSobOpportunity) {
            if (!tmp.values || (tmp.values && tmp.values.length === 0)) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          }
          else if (tmp.field == "add-ceo" && (this.boardId == Constants.boardIdForSobContacts || this.boardId == Constants.boardIdForCRMContacts)) {
            if (!tmp.values || (tmp.values && tmp.values.length === 0)) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          }
          else if (tmp.field == "private-owner" && (this.boardId == Constants.boardIdForSobContacts || this.boardId == Constants.boardIdForCRMContacts)) {
            if (!tmp.values || (tmp.values && tmp.values.length === 0)) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          }
          else if (tmp.key == "userTeam") {
            if (!tmp.value || tmp.value.toString().trim() == "") {
              tmp.error = tmp.displayName + ' :  Required';
              error = true;
            }
          }
          if (this.fieldAutoComponent) {
            this.fieldAutoComponent.some(child => {
              if (child.fieldData.config.field == tmp.field) {
                let valid = child.validate();
                console.log('valid check for field', child.fieldData.config.displayName, valid);
                if (!valid) {
                  tmp.error = child.fieldData.config.displayName + ' : Required';
                  error = true;
                  return true;
                }
              }
            });
          }
        }
        else if (tmp.field == "closing-date" && ((this.boardId == Constants.boardIdForCRMDeals && !Constants.nonActiveDealIds.includes(this.laneTaskIDDetail.lane.id)) ||
          (this.boardId == Constants.boardIdForSobOpportunity && !Constants.nonActiveOppIds.includes(this.laneTaskIDDetail.lane.id)))) {
          if (!currentFieldLaneMandatory && (!tmp.value || tmp.value.toString().trim() == "")) {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          } else {
            let date = new Date(tmp.value.toString());
            if (new Date(date.toDateString()) < new Date(new Date().toDateString())) {
              tmp.error = tmp.displayName + ' : can not be older than today';
              error = true;
            }
          }
        }
        else if (tmp.optional && tmp.type == "select") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "phone") {
          const phoneNumber = tmp.value.toString().trim();
          var pattern = /^[0-9]+$/;
          const commonErrorMessage = "Phone number should be numeric.";
          const indiaErrorMessage = "Please enter 10 digit mobile number.";
          if (this.boardId === this.boardIdForCRMContacts) {
            if (phoneNumber.length > 0 && !phoneNumber.match(pattern)) {
              tmp.error = commonErrorMessage;
              error = true;
            }
            else if (this.isCountryIndia && phoneNumber.length !== 10) {
              tmp.error = indiaErrorMessage;
              error = true;
            }
          }
          else {
            if (phoneNumber.length > 0 && !phoneNumber.match(pattern)) {
              tmp.error = commonErrorMessage;
              error = true;
            }
          }
        }
        else if (tmp.optional && tmp.type == "email") {
          if (!tmp.value || tmp.value.toString().trim() == "" || this.validateEmail(tmp)['error']) {
            tmp.error = "The email address you entered is not in the correct format.";
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "date") {
          if (tmp.field == "closing-date") {
            if ((this.boardId == Constants.boardIdForCRMDeals && !Constants.nonActiveDealIds.includes(this.laneTaskIDDetail.lane.id)) ||
              (this.boardId == Constants.boardIdForSobOpportunity && !Constants.nonActiveOppIds.includes(this.laneTaskIDDetail.lane.id)) ||
              (this.boardId == Constants.boardIdForCRMContacts || this.boardId == Constants.boardIdForCRMAccount)) {
              if (!currentFieldLaneMandatory && (!tmp.value || tmp.value.toString().trim() == "")) {
                tmp.error = tmp.displayName + ' :  Required';
                error = true;
              } else {
                let date = new Date(tmp.value.toString());
                if (new Date(date.toDateString()) < new Date(new Date().toDateString())) {
                  tmp.error = tmp.displayName + ' : can not be older than today';
                  error = true;
                }
              }
            }
          }
          else if (tmp.field !== 'closing-date') {
            if (!tmp.value || tmp.value.toString().trim() == "") {
              tmp.error = tmp.displayName + ' :  Required';
              error = true;
            } else {
              tmp.error = '';
            }
          }
        }
        else if (tmp.optional && tmp.type == "form") {
          if (this.formArrayComponent) {
            this.formArrayComponent.forEach(child => {
              const validForm = child.onSubmit();
              if (validForm.trim() != '') {
                tmp.error = validForm
                error = true;
                return;
              }
            });
          }
        }

        if (tmp.nameField) {
          name = tmp.value;
        }
        if (tmp.error) {
          if (!errorMessage) {
            errorMessage = tmp.error;
          }
          error = true;
        }
        kp.push(tmp);
      }
    }
    if (error) {
      this.laneTaskConfig = kp;
      return {
        "message": errorMessage,
        "isValid": false
      };
    } else {
      return {
        "message": "",
        "isValid": true
      };
    }
  }
  onBlur(event: FocusEvent, formItem: any) {
    let inputElement = event.target as HTMLInputElement;
    let inputValue = parseFloat(inputElement.value);
    if (inputValue > 100) {
      inputElement.value = '100';
    } else if (inputValue < 0) {
      inputElement.value = '0';
    }
    formItem.config.value = inputElement.value;
    this.console.log("formItem.config.value", formItem.config.value);
  }
  onSelectOption(formItem: any, config: any) {
    this.console.log("onSelectOption", formItem);
    config.error = null;
    const fieldName = config.field;

    if (config.field === 'country') {
      this.isCountryIndia = config.value === 'India';
      const eventNameElement = this.laneArrayKeys.find(element => element.subKeys === 'mobile-number');
      if (eventNameElement) {
        eventNameElement.config.value = '';
      }
    }

    if (config.field === "role" && (this.boardId == this.boardIdForSobContacts || this.boardId == this.boardIdForCRMContacts)) {
      this.selectedUsers = [];
      this.selectedCEOIds = [];
      this._kService.getRoleTypeByRoleName(config.value).subscribe(res => {
        if (res && res['result']) {
          this.roleTypeList = res['result'];
          this.roleTypeListSearch = this.roleTypeList;
        }
        this.laneArrayKeys.forEach(element => {
          if (element.subKeys === 'role-type') {
            element.config.value = '';
          }
          if (element.subKeys === 'add-ceo') {
            element.config.values = null;
            element.config.optional = false;
          }
          if (element.subKeys === 'private-owner') {
            element.config.values = null;
            element.config.optional = false;
          }
        });
      });
    }

    if (config.field === 'role-type') {
      const isEA = config.value === 'EA';
      const isCEO = config.value === 'CEO';

      this.laneArrayKeys.forEach(element => {
        this.setOptionalConfigWithValues(element, isCEO, isEA);
      });
      if (isEA || isCEO) {
        this.openAddCEOSelect();
        this.loadUsers();
      }
    }

    if (config.field === 'lead-source') {
      const isEvent = config.value === 'Event';
      const eventNameElement = this.laneArrayKeys.find(element => element.subKeys === 'event-name');
      const eventDateElement = this.laneArrayKeys.find(element => element.subKeys === 'event-date');

      if (eventNameElement) {
        eventNameElement.config.optional = isEvent;
      }
      if (eventDateElement) {
        eventDateElement.config.optional = isEvent;
      }
    }

    if (config.field === 'm-a') {
      const isEvent = (config.value).toLowerCase() === 'yes';
      const revenueRangeElement = this.laneArrayKeys.find(element => element.subKeys === 'revenue-range');
      const ownerElement = this.laneArrayKeys.find(element => element.subKeys === 'owner');

      if (revenueRangeElement) {
        revenueRangeElement.config.optional = isEvent;
      }
      if (ownerElement) {
        ownerElement.config.optional = isEvent;
      }
    }

    if (this.boardId === this.boardIdForSobOpportunity) {
      switch (fieldName) {
        case 'po-type':
          this.isRepeatedRFQFrequency = config.value === 'Repeat RFQ';
          if (this.isRepeatedRFQFrequency) {
            const repeatRfqFrequencyElement = this.laneArrayKeys.find((element) => element.subKeys === 'repeat-rf-qs-frequency');
            if (repeatRfqFrequencyElement) {
              repeatRfqFrequencyElement.config.value = null;
              repeatRfqFrequencyElement.config.optional = true;
            }
          }
          else {
            const repeatRfqFrequencyElement = this.laneArrayKeys.find((element) => element.subKeys === 'repeat-rf-qs-frequency');
            if (repeatRfqFrequencyElement) {
              repeatRfqFrequencyElement.config.value = null;
              repeatRfqFrequencyElement.config.optional = false;
            }
          }
          break;
        case 'deal-type':
          this.isDealTypeNewCategories = config.value === 'New category';

          if (this.isDealTypeNewCategories) {
            const newCategoriesElement = this.laneArrayKeys.find((element) => element.subKeys === 'new-categories');
            if (newCategoriesElement) {
              newCategoriesElement.config.value = null;
              newCategoriesElement.config.optional = true;
            }
            const newCategoryListElement = this.laneArrayKeys.find((element) => element.subKeys === 'new-category-list');
            if (newCategoryListElement) {
              this.isNewCategories = false;
              newCategoryListElement.config.values = null;
              newCategoryListElement.config.optional = false;
            }
          }
          else {
            for (let index = 0; index < this.laneArrayKeys.length; index++) {
              const element = this.laneArrayKeys[index];
              if (element.subKeys === 'new-categories') {
                element.config.value = null;
                element.config.optional = false;
              }
              if (element.subKeys === 'new-category-list') {
                this.isNewCategories = false;
                element.config.values = null;
                element.config.optional = false;
              }
            }
          }
          break;
        case 'new-categories':
          let newCategoryValue = config.value;
          this.isNewCategories = true;
          if (newCategoryValue) {
            const elementToUpdate = this.laneArrayKeys.find((element) => element.subKeys === 'new-category-list');
            if (elementToUpdate) {
              elementToUpdate.config.values = null;
              elementToUpdate.config.optional = true;
            }

            this._kService.getL2Categories(newCategoryValue).subscribe(category => {
              this.subCategoriesOfNewCategories = category;
            });
          }
        default:
          break;
      }
    }
  }
  saveLaneTask() {
    if (this.boardId === this.boardIdForCRMDeals && this.isW1Checked && !this.disableW1checkBox) {
      if (!this.plantId) {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Please add Plant ID to proceed"
        });

        // this.toasterService.error("Please add Plant ID to proceed");
      }
      else if (!this.customerId) {


        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Please add Customer ID to proceed"
        });



        // this.toasterService.error("Please add Customer ID to proceed");

      } else {
        this.rfqWonConfirmation()
      }
    } else {
      this.callSaveLaneTask();
    }
  }
  rfqWonConfirmation() {
    const dialogData = {
      plantId: this.plantId,
      customerId: this.customerId,
      stage: this.laneTaskIDDetail.lane.displayName,
    };
    const dialogRef = this.dialog.open(RfqWonConfirmationComponent, {
      disableClose: false,
      width: '600px',
      panelClass: 'rfq-confirmation-panel-class',
      data: dialogData,
      height: '368px',

    });
    dialogRef.afterClosed().subscribe(result => {
      this.isRfqWonConfirmation = result;
      if (!result) {
        // this.plantId = null;
        // this.customerId = null;
        // this.rfqDTOs=[];
        // this.isW1Checked=false;
      } else {
        this.rfqDTOs = [{ "level": "W1", "stage": this.laneTaskIDDetail.lane.displayName, "plantId": this.plantId, "accountId": this.customerId, "creationDate": new Date().getTime() }]
        this.callSaveLaneTask();
      }
    });
    return this.isRfqWonConfirmation;
  }
  shouldShowUlElement(): boolean {
    return this.isW1Checked;
  }
  private callSaveLaneTask() {
    let isValidResp = this.validateLaneTask(this.laneTaskIDDetail);
    if (!isValidResp || !isValidResp['isValid']) {
      this.saveError = isValidResp;
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: isValidResp.message
      });
      // this.toasterService.error(isValidResp.message);

      return;
    }
    let updateData = [];
    let name = this.laneTaskIDDetail.name;
    for (let i = 0; i < this.laneArrayKeys.length; i++) {
      updateData.push(this.laneArrayKeys[i].config);
      if (this.laneArrayKeys[i].config.nameField) {
        name = this.laneArrayKeys[i].config.value;
      }
    }

    for (let index = 0; index < this.remainingFinalConfigList.length; index++) {
      const element = this.remainingFinalConfigList[index];
      updateData.push(element);
    }

    let projection: any[] = this.laneTaskIDDetail.projection;
    if (this.laneTaskIDDetail.projection) {
      projection[0].value = this.m1;
      projection[1].value = this.m2;
      projection[2].value = this.m3;
      projection[3].value = this.m4;
      projection[4].value = this.m5
      projection[5].value = this.m6
    }


    let updateTaskReqObj = {
      "id": this.laneTaskIDDetail.id,
      "name": name,
      "data": updateData,
      "laneId": this.laneTaskIDDetail.lane.id,
      //"projection":projection
    };
    if (this.currentSubLaneId) {
      updateTaskReqObj['currentSubLaneId'] = this.currentSubLaneId;
    }
    if (this.rfqDTOs && this.rfqDTOs.length > 0) {
      updateTaskReqObj['rfqDto'] = this.rfqDTOs;
    }
    this._kService.updateLaneTask(updateTaskReqObj).subscribe(res => {
      if (res['status'] == 201) {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 6000,
          data: "Data Saved Successfully"
        });
        // this.toasterService.success("Data Saved Successfully");

        this.getLaneDetailsById();
      }
    });
  }
  showLaneTaskMove(toLane) {
    const dialogRef = this.dialog.open(LaneMovementComponent, {
      disableClose: false,
      width: '600px',
      panelClass: 'laneMovement-panel-class',

      height: '368px',
      data: {
        'laneTask': this.laneTaskIDDetail,
        'toLane': toLane
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //  console.log(result)
      if (result && result.id) {
        // this.previouslySelected = {
        //   ...this.laneTaskIDDetail.lane
        // }
        // result.lane = toLane;
        // this.laneTaskIDDetail = result;
        // this.currentSelectedLane = toLane;
        // this.currentSelectedLaneId = toLane.id;
        // let tmp = toLane.id;
        // this.currentTabId = tmp +'';
        this.getLaneDetailsById();
      }

    });
  }
  showComments() {
    const dialogRef = this.dialog.open(CommentsTabComponent, {
      disableClose: false,
      width: '55%',
      height: '100%',
      position: { bottom: '0px', right: '0px' },
      data: {
        laneTask: this.laneTaskIDDetail,
        userList: [],
        type: "comments",
        title: "Notes"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)

    });
  }
  showActionItems() {
    const dialogRef = this.dialog.open(CommentsTabComponent, {
      disableClose: false,
      width: '40%',
      height: '100%',
      position: { bottom: '0px', right: '0px' },
      data: {
        laneTask: this.laneTaskIDDetail,
        userList: [],
        type: "action-item",
        title: "Action Items"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      //window.location.reload();
      this._kService.detailsByLaneId(this.laneTaskIdByUrl).subscribe(res => {
        let laneTaskIDDetail = res['result'];
        this.laneTaskIDDetail.nextActionDate = laneTaskIDDetail.nextActionDate;
      });
    });
  }
  showHistory(task) {
    const dialogRef = this.dialog.open(HistoryTabComponent, {
      disableClose: false,
      width: '70%',
      height: '80%',
      panelClass: 'right-side-dialog',
      //  position : {bottom: '0px', right:'0px'} ,
      data: {
        laneTask: this.laneTaskIDDetail,
        boardId: this.boardId
      }
    });
  }
  loadLaneTaskDetails() {
    this._kService.getLaneConfigById(this.laneTaskIdByUrl).subscribe((res) => {
      if (res && res['result'] && res['result']['config']) {
        let config = res['result']['config'];
        let laneDisplayConfig = config.filter((ele) => {
          if (ele['displayWhileListing']) {
            return true
          }
        });
        laneDisplayConfig.sort(function (x, y) {
          return x.index - y.index;
        });
        this.getLaneDetailsById();
      }
    });

  }
  getLaneDetailsById() {
    this._kService.detailsByLaneId(this.laneTaskIdByUrl).subscribe(res => {
      this.laneTaskIDDetail = res['result'];
      this.currentSelectedLane = this.laneTaskIDDetail.lane;
      this.boardLane = this.laneTaskIDDetail.boardLane;
      this.allDataLaneArrayKeys = this.laneTaskIDDetail.data;
      let currentFieldLaneMandatory = this.optionalLanes.indexOf(this.laneTaskIDDetail.lane.displayName) != -1;
      this.customFieldOptional = currentFieldLaneMandatory;
      this.currentSelectedLaneId = this.laneTaskIDDetail.lane.id;
      this.previouslySelected = {
        ...this.laneTaskIDDetail.lane
      }
      let tmp = this.laneTaskIDDetail.lane.id;
      this.laneId = this.laneTaskIDDetail.lane.id;
      if (this.laneId == Constants.C4 || this.laneId == Constants.S4) {
        this.isHidden = false;
      } else {
        this.isHidden = true;
      }
      this.currentTabId = tmp + '';
      this.kamHandoverAllowed = this.laneTaskIDDetail.kamHandoverAllowed;
      this._kService.getLaneBoardConfigById(this.laneTaskIDDetail['boardId']).subscribe((configResponse) => {
        if (configResponse && configResponse['result'] && configResponse['result']) {
          let config = configResponse['result'];
          let laneDisplayConfig = config;
          laneDisplayConfig.sort(function (x, y) {
            return x.index - y.index;
          });

          this.laneArrayKeys = [];

          this.laneTaskIDDetail['laneType'] = "form";
          this.boardId = res['result'].boardId;
          this.columnWidthCheck()
          if (this.boardId === this.boardIdForCRMDeals || this.boardId === this.boardIdForSobOpportunity) {
            this.getContactBoardLane();
            let rfqDTOs: any[] = res['result']['rfqDto'];
            if (rfqDTOs && rfqDTOs.length > 0) {
              this.isW1Checked = true;
              this.plantId = rfqDTOs[0]['plantId']
              this.customerId = rfqDTOs[0]['accountId']
              let w1stage = rfqDTOs[0]['stage']
              let creationDate = rfqDTOs[0]['creationDate']
              this.rfqCapturedText = "Captured at Stage " + w1stage + " ( " + this.formatDisplayDate(creationDate) + " )"
              this.disableW1checkBox = true;
            }
          }

          // if (this.boardId === this.boardIdForSobAccount) {
          //   for (let index = 0; index < this.boardLane.length; index++) {
          //     const element = this.boardLane[index];
          //     if (element.name === 'kyc') {

          //       let selectedSubLaneId = localStorage.getItem("selectedSubLaneId");
          //       if (selectedSubLaneId === null) {
          //         this.selectedTabIndex = 0;
          //         this.currentSubLaneId = element.laneSubdivisions[0].id;
          //       } else {
          //         switch (selectedSubLaneId) {
          //           case Constants.selectionCriteriaSubLaneId:
          //             this.selectedTabIndex = 0;
          //             this.currentSubLaneId = element.laneSubdivisions[0].id;
          //             break;
          //           case Constants.customerProfileSubLaneId:
          //             this.selectedTabIndex = 1;
          //             this.currentSubLaneId = element.laneSubdivisions[1].id;
          //             break;
          //           case Constants.serviceAbilitySubLaneId:
          //             this.selectedTabIndex = 2;
          //             this.currentSubLaneId = element.laneSubdivisions[2].id;
          //             break;
          //           default:
          //             this.selectedTabIndex = 0;
          //             this.currentSubLaneId = element.laneSubdivisions[0].id;
          //             break;
          //         }
          //       }
          //       localStorage.setItem('selectedSubLaneId', this.currentSubLaneId);

          //       this.subdivisionLaneConfig = [];
          //       this.remainingLaneConfig = [];
          //       for (let i = 0; i < laneDisplayConfig.length; i++) {
          //         const config = laneDisplayConfig[i];
          //         if (config.subLaneId === this.currentSubLaneId) {
          //           this.subdivisionLaneConfig.push(config);
          //         } else {
          //           this.remainingLaneConfig.push(config);
          //         }

          //       }
          //       laneDisplayConfig = this.subdivisionLaneConfig;
          //     }
          //   }
          // }
          this.leadName = this.laneTaskIDDetail.name

          this.laneTaskConfig = res['result']['laneWiseData'];
          let formObject = {};
          let configObject = {};
          let data = this.laneTaskIDDetail.data;
          for (let k = 0; k < laneDisplayConfig.length; k++) {

            let tmpFormField = data.find((tmp) => {
              return tmp.field == laneDisplayConfig[k].field;
            })

            let formField = laneDisplayConfig[k];
            if (!tmpFormField) {
              tmpFormField = formField;
            }
            formField.values = tmpFormField.values;
            formField.value = tmpFormField.value;
            formField.additionalData = tmpFormField.additionalData;
            formField.rawData = tmpFormField.rawData;
            if (formField) {
              formObject[formField['field']] = [formField['value'], !formField['optional'] ? [Validators.required] : []];
              configObject[formField['field'] + "_config"] = formField;
            } else {
              formField = laneDisplayConfig[k];
              formObject[formField['field']] = [formField['value'], !formField['optional'] ? [Validators.required] : []];
              configObject[formField['field'] + "_config"] = formField;
            }
          }
          this.formDetails = this.formBuilder.group(formObject);
          let subKeys = Object.keys(formObject);
          let tmp = []
          for (let i = 0; i < subKeys.length; i++) {
            let obj = {
              subKeys: subKeys[i],
              config: configObject[subKeys[i] + "_config"]
            }
            tmp.push(obj);
          }
          this.laneArrayKeys = tmp;
          if (this.currentSubLaneId === Constants.selectionCriteriaSubLaneId) {
            this.calculateScoring(this.laneArrayKeys);
          }

          this.remainingFinalConfigList = [];
          if (this.remainingLaneConfig) {
            for (let index = 0; index < this.remainingLaneConfig.length; index++) {
              const element = this.remainingLaneConfig[index];

              let abc = data.find((tmp) => {
                return tmp && tmp.subLaneId && (tmp.field === element.field) && tmp.subLaneId === element.subLaneId;
              });
              if (abc) {
                this.remainingFinalConfigList.push(abc);
              }
            }
          }
          for (let i = 0; i < this.laneArrayKeys.length; i++) {
            const laneKey = this.laneArrayKeys[i];

            if (laneKey.subKeys === 'country' && laneKey.config.value == 'India') {
              this.isCountryIndia = true;
            }

            if (laneKey.subKeys === 'account' && this.boardId == Constants.boardIdForSobContacts) {
              //this.openPlantSelect();
            }

            if (laneKey.subKeys === 'industry') {
              this.industryList = laneKey.config.dataList;
              this.industryListSearch = this.industryList;
            }

            if (laneKey.subKeys === 'business-vertical') {
              this.businessVerticalList = laneKey.config.dataList;
              this.businessVerticalListSearch = this.businessVerticalList;
            }

            if (laneKey.subKeys === 'lead-source') {
              this.leadSourceList = laneKey.config.dataList;
              this.leadSourceListSearch = this.leadSourceList;
            }

            if (laneKey.subKeys === 'plant-name' || laneKey.subKeys === 'account-name') {
              // this.setPlantIdAndAccountId(laneKey);

              if (this.boardId === Constants.boardIdForSobOpportunity) {
                if (laneKey.subKeys === 'account-name' && laneKey.config.value !== null) {
                  this.accountId = laneKey.config.value;
                  this._kService.setIds(this.accountId, this.laneTaskIdByUrl);
                  this.openPlantSelect();
                  this.getContactListOfAccount();
                }
              } else if (this.boardId === Constants.boardIdForCRMDeals) {
                if (laneKey.config.value !== null) {
                  this.accountId = laneKey.config.value;
                  this.getContactListOfAccount();
                }

                // if (laneKey.config.values !== null && laneKey.config.values.length > 0) {
                //   const values = laneKey.config.values;
                //   this.accountId.push(values);
                // }

                this._kService.setIds(this.accountId, this.laneTaskIdByUrl);
              }
            }
            if (laneKey.subKeys === 'deal-type' && this.boardId === Constants.boardIdForCRMDeals) {
              this.dealTypeList = laneKey.config.dataList;
              this.dealTypeListSearch = this.dealTypeList;
              this.dealType = laneKey.config.value;
            }

            if (laneKey.subKeys === 'po-type' && this.boardId == Constants.boardIdForSobOpportunity) {
              let poValue = laneKey.config.value;
              if (poValue !== null && poValue === 'Repeat RFQ') {
                this.isRepeatedRFQFrequency = true;
              }
              else {
                this.isRepeatedRFQFrequency = false;
              }
              if (this.isRepeatedRFQFrequency) {
                const repeatRfqFrequencyElement = this.laneArrayKeys.find((element) => element.subKeys === 'repeat-rf-qs-frequency');
                if (repeatRfqFrequencyElement) {
                  repeatRfqFrequencyElement.config.optional = true;
                }
              }
              else {
                const repeatRfqFrequencyElement = this.laneArrayKeys.find((element) => element.subKeys === 'repeat-rf-qs-frequency');
                if (repeatRfqFrequencyElement) {
                  repeatRfqFrequencyElement.config.optional = false;
                }
              }
            }
            if (laneKey.subKeys === 'new-categories' && this.boardId == Constants.boardIdForSobOpportunity) {
              this.newCategoryList = laneKey.config.dataList;
              this.newCategoryListSearch = this.newCategoryList;

              if (laneKey.config.value) {
                this.isNewCategories = true;
                let newCategoryValue = laneKey.config.value;
                this._kService.getL2Categories(newCategoryValue).subscribe(category => {
                  this.subCategoriesOfNewCategories = category;
                });
              }
            }
            if (laneKey.subKeys === 'sob-l1-categories' && this.boardId == Constants.boardIdForCRMDeals) {
              if (laneKey.config.value) {
                let newCategoryValue = laneKey.config.value;
                this._kService.getL2Categories(newCategoryValue).subscribe(category => {
                  this.subCategoriesOfNewCategories = category;
                });
              }
            }
            if (laneKey.subKeys === 'deal-type' && this.boardId == Constants.boardIdForSobOpportunity) {
              this.dealTypeList = laneKey.config.dataList;
              this.dealTypeListSearch = this.dealTypeList;

              let poValue = laneKey.config.value;
              if (poValue !== null && poValue === 'New category') {
                this.isDealTypeNewCategories = true;
              }
              else {
                this.isDealTypeNewCategories = false;
              }
              if (this.isDealTypeNewCategories) {
                const newCategoriesElement = this.laneArrayKeys.find((element) => element.subKeys === 'new-categories');
                if (newCategoriesElement && newCategoriesElement.config.value) {
                  newCategoriesElement.config.optional = true;
                }
                const newCategoryListElement = this.laneArrayKeys.find((element) => element.subKeys === 'new-category-list');
                if (newCategoryListElement && newCategoryListElement.config.values) {
                  this.isNewCategories = true;
                  newCategoryListElement.config.optional = true;
                }
              }
            }
            if (laneKey.subKeys === 'role' && (this.boardId == Constants.boardIdForSobContacts || this.boardId == Constants.boardIdForCRMContacts)) {
              this.roleNameList = laneKey.config.dataList;
              this.roleNameListSearch = this.roleNameList;
              if (laneKey.config.value) {
                let roleNameValue = laneKey.config.value;
                this._kService.getRoleTypeByRoleName(roleNameValue).subscribe(res => {
                  if (res && res['result']) {
                    this.roleTypeList = res['result'];
                    this.roleTypeListSearch = this.roleTypeList;
                  }
                });
              }
            }
            if (laneKey.subKeys === 'role-type' && (this.boardId == Constants.boardIdForSobContacts || this.boardId == Constants.boardIdForCRMContacts)) {
              const isEA = laneKey.config.value === 'EA';
              const isCEO = laneKey.config.value === 'CEO';
              if (isEA || isCEO) {
                this.loadUsers();
              }
              this.laneArrayKeys.forEach(element => {
                this.setOptionalConfig(element, isCEO, isEA);
              });
            }

            if (laneKey.subKeys === 'private-owner') {
              const privateOwner = laneKey.config.values || [];
              if (this.boardId === this.boardIdForCRMContacts) {
                this.selectedBusinessId = Constants.businessIdForCRM;
              } else if (this.boardId === this.boardIdForSobContacts) {
                this.selectedBusinessId = Constants.businessIdForSOB;
              }
              let obj = {};
              obj['from'] = 0;
              obj['size'] = 100;
              obj['businessId'] = this.selectedBusinessId;
              this.settingServ.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
                let userList = [];
                this.selectedUsers = [];
                for (let i = 0; i < list['result'].length; i++) {
                  let obj = list['result'][i];
                  let tmp = list['result'][i].userTeams;
                  let teamList = [];
                  if (tmp) {
                    for (let k = 0; k < tmp.length; k++) {
                      teamList.push(tmp[k].id);
                    }
                    obj.userTeams = teamList;
                    userList.push(obj)
                  } else {
                    obj.userTeams = [];
                    userList.push(obj)
                  }
                }
                userList.sort(function (a, b) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                });
                this.userList = userList;
                this.userListSearch = this.userList;
                userList.forEach(ele => {
                  if (privateOwner.includes(ele.id)) {
                    this.selectedUsers.push(ele);
                  }
                });
              });
            }
            if (laneKey.subKeys === 'add-ceo') {
              let obj = {
                "where": {
                  "boardId": {
                    "type": "search",
                    "operator": "or",
                    "value": [
                      {
                        "field": "boardId.raw",
                        "value": this.boardId
                      }
                    ]
                  }
                },
                "sort": { "field": "updatedAt", "order": "desc" },
                "from": this.pageFrom,
                "size": this.pageSize
              };

              let query = {
                "query": {
                  "nestedWhere": {}
                }
              };

              // Add roleType query
              query['query']['nestedWhere']["roleType_query"] = {
                "type": "search",
                "operator": "or",
                "value": [
                  {
                    "field": "data.roletype.raw",
                    "value": "CEO"
                  }
                ]
              };

              // Check for account in laneArrayKeys and set accountId
              for (let element of this.laneArrayKeys) {
                if (element.config.field === 'account' && element.config.value !== '') {
                  this.accountId = element.config.value;
                  break;
                }
              }

              // Show error if accountId is not set
              if (!this.accountId) {
                this._snackBar.openFromComponent(ErrorToastComponent, {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  panelClass: ['atom-toast', 'error'],
                  duration: 6000,
                  data: "Please select Account first."
                });
                return;
              }

              // Add account query
              query['query']['nestedWhere']["account_query"] = {
                "type": "search",
                "operator": "or",
                "value": [
                  {
                    "field": "data.account.raw",
                    "value": this.accountId
                  }
                ]
              };

              obj['nestedWhere'] = query['query']['nestedWhere'];

              this._kService.getLaneTask(obj).subscribe(res => {
                if (res == null) {
                  return;
                }
                else {
                  this.accountContactCeoList = res['result'];
                  this.accountContactCeoListSearch = this.accountContactCeoList;
                  const s = laneKey.config.values;
                  this.selectedCEOIds = [];
                  this.accountContactCeoList.forEach(element => {
                    if (s.includes(element.id)) {
                      this.selectedCEOIds.push(element);
                    }
                  });
                }
                this.pageIndex++;
              },
                (error) => {
                  console.log('getLaneTask error', error)
                }
              );
            }
            if (laneKey.subKeys === 'lead-source' && (this.boardId === this.boardIdForCRMAccount || this.boardId === this.boardIdForCRMContacts)) {
              const laneConfig = laneKey.config;
              let leadSourceValue = laneConfig.value;
              console.log("Setting", leadSourceValue);

              if (leadSourceValue && leadSourceValue === 'Event') {
                this.console.log("Setting", this.laneArrayKeys)
                const eventNameElement = this.laneArrayKeys.find(element => element.subKeys === 'event-name');
                if (eventNameElement) {
                  eventNameElement.config.optional = true;
                }
                const eventDateElement = this.laneArrayKeys.find(element => element.subKeys === 'event-date');
                if (eventDateElement) {
                  eventDateElement.config.optional = true;
                }
              } else {
                const eventNameElement = this.laneArrayKeys.find(element => element.subKeys === 'event-name');
                if (eventNameElement) {
                  eventNameElement.config.value = '';
                  eventNameElement.config.optional = false;
                }
                const eventDateElement = this.laneArrayKeys.find(element => element.subKeys === 'event-date');
                if (eventDateElement) {
                  eventNameElement.config.value = '';
                  eventDateElement.config.optional = false;
                }
              }
            }
            if (laneKey.subKeys === 'm-a') {
              if (laneKey.config.value) {
                const isEvent = (laneKey.config.value).toLowerCase() === 'yes';
                const revenueRangeElement = this.laneArrayKeys.find(element => element.subKeys === 'revenue-range');
                const ownerElement = this.laneArrayKeys.find(element => element.subKeys === 'owner');

                if (revenueRangeElement) {
                  revenueRangeElement.config.optional = isEvent;
                }
                if (ownerElement) {
                  ownerElement.config.optional = isEvent;
                }
              }
            }
          }
          this.loadProjectionData();
        }
      });
    });
  }
  loadProjectionData() {
    let projectionMap: any = this.laneTaskIDDetail.projection;
    if (projectionMap) {
      projectionMap.sort(function (a, b) {
        let x = new Date(a[0]);
        let y = new Date(b[0]);
        // Compare the 2 dates
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });
      this.m1 = projectionMap[0].value;
      this.m2 = projectionMap[1].value;
      this.m3 = projectionMap[2].value;
      this.m4 = projectionMap[3].value;
      this.m5 = projectionMap[4].value;
      this.m6 = projectionMap[5].value;
      this.projectionTimeline = projectionMap[0].date + " to " + projectionMap[5].date;
    }
  }
  shouldDisableField(fieldName: string): boolean {
    switch (fieldName) {
      case 'repeat-rf-qs-frequency':
        return !this.isRepeatedRFQFrequency;
      case 'new-categories':
        return !this.isDealTypeNewCategories;
      case 'new-category-list':
        return this.isNewCategories;
      default:
        return false; // For other fields, assume they are not disabled
    }
  }
  shouldDisableFieldConfig(fieldConfig: any): boolean {
    return fieldConfig.fieldEditable != undefined ? !fieldConfig.fieldEditable : false;
  }
  getContactListOfAccount() {
    let boardContactId;
    if (this.boardId === this.boardIdForSobOpportunity) {
      boardContactId = this.boardIdForSobContacts;
    }
    else {
      boardContactId = this.boardIdForCRMContacts;
    }
    this.contactDetails = [];
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": boardContactId
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let tQuery = [];
    tQuery.push({
      "field": "data.account.raw",
      "value": this.accountId
    })
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    query['query']['nestedWhere']["account_query"] = {};
    query['query']['nestedWhere']["account_query"]["type"] = "search";
    query['query']['nestedWhere']["account_query"]["operator"] = "or";
    query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    obj['nestedWhere'] = query['query']['nestedWhere'];
    this._kService.getLaneTaskContactDetails(obj, this.laneTaskIdByUrl).subscribe(result => {
      this.isLoading = false;
      if (result == null) {
        return;
      }
      this.pageIndex++;
      const contactList = result['result'];;
      for (let index = 0; index < contactList.length; index++) {
        const element = contactList[index];
        if (element.isChecked) {
          this.contactDetails.push(contactList[index])
        }
      }
      const idList = this.contactDetails.map(contact => contact.id.toString());
      const contactElement = this.laneArrayKeys.find(element => element.subKeys === 'contact');
      if (contactElement) {
        contactElement.config.values = idList;
      }
    })
  }
  contactInfo() {
    let contactBoardId = null;
    if (this.boardId === this.boardIdForCRMDeals) {
      contactBoardId = this.boardIdForCRMContacts;
    }
    else {
      contactBoardId = this.boardIdForSobContacts;
    }
    this.laneArrayKeys.forEach(element => {
      if (element.subKeys === 'account') {
        this.accountId = element.config.value;
        this._kService.setIds(this.accountId, this.laneTaskIdByUrl);
      }
      if (element.subKeys === 'account-name') {
        this.accountId = element.config.value;
        this._kService.setIds(this.accountId, this.laneTaskIdByUrl);
      }
    });
    this.console.log(":;kanca;kva", this.laneTaskIDDetail.lane.id);

    const dialogRef = this.dialog.open(ContactInfoComponent, {
      panelClass: 'right-side-dialog',
      data: {
        "boardId": contactBoardId,
        "laneTaskLaneID": this.laneTaskIDDetail.lane.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getContactListOfAccount();
    });
  }
  accountInfo() {
    const dialogRef = this.dialog.open(AccountInfoComponent, {

      panelClass: 'right-side-dialog',

    });
  }
  removeContact(contact: any): void {
    const dialogRef = this.dialog.open(ContactInfoComponent, {

      panelClass: 'right-side-dialog',

    });
  }
  fetchConactBoardlane(boardId: string) {
    this._kService.getLaneWithUserAccess(boardId, '').subscribe(res => {
      let tmp = [];
      for (let i = 0; i < res['result']['levelAccess'].length; i++) {
        let t = res['result']['levelAccess'][i].laneDTO;
        tmp.push(t);
      }
      this.contactBoardLane = tmp;
    });

  }
  fetchBoardLanesAndSubLanes(boardId: string) {
    this.console.log("fetchBoardLanesAndSubLanes method start ::")
    if (boardId === null || boardId === undefined) {
      boardId = Constants.boardIdForSobAccount;
    }
    this._kService.getLaneWithUserAccess(boardId, '').subscribe(res => {
      let tmp = [];
      for (let i = 0; i < res['result']['levelAccess'].length; i++) {
        let t = res['result']['levelAccess'][i].laneDTO;
        tmp.push(t);
      }
      this.lanesWithSubdivisionLanes = tmp;
      this.console.log("lanesWithSubdivisionLanes", this.lanesWithSubdivisionLanes);
      this.subdivisionLanes = this.lanesWithSubdivisionLanes[0].laneSubdivisions;
      this.console.log("subdivisionLanes", this.subdivisionLanes);
      this.currentSubLaneId = this.subdivisionLanes[0].id;
      this._kService.getSubdivisionLaneBoardConfigById(this.subdivisionLanes[0].id).subscribe(data => {
        this.subdivisionLaneConfig = data['result'];
        this.console.log("subdivisionLaneConfig", this.subdivisionLaneConfig);
        let configFields = this.subdivisionLaneConfig.map(item => {
          return { subKeys: item.field, config: item };
        });

        this.allDataLaneArrayKeys = this.laneTaskIDDetail.data.map(item => {
          return { subKeys: item.field, config: item };
        });

        this.console.log("configFields", configFields);
        this.console.log("allDataLaneArrayKeys", this.allDataLaneArrayKeys);

        let filteredAllDataLaneArrayKeys = [];

        configFields.forEach(item => {

          let matchedConfig = this.allDataLaneArrayKeys.find(data => data.subKeys === item.subKeys);

          if (matchedConfig && matchedConfig.config && (matchedConfig.config.subLaneId !== null || matchedConfig.config.subLane !== undefined)) {
            if (matchedConfig.config.subLaneId === this.subdivisionLanes[0].id) {
              filteredAllDataLaneArrayKeys.push(matchedConfig);
            }
          }
        });

        this.laneArrayKeys = filteredAllDataLaneArrayKeys;
        this.console.log("filteredAllDataLaneArrayKeys", filteredAllDataLaneArrayKeys);
        if (this.laneArrayKeys.length < 1) {
          this.laneArrayKeys = configFields;
        }
        this.subdivisionLaneConfig = this.laneArrayKeys;
        this.console.log("laneArrayKeys", this.laneArrayKeys);
      }, err => this.console.log("error while getting subdivision lane config", err))
    });
  }
  addNewLead() {
    console.log('addNewLead in lane-card - details ::', this.boardId, this.boardLane)
    const createLaneTaskDialog = this.dialog.open(CreateLaneTaskComponent, {
      disableClose: false,
      width: '68%',
      height: '100vh',
      position: { bottom: '0px', right: '0px' },
      data: {
        "boardId": this.boardIdForCRMContacts,
        "boardName": "contact",
        "selectedLane": this.contactBoardLane[0]
      },
      panelClass: 'addnewleadoverlay'
    });
    createLaneTaskDialog.afterClosed().subscribe(result => {
      if (result == true) {
      }
    });
  }
  addNewLeadOppContact() {
    console.log('addNewLeadOppContact in lane-card - details ::', this.boardId, this.boardLane)
    const createLaneTaskDialog = this.dialog.open(CreateLaneTaskComponent, {
      disableClose: false,
      width: '68%',
      height: '100vh',
      position: { bottom: '0px', right: '0px' },
      data: {
        "boardId": this.boardIdForSobContacts,
        "boardName": "contact",
        "selectedLane": this.contactBoardLane[0]
      },
      panelClass: 'addnewleadoverlay'
    });
    createLaneTaskDialog.afterClosed().subscribe(result => {
      if (result == true) {
      }
    });
  }
  getPlantIdValue() {
    const plantNameItem = this.laneArrayKeys.find(item => item.config.field === 'plant-name');
    return plantNameItem ? plantNameItem.config.value : '';
  }
  setPlantIdAndAccountId(laneKey: any) {
    this.console.log("setPlantIdAndAccountId")
    if (laneKey.subKeys === 'plant-name' || laneKey.subKeys === 'account-name') {
      this.accountContactListSearch = [];
      const id = laneKey.config.value;
      if (id != null && id != undefined && id.trim() != "" && this.boardId !== Constants.boardIdForSobAccount && this.boardId !== Constants.boardIdForCRMAccount) {
        this.isLoading = true;
        this._kService.detailsByLaneId(id).subscribe((details) => {
          this.isLoading = false;
          const { plantid, companyid } = details['result'].searchData;
          if (plantid) {
            this.plantNameId = plantid;
          }
          if (companyid) {
            this.accountNameId = companyid;
          }
        });
      }
    }
    if (this.boardId === Constants.boardIdForSobOpportunity) {
      this.openPlantSelect();
    }
  }
  applySelections() {
    this.laneArrayKeys.forEach(element => {
      if (element.subKeys === 'plant' || element.subKeys === 'plant-name') {
        element.config.values = this.selectedPlantIds;
      }
    });
  }
  clearSelections() {
    this.selectedPlantIds = [];
    this.laneArrayKeys.forEach(element => {
      if (element.subKeys === 'plant' || element.subKeys === 'plant-name') {
        element.config.values = this.selectedPlantIds;
      }
    });
  }
  openPlantSelect() {
    this.laneArrayKeys.forEach(element => {
      if (element.subKeys === 'account') {
        this.accountId = element.config.value;
      }
      if (element.subKeys === 'account-name') {
        this.accountId = element.config.value;
      }
      if (element.subKeys === 'plant') {
        this.selectedPlantIds = element.config.values;
      }
      if (element.subKeys === 'plant-name') {
        this.selectedPlantIds = element.config.values;
      }
    });
    this.console.log("selected")
    this.getPlantList();
  }
  getPlantList() {
    const accountElement = this.laneArrayKeys.find(element => element.subKeys === 'account' && element.config.value !== '' || element.subKeys === 'account-name' && element.config.value !== '');
    if (accountElement) {
      this.accountId = accountElement.config.value;
    }

    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardIdForSobPlant
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let tQuery = [];
    tQuery.push({
      "field": "data.account.raw",
      "value": this.accountId
    })
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    query['query']['nestedWhere']["account_query"] = {};
    query['query']['nestedWhere']["account_query"]["type"] = "search";
    query['query']['nestedWhere']["account_query"]["operator"] = "or";
    query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    obj['nestedWhere'] = query['query']['nestedWhere'];
    this.getLaneTask(obj);
  }
  getLaneTask(obj) {
    this.isLoading = true;
    this._kService.getLaneTask(obj).subscribe(res => {
      if (res == null) {
        return;
      }
      else {
        this.accountContactList = res['result'];
        this.isLoading = false;
        this.accountContactListSearch = this.accountContactList;
      }
      this.pageIndex++;
    },
      (error) => {
        console.log('getLaneTask error', error)
      }
    );
    // if (this.sl2) {
    //   this.sl2.open();
    // }
  }
  filterOptions(event) {
    this.accountContactListSearch = this.accountContactList;
    const filterValue = event.target.value.toLowerCase();
    this.accountContactListSearch = this.accountContactList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  onIndustrySelectionChange(formItem: any) {
    let formItemWithSubKeys: any = {
      config: formItem,
      subKeys: formItem.field
    };
    this.onSelectOption(formItemWithSubKeys, formItemWithSubKeys.config);
  }
  onSelectOptionSelectionChange(formItem: any) {
    let formItemWithSubKeys: any = {
      config: formItem,
      subKeys: formItem.field
    };
    this.onSelectOption(formItemWithSubKeys, formItemWithSubKeys.config);
  }
  filterOptionsDataList(event: any) {
    this.dealTypeListSearch = this.dealTypeList.filter(option => (option).toLowerCase().includes(event));
  }
  onIndustrySearchChange(event: any) {
    this.industryListSearch = this.industryList.filter((option: any) => (option).toLowerCase().includes(event));
  }
  filterOptionsBusinessVertical(event: any) {
    this.businessVerticalListSearch = this.businessVerticalList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsRegionTeam() {
    this.regionTeamListSearch = this.regionTeamList.filter(option => (option.name).toLowerCase().includes(this.searchControlRegionTeam));
  }
  filterOptionsDealType(event: any) {
    this.dealTypeListSearch = this.dealTypeList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsLeadSource(event: any) {
    this.leadSourceListSearch = this.leadSourceList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsL1CategoryList(event: any) {
    this.newCategoryListSearch = this.newCategoryList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsL2Categories(event, dataList: any) {
    this.l2CategoryListSearch = this.subCategoriesOfNewCategories.filter(option => (option).toLowerCase().includes(this.searchControlL2Category));
  }
  filterOptionsRoleNameList(event: any) {
    this.roleNameListSearch = this.roleNameList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsRoleType(event, dataList: any) {
    this.roleTypeListSearch = this.roleTypeList.filter(option => (option).toLowerCase().includes(event));
  }
  fetchTeams() {
    this.settingServ.fetchTeams().subscribe(teams => {
      if (teams) {
        this.regionTeamListSearch = teams['result'];
        this.regionTeamList = this.regionTeamListSearch;
      } else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Error while fetching Region/Teams"
        });
        // this.toasterService.error("Error while fetching Region/Teams")
      }
    });
  }
  cacheSpan(key, accessor, datatset) {
    for (let i = 0; i < datatset.length;) {
      let currentValue = accessor(datatset[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < datatset.length; j++) {
        if (currentValue != accessor(datatset[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
  }
  getRowSpan(col, index) {

    return this.spans[index] && this.spans[index][col];
  }
  removeSelectedFile() {
    this.file = null;
  }
  fileUpload(event) {
    this.console.log(" upload file event: ", event)
    this.isProgressVisible = true;
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }
  }
  tableUploadForLane(event, tableName) {
    this.console.log(" upload file event: ", event, "tableName", tableName);
    this.isProgressVisible = true;
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }
    this._kService.updateTableLaneTask(this.file, this.boardId, null, this.laneTaskIdByUrl, null, tableName).subscribe((data) => {
      this.console.log("Update Table Task: ", data['result']['searchData']['tableData']);
      this.file = null;
      if (data['status'] == 200) {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 6000,
          data: "Data Saved Successfully"
        });
        let searchData = this.laneTaskIDDetail.searchData;

        if (searchData) {
          let dataSource = data['result']['searchData']['tableData'];

          if (!searchData.tableData) {
            searchData.tableData = [];
          }

          if (tableName === 'financials') {
            this.financialsTableDataSource = dataSource['financials'];
            this.updateTableData('financials', this.financialsTableDataSource);
          }
          if (tableName === 'moglix-business-history') {
            this.moglixBusinessHistoryTableDataSource = dataSource['moglix-business-history'];
            this.updateTableData('moglix-business-history', this.moglixBusinessHistoryTableDataSource);
          }
          if (tableName === 'serviceability') {
            this.serviceabilityTableDataSource = dataSource['serviceability'];
            this.updateTableData('serviceability', this.serviceabilityTableDataSource);
          }
          if (tableName === 'plant-table') {
            this.plantTableDataSource = dataSource['plant-table'];
            this.updateTableData('plant-table', this.plantTableDataSource);
          }
        } else {
          console.error("Error: searchData is null or undefined");

        }
        window.location.reload();
      }
      else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Error while updating data to table"
        });
      }
    });

  }
  tableUpload(event, laneId, laneSubdivisionId, tableName) {
    this.console.log(" upload file event: ", event, " laneId", laneId, "laneSubdivision", laneSubdivisionId, "tableName", tableName);
    this.isProgressVisible = true;
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }
    this._kService.updateTableLaneTask(this.file, this.boardId, laneId, this.laneTaskIdByUrl, laneSubdivisionId, tableName).subscribe((data) => {
      this.console.log("Update Table Task: ", data['result']['searchData']['tableData']);
      this.file = null;
      if (data['status'] == 200) {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 6000,
          data: "Data Saved Successfully"
        });

        let searchData = this.laneTaskIDDetail.searchData;

        if (searchData) {
          let dataSource = data['result']['searchData']['tableData'];

          if (!searchData.tableData) {
            searchData.tableData = [];
          }

          if (tableName === 'financials') {
            this.financialsTableDataSource = dataSource['financials'];
            this.updateTableData('financials', this.financialsTableDataSource);
          }
          if (tableName === 'moglix-business-history') {
            this.moglixBusinessHistoryTableDataSource = dataSource['moglix-business-history'];
            this.updateTableData('moglix-business-history', this.moglixBusinessHistoryTableDataSource);
          }
          if (tableName === 'serviceability') {
            this.serviceabilityTableDataSource = dataSource['serviceability'];
            this.updateTableData('serviceability', this.serviceabilityTableDataSource);
          }
          if (tableName === 'plant-table') {
            this.plantTableDataSource = dataSource['plant-table'];
            this.updateTableData('plant-table', this.plantTableDataSource);
          }
        } else {
          console.error("Error: searchData is null or undefined");
        }
      }
      else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Error while updating data to table"
        });
        this.file = null;
      }
    }, (error) => {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: error.error.message
      });
      this.file = null;
    });

  }
  updateTableData(fieldName: string, dataSource: any) {
    if (!this.laneTaskIDDetail.searchData.tableData[fieldName]) {
      this.laneTaskIDDetail.searchData.tableData[fieldName] = [];
    }
    this.laneTaskIDDetail.searchData.tableData[fieldName] = dataSource;
  }
  toggleTab() {
    this.tabActive = !this.tabActive;
  }
  getTableDataSource(field: string) {
    if (field === 'financials') {
      this.financialsTable();
      return this.financialsTableDataSource;
    }
    else if (field === 'moglix-business-history') {
      this.moglixBusinessHistoryTable();
      return this.moglixBusinessHistoryTableDataSource;
    }
    else if (field === 'serviceability') {
      if (this.laneTaskIDDetail.searchData.tableData) {
        this.serviceabilityTableDataSource = this.laneTaskIDDetail.searchData.tableData['serviceability'];
        return this.serviceabilityTableDataSource;
      }
    }
    else if (field === 'plant-table') {
      if (this.laneTaskIDDetail.searchData.tableData) {
        this.plantTableDataSource = this.laneTaskIDDetail.searchData.tableData['plant-table'];
        return this.plantTableDataSource;
      }
    }
  }
  downloadTemplate(field: string, subLaneId: string) {
    console.log('downloadTemplate()', field);
    this._kService.downloadTableData(this.laneTaskIdByUrl, field, subLaneId).subscribe(data => {
      if (data['status'] === 200) {
        window.location.assign(data['result']);
      }
    });
  }
  financialsTable() {
    if (this.laneTaskIDDetail.searchData.tableData) {
      this.financialsTableDataSource = this.laneTaskIDDetail.searchData.tableData.financials;
    }
  }
  moglixBusinessHistoryTable() {
    if (this.laneTaskIDDetail.searchData.tableData) {
      this.moglixBusinessHistoryTableDataSource = this.laneTaskIDDetail.searchData.tableData['moglix-business-history'];
    }
  }
  onTabChange(event: any, subLane: any) {
    this.currentSubLaneId = subLane.id;
    this.console.log("selectedtabInmdex", this.selectedTabIndex);
    switch (this.currentSubLaneId) {
      case Constants.selectionCriteriaSubLaneId:
        this.selectedTabIndex = 0;
        break;
      case Constants.customerProfileSubLaneId:
        this.selectedTabIndex = 1;
        break;
      case Constants.serviceAbilitySubLaneId:
        this.selectedTabIndex = 2;
        break;
      default:
        this.selectedTabIndex = 0;
        break;
    }
    localStorage.setItem('selectedSubLaneId', this.currentSubLaneId);
    this.remainingLaneConfig = [];
    this.remainingFinalConfigList = [];
    this.laneArrayKeys = [];

    if (subLane.id === Constants.serviceAbilitySubLaneId) {
      // this.loadPlants();
    }

    let data = this.laneTaskIDDetail.data;
    this._kService.getSubdivisionLaneBoardConfigById(subLane.id).subscribe(
      (config) => {
        this.subdivisionLaneConfig = config['result'];
        let laneDisplayConfig = config['result'];

        let formObject = {};
        let configObject = {};

        // for finding remaining remainingLaneConfig keys
        const confMap = {};
        for (const fieldConfig of laneDisplayConfig) {
          confMap[fieldConfig.field] = fieldConfig;
        }

        for (const config of data) {
          const conf = confMap[config.field];
          if (!conf || config.subLaneId !== conf.subLaneId) {
            this.remainingLaneConfig.push(config);
          }
        }

        // for assigning values from data to laneArrayKeys
        for (let k = 0; k < laneDisplayConfig.length; k++) {

          let tmpFormField = data.find((tmp) => {
            return tmp.field == laneDisplayConfig[k].field;
          })

          let formField = laneDisplayConfig[k];
          if (!tmpFormField) {
            tmpFormField = formField;
          }
          formField.values = tmpFormField.values;
          formField.value = tmpFormField.value;
          formField.additionalData = tmpFormField.additionalData;
          if (formField) {
            formObject[formField['field']] = [formField['value'], !formField['optional'] ? [Validators.required] : []];
            configObject[formField['field'] + "_config"] = formField;
          } else {
            formField = laneDisplayConfig[k];
            formObject[formField['field']] = [formField['value'], !formField['optional'] ? [Validators.required] : []];
            configObject[formField['field'] + "_config"] = formField;
          }
        }
        this.formDetails = this.formBuilder.group(formObject);
        let subKeys = Object.keys(formObject);
        let tmp = subKeys.map(subKey => ({
          subKeys: subKey,
          config: configObject[subKey + "_config"]
        }));

        this.laneArrayKeys = tmp;
        const dataMap = {};
        for (const tmp of data) {
          if (tmp && tmp.subLaneId) {
            dataMap[tmp.field + '_' + tmp.subLaneId] = tmp;
          }
        }
        for (const element of this.remainingLaneConfig) {
          const key = element.field + '_' + element.subLaneId;
          const abc = dataMap[key];
          if (abc) {
            this.remainingFinalConfigList.push(abc);
          }
        }
        if (this.currentSubLaneId === Constants.selectionCriteriaSubLaneId) {
          this.calculateScoring(this.laneArrayKeys);
        }

        for (let i = 0; i < this.laneArrayKeys.length; i++) {
          const laneKey = this.laneArrayKeys[i];

          if (laneKey.config.field == 'country' && laneKey.config.value == 'India') {
            this.isCountryIndia = true;
          }

          if (laneKey.subKeys === 'account' && this.boardId == Constants.boardIdForSobContacts) {
            this.openPlantSelect();
          }

          if (laneKey.subKeys === 'industry') {
            this.industryList = laneKey.config.dataList;
            this.industryListSearch = this.industryList;
          }

          if (laneKey.subKeys === 'business-vertical') {
            this.businessVerticalList = laneKey.config.dataList;
            this.businessVerticalListSearch = this.businessVerticalList;
          }

          if (laneKey.subKeys === 'lead-source') {
            this.leadSourceList = laneKey.config.dataList;
            this.leadSourceListSearch = this.leadSourceList;
          }

          if (laneKey.subKeys === 'plant-name' || laneKey.subKeys === 'account-name') {
            // this.setPlantIdAndAccountId(laneKey);

            if (this.boardId === Constants.boardIdForSobOpportunity) {
              if (laneKey.subKeys === 'account-name' && laneKey.config.value !== null) {
                this.accountId = laneKey.config.value;
                this._kService.setIds(this.accountId, this.laneTaskIdByUrl);
                this.openPlantSelect();
                this.getContactListOfAccount();
              }
            } else if (this.boardId === Constants.boardIdForCRMDeals) {
              if (laneKey.config.value !== null) {
                this.accountId = laneKey.config.value;
                this.getContactListOfAccount();
              }
              this._kService.setIds(this.accountId, this.laneTaskIdByUrl);
            }
          }
          if (laneKey.subKeys === 'deal-type' && this.boardId === Constants.boardIdForCRMDeals) {
            this.dealTypeList = laneKey.config.dataList;
            this.dealTypeListSearch = this.dealTypeList;
            this.dealType = laneKey.config.value;
          }

          if (laneKey.subKeys === 'po-type' && this.boardId == Constants.boardIdForSobOpportunity) {
            let poValue = laneKey.config.value;
            if (poValue !== null && poValue === 'Repeat RFQ') {
              this.isRepeatedRFQFrequency = true;
            }
            else {
              this.isRepeatedRFQFrequency = false;
            }
            if (this.isRepeatedRFQFrequency) {
              const repeatRfqFrequencyElement = this.laneArrayKeys.find((element) => element.subKeys === 'repeat-rf-qs-frequency');
              if (repeatRfqFrequencyElement) {
                repeatRfqFrequencyElement.config.optional = true;
              }
            }
            else {
              const repeatRfqFrequencyElement = this.laneArrayKeys.find((element) => element.subKeys === 'repeat-rf-qs-frequency');
              if (repeatRfqFrequencyElement) {
                repeatRfqFrequencyElement.config.optional = false;
              }
            }
          }
          if (laneKey.subKeys === 'new-categories' && this.boardId == Constants.boardIdForSobOpportunity) {
            this.newCategoryList = laneKey.config.dataList;
            this.newCategoryListSearch = this.newCategoryList;

            if (laneKey.config.value) {
              this.isNewCategories = true;
              let newCategoryValue = laneKey.config.value;
              this._kService.getL2Categories(newCategoryValue).subscribe(category => {
                this.subCategoriesOfNewCategories = category;
              });
            }
          }
          if (laneKey.subKeys === 'sob-l1-categories' && this.boardId == Constants.boardIdForCRMDeals) {
            if (laneKey.config.value) {
              let newCategoryValue = laneKey.config.value;
              this._kService.getL2Categories(newCategoryValue).subscribe(category => {
                this.subCategoriesOfNewCategories = category;
              });
            }
          }
          if (laneKey.subKeys === 'deal-type' && this.boardId == Constants.boardIdForSobOpportunity) {
            this.dealTypeList = laneKey.config.dataList;
            this.dealTypeListSearch = this.dealTypeList;

            let poValue = laneKey.config.value;
            if (poValue !== null && poValue === 'New category') {
              this.isDealTypeNewCategories = true;
            }
            else {
              this.isDealTypeNewCategories = false;
            }
            if (this.isDealTypeNewCategories) {
              const newCategoriesElement = this.laneArrayKeys.find((element) => element.subKeys === 'new-categories');
              if (newCategoriesElement && newCategoriesElement.config.value) {
                newCategoriesElement.config.optional = true;
              }
              const newCategoryListElement = this.laneArrayKeys.find((element) => element.subKeys === 'new-category-list');
              if (newCategoryListElement && newCategoryListElement.config.values) {
                this.isNewCategories = true;
                newCategoryListElement.config.optional = true;
              }
            }
          }
        }


      },
      (err: any) => console.error("Error while getting subdivision lane config", err)
    );
  }
  calculateScoring(laneArrayKeys: any) {
    let totalValue = 0;
    let totalPossibleValue = 0;

    // Iterate over each object in the array
    laneArrayKeys.forEach(item => {
      // Extract values from the object
      const { config } = item;
      let { value } = config;
      if (!value) {
        value = 0;
      }
      // Ensure value is numeric
      const numericValue = parseFloat(value);

      // Check if numericValue is valid
      if (!isNaN(numericValue)) {
        totalValue += numericValue;
        // Assuming that possible value is 100 (you can change this according to your requirements)
        totalPossibleValue += 100;
      }
    });

    // Calculate percentage
    this.percentage = Math.round((totalValue / totalPossibleValue) * 100);
    // Determine strength level based on percentage
    this.strengthLevel = '';
    if (this.percentage >= 80) {
      this.strengthLevel = 'Strong';
    } else if (this.percentage >= 60) {
      this.strengthLevel = 'Intermediate';
    } else {
      this.strengthLevel = 'Weak';
    }
  }
  loadPlants() {
    console.log("loadPlants")
    this.isLoading = true;
    this.settingServ.fetchPlantByUserId(this.sobUserId).subscribe(response => {
      this.isLoading = false;
      this.plants = response['result'];
      this.plantOptions = this.plants;
      this.plantOptions.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

      // this.plantOptionsAllIds = this.plantOptionsSelected.map(element => element.id);
    });
  }
  filterOptionsPlants(event) {
    this.plantOptions = this.plants.map(a => ({ ...a }));
    const filterValue = event.target.value.toLowerCase();
    this.plantOptions = this.plants.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  onApplyPlants() {
    this.console.log('onApplyPlants');
    // const selectedPlantsList = this.selectedPlantsForMeeting.map(item => item.id);
    // TODO: Filter Data using plant ids;
  }
  fileUploadForField(event, formItem: any) {
    this.isLoading = true;
    if (event[0]) {
      this.fileForFields[formItem.field] = event[0];
    }
    else {
      this.fileForFields[formItem.field] = event.target.files[0];
    }
    console.log('fileUploadForField file form item', event, formItem)
  }
  downloadSingleDocument(id) {
    this._kService.downloadSingleUploadDocument(id).subscribe(res => {
      this.downloadSheetCommonMethod(res);

    })
  }
  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    let fileName = resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }
  removeSelectedFileForField(formItem: any) {
    if (this.fileForFields[formItem.field]) {
      this.fileForFields[formItem.field] = null;
    }
  }
  uploadAttachemet(formItem: any) {
    let file = this.fileForFields[formItem.field];
    this.console.log('uploadAttachemet', file);
    if (this.fileForFields[formItem.field]) {
      this.isLoading = true;
      const uploadData = new FormData();
      uploadData.append('file', file, file.name);
      this._kService.uploadSubDocumentsForConfig(uploadData, null, null, this.laneTaskIDDetail.id, formItem.field, "config", null).subscribe(file => {
        if (file['status'] == 200) {
          if (this.fileForFields[formItem.field]) {
            this.fileForFields[formItem.field] = null;
          }
          this.isLoading = false;
          this.getLaneDetailsById();
        }

      });
    }
  }
  filterOptionsConfigDataList(formItem: any): void {
    this.console.log('filterOptionsConfigDataList', formItem);
    const searchTerm = this.searchControl.toLowerCase();
    formItem.config.filteredOptions = formItem.config.dataList.filter(option =>
      option.toLowerCase().includes(searchTerm)
    );
    if (formItem.config.filteredOptions.length > 100) {
      formItem.config.filteredOptions.slice(0, 100);
    }
  }
  getFilteredOptions(formItem: any): string[] {
    // Return filtered options if available, otherwise return all options
    if (formItem.config.filteredOptions && formItem.config.filteredOptions.length > 100) {
      formItem.config.filteredOptions = formItem.config.filteredOptions.slice(0, 100);
    }
    return formItem.config.filteredOptions || formItem.config.dataList.slice(0, 100);
  }
  getSelectionChange(event: any, formItem) {
    this.console.log('getSelectionChange', event);
    formItem.config.error = null;
    formItem.config.values = event;
  }
  searchChange(event: any, formItem) {

    this.console.log('filterOptionsConfigDataList', formItem);

    formItem.config.filteredOptions = formItem.config.dataList.filter(option =>
      option.toLowerCase().includes(event.toLowerCase())
    );

    if (formItem.config.filteredOptions.length > 100) {
      formItem.config.filteredOptions.slice(0, 100);
    }
  }

  openAddCEOSelect() {
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardId
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    let tQuery = [];
    tQuery.push({
      "field": "data.roletype.raw",
      "value": "CEO"
    })
    query['query']['nestedWhere']["roleType_query"] = {};
    query['query']['nestedWhere']["roleType_query"]["type"] = "search";
    query['query']['nestedWhere']["roleType_query"]["operator"] = "or";
    query['query']['nestedWhere']["roleType_query"]["value"] = tQuery;


    for (let index = 0; index < this.laneArrayKeys.length; index++) {
      const element = this.laneArrayKeys[index];
      if (element.config.field == 'account' && element.config.value !== '') {
        this.accountId = element.config.value;
      }
    }
    if (!this.accountId) {

      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please select Account first."
      });
      return;
    }
    else {
      let tQuery = [];
      tQuery.push({
        "field": "data.account.raw",
        "value": this.accountId
      })
      query['query']['nestedWhere']["account_query"] = {};
      query['query']['nestedWhere']["account_query"]["type"] = "search";
      query['query']['nestedWhere']["account_query"]["operator"] = "or";
      query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    }
    obj['nestedWhere'] = query['query']['nestedWhere'];
    this._kService.getLaneTask(obj).subscribe(res => {
      if (res == null) {
        return;
      }
      else {
        this.accountContactCeoList = res['result'];
        this.accountContactCeoListSearch = this.accountContactCeoList
      }
      this.pageIndex++;
    },
      (error) => {
        console.log('getLaneTask error', error)
      }
    )

  }

  filterOptionsCeo(event: any, formItem) {
    this.accountContactCeoListSearch = this.accountContactCeoList.filter(option =>
      (option.name).toLowerCase().includes(event.toLowerCase())
    );
  }
  applyCeo(selection: any) {
    const s = selection.map(value => value.id);
    this.laneArrayKeys.forEach(element => {
      if (element.config.field === 'add-ceo') {
        element.config.values = s;
      }
    });
  }

  selectedBusinessId: any = '';
  selectedUsers = [];
  searchControlOwner = '';

  loadUsers() {
    if (this.boardId === this.boardIdForCRMContacts) {
      this.selectedBusinessId = Constants.businessIdForCRM;
    } else if (this.boardId === this.boardIdForSobContacts) {
      this.selectedBusinessId = Constants.businessIdForSOB;
    }
    let obj = {};
    obj['from'] = 0;
    obj['size'] = 100;
    obj['businessId'] = this.selectedBusinessId;
    this.settingServ.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      let userList = [];
      for (let i = 0; i < list['result'].length; i++) {
        let obj = list['result'][i];
        let tmp = list['result'][i].userTeams;
        let teamList = [];
        if (tmp) {
          for (let k = 0; k < tmp.length; k++) {
            teamList.push(tmp[k].id);
          }
          obj.userTeams = teamList;
          userList.push(obj)
        } else {
          obj.userTeams = [];
          userList.push(obj)
        }
      }
      userList.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.userList = userList;
      this.userListSearch = this.userList;
      this.console.log("user", this.userList);
    });
  }

  onPrivateOwnerSelectionChange(selection: any) {
    this.laneArrayKeys.forEach(element => {
      if (element.config.field === 'private-owner') {
        element.config.values = selection.map(value => value.id);
      }
    });
  }

  filterOptionsPrivateOwner(event) {
    const filterValue = event.toLowerCase();
    this.userListSearch = this.userList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  setOptionalConfig(element, isCEO, isEA) {
    if (isCEO) {
      if (['add-ceo', 'private-owner', 'mobile-number', 'email'].includes(element.subKeys)) {
        element.config.optional = false;
      }
    } else if (isEA) {
      if (['add-ceo', 'private-owner'].includes(element.subKeys)) {
        element.config.optional = true;
      } else if (['mobile-number', 'email'].includes(element.subKeys)) {
        element.config.optional = false;
      }
    } else {
      if (['add-ceo', 'private-owner'].includes(element.subKeys)) {
        element.config.optional = false;
      } else if (['mobile-number', 'email'].includes(element.subKeys)) {
        element.config.optional = true;
      }
    }
  }
  setOptionalConfigWithValues(element, isCEO, isEA) {
    if (isCEO) {
      if (['add-ceo', 'private-owner', 'mobile-number', 'email'].includes(element.subKeys)) {
        element.config.optional = false;
      }
    } else if (isEA) {
      if (['add-ceo', 'private-owner'].includes(element.subKeys)) {
        element.config.optional = true;
      } else if (['mobile-number', 'email'].includes(element.subKeys)) {
        element.config.optional = false;
      }
    } else {
      if (['add-ceo', 'private-owner'].includes(element.subKeys)) {
        element.config.optional = false;
        element.config.values = [];
        element.config.value = '';
        this.selectedCEOIds = [];
        this.selectedUsers = [];
      } else if (['mobile-number', 'email'].includes(element.subKeys)) {
        element.config.optional = true;
      }
    }
  }
}
