<!-- Desktop Version -->
<div *ngIf="!pagesService.checkMobileScreen()">
  <!-- Tab Switcher / Month Date picker-->
   <div>
     <div class="month-strip" style="width: 100%;">
       <ul class="list-tab">
         <li [ngClass]="{'active': selectedTab === 'ALL'}" (click)="meetingTabSelection('ALL')">
           All Meetings
         </li>
         <li [ngClass]="{'active': selectedTab === 'MOM'}" (click)="meetingTabSelection('MOM')">
           With MOM
         </li>
         <li class="date-picker">
           <mat-form-field class="mog3_matinput meeting_month_picker" appearance="outline">
             <input matInput [matDatepicker]="dp" [formControl]="date" (click)="dp.open()" readonly>
             <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
             <mat-datepicker #dp startView="year" (yearSelected)="chosenYearHandler($event)"
               (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker"></mat-datepicker>
           </mat-form-field>
         </li>
         <li>
           <button mat-flat-button class="mog3_primary_flat_btn add-mom mr-10" aria-label="Save" matTooltip="Refresh" matTooltipClass="new-tooltip" (click)="newMeetingDialog()"><i class="ri-add-line"></i> New Meeting</button>
         </li>
         <li>
           <button mat-flat-button class="mog3_primary_flat_btn add-mom refresh-btn" aria-label="Save" matTooltip="Refresh" matTooltipClass="new-tooltip" (click)="refreshMeetingFromAzure()">
           <i class="ri-refresh-line"></i> Refresh
         </button>
         </li>
       </ul>
     </div>
   </div>
   
   <!-- Meeting View Panel -->
   <div fxLayout="row" class="border-top">
       <!-- Meeting Listing -->
       <div fxFlex="30">
          <div class="meeting-tabs scrollbar-1">
            <div *ngFor="let data of datewisemap | keyvalue">
              <div class="meeting-date">{{ data.key }}</div>
              <ul class="meeting-list" *ngFor="let listdata of data.value">
                <li
                  [ngClass]="{ active: listdata.isSelected === true }"
                  (click)="onClickNavigation(listdata)">
                  <!-- <div class="adhoc-batch hunting" *ngIf="huntingUserId && sobUserId && listdata.selectedPlatform?.toUpperCase() === 'HUNTING'">
                    {{ listdata.selectedPlatform || "Hunting/SOB" }}
                </div>
                <div class="adhoc-batch sob" *ngIf="huntingUserId && sobUserId && listdata.selectedPlatform?.toUpperCase() === 'SOB'">
                    {{ listdata.selectedPlatform || "Hunting/SOB" }}
                </div>
                
                

                  <div class="adhoc-batch hunting" *ngIf="huntingUserId && !sobUserId">
                    {{ listdata.selectedPlatform || "Hunting" }}
                  </div>
                  <div class="adhoc-batch hunting" *ngIf="listdata.selectedPlatform=='SRM'">
                    {{ listdata.selectedPlatform || "SRM" }}
                  </div>
                  <div class="adhoc-batch sob" *ngIf="!huntingUserId && sobUserId">
                    {{ listdata.selectedPlatform || "SOB" }}
                  </div>               -->
                  <div class="adhoc-batch ad-hock" *ngIf="listdata.meetingCreatedFromCRM == true">
                    Ad-hock Meeting
                  </div>
                  <div class="adhoc-batch ad-hock" *ngIf="listdata?.momType == 'DRAFT'">
                    DRAFT MOM
                  </div>
                  <h2>{{ listdata.subject }}</h2>
                  <h5>Organiser: {{ listdata.organizer.emailAddress.address }}</h5>
                  <h4>
                    Time: {{ listdata.start.time | date : "short" }} to
                    {{ listdata.end.time | date : "short" }}
                  </h4>
                </li>
              </ul>
            </div>
          </div>
       </div>
     
       <!-- Meeting Detail -->
       <div class="border-right-1" fxFlex="50" *ngIf="meetingDetail">
         <div *ngIf="!isRefresh" class="meeting-detail-group">
           <div *ngIf="!isLoading">
             <div fxLayout="row">
               <!-- Select Platform -->
               <!-- <div class="formfield w-p190" *ngIf="isPlatformDropdownVisible">
                 <label class="modal-input-label-2 required">Select Platform</label>
                 <mat-form-field appearance="outline" class="mat-select deep-0">
                   <mat-select placeholder="Select Platform" [(ngModel)]="selectedPlatform" (selectionChange)="onSelectedValueChange()">
                     <mat-option *ngFor="let platform of platformOptions" [value]="platform.value">
                       {{ platform.display }}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>
               </div>             -->

               <!-- Select Account -->
               <!-- <div class="formfield ml-10 w-p190">
                <div class="field-container">
                  <label class="modal-input-label-2 required">Select Account</label>
                  <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0">
                    <mat-select #sa placeholder="Select Account" multiple [panelClass]="'select-panel-class'" [(ngModel)]="selectedAccountsForMOM" (selectionChange)="onApplyAccounts()">
                      <mat-form-field class="mat-input icon" appearance="outline">
                        <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlAccounts" (keyup)="filterOptionsAccounts($event)" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                          <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="rgba(111,111,112,1)"></path>
                        </svg>
                      </mat-form-field>
                      <div class="select-options">
                        <mat-option *ngFor="let account of accountDetailsTrimmed" [value]="account">{{ account.name }}</mat-option>
                      </div>
                      <div class="select-footer">
                        <button class="filter-btn clear" (click)="clearAccounts(); selectedAccountsForMOM = []; sa.close()">Clear</button>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
               </div> -->
              

               <!-- Select Plant -->
               <!-- <div class="formfield ml-10 w-p190">
                 <div class="field-container">
                 <label class="modal-input-label-2">Select Plant</label>

                 <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0">
                   <mat-select #sl2 placeholder="Select Plants" multiple [panelClass]="'select-panel-class'" [(ngModel)]="selectedPlantsForMeeting" (selectionChange)="onApplyPlants()">
                     <mat-form-field class="mat-input icon" appearance="outline">
                       <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlPlants" (keyup)="filterOptionsPlants($event)"/>
                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                         <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="rgba(111,111,112,1)"></path>

                       </svg>
                     </mat-form-field>
                     <div class="select-options">
                       <mat-option *ngFor="let plant of plantDetailsTrimmed" [value]="plant">{{ plant.name }}</mat-option>
                     </div>
                     <div class="select-footer">
                       <button class="filter-btn clear" (click)="clearPlants(); searchControlPlants = ''; selectedPlantsForMeeting = []">Clear</button>
                     </div>
                   </mat-select>               
                 </mat-form-field>
               </div>
               </div>  -->

               <!-- Select Opportunity -->
               <!-- <div class="formfield ml-10 w-p190" *ngIf="selectedPlatform === 'SOB'">
                 <label class="modal-input-label-2">Select Opportunity</label>
                 <mat-form-field
                   appearance="outline"
                   class="mat-select mat-select-search deep-0">
                   <mat-select
                     #s2
                     placeholder="Select Opportunity"
                     multiple
                     [(ngModel)]="selectedOpportunity"
                     [disabled]="!opportunityList || opportunityList.length === 0"
                     (selectionChange)="onApplyOpportunity()">
                     <div class="custom-panel search-with-options">
                       <mat-option
                         *ngFor="let opportunity of opportunityList"
                         [value]="opportunity"
                         >{{ opportunity.displayName }}</mat-option>
                     </div>
                     <div
                       fxLayout="row"
                       fxLayoutAlign="flex-end space-between"
                       class="filter-btn-section">
                       <button
                         class="filter-btn clear"
                         (click)="clearOpportunity(); selectedOpportunity = []">
                         Clear
                       </button>
                     </div>
                   </mat-select>
                 </mat-form-field>
               </div> -->

               <!-- Select Deals -->
               <!-- <div class="formfield ml-10 w-p190" *ngIf="selectedPlatform === 'Hunting'">
                 <label class="modal-input-label-2">Select Deals</label>
                 <mat-form-field
                   appearance="outline"
                   class="mat-select mat-select-search deep-0">
                   <mat-select
                     #s2
                     placeholder="Select Deals"
                     multiple
                     [panelClass]="'select-panel-class'"
                     [(ngModel)]="selectedDeals"
                     [disabled]="!dealsList || dealsList.length === 0"
                     (selectionChange)="onApplyDeals()">
                     <div class="select-options">
                       <mat-option *ngFor="let deal of dealsList" [value]="deal">{{
                         deal.displayName
                       }}</mat-option>
                     </div>
                     <div
                     class="select-footer">
                       <button
                         class="filter-btn clear"
                         (click)="clearDeals(); selectedDeals = []; s2.close()">
                         Clear
                       </button>
                     </div>
                   </mat-select>
                 </mat-form-field>
               </div> -->

               <!-- <div class="formfield ml-10 w-p190" *ngIf="selectedPlatform === 'SRM'" >
                 <div class="field-container">
                   <label class="modal-input-label-2 required">Select Supplier</label>
                   <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0">
                       <mat-select #sa placeholder="Select Supplier" multiple [panelClass]="'select-panel-class'" [(ngModel)]="selectedSupplier" (selectionChange)="onApplySupplier()">
                           <mat-form-field class="mat-input icon" appearance="outline">
                               <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlSupplier" (keyup)="filterOptionsSupplier($event)" />
                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                   <path
                                       d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                                       fill="rgba(111,111,112,1)"
                                   ></path>
                               </svg>
                           </mat-form-field>
                           <div class="select-options">
                               <mat-option *ngFor="let supplier of supplierList" [value]="supplier">{{ supplier.name }}</mat-option>
                           </div>
                           <div class="select-footer">
                               <button
                                   class="filter-btn clear"
                                   (click)="
                                     clearAccounts();
                                     selectedSupplier = [];
                                     sa.close()"
                               >
                                   Clear
                               </button>
                           </div>
                       </mat-select>
                   </mat-form-field>
               </div>
               </div> -->

             </div>
             <br/>
             <!-- Meeting SUbject -->
             <h1>{{ meetingDetail.subject }}</h1>
             <!-- Meeting Time -->
             <h3>
               <span> </span>
               <span
                 >Time : {{ meetingDetail.starttime | date : "short" }} to
                 {{ meetingDetail.endtime | date : "short" }}
               </span>
             </h3>
             <!-- Meeting Body -->
             <div class="meeting-detail">
               <div [innerHtml]="meetingDetail.body"></div>
             </div>
     
             <!-- Showing Latest MOM And ADD MOM-->
             <hr *ngIf="momDetails.fetchedMOMContent" />
             <div class="mom">
              <mat-tab-group class="outline-tab addTask-tab" animationDuration="0ms" mat-align-tabs="start" *ngIf="enableAddMomButton">
                <mat-tab>
                  <ng-template mat-tab-label>
                    MOM
                  </ng-template>
                  <div class="mom-content" *ngIf="momDetails.fetchedMOMContent">
                 
                    <div class="d-flex justify-content-between">
                      <p>
                        <strong class="bold-text">MOM - Details : </strong> 
                        <span class="grey-text">{{ momDetails.fetchedMOMCreationDate | date: "short" }}</span>
                      </p>
                    </div>
                    
                    <p>
                      <strong class="bold-text">Plant: </strong> 
                      <span class="grey-text">{{ momDetails.fetchedMOMPlantsString }}</span>
                    </p>
                    
                    <p>
                      <strong class="bold-text">Account: </strong> 
                      <span class="grey-text">{{ momDetails.fetchedMOMAccountsString }}</span>
                    </p>
                    
                    <!-- <p *ngIf="selectedPlatform === 'SRM'">
                      <strong class="bold-text">Supplier: </strong> 
                      <span class="grey-text">{{ fetchedMOMSuppliersString }}</span>
                    </p> -->
                    <p>
                      <strong class="bold-text">Value delivery: </strong> 
                      <span class="grey-text">{{ momDetails.fetchedMOMValue }}</span>
                    </p>
                    
                    <div [innerHTML]="momDetails.fetchedMOMContent"></div>
                    
                    <p>
                      <strong class="bold-text">Action date: </strong> 
                      <span class="grey-text">{{ momDetails.fetchedMOMNextActionDate | date: "short" }}</span>
                    </p>
                            
   
                    <!-- MOM Attachment -->
                    <ng-container
                      *ngIf="fetchedMOMAttachement && fetchedMOMAttachement.length">
                      <div class="file-upload-section">
                        <div
                          class="file-uploading-status d-flex justify-content-between">
                          <a
                            (click)="
                              downloadSingleDocument(fetchedMOMAttachement[0].id)">
                            <span class="file-name">
                              {{ momDetails.fetchedMOMAttachement[0].name }}
                              <mat-icon class="material-icons">download</mat-icon>
                            </span>
                          </a>
                        </div>
                      </div>
                    </ng-container>
                 </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    Task
                  </ng-template>
                  <div class="mom-content" *ngIf="taskDetails.id">
                    <div class="d-flex justify-content-between">
                      <p>
                        <strong class="addTask-Assign">Title : </strong> 
                        <span class="addTask-Assign">{{taskDetails.fetchedTaskTitle}}</span>
                      </p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p>
                        <strong class="addTask-Assign">Description : </strong> 
                        <span class="addTask-Assign">{{taskDetails.fetchedTaskDescription}}</span>
                      </p>
                    </div>
                    <!-- <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                    <div fxFlex="50" fxLayout="column" class="mr-10">
                      <div class="formfield-addTask">
                        <label>Outcome</label>
                        <mat-form-field class="mat-select mog3-matselect" appearance="outline" >
                          <mat-select placeholder="Select">
                            <mat-option class="mat-option" value="option1" selected>Option 1</mat-option>
                            <mat-option class="mat-option" value="option2">Option 2</mat-option>
                            <mat-option class="mat-option" value="option3">Option 3</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <section class="addTaskCheckbox mt-20" fxFlex="48">
                      <mat-checkbox class="example-margin" type="checked">Mark as complete</mat-checkbox>
                    </section>
                    </div> -->

                    <p>
                      <strong class="addTask-owner">Owner : </strong> 
                      <span class="addTask-owner">{{taskDetails.fetchedTaskOwnerValue}}</span>
                    </p>
                    <p>
                      <strong class="addTask-Assign">Assigned to : </strong> 
                      <span class="addTask-Assign">{{taskDetails.fetchedTaskAssigneeValue}}</span>
                    </p>
                    
                    <p>
                      <strong class="addTask-Assign"></strong> 
                      <span class="addTask-Assign"></span>
                    </p>

                    <p>
                      <strong class="addTask-owner">Task Created : </strong> 
                      <span class="addTask-owner">{{ taskDetails.fetchedTaskCreationDate | date: "short" }}</span>
                    </p>
                    <p>
                      <strong class="addTask-Assign">Due date: </strong> 
                      <span class="addTask-Assign">{{ taskDetails.fetchedTaskDueDate }}</span>
                    </p>
                 </div>
                </mat-tab>
              </mat-tab-group>
              
               <hr *ngIf="momDetails.fetchedMOMContent" />
               <!-- ADD MOM Button -->
               <div class="mb-10" fxLayout="row" fxLayoutAlign="flex-start center">
                 <button
                 *ngIf="enableAddMomButton"
                   (click)="toggleVisibility()"
                   mat-flat-button
                   class="mog3_primary_flat_btn add-mom"
                   aria-label="Save"
                   matTooltip="Add MOM"
                   matTooltipClass="new-tooltip">
                   {{momDetails.addMomDisplayText}}
                 </button>
               </div>
             
               <ng-container *ngIf="!isHidden">
                <mat-tab-group class="outline-tab addTask-tab" animationDuration="0ms" mat-align-tabs="start">
                  <!-- Add new MOM -->
                  <mat-tab>
                    <ng-template mat-tab-label>
                      Add MOM
                    </ng-template>
                     <!-- NEW MOM -->
                      <div *ngIf="!isHidden">
                        <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                          <div fxFlex="30" fxLayout="column" class="mr-10">
                            <div class="formfield-mom">
                              <label>Value delivery*</label>
                              <mat-form-field appearance="outline" class="mog3-matselect">
                                <mat-select
                                  [(value)]="selectedValue"
                                  placeholder="Select Option">
                                  <div class="custom-panel">
                                    <mat-option value="YES"> YES </mat-option>
                                    <mat-option value="NO"> NO </mat-option>
                                  </div>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          
                          <div fxFlex="30" fxLayout="column" class="mr-10">
                            <div class="formfield-mom">
                              <label>MOM to be shared with</label>
                              <app-custom-multiple-select [label]="attendeeLabel" 
                                  [selectedValues]="selectedAttendees" 
                                  [availableOptions]="allUserListSearch" 
                                  [searchControl]="searchControlOwner" 
                                  [valueCountView]="true"
                                  [selectedHighlight]="false"
                                  (selectionChange)="onAttendeeSelectionChange($event)" 
                                  (searchChange)="filterOptionsAttendees($event)">
                              </app-custom-multiple-select>
                            </div>
                          </div>                
                          <div fxFlex="30" fxLayout="unset">
                            <div class="formfield-mom" style="display: flex; align-items: center;">
                            
                              <mat-slide-toggle style="margin-top: 12px;" class ="mat-slider-toggle pl-15 mt-15" (change)="sendMail($event.checked)">Share MOM with all attendees</mat-slide-toggle>
                            
                            </div>
                          </div>
                          
                          <div fxFlex="10" fxLayout="column">
                          </div>
                        </div>
                        <quill-editor
                          class="mb-10"
                          [(ngModel)]="content"
                          [modules]="modules">
                        </quill-editor>
                        
                        <div
                          class="mt-10"
                          fxLayout="row"
                          fxLayoutAlign="space-between center">
                          <div fxLayout="row" class="field-width" style="width: 42%">
                            <div class="formfield">
                              <label class="mr-10">Next Action Date</label>
                              <mat-form-field
                                class="mog3_matinput lane-card-input datePicker"
                                appearance="outline">
                                <input
                                  (focus)="effectivePicker.open()"
                                  matInput
                                  [matDatepicker]="effectivePicker"
                                  placeholder="Select Date"
                                  [(ngModel)]="nextActionDate"
                                  [min]="minDateForNextAction"
                                  readonly
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="effectivePicker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #effectivePicker></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="d-flex mt-10 mb-20">
                            <section aria-label="documents" class="cb_fileinput">
                              <ng-container *ngIf="file == null">
                                <div
                                  draggable="true"
                                  class="dragArea config"
                                  ngFileDragDrop
                                  (fileDropped)="fileUpload($event)">
                                  <span class="lbl">
                                    <input
                                      style="display: none"
                                      type="file"
                                      (change)="fileUpload($event)"
                                      #fileInput
                                    />
                                    <span
                                      (click)="fileInput.value = ''; fileInput.click()">
                                      <mat-icon class="material-icons"
                                        >attachment</mat-icon>
                                    </span>
                                  </span>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="file != null">
                                <div class="file-upload-section">
                                  <div class="d-flex flex-column w-100">
                                    <div
                                      class="file-uploading-status d-flex justify-content-between">
                                      <span class="file-name">
                                        {{ file.name }}
                                        <a>
                                          <i
                                            class="ri-close-line"
                                            (click)="rmeoveSelectedFile()"
                                          ></i>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </section>
  
                            <button
                              mat-flat-button
                              class="mog3_primary_flat_btn cancel-mom mr-10"
                              aria-label="Save"
                              matTooltip="cancel MOM"
                              matTooltipClass="new-tooltip"
                              (click)="cancelMOM()">
                              Cancel
                            </button>
                            <button
                            mat-flat-button
                            class="mog3_primary_flat_btn add-mom mr-10"
                            aria-label="Save"
                            matTooltip="Save as Draft"
                            matTooltipClass="new-tooltip"
                            (click)="postMOM('DRAFT')">
                            Save as Draft
                            </button>
                            <button
                              mat-flat-button
                              class="mog3_primary_flat_btn add-mom"
                              aria-label="Save"
                              matTooltip="Submit MOM"
                              matTooltipClass="new-tooltip"
                              (click)="postMOM('SUBMIT')">
                              Submit MOM
                            </button>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                  </mat-tab>
                  <!-- Add new Task -->
                  <mat-tab>
                    <ng-template mat-tab-label>
                      Add Task
                    </ng-template>
                    <div *ngIf="!isHidden">
                      <div fxLayout="row wrap" fxLayoutAlign="flex-start">                      
                        <div fxFlex="32" fxLayout="column" class="mr-10">
                          <div class="formfield-addTask">
                            <label>Owner</label>
                            <app-custom-multiple-select [label]="attendeeLabel" 
                                [selectedValues]="selectedTaskOwners" 
                                [availableOptions]="allTaskOwnerUserListSearch" 
                                [searchControl]="searchControlOwner" 
                                [valueCountView]="true"
                                [selectedHighlight]="false"
                                (selectionChange)="onTaskOwnerSelectionChange($event)" 
                                (searchChange)="filterOptionsTaskOwner($event)">
                            </app-custom-multiple-select>
                          </div>
                        </div>
                        
                        <div fxFlex="32" fxLayout="column" class="mr-10">
                          <div class="formfield-addTask">
                            <label>Assign to</label>
                            <app-custom-multiple-select [label]="attendeeLabel" 
                                [selectedValues]="selectedTaskAssignees" 
                                [availableOptions]="allTaskAssigneeUserListSearch" 
                                [searchControl]="searchControlOwner" 
                                [valueCountView]="true"
                                [selectedHighlight]="false"
                                (selectionChange)="onTaskAssigneeSelectionChange($event)" 
                                (searchChange)="filterOptionsTaskAsshignee($event)">
                            </app-custom-multiple-select>
                          </div>
                        </div>

                        <div fxFlex="32" fxLayout="column" class="mr-10">
                          <div class="formfield-addTask">
                            <label>Collaborator</label>
                            <app-custom-multiple-select [label]="attendeeLabel" 
                                [selectedValues]="selectedTaskCollaborators" 
                                [availableOptions]="allTaskCollaboratorUserListSearch" 
                                [searchControl]="searchControlOwner" 
                                [valueCountView]="true"
                                [selectedHighlight]="false"
                                (selectionChange)="onTaskCollaboratorsSelectionChange($event)" 
                                (searchChange)="filterOptionsTaskCollaborator($event)">
                            </app-custom-multiple-select>
                          </div>
                        </div>
                          
                        <div class="formfield-addTask w-100 mt-10 mr-10">
                          <label>Title</label>
                           <mat-form-field class="mat-input mog3-matselect" appearance="outline" >
                            <input matInput placeholder="Enter task title" value=""[(ngModel)]="taskTitle" />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="formfield-addTask mr-10 mt-10">
                        <label>Description</label>
                          <mat-form-field appearance="outline" class="w-100 mog3-matselect">
                            <textarea matInput placeholder="Start typing the details..." [(ngModel)]="taskDescription"></textarea>
                        </mat-form-field>
                      </div>
                      
                      <div
                        class="mt-10"
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div fxLayout="row" class="field-width" style="width: 42%">
                          <div class="formfield formfield-addTask">
                            <label class="mr-10">Due Date</label>
                            <mat-form-field
                              class="mog3_matinput lane-card-input datePicker"
                              appearance="outline">
                              <input
                                (focus)="effectivePicker.open()"
                                matInput
                                [matDatepicker]="effectivePicker"
                                placeholder="Select Date"
                                [(ngModel)]="taskDueDate"
                                [min]="minDateForNextAction"
                                readonly
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="effectivePicker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #effectivePicker></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="d-flex mt-10">
                          <button
                            mat-flat-button
                            class="mog3_primary_flat_btn cancel-mom mr-10"
                            aria-label="Save"
                            matTooltip="cancel MOM"
                            matTooltipClass="new-tooltip"
                            (click)="cancelTask()">
                            Cancel
                          </button>
                          <button
                            mat-flat-button
                            class="mog3_primary_flat_btn add-mom"
                            aria-label="Save"
                            matTooltip="Submit MOM"
                            matTooltipClass="new-tooltip"
                            (click)="postTask()">
                            Submit Task
                          </button>
                        </div>
                      </div>
                      
                    </div>
                  </mat-tab>
                </mat-tab-group>
               </ng-container>
               
             </div>
           </div>
         </div>
       </div>

       <!-- Contact Panel -->
       <div fxFlex="20" *ngIf="meetingDetail">      
         <div class="meeting-member-list" *ngIf="meetingContacts && !isRefresh">
           <h2>Contacts</h2>
           <ul>
             <li *ngFor="let contact of meetingContacts">
               <div
                 style="background-color: #b5f2ba; color: #272727"
                 class="user-name-letter ">
                 {{ contact.name[0] }}
               </div>
               <div class="user-info-seciton">
                 <h3>{{ contact.name }}</h3>
                 <h5 *ngIf="contact.organiser">(Organiser)</h5>
                 <h4>{{ contact.email }}</h4>
               </div>
             </li>
           </ul>
           <h2 *ngIf="momAttendees.length > 0">Vedanta Attendees</h2>
           <ul>
             <li *ngFor="let attendee of momAttendees">
               <div style="background-color: #b5f2ba; color: #272727"  class="user-name-letter ">
                 {{ attendee.name[0] }}
               </div>
               <div class="user-info-seciton">
                 <h3>{{ attendee.name }}</h3>
                 <h4>{{ attendee.email }}</h4>
               </div>
             </li>
           </ul>
         </div>
       </div>

       <div *ngIf="!meetingDetail" class="mt-30">
        No Document to Show.
       </div>      
   </div>
</div>
