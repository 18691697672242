<!-- Filter Section -->
<section class="section mb-0">
  <div class="atom-row  ml-5 mb-10">  
    <!-- Market -->
    <div class="w-p135 mr-10 mb-0">
      <div class="input-group">
        <app-custom-multiple-select [label]="'Market'" 
                    [selectedValues]="filterMarket"
                    [availableOptions]="marketListAvailable" 
                    [searchControl]="scMarket"
                    (selectionChange)="updateSelection('filterMarket', $event)"
                    (searchChange)="filterList($event, 'marketListAvailable', 'defaultMarketList')">
        </app-custom-multiple-select>    
      </div> 
    </div> 

     <!-- Account/Group Name -->
     <div class="w-p180 mr-10 mb-0">
      <div class="input-group">
        <app-custom-select #cusComComplete [fieldData]="{
                'config' : accountConfigVedanta,
                'isFilter' : true
              }" (selectedAccountIdsChange)="groupSelected($event, accountConfigVedanta)">
        </app-custom-select>
      </div> 
    </div>

     <!-- Plant -->
    <div class="w-p180 mr-10 mb-0">
      <div class="input-group">
        <app-custom-select #cusComComplete [fieldData]="{
            'config' : plantConfigOpportunity,
            'isFilter' : true
          }" (selectedAccountIdsChange)="groupSelected($event,plantConfigOpportunity)">
        </app-custom-select>
      </div>
  </div>

  <!-- Meeting Category -->
  <div class="w-p170 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="'Meeting Category'" 
                  [selectedValues]="filterMeetingCategory"
                  [availableOptions]="meetingCategoryAvailable" 
                  [searchControl]="scMeetingCategory"
                  (selectionChange)="updateSelection('filterMeetingCategory', $event)"
                  (searchChange)="filterList($event, 'meetingCategoryAvailable', 'defaultMeetingCategoryAvailable')">
      </app-custom-multiple-select>    
    </div> 
  </div>

  <!-- Meeting Id -->
  <div class="w-p135 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="'Meeting Id'" 
                  [selectedValues]="filterMeetingIds"
                  [availableOptions]="meetingIdsAvailable" 
                  [searchControl]="scMeetingIds"
                  (selectionChange)="updateSelection('filterMeetingIds', $event)"
                  (searchChange)="filterArrayList($event, 'meetingIdsAvailable', 'defaultMeetingIds')">
      </app-custom-multiple-select>    
    </div> 
  </div>

  <!-- Product -->
  <div class="w-p135 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="'Product'" 
                  [selectedValues]="filterProduct"
                  [availableOptions]="productListAvailable" 
                  [searchControl]="scProduct"
                  (selectionChange)="updateSelection('filterProduct', $event)"
                  (searchChange)="filterList($event, 'productListAvailable', 'defaultProductListAvailable')">
      </app-custom-multiple-select>    
    </div> 
  </div>

  <!-- Variant -->
  <div class="w-p135 mr-10 mb-0">
    <div class="input-group">
      <app-custom-multiple-select [label]="'Variant'" 
                  [selectedValues]="filterVariant"
                  [availableOptions]="variantListAvailable" 
                  [searchControl]="scVariant"
                  (selectionChange)="updateSelection('filterVariant', $event)"
                  (searchChange)="filterList($event, 'variantListAvailable', 'defaultVariantListAvailable')">
      </app-custom-multiple-select>    
    </div> 
  </div>

  </div>
</section>

<!-- Desktop Version -->
<div *ngIf="!pagesService.checkMobileScreen()">
  <!-- Tab Switcher / Month Date picker-->
   <div>
     <div class="month-strip" style="width: 100%;">
       <ul class="list-tab">
         <li [ngClass]="{'active': selectedTab === 'ALL'}" (click)="meetingTabSelection('ALL')">
           All Meetings
         </li>
         <li [ngClass]="{'active': selectedTab === 'MOM'}" (click)="meetingTabSelection('MOM')">
           With MOM
         </li>
         <li class="date-picker">
           <mat-form-field class="mog3_matinput meeting_month_picker" appearance="outline">
             <input matInput [matDatepicker]="dp" [formControl]="date" (click)="dp.open()" readonly>
             <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
             <mat-datepicker #dp startView="year" (yearSelected)="chosenYearHandler($event)"
               (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker"></mat-datepicker>
           </mat-form-field>
         </li>
         <li>
           <button mat-flat-button class="mog3_primary_flat_btn add-mom mr-10" aria-label="Save" matTooltip="Refresh" matTooltipClass="new-tooltip" (click)="newMeetingDialog()"><i class="ri-add-line"></i> New Meeting</button>
         </li>
         <li>
           <button mat-flat-button class="mog3_primary_flat_btn add-mom refresh-btn" aria-label="Save" matTooltip="Refresh" matTooltipClass="new-tooltip" (click)="refreshMeetingFromAzure()">
           <i class="ri-refresh-line"></i> Refresh
         </button>
         </li>
       </ul>
     </div>
   </div>
   
   <!-- Meeting View Panel -->
   <div fxLayout="row" class="border-top">
       <!-- Meeting Listing -->
       <div fxFlex="30">
          <div class="meeting-tabs scrollbar-1">
            <div *ngFor="let data of datewisemap | keyvalue">
              <div class="meeting-date">{{ data.key }}</div>
              <ul class="meeting-list" *ngFor="let listdata of data.value">
                <li
                  [ngClass]="{ active: listdata.isSelected === true }"
                  (click)="onClickNavigation(listdata)">
                  <div class="adhoc-batch ad-hock" *ngIf="listdata.meetingCreatedFromCRM == true">
                    Ad-hock Meeting
                  </div>
                  <div class="adhoc-batch ad-hock" *ngIf="listdata?.momType == 'DRAFT'">
                    DRAFT MOM
                  </div>
                  <h2>{{ listdata.subject }}</h2>
                  <h5>Organiser: {{ listdata.organizer.emailAddress.address }}</h5>
                  <h4>
                    Time: {{ listdata.start.time | date : "short" }} to
                    {{ listdata.end.time | date : "short" }}
                  </h4>
                </li>
              </ul>
            </div>
          </div>
       </div>
     
       <!-- Meeting Detail -->
       <div class="border-right-1" fxFlex="50" *ngIf="meetingDetail">
         <div *ngIf="!isRefresh" class="meeting-detail-group">
           <div *ngIf="!isLoading">
             <div fxLayout="row">
             </div>
             <br/>
             <!-- Meeting SUbject -->
             <h1>{{ meetingDetail.subject }}</h1>
             <!-- Meeting Time -->
             <h3>
               <span> </span>
               <span
                 >Time : {{ meetingDetail.starttime | date : "short" }} to
                 {{ meetingDetail.endtime | date : "short" }}
               </span>
             </h3>

             <!-- Meeting Category -->
             <h3>
              <span> </span>
              <span> Category : {{ meetingDetail.meetingCategory }}</span>
              </h3>
              <!-- Meeting Account -->
             <h3>
              <span> </span>
              <span> Account : {{ meetingDetail.accounts }}</span>
              </h3>
              <!-- Meeting Plant -->
             <h3>
              <span> </span>
              <span> Plant : {{ meetingDetail.plants }}</span>
              </h3>

             <!-- Meeting Body -->
             <div class="meeting-detail">
               <div [innerHtml]="meetingDetail.body"></div>
             </div>
     
             <!-- Showing MOM/Task List And ADD MOM/Task-->
             <hr *ngIf="momDetails" />
             <div class="mom">
               <!-- ADD MOM Button -->
               <div class="mb-10" fxLayout="row" fxLayoutAlign="flex-start center">
                 <button (click)="toggleVisibility()"
                   mat-flat-button
                   class="mog3_primary_flat_btn add-mom"
                   aria-label="Save"
                   [matTooltip]="showAddButton"
                   matTooltipClass="new-tooltip">
                   {{showAddButton}}
                 </button>
               </div>
             
               <ng-container *ngIf="!isHidden">
                <mat-tab-group class="outline-tab addTask-tab" animationDuration="0ms" mat-align-tabs="start">
                  <!-- Add new MOM -->
                  <mat-tab>
                    <ng-template mat-tab-label>
                      Add MOM
                    </ng-template>
                     <!-- NEW MOM -->
                      <div *ngIf="!isHidden">
                        <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                          
                          <div fxFlex="30" fxLayout="column" class="mr-10">
                            <div class="formfield-mom">
                              <label>MOM to be shared with</label>
                              <app-custom-multiple-select [label]="attendeeLabel" 
                                  [selectedValues]="selectedAttendees" 
                                  [availableOptions]="allUserListSearch" 
                                  [searchControl]="searchControlOwner" 
                                  [valueCountView]="true"
                                  [selectedHighlight]="false"
                                  (selectionChange)="onAttendeeSelectionChange($event)" 
                                  (searchChange)="filterOptionsAttendees($event)">
                              </app-custom-multiple-select>
                            </div>
                          </div>                
                          <div fxFlex="30" fxLayout="unset">
                            <div class="formfield-mom" style="display: flex; align-items: center;">
                              <mat-slide-toggle style="margin-top: 12px;" class ="mat-slider-toggle pl-15 mt-15" (change)="sendMail($event.checked)">Share MOM with all attendees</mat-slide-toggle>
                            </div>
                          </div>
                          <div fxFlex="10" fxLayout="column">
                          </div>
                        </div>
                        <quill-editor
                          class="mb-10 w-100 comments-quill-editor"
                          [(ngModel)]="content"
                          [modules]="mommodules">
                        </quill-editor>
                        <div
                          class="mt-10"
                          fxLayout="row"
                          fxLayoutAlign="space-between center">
                          <div fxLayout="row" class="field-width" style="width: 42%">
                            <div class="formfield">
                              <label class="mr-10">Next Action Date</label>
                              <mat-form-field
                                class="mog3_matinput lane-card-input datePicker"
                                appearance="outline">
                                <input
                                  (focus)="effectivePicker.open()"
                                  matInput
                                  [matDatepicker]="effectivePicker"
                                  placeholder="Select Date"
                                  [(ngModel)]="nextActionDate"
                                  [min]="minDateForNextAction"
                                  readonly/>
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="effectivePicker">
                                </mat-datepicker-toggle>
                                <mat-datepicker #effectivePicker></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="d-flex mt-10 mb-20">
                            <section aria-label="documents" class="cb_fileinput">
                              <ng-container *ngIf="file == null">
                                <div
                                  draggable="true"
                                  class="dragArea config"
                                  ngFileDragDrop
                                  (fileDropped)="fileUpload($event)">
                                  <span class="lbl">
                                    <input
                                      style="display: none"
                                      type="file"
                                      (change)="fileUpload($event)"
                                      #fileInput/>
                                    <span
                                      (click)="fileInput.value = ''; fileInput.click()">
                                      <mat-icon class="material-icons"
                                        >attachment</mat-icon>
                                    </span>
                                  </span>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="file != null">
                                <div class="file-upload-section">
                                  <div class="d-flex flex-column w-100">
                                    <div
                                      class="file-uploading-status d-flex justify-content-between">
                                      <span class="file-name">
                                        {{ file.name }}
                                        <a>
                                          <i
                                            class="ri-close-line"
                                            (click)="rmeoveSelectedFile()">
                                          </i>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </section>
  
                            <button
                              mat-flat-button
                              class="mog3_primary_flat_btn cancel-mom mr-10"
                              aria-label="Save"
                              matTooltip="cancel MOM"
                              matTooltipClass="new-tooltip"
                              (click)="cancelMOM()">
                              Cancel
                            </button>
                            <button
                            mat-flat-button
                            class="mog3_primary_flat_btn add-mom mr-10"
                            aria-label="Save"
                            matTooltip="Save as Draft"
                            matTooltipClass="new-tooltip"
                            (click)="postMOM('DRAFT','WEB')">
                            Save as Draft
                            </button>
                            <button
                              mat-flat-button
                              class="mog3_primary_flat_btn add-mom"
                              aria-label="Save"
                              matTooltip="Submit MOM"
                              matTooltipClass="new-tooltip"
                              (click)="postMOM('SUBMIT','WEB')">
                              Submit MOM
                            </button>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                  </mat-tab>
                  <!-- Add new Task -->
                  <mat-tab>
                    <ng-template mat-tab-label>
                      Add Task
                    </ng-template>
                    <div *ngIf="!isHidden">
                      <div fxLayout="row wrap" fxLayoutAlign="flex-start">                      
                        <div fxFlex="32" fxLayout="column" class="mr-10">
                          <div class="formfield-addTask">
                            <label>Owner</label>
                            <app-custom-multiple-select [label]="attendeeLabel" 
                                [selectedValues]="selectedTaskOwners" 
                                [availableOptions]="allTaskOwnerUserListSearch" 
                                [searchControl]="searchControlOwner" 
                                [valueCountView]="true"
                                [selectedHighlight]="false"
                                (selectionChange)="onTaskOwnerSelectionChange($event)" 
                                (searchChange)="filterOptionsTaskOwner($event)">
                            </app-custom-multiple-select>
                          </div>
                        </div>
                        
                        <div fxFlex="32" fxLayout="column" class="mr-10">
                          <div class="formfield-addTask">
                            <label>Assign to</label>
                            <app-custom-multiple-select [label]="attendeeLabel" 
                                [selectedValues]="selectedTaskAssignees" 
                                [availableOptions]="allTaskAssigneeUserListSearch" 
                                [searchControl]="searchControlOwner" 
                                [valueCountView]="true"
                                [selectedHighlight]="false"
                                (selectionChange)="onTaskAssigneeSelectionChange($event)" 
                                (searchChange)="filterOptionsTaskAsshignee($event)">
                            </app-custom-multiple-select>
                          </div>
                        </div>

                        <div fxFlex="32" fxLayout="column" class="mr-10">
                          <div class="formfield-addTask">
                            <label>Collaborator</label>
                            <app-custom-multiple-select [label]="attendeeLabel" 
                                [selectedValues]="selectedTaskCollaborators" 
                                [availableOptions]="allTaskCollaboratorUserListSearch" 
                                [searchControl]="searchControlOwner" 
                                [valueCountView]="true"
                                [selectedHighlight]="false"
                                (selectionChange)="onTaskCollaboratorsSelectionChange($event)" 
                                (searchChange)="filterOptionsTaskCollaborator($event)">
                            </app-custom-multiple-select>
                          </div>
                        </div>
                          
                        <div class="formfield-addTask w-100 mt-10 mr-10">
                          <label>Title</label>
                           <mat-form-field class="mat-input mog3-matselect" appearance="outline" >
                            <input matInput placeholder="Enter task title" value=""[(ngModel)]="taskTitle" />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="formfield-addTask mr-10 mt-10">
                        <label>Description</label>
                          <mat-form-field appearance="outline" class="w-100 mog3-matselect">
                            <textarea matInput placeholder="Start typing the details..." [(ngModel)]="taskDescription"></textarea>
                        </mat-form-field>
                      </div>
                      
                      <div
                        class="mt-10"
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div fxLayout="row" class="field-width" style="width: 42%">
                          <div class="formfield formfield-addTask">
                            <label class="mr-10">Due Date</label>
                            <mat-form-field
                              class="mog3_matinput lane-card-input datePicker"
                              appearance="outline">
                              <input
                                (focus)="effectivePicker.open()"
                                matInput
                                [matDatepicker]="effectivePicker"
                                placeholder="Select Date"
                                [(ngModel)]="taskDueDate"
                                [min]="minDateForNextAction"
                                readonly
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="effectivePicker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #effectivePicker></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="d-flex mt-10">
                          <button
                            mat-flat-button
                            class="mog3_primary_flat_btn cancel-mom mr-10"
                            aria-label="Save"
                            matTooltip="cancel MOM"
                            matTooltipClass="new-tooltip"
                            (click)="cancelTask()">
                            Cancel
                          </button>
                          <button
                            mat-flat-button
                            class="mog3_primary_flat_btn add-mom"
                            aria-label="Save"
                            matTooltip="Submit MOM"
                            matTooltipClass="new-tooltip"
                            (click)="postTask()">
                            Submit Task
                          </button>
                        </div>
                      </div>
                      
                    </div>
                  </mat-tab>
                </mat-tab-group>
               </ng-container>
               <!-- MOM/Task Listing -->
               <hr *ngIf="enableAddMomButton" />
               <mat-tab-group class="outline-tab addTask-tab" animationDuration="0ms" mat-align-tabs="start">
                <mat-tab>
                  <ng-template mat-tab-label>
                    MOM
                  </ng-template>
                    <div class="mom-card-scroll">                
                    <div class="mom-content" *ngFor="let mom of momList">
                 
                      <p>
                        <strong class="bold-text">MOM - Details : </strong> 
                        <span class="grey-text">{{ mom.createdAt | date: "short" }}</span>
                      </p>
                      <p>
                        <strong class="bold-text">Attendees: </strong> 
                        <span class="grey-text">{{ getEmailFromUserDTOEmail(mom.attendees) }}</span>
                      </p>
                      <p>
                        <strong class="bold-text">Message: </strong> 
                        <span [innerHTML]="mom.message"></span>
                      </p>
                      
                      <p>
                        <strong class="bold-text">Action date: </strong> 
                        <span class="grey-text">{{ mom.nextActionDate | date: "short" }}</span>
                      </p>
                              
                      <!-- MOM Attachment -->
                      <ng-container
                        *ngIf="mom.attachments && mom.attachments.length">
                        <div class="file-upload-section">
                          <div
                            class="file-uploading-status d-flex justify-content-between">
                            <a
                              (click)="
                                downloadSingleDocument(mom.attachments[0].id)">
                              <span class="file-name">
                                {{ mom.attachments[0].name }}
                                <mat-icon class="material-icons">download</mat-icon>
                              </span>
                            </a>
                          </div>
                        </div>
                      </ng-container>
                    <!-- </div> -->
                    </div>
                    <a *ngIf="momList.length > 0 && !noMoreData" class="blue-anchor-2" (click)="onScroll($event)">Load More...</a>
                    </div>
                
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    Task
                  </ng-template>
                  <div class="mom-card-scroll">
                  <div class="mom-content" *ngFor="let task of taskList">
                      <p>
                        <strong class="addTask-Assign">Title : </strong> 
                        <span class="addTask-Assign">{{task.title}}</span>
                      </p>
                    
                      <p>
                        <strong class="addTask-Assign">Description : </strong> 
                        <span class="addTask-Assign">{{task.description}}</span>
                      </p>

                    <p>
                      <strong class="addTask-owner">Owner : </strong> 
                      <span class="addTask-owner">{{getEmailFromUserDTOEmail(task.owners)}}</span>
                    </p>
                    <p>
                      <strong class="addTask-Assign">Assigned to : </strong> 
                      <span class="addTask-Assign">{{getEmailFromUserDTOEmail(task.assignees)}}</span>
                    </p>
                    <p>
                      <strong class="addTask-Assign">Collaborators : </strong> 
                      <span class="addTask-Assign">{{getEmailFromUserDTOEmail(task.collaborators)}}</span>
                    </p>
                    
                    <p>
                      <strong class="addTask-Assign"></strong> 
                      <span class="addTask-Assign"></span>
                    </p>

                    <p>
                      <strong class="addTask-owner">Task Created : </strong> 
                      <span class="addTask-owner">{{ task.createdAt | date: "short" }}</span>
                    </p>
                    <p>
                      <strong class="addTask-Assign">Due date: </strong> 
                      <span class="addTask-Assign">{{ task.dueDate | date: "short" }}</span>
                    </p>
                  </div>
                  <a *ngIf="taskList.length > 0 && !tasknoMoreData" class="blue-anchor-2" (click)="onTaskScroll($event)">Load More...</a>
                  </div>
                </mat-tab>
              </mat-tab-group>
             </div>
           </div>
         </div>
       </div>

       <!-- Contact Panel -->
       <div fxFlex="20" *ngIf="meetingDetail">      
         <div class="meeting-member-list" *ngIf="meetingContacts && !isRefresh">
           <h2>Contacts</h2>
           <ul>
             <li *ngFor="let contact of meetingContacts">
               <div
                 style="background-color: #b5f2ba; color: #272727"
                 class="user-name-letter ">
                 {{ contact.name[0] }}
               </div>
               <div class="user-info-seciton">
                 <h3>{{ contact.name }}</h3>
                 <h5 *ngIf="contact.organiser">(Organiser)</h5>
                 <h4>{{ contact.email }}</h4>
               </div>
             </li>
           </ul>
           <h2 *ngIf="momAttendees.length > 0">Vedanta Attendees</h2>
           <ul>
             <li *ngFor="let attendee of momAttendees">
               <div style="background-color: #b5f2ba; color: #272727"  class="user-name-letter ">
                 {{ attendee.name[0] }}
               </div>
               <div class="user-info-seciton">
                 <h3>{{ attendee.name }}</h3>
                 <h4>{{ attendee.email }}</h4>
               </div>
             </li>
           </ul>
         </div>
       </div>

       <div *ngIf="!meetingDetail" class="mt-30">No Document to Show.</div>
   </div>
</div>
<section *ngIf="pagesService.checkMobileScreen()">
  <div class="mobile-date-picker">
    <div class="month-strip" style="width: 100%">
      All Meetings
      <div class="date-picker">
        <mat-form-field
          class="mog3_matinput meeting_month_picker"
          appearance="outline">
          <input
            matInput
            [matDatepicker]="dp"
            [formControl]="date"
            (click)="dp.open()"
            readonly/>
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker
            #dp
            startView="year"
            (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, dp)"
            panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- Meeting / MOM Tab -->
  <ng-container *ngIf="!showMeetingDetailsPopup && !showAdHocMeetingPopup">
    <div class="mobile-height">
      <mat-tab-group animationDuration="0ms" class="combine-tab pwa-tabs" mat-align-tabs="center" (selectedTabChange)="tabClick($event)">
      <!-- Meeting -->
      <mat-tab label="Meeting">
      <ng-template mat-tab-label> Meeting </ng-template>
      <div class="meeting-tabs scrollbar-1 pwa-meeting-tabs" [ngClass]="{'height-blank': !datewisemap || (datewisemap | keyvalue)?.length === 0}">
        <button
          class="btn-primary floating-add-btn pwa-refresh-button"
          mat-raised-button
          (click)="refreshMeetingFromAzure()">
          <i class="ri-refresh-line mr-10"></i>
          Refresh
        </button>
        <!-- Date wise showing Meetings -->
        <div class="no-meeting-found" *ngIf=" !datewisemap ||(datewisemap | keyvalue : customSortFn)?.length === 0">No Meetings</div>
        <div *ngFor="let data of datewisemap | keyvalue : customSortFn">
          <div class="meeting-date">{{ data.key }}</div>
          <ul class="meeting-list" *ngFor="let listdata of data.value">
            <li
              [ngClass]="{ active: listdata.isSelected === true }"
              (click)="onClickNavigation(listdata); toggleMeetingDetailsPopup()">
              <div
                class="adhoc-batch ad-hock"
                *ngIf="listdata.meetingCreatedFromCRM == true">
                Ad-hock Meeting
              </div>
              <div class="adhoc-batch ad-hock" *ngIf="listdata?.momType == 'DRAFT'">
                DRAFT MOM
              </div>
              <!-- Meeting Subject -->
              <h2>{{ listdata.subject }}</h2>
              <!-- Meeting Orgniser -->
              <h5>Organiser: {{ listdata.organizer.emailAddress.address }}</h5>
              <!-- Meeting time -->
              <h4>
                Time: {{ listdata.start.time | date : "short" }} to
                {{ listdata.end.time | date : "short" }}
              </h4>
            </li>
          </ul>
        </div>
      </div>
      <button
        class="btn-primary floating-add-btn"
        mat-raised-button
        color="warn"
        matTooltip="Refresh"
        matTooltipClass="new-tooltip"
        (click)="toggleAdHocMeetingPopup()">
        <i class="ri-add-fill"></i>
      </button>
    </mat-tab>
    <!-- MOM -->
    <mat-tab label="MOM">
      <ng-template mat-tab-label> MOM </ng-template>
      <div class="meeting-tabs scrollbar-1 pwa-meeting-tabs">
        <!--Refresh button -->
        <button
        class="btn-primary floating-add-btn pwa-refresh-button"
        mat-raised-button
        (click)="refreshMeetingFromAzure()">
        <i class="ri-refresh-line mr-10"></i>
        Refresh
      </button>
        <div *ngFor="let data of datewisemap | keyvalue">
          <div class="meeting-date">{{ data.key }}</div>
          <ul class="meeting-list" *ngFor="let listdata of data.value">
            <li [ngClass]="{ active: listdata.isSelected === true }"
              (click)="onClickNavigation(listdata); toggleMeetingDetailsPopup()">
            <div class="flex items-center justify-between">
            <a class="view-mom">View MOM  <i class="ri-arrow-right-s-line"></i></a>
            </div>
            <div class="adhoc-batch ad-hock" *ngIf="listdata.meetingCreatedFromCRM == true">
                Ad-hock Meeting
            </div>
            <div class="adhoc-batch ad-hock" *ngIf="listdata?.momType == 'DRAFT'">
                DRAFT MOM
            </div>
            <h2>{{ listdata.subject }}</h2>
            <h5>Organiser: {{ listdata.organizer.emailAddress.address }}</h5>
            <h4>
                Time: {{ listdata.start.time | date : "short" }} to
                {{ listdata.end.time | date : "short" }}
            </h4>
            </li>
          </ul>
        </div>
      </div>
      <button
        class="btn-primary floating-add-btn"
        mat-raised-button
        color="warn"
        matTooltip="Refresh"
        matTooltipClass="new-tooltip"
        (click)="toggleAdHocMeetingPopup()">
        <i class="ri-add-fill"></i>
      </button>
    </mat-tab>
  </mat-tab-group>
 </div>
 </ng-container>

  <!-- Meeting Detail -->
  <div class="popup" *ngIf="showMeetingDetailsPopup">
    <!-- Navigate back to meeting Listing -->
    <div class="popup-header-fixed">
      <!-- Go Back to Meeting Listing -->
      <div class="flex items-center justify-start popup-header">
        <i (click)="toggleMeetingDetailsPopup()" class="ri-arrow-left-line"></i>
        <h1>{{ meetingDetail.subject }}</h1>
      </div>
    </div>

    <!-- Meeting Detail -->
    <div *ngIf="!isRefresh" class="meeting-detail-group pwa-meeting-detail-group">
      <div *ngIf="meetingDetail">
        <!-- Meeting Subject -->
        <h1>{{ meetingDetail.subject }}</h1>
        <!-- Meeting Time -->
        <h3>
          <span> 
          </span>
          <span>
            Time : {{ meetingDetail.starttime | date : "short" }} to
            {{ meetingDetail.endtime | date : "short" }}
          </span>
        </h3>
        <!-- Meeting Category -->
        <h3><span> Category : {{ meetingDetail.meetingCategory }}</span></h3>
        <!-- Meeting Account -->
        <h3><span> Account : {{ meetingDetail.accounts }}</span></h3>
        <!-- Meeting Plant -->
        <h3><span> Plant : {{ meetingDetail.plants }}</span></h3>
        <!-- Meeting Body -->
        <div class="meeting-detail">
          <div [innerHtml]="meetingDetail.body"></div>
        </div>
        <hr *ngIf="fetchedMOMContent" />
        <hr *ngIf="momList" />
        <!-- Attendees List -->
        <div class="popup-footer justify-between pl-0">
          <div class="pwa-attendees-list" *ngIf="meetingContacts && !isRefresh">
            <label for="">Attendees:</label>
            <button
              class="pwa-list-view-button"
              mat-button
              [matMenuTriggerFor]="menu">
              {{ meetingContacts.length }} Contacts
              <span *ngIf="momAttendees.length > 0">
                + {{ momAttendees.length }} Attendees
              </span>
              <i class="ri-arrow-down-s-line ml-10"></i>
            </button>

            <mat-menu class="pwa-list-view-dropdown" #menu="matMenu">
              <h4 class="ml-10">Contacts</h4>
              <button mat-menu-item *ngFor="let contact of meetingContacts">
                <div
                  style="background-color: #b5f2ba; color: #272727"
                  class="user-name-letter">
                  {{ contact.name[0] }}
                </div>
                <div>
                  <h3>{{ contact.name }}</h3>
                  <h5 *ngIf="contact.organiser">(Organiser)</h5>
                  <h4>{{ contact.email }}</h4>
                </div>
              </button>

              <h4 *ngIf="momAttendees.length > 0" class="ml-10">Attendees</h4>
              <button mat-menu-item *ngFor="let attendee of momAttendees">
                <div
                  style="background-color: #b5f2ba; color: #272727"
                  class="user-name-letter">
                  {{ attendee.name[0] }}
                </div>
                <div>
                  <h3>{{ attendee.name }}</h3>
                  <h5 *ngIf="attendee.organiser">(Organiser)</h5>
                  <h4>{{ attendee.email }}</h4>
                </div>
              </button>
            </mat-menu>
          </div>
        </div>
        <!-- View Task & View MOM Tab -->
        <mat-tab-group class="outline-tab addTask-tab-mobile mt-20"
          animationDuration="0ms"
          mat-align-tabs="start"
          *ngIf="enableAddMomButton">
          <mat-tab>
            <ng-template mat-tab-label> View MOM </ng-template>
            <div class="mom-content" *ngFor="let mom of momList">
              <!-- <div class="d-flex justify-content-between"> -->
                <p>
                  <strong class="bold-text">MOM : </strong>
                  <span class="grey-text">
                    {{mom.createdAt | date : "short"}}
                  </span>
                </p>
              <!-- </div> -->
              <p>
                <strong class="bold-text">Message : </strong>
              <span [innerHtml]="mom.message"></span>
              </p>
              <p>
                <strong class="bold-text">Action date: </strong>
                <span class="grey-text">
                  {{mom.nextActionDate | date : "short"}}
                </span>
              </p>
    
              <!-- Meeting MOM Attachement -->
              <ng-container *ngIf="mom.attachments && mom.attachments.length > 0">
                <div class="file-upload-section">
                  <div class="file-uploading-status w-100">
                    <a (click)="downloadSingleDocument(mom.attachments[0].id)">
                      <span class="pwa-add-attachment">
                        {{ mom.attachments[0].name }}
                        <mat-icon class="material-icons">download</mat-icon>
                      </span>
                    </a>
                  </div>
                </div>
              </ng-container>
            </div>
            <a *ngIf="momList.length > 0 && !noMoreData" class="blue-anchor-2" (click)="onScroll($event)">Load More...</a>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label> View Task </ng-template>
            <div class="mom-content" *ngFor="let task of taskList">
              <p>
                <strong class="addTask-Assign">Title : </strong> 
                <span class="addTask-Assign">{{task.title}}</span>
              </p>
            
              <p>
                <strong class="addTask-Assign">Description : </strong> 
                <span class="addTask-Assign">{{task.description}}</span>
              </p>

            <p>
              <strong class="addTask-owner">Owner : </strong> 
              <span class="addTask-owner">{{getEmailFromUserDTOEmail(task.owners)}}</span>
            </p>
            <p>
              <strong class="addTask-Assign">Assigned to : </strong> 
              <span class="addTask-Assign">{{getEmailFromUserDTOEmail(task.assignees)}}</span>
            </p>
            <p>
              <strong class="addTask-Assign">Collaborators : </strong> 
              <span class="addTask-Assign">{{getEmailFromUserDTOEmail(task.collaborators)}}</span>
            </p>
            
            <p>
              <strong class="addTask-Assign"></strong> 
              <span class="addTask-Assign"></span>
            </p>

            <p>
              <strong class="addTask-owner">Task Created : </strong> 
              <span class="addTask-owner">{{ task.createdAt | date: "short" }}</span>
            </p>
            <p>
              <strong class="addTask-Assign">Due date: </strong> 
              <span class="addTask-Assign">{{ task.dueDate | date: "short" }}</span>
            </p>
            </div>
            <a *ngIf="taskList.length > 0 && !tasknoMoreData" class="blue-anchor-2" (click)="onTaskScroll($event)">Load More...</a>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <!-- ADD Task & ADD MOM Button -->
    <div class="addtak-addmom">
      <div fxLayout="row" fxLayoutAlign="flex-start center">
        <button
          (click)="toggleAddTASKPopup()"
          mat-flat-button
          class="mog3_primary_flat_btn add-task w-50"
          aria-label="Save"
          matTooltip="Add Task"
          matTooltipClass="new-tooltip">
          ADD Task
          <i class="ri-arrow-right-s-line"></i>
        </button>
        <button
          (click)="toggleAddMOMPopup()"
          mat-flat-button
          class="mog3_primary_flat_btn add-mom w-50 ml-15"
          aria-label="Save"
          matTooltip="Add MOM"
          matTooltipClass="new-tooltip">
          ADD MOMs
          <i class="ri-arrow-right-s-line"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- New MOM POP UP -->
  <div class="popup" *ngIf="showAddMOMPopup">
    <div class="popup-header-fixed">
      <!-- GO Back To Meeting Detail -->
      <div class="flex items-center justify-start popup-header">
        <i (click)="toggleAddMOMPopup()" class="ri-arrow-left-line"></i>
        <h1>Add MOM</h1>
      </div>
    </div>
    <!-- NEW MOM-->
    <div class="meeting-detail-group pwa-meeting-detail-group">
      <div fxLayout="row wrap" fxLayoutAlign="flex-start">
        <div
          class="formfield-mom w-100 mt-10"
          style="display: flex; align-items: center; justify-content: space-between;">
          <p style="font-size: 12px">Share MOM with all attendees</p>
          <mat-slide-toggle
            class="mat-slider-toggle"
            (change)="sendMail($event.checked)">
        </mat-slide-toggle>
        </div>
        <div class="formfield-mom w-100 mt-10 mb-10">
          <label style="font-size: 12px">MOM to be shared with</label>
          <app-custom-multiple-select
            [label]="attendeeLabel"
            [selectedValues]="selectedAttendees"
            [availableOptions]="allUserListSearch"
            [searchControl]="searchControlOwner"
            [valueCountView]="true"
            [selectedHighlight]="false"
            (selectionChange)="onAttendeeSelectionChange($event)"
            (searchChange)="filterOptionsAttendees($event)">
          </app-custom-multiple-select>
        </div>
      </div>
      <div class="pwa-quill-editor-height mb-20">
        <quill-editor class="mb-10 w-100 comments-quill-editor"
          [(ngModel)]="content"
          [modules]="mobileMOMModules">
        </quill-editor>
      </div>
      <div fxLayout="row" class="field-width">
        <div class="formfield w-100 mb-10">
          <label class="mr-10">Next Action Date</label>
          <mat-form-field
            class="mog3_matinput lane-card-input datePicker"
            appearance="outline">
            <input
              (focus)="effectivePicker.open()"
              matInput
              [matDatepicker]="effectivePicker"
              placeholder="Select Date"
              [(ngModel)]="nextActionDate"
              [min]="minDateForNextAction"
              readonly/>
            <mat-datepicker-toggle
              matSuffix
              [for]="effectivePicker">
            </mat-datepicker-toggle>
            <mat-datepicker #effectivePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!-- NEW MOM ATTACHMENT -->
      <section aria-label="documents" class="cb_fileinput mr-0">
        <ng-container *ngIf="file == null">
          <div
            draggable="true"
            class="dragArea config"
            ngFileDragDrop
            (fileDropped)="fileUpload($event)">
            <span class="lbl">
              <input
                style="display: none"
                type="file"
                (change)="fileUpload($event)"
                #fileInput/>
              <span
                class="pwa-add-attachment"
                (click)="fileInput.value = ''; fileInput.click()">
                <i class="ri-add-fill mr-10"></i>
                Add Attachment
              </span>
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="file != null">
          <div class="file-upload-section">
            <div class="d-flex flex-column w-100">
              <div class="file-uploading-status w-100">
                <span class="pwa-add-attachment">
                  {{ file.name }}
                  <a>
                    <i
                      class="ri-close-line ml-10"
                      (click)="rmeoveSelectedFile()">
                    </i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </section>
    </div>
    <!-- Save Drfat and Submit MOM Button -->
    <div class="addtak-addmom">
      <button
        mat-flat-button
        class="mog3_primary_flat_btn add-task w-47"
        aria-label="Save"
        matTooltip="Save as Draft"
        matTooltipClass="new-tooltip"
        (click)="postMOM('DRAFT','MOBILE')">
        Save as Draft
      </button>
      <button
        mat-flat-button
        class="mog3_primary_flat_btn add-mom w-48 ml-15"
        aria-label="Save"
        matTooltip="Submit MOM"
        matTooltipClass="new-tooltip"
        (click)="postMOM('SUBMIT','MOBILE')">
        Submit MOM
      </button>
    </div>
  </div>

  <!-- New Task POP UP -->
  <div class="popup" *ngIf="showAddTASKPopup">
    <div class="popup-header-fixed">
      <!-- GO Back To Meeting Detail -->
      <div class="flex items-center justify-start popup-header">
        <i (click)="toggleAddTASKPopup()" class="ri-arrow-left-line"></i>
        <h1>Add Task</h1>
      </div>
    </div>
    <!-- NEW Task-->
    <div class="meeting-detail-group pwa-meeting-detail-group mt-10">
      <div>
        <div fxLayout="column">
          <div class="formfield-addTask mb-20">
              <label class="required">Owner</label>
              <app-custom-multiple-select
                [label]="attendeeLabel"
                [selectedValues]="selectedTaskOwners"
                [availableOptions]="allTaskOwnerUserListSearch"
                [searchControl]="searchControlOwner"
                [valueCountView]="true"
                [selectedHighlight]="false"
                (selectionChange)="onTaskOwnerSelectionChange($event)"
                (searchChange)="filterOptionsTaskOwner($event)">
              </app-custom-multiple-select>
            </div>
        </div>

        <div fxLayout="column">
          <div class="formfield-addTask mb-20">
            <label class="required">Assign to</label>
            <app-custom-multiple-select
             [label]="attendeeLabel"
             [selectedValues]="selectedTaskAssignees"
             [availableOptions]="allTaskAssigneeUserListSearch"
             [searchControl]="searchControlOwner"
             [valueCountView]="true"
             [selectedHighlight]="false"
             (selectionChange)="onTaskAssigneeSelectionChange($event)"
             (searchChange)="filterOptionsTaskAsshignee($event)">
            </app-custom-multiple-select>
        </div>
        </div>
        <div fxLayout="column">
          <div class="formfield-addTask mb-10">
            <label>Collaborator</label>
            <app-custom-multiple-select
              [label]="attendeeLabel"
              [selectedValues]="selectedTaskCollaborators"
              [availableOptions]="allTaskCollaboratorUserListSearch"
              [searchControl]="searchControlOwner"
              [valueCountView]="true"
              [selectedHighlight]="false"
              (selectionChange)="onTaskCollaboratorsSelectionChange($event)"
              (searchChange)="filterOptionsTaskCollaborator($event)">
            </app-custom-multiple-select>
          </div>
        </div>
        <div class="formfield-addTask w-100 mt-10">
          <label class="required">Title</label>
          <mat-form-field class="mat-input mog3-matselect" appearance="outline">
            <input matInput placeholder="Enter task title" value="" [(ngModel)]="taskTitle"/>
          </mat-form-field>
        </div>
        <div class="formfield-addTask mt-10">
          <label class="required">Description</label>
          <mat-form-field appearance="outline" class="w-100 mog3-matselect">
            <textarea
              matInput
              placeholder="Start typing the details..."
              [(ngModel)]="taskDescription"
            ></textarea>
          </mat-form-field>
        </div>

        <div fxLayout="row" class="field-width">
          <div class="formfield formfield-addTask w-100">
            <label class="mr-10">Next Action Date</label>
            <mat-form-field
              class="mog3_matinput lane-card-input datePicker"
              appearance="outline">
              <input
                (focus)="effectivePicker.open()"
                matInput
                [matDatepicker]="effectivePicker"
                placeholder="Select Date"
                [(ngModel)]="nextActionDate"
                [min]="minDateForNextAction"
                readonly/>
              <mat-datepicker-toggle
                matSuffix
                [for]="effectivePicker">
              </mat-datepicker-toggle>
              <mat-datepicker #effectivePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="addtak-addmom">
      <button
        mat-flat-button
        class="mog3_primary_flat_btn add-mom w-100"
        aria-label="Save"
        matTooltip="Submit MOM"
        matTooltipClass="new-tooltip"
        (click)="postTask()">
        Submit Task 
      </button>
    </div>
  </div>

  <!-- ADD NEW MEETING -->
  <div class="popup" *ngIf="showAdHocMeetingPopup">
    
    <div class="popup-header-fixed">
      <div class="flex items-center justify-start popup-header">
        <i (click)="toggleAdHocMeetingPopup()" class="ri-arrow-left-line"></i>
        <h1>New Meeting</h1>
      </div>
    </div>
    <div class="meeting-detail-group pwa-meeting-detail-group">
      <div fxLayout="column">
        <!--NEW Meeting Title --->
        <div class="field-container mb-20">
          <label class="modal-input-label-2 required">Meeting Title</label>
          <mat-form-field
            class="mat-input create-lane-input deep-0"
            appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Type Here"
              class="input-field"
              [(ngModel)]="meetingTitle"/>
          </mat-form-field>
        </div>
        <!-- Select Account -->
        <div class="formfield mb-20">
          <div class="field-container">
            <label class="modal-input-label-2 required">Select Account</label>
            <mat-form-field appearance="outline"  class="mat-select mat-select-search deep-0">
              <mat-select [panelClass]="'select-panel-class'" #sa placeholder="Select Account" multiple [(ngModel)]="selectedAccounts" (selectionChange)="onApplyAccounts()">
                <mat-form-field  class="mat-input icon"  appearance="outline">
                  <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlAccounts" (keyup)="filterOptionsAccounts($event)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                    <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="rgba(111,111,112,1)"></path>
                  </svg>
                </mat-form-field>
                <div class="select-options">
                  <mat-option *ngFor="let account of accountOptionsSelected" [value]="account">{{account.name}}</mat-option>
                </div>
                <div  class="select-footer">
                  <button mat-button
                  class="btn-basic btn-text-black mr-10" (click)="clearAccounts();selectedAccounts=[];sa.close();">Clear</button>
                </div>
              </mat-select>
              <div *ngIf="isLoading" fxLayout="row" class="sp-cont">
                <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
              </div>
            </mat-form-field>
          </div>
        </div>
        <!-- Select Plant -->
        <div class="formfield mb-20">
          <div class="field-container">
            <label class="modal-input-label-2 ">Select Plant</label>
            <mat-form-field  appearance="outline"  class="mat-select mat-select-search deep-0">
              <mat-select [panelClass]="'select-panel-class'" #sl placeholder="Select Plants" multiple [(ngModel)]="selectedPlantsForMeeting" (selectionChange)="onApplyPlants()">
                <mat-form-field class="mat-input icon" appearance="outline">
                  <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlPlants" (keyup)="filterOptionsPlants($event)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                    <path
                      d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                      fill="rgba(111,111,112,1)"></path>
                  </svg>
                </mat-form-field>
                <div class="select-options">
                  <mat-option *ngFor="let plant of plantOptionsSelected" [value]="plant">{{plant.name}}</mat-option>
                </div>
                <div  class="select-footer">
                  <button class="filter-btn clear" (click)="clearPlants();selectedPlantsForMeeting=[];">Clear</button>
                </div>
              </mat-select>
              <div *ngIf ="isLoading" fxLayout="row" class="sp-cont">
                <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
              </div>
            </mat-form-field>
          </div>
        </div>
        <!-- Select Contacts -->
        <div class="field-container mb-20">
          <label class="modal-input-label-2 ">Contact</label>
          <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
            <mat-select #s3 placeholder="Select Contact" multiple [(ngModel)]="selectedContact" [disabled]="!contactList || contactList.length === 0" (selectionChange)="onApplyContacts()">
              <div class="custom-panel search-with-options">
                <mat-option *ngFor="let contact of contactList" [value]="contact">{{contact.displayName}}</mat-option>
              </div>
              <div fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
                <button class="filter-btn clear" (click)="clearContacts();">Clear</button>
              </div>
            </mat-select>
            <div *ngIf ="isLoading" fxLayout="row" class="sp-cont">
              <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
            </div>
          </mat-form-field>
        </div>
         <!-- Select Attendee -->
         <div class="field-container mb-20">
          <label class="modal-input-label-2">Add Vedanta Attendees</label>
          <app-custom-multiple-select [label]="attendeeLabel" 
              [selectedValues]="newMeetingSelectedAttendees" 
              [availableOptions]="allUserListSearchNewMeeting" 
              [searchControl]="searchControlAttendees" 
              [valueCountView]="true"
              [selectedHighlight]="false"
              (selectionChange)="onNewMeetingAttendeeSelectionChange($event)" 
              (searchChange)="newMeetingfilterOptionsAttendees($event)">
          </app-custom-multiple-select>
        </div>
        <!--NEW Meeting Type --->
        <div class="field-container mb-20">
          <label class="modal-input-label-2 required">Meeting Type</label>
          <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
            <mat-select #s4 placeholder="Select Meeting Type" [(ngModel)]="selectedMeetingType">
              <div class="custom-panel search-with-options meeting-type">
                <mat-option *ngFor="let meetingType of meetingTypeOptions"
                  [value]="meetingType">{{meetingType}}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <!--Meeting Categgory --->
        <div class="field-container mb-20">
          <label class="modal-input-label-2 required">Meeting Category</label>
          <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
            <mat-select #s5 placeholder="Select Option" [(ngModel)]="selectedMeetingCategory">
              <div class="custom-panel search-with-options value-delivery">
                <mat-option *ngFor="let meetingCategory of meetingCategoryOptions"
                  [value]="meetingCategory">{{meetingCategory}}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Product , Variant & Remarks-->
        <div *ngIf="selectedMeetingCategory === 'New Product Development'">
            <div class="field-container mb-20">
              <label class="modal-input-label-2 required">Product</label>
              <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
                <mat-select #s4 placeholder="Select Meeting Type" [(ngModel)]="selectedProduct" multiple>
                  <div class="custom-panel search-with-options value-delivery">
                    <mat-option *ngFor="let product of productOptions"
                      [value]="product">{{product}}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field-container">
              <label class="modal-input-label-2 required">Variant</label>
              <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
                <mat-select #s5 placeholder="Select Option" [(ngModel)]="selectedVariant" multiple>
                  <div class="custom-panel search-with-options value-delivery">
                    <mat-option *ngFor="let variant of variantOptions"
                      [value]="variant">{{variant}}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field-container">
              <label class="modal-input-label-2 ">Remarks</label>
              <mat-form-field class="mog3_matinput create-lane-input" appearance="outline">
                <input matInput type="text" placeholder="Type Here" class="input-field" [(ngModel)]="remarks"
                matTooltip="Billet: Alloy/Diameter/Length/End Application/Others
                Wire Rod: Diameter/UTS/Conductivity/Elongation/End Application/Others
                PFA: Size(10 kg or 22 kg)/ End Application/Others
                T-Ingot: Size/ End Application/Others
                Ingots: Size(10kg or 22 kg)/Purity/Fe/Si/ End Application/Others
                RP: No convention to be shown
                Others: No convention to be shown"
                matTooltipPosition="below" />
              </mat-form-field>
          </div>
        </div>
        <!-- Start Date -->
        <div fxLayout="row">
          <!-- Start Date -->
          <div class="field-container mb-20">
            <label class="modal-input-label-2 required">Start Date</label>
            <mat-form-field
              class="mat-input lane-card-input deep-0"
              appearance="outline">
              <input
                matInput
                (focus)="startDate.open()"
                [matDatepicker]="startDate"
                placeholder="Select Date"
                [(ngModel)]="startDateValue"
                (ngModelChange)="selectDate($event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- Start time -->
          <div class="field-container mb-20 pl-15">
            <label class="modal-input-label-2 required">Start time</label>
            <mat-form-field appearance="outline" class="mat-select deep-0">
              <mat-select
                placeholder="Select start time"
                [ngModel]="startTimeValue"
                (ngModelChange)="selectTime($event)"
                [disabled]="
                  startDateValue == null || startDateValue == undefined">
                <mat-option
                  class="time-select-option"
                  value="select time"
                  selected
                  disabled
                  >Select time</mat-option>
                <mat-option
                  class="time-select-option"
                  *ngFor="let time of times"
                  [value]="time"
                  >{{ time }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- End Date -->
        <div fxLayout="row">
          <!-- End Date -->
          <div class="field-container mb-20">
            <label class="modal-input-label-2 required">End Date</label>
            <mat-form-field
              class="mat-input lane-card-input deep-0"
              appearance="outline"
            >
              <input
                matInput
                (focus)="endDate.open()"
                [matDatepicker]="endDate"
                [disabled]="
                  startTimeValue == null || startTimeValue == undefined
                "
                placeholder="Select Date"
                [(ngModel)]="endDateValue"
                (ngModelChange)="selectEndDate($event)"
                [min]="minEndDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- End time -->
          <div class="field-container mb-20 pl-15">
            <label class="modal-input-label-2 required">End time</label>
            <mat-form-field appearance="outline" class="mat-select deep-0">
              <mat-select
                placeholder="Select end time"
                [ngModel]="endTimeValue"
                (ngModelChange)="selectEndTime($event)"
                [disabled]="endDateValue == null || endDateValue == undefined"
              >
                <mat-option *ngFor="let time of timesEnd" [value]="time">{{
                  time
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- NEW MEETING TEXT EDITOR -->
      <div class="pwa-quill-editor-height mb-20">
        <quill-editor class="mb-10 w-100 comments-quill-editor"
          [(ngModel)]="contentForNewMeeting"
          [modules]="mobileNewMeetingModules">
        </quill-editor>
      </div>
      <!-- NEW MEETING ATTTACHMENT -->
      <section aria-label="documents" class="cb_fileinput mr-0">
        <ng-container *ngIf="file == null">
          <div
            draggable="true"
            class="dragArea config"
            ngFileDragDrop
            (fileDropped)="fileUpload($event)">
            <span class="lbl">
              <input
                style="display: none"
                type="file"
                (change)="fileUpload($event)"
                #fileInput/>
              <span
                class="pwa-add-attachment"
                (click)="fileInput.value = ''; fileInput.click()">
                <i class="ri-add-fill mr-10"></i>
                Add Attachment
              </span>
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="file != null">
          <div class="file-upload-section">
            <div class="d-flex flex-column w-100">
              <div class="file-uploading-status w-100">
                <span class="pwa-add-attachment">
                  {{ file.name }}
                  <a>
                    <i
                      class="ri-close-line ml-10"
                      (click)="rmeoveSelectedFile()">
                  </i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </section>
    </div>
    <div class="addtak-addmom justify-between">
      <button
        class="btn-primary round-btn w-100 pwa-footer-button-height"
        mat-raised-button
        (click)="postNewMeeting()">
        Schedule Meeting
      </button>
    </div>
  </div>
</section>
