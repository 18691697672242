import { Component, OnInit, Input, OnChanges, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, SimpleChanges, } from '@angular/core';
import { KanbanService } from '../../kanban.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CardDetailsComponent } from './card-details/card-details.component';
import { MatDialog } from '@angular/material';
import { LaneMoveComponent } from '../lanes-list/lane-move/lane-move.component';
import { saveAs } from 'file-saver';

import { ToastrService } from 'ngx-toastr';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ViewChild } from '@angular/core';
import { MatBottomSheet, MatSnackBar, MatOption, MatPaginator, MatSelect, MatSnackBarVerticalPosition, Sort, MatSort, MatTableDataSource } from '@angular/material';
import { MatCardModule } from "@angular/material/card";
import { ViewLaneComponent } from './view-lane/view-lane.component';
import { LaneTaskUsersTabComponent } from './card-details/lane-task-users-tab/lane-task-users-tab.component';
import { HistoryTabComponent } from './card-details/history-tab/history-tab.component';
import { SettingService } from 'src/app/settings/setting.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { AccountRejectComponent } from './account-reject/account-reject.component';
import { Constants } from 'src/app/Constants';

import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MeetingListComponent } from '../task-dashboard/hunting-listing/meeting-list/meeting-list.component';

@Component({
  selector: 'app-board-lane',
  templateUrl: './board-lane-listing.component.html',
  styleUrls: ['./board-lane.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class BoardLaneComponent implements OnChanges {
  @Input() inputData;
  board_id: string;
  filteredObj: {}
  dragDisabled = true;
  loading;
  laneTile;
  boardLaneResponseObj: [];
  filterFields: Array<any> = [];
  selectedTrIndex: number = -1;
  leadListing: any = [];
  isLoading: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource(this.leadListing);
  boardLane = [];
  boardId: any;
  laneTaskList = [];
  boardLaneResponse: any;
  projectLists = [];
  selectTeams = [];
  queryLanes: any[];
  selectedLane: any;
  sortNumberFieldRegex = /date|potential|actionItem|probability|annualturnovercr|dealsize/igm;
  isEditAccessLane: false;
  pageSize = 20;
  pageFrom = 0;
  lanePageSize = 5;
  lanePageFrom = 0;
  pageIndex = -1;
  totalRecords;
  breakpoint: number;
  teams: any;
  allUserList: any;
  selectedUsers: any = [];
  searchText = "";
  passOnData: any;
  laneStats: any;
  currentState: any = {};
  all: any = { "laneId": null, "boardId": null, "accessType": ["EDIT"], "laneDTO": { "id": "all", "name": "all", "displayName": "All", "position": 1, "board": { "id": "63bfa8ccade7bc466d1c780e", "name": "deals", "createdBy": { "id": null, "uuid": null, "firstName": null, "lastName": null, "name": null, "email": null, "phoneNumber": null, "roleId": null, "role": null, "roles": null, "businessAccounts": null, "userRoles": null, "activeLogin": false, "totalCount": 0, "designation": null, "countryCode": null, "customUserRoleId": null, "loggedInUserRole": null, "masterAccountId": null, "userTeams": null, "sendMail": true, "verified": false, "deleted": false, "uniqueRoleId": null }, "status": null, "type": "MOGLIX", "isActive": true, "isDeleted": false, "watchers": [], "customInfo": null, "buyerDataConfig": null, "createdAt": "2023-01-12T06:29:32.800+0000", "updatedAt": "2023-01-12T06:29:32.800+0000" }, "watchersId": null, "watchers": [], "type": null, "status": null, "isDeleted": false, "isActive": true, "createdBy": { "id": "63c8deac6210302382b4dc6e", "uuid": null, "firstName": null, "lastName": null, "name": "moglix_user1", "email": "moglix@credlix.com", "phoneNumber": null, "roleId": null, "role": null, "roles": null, "businessAccounts": null, "userRoles": null, "activeLogin": false, "totalCount": 0, "designation": null, "countryCode": null, "customUserRoleId": null, "loggedInUserRole": null, "masterAccountId": null, "userTeams": null, "sendMail": true, "verified": false, "deleted": false, "uniqueRoleId": null }, "customInfo": null, "createdAt": "2023-01-23T07:39:30.897+0000", "updatedAt": "2023-01-23T07:39:30.897+0000", "class": "data-pending" }, "authorityType": "LANE", "boardName": null, "accessLevel": null };
  displayedColumns: any[] = [];
  displayedColumns1: any[] = [];
  displayConfig = [{ "lane": "63bfae26ade7bc466d1c7812", "displayConfig": [{ "field": "account-name", "label": "Account Name", "index": 1, "icon": "account" }, { "field": "account-owner", "label": "Account Owner", "index": 4, "icon": "person" }, { "field": "industry", "label": "Industry", "index": 3, "icon": "account" }, { "field": "revenue-in-cr", "label": "Revenue (In Cr)", "index": 2, "icon": "account" }, { "field": "parent-account-name", "label": "Parent Account Name", "index": 5, "icon": "account" }] }, { "lane": "63ce5630a5c09f3f171460b2", "displayConfig": [{ "field": "account-name", "label": "Account Name", "index": 1, "icon": "account" }, { "field": "account-owner", "label": "Account Owner", "index": 4, "icon": "person" }, { "field": "industry", "label": "Industry", "index": 3, "icon": "account" }, { "field": "number-of-plants", "label": "Number of Plants", "index": 2, "icon": "account" }, { "field": "parent-account-name", "label": "Parent Account Name", "index": 5, "icon": "account" }] }, { "lane": "63ce39b2a5c09f3f171460a3", "displayConfig": [{ "field": "deal-name", "label": "Deal Name", "index": 1, "icon": "account" }, { "field": "account-name", "label": "Account Name", "index": 2, "icon": "account" }, { "field": "deal-owner", "label": "Deal Owner", "index": 5, "icon": "person" }, { "field": "industry", "label": "Industry", "index": 4, "icon": "account" }, { "field": "realistic-deal-size-in-cr", "label": "Realistic Deal Size (In Cr)", "index": 3, "icon": "account" }, { "field": "parent-account-name", "label": "Parent Account Name", "index": 6, "icon": "account" }] }];
  @HostBinding('class') class = 'block-100';
  isReadMore: boolean = true;
  console = console;
  selectedBusinessId: any;
  boardIdForSobPlant = Constants.boardIdForSobPlant;
  boardIdForSobContact = Constants.boardIdForSobContacts;
  boardIdForCrmContact = Constants.boardIdForCRMContacts;
  boardIdForSobOpportunity = Constants.boardIdForSobOpportunity;
  boardIdForCRMAccount = Constants.boardIdForCRMAccount;
  boardIDForSOBAccount = Constants.boardIdForSobAccount;
  boardIdForVedantaAccount = Constants.boardIdForVedantaAccount;
  constantArray = [
    { name: 'C0', value: Constants.C0 },
    { name: 'C1', value: Constants.C1 },
    { name: 'C2', value: Constants.C2 },
    { name: 'C3', value: Constants.C3 },
    { name: 'C4', value: Constants.C4 },
    { name: 'S0', value: Constants.S0 },
    { name: 'S1', value: Constants.S1 },
    { name: 'S2', value: Constants.S2 },
    { name: 'S3', value: Constants.S3 },
    { name: 'S4', value: Constants.S4 },
    { name: 'coldDeal', value: Constants.coldDeal },
    { name: 'lostDeal', value: Constants.lostDeal },
    { name: 'dealWon', value: Constants.dealWon },
  ];
  roleName: any;
  selectedBoardName = '';
  setReasonToMove: string;
  selectedTaskArrayList = []
  userProfileName: string;
  teamQuery: any;
  bsQuery: any;
  mywatchlistQuery: any;
  dealtypeQuery: any;
  l1CategoryQuery: any;
  poTypeQuery: any;
  userQuery: any;
  resetFilter: any;
  teamFilter: any;
  closingDateQuery: any;
  createdDateQuery: any;
  accountQuery: any;
  plantQuery: any;
  industryQuery: any;
  stagesFilter = [];
  activeSorts = [];
  searchByNameQuery: any;
  w1recievedQuery: any;
  tagTypeQuery: any;
  currentLaneMovedID: string
  isHorizontalScroll: boolean = false;
  public textServerSideCtrl: FormControl = new FormControl(null);
  public textServerSideFilteringCtrl: FormControl = new FormControl('');
  showListing = false;
  /** indicate search operation is in progress */
  public searching = false;
  results = [];
  public addData: string = "Search";
  /** list of banks filtered after simulating server side search */
  public filteredServerSideTexts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  showCloseIcon = false;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  showAmountOnLanes: boolean = false;

  supplierTypeQuery: any;
  brandQuery: any;
  locationQuery: any;
  l2CategoryQuery: any;
  moglixPOCQuery: any;
  MAQuery: any;
  targetPanelQuery: any;
  leadSourceQuery: null;
  companyTypeQuery: any;
  productInterestedInQuery: any;
  lifeCycleStageQuery: any;
  marketQuery: any;

  constructor(public _kService: KanbanService, public toasterService: ToastrService, public cRef: ChangeDetectorRef, public dialog: MatDialog, public settingService: SettingService, private datePipe: DatePipe, private _snackBar: MatSnackBar) {

  }
  ngOnInit() {
    this.laneTaskList = [];
    this.leadListing = [];
    this.dataSource.data = [];
    this.totalRecords = 0;
    this.setBreakPoint();
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if (getLocalStorage && getLocalStorage['role']) {
      this.roleName = getLocalStorage['role']['role']['displayName'];
    }
    // this.loadTeams();
    this.cRef.detectChanges();
  }
  ngAfterViewInit() {

  }
  onResize(event) {
    this.setBreakPoint();
  }
  setBreakPoint() {

    if ((window.innerWidth <= 400)) {
      this.breakpoint = 2
    } else if ((window.innerWidth > 400) && (window.innerWidth <= 768)) {
      this.breakpoint = 4
    } else if ((window.innerWidth > 768) && (window.innerWidth <= 1390)) {
      this.breakpoint = 6;
    } else if ((window.innerWidth > 1390)) {
      this.breakpoint = 8;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.console.log('board-lane ngonchanges', changes)
    this.isLoading = true;
    if (changes.inputData.previousValue && changes.inputData.currentValue.boardId != changes.inputData.previousValue.boardId) {
      this.activeSorts = [];
    }
    if (changes.inputData.currentValue.boardId) {
      this.boardId = changes.inputData.currentValue.boardId;
      this.console.log("boardId", this.boardId);
      if (this.boardId != null && (this.boardId === Constants.boardIdForCRMDeals || this.boardId === Constants.boardIdForSobOpportunity || this.boardId === Constants.boardIdForSrmSupplier)) {
        let statsQuery = {
          "query": {
            "sort": { "field": "data.realisticdealsizecr", "order": "desc" },
            "where": { "boardId": { "type": "search", "field": "boardId.raw", "value": this.boardId } }
          },
          "size": 10, "type": "TOP_DEALS_BY_CLOSING_DATE"
        };
        this.loadLaneStats(statsQuery);
        if (this.boardId === Constants.boardIdForCRMDeals || this.boardId === Constants.boardIdForSobOpportunity) {
          this.showAmountOnLanes = true;
        } else {
          this.showAmountOnLanes = false;
        }
      }
    }
    if (changes.inputData.currentValue.boardData) {
      this.selectedBusinessId = changes.inputData.currentValue.selectedBusinessId;
      this.boardLaneResponse = changes.inputData.currentValue.boardData;
      if (changes.inputData.currentValue.selectedLaneIndex != null && changes.inputData.currentValue.selectedLaneIndex > -1) {
        this.selectedLane = changes.inputData.currentValue.boardData.result.levelAccess[changes.inputData.currentValue.selectedLaneIndex];
      }
      let response = this.boardLaneResponse;
      //   this._kService.getLaneWithUserAccess(this.boardId, '', this.lanePageSize, this.lanePageFrom).subscribe(response => {
      let previousBoardLaneLength: number = this.boardLane.length;
      let tmp = response['result']['levelAccess'];
      tmp = Object.values(tmp.reduce((acc, cur) => Object.assign(acc, { [cur.laneDTO.id]: cur }), {}));
      if (tmp && tmp.length > 1 && tmp[0].laneDTO.id != "all" && this.boardId != this.boardIDForSOBAccount) {
        tmp.unshift(this.all);
      }
      this.selectedBoardName = changes.inputData.currentValue.selectedBoardName;
      this.selectedLane = tmp[0];
      if (this.allUserList && this.allUserList.length) {
        if (this.boardId == "63bfa8ccade7bc466d1c780e") {
          this.passOnData = {
            "teams": this.teams,
            "users": this.allUserList,
            "isDeals": true,
            "selectedLane": this.selectedLane,
            "boardId": this.boardId,
            "selectedBusinessId": this.selectedBusinessId,
            "selectedBoardName": this.selectedBoardName
          }
        } else {
          this.passOnData = {
            "teams": this.teams,
            "users": this.allUserList,
            "isDeals": false,
            "selectedLane": this.selectedLane,
            "boardId": this.boardId,
            "selectedBusinessId": this.selectedBusinessId,
            "selectedBoardName": this.selectedBoardName
          }
        }
      } else {
        this.passOnData = {
          "teams": this.teams,
          "users": this.allUserList,
          "isDeals": false,
          "selectedLane": this.selectedLane,
          "boardId": this.boardId,
          "selectedBusinessId": this.selectedBusinessId,
          "selectedBoardName": this.selectedBoardName
        }
      }
      this.boardLane = tmp.sort((a, b) => a.laneDTO.position - b.laneDTO.position).map((exemple, index, array) => exemple)
      this.boardLane['totalRecords'] = response['total'];
      let tmpQueryLanes = [];
      tmpQueryLanes.push(this.boardId);
      if (this.boardLane.length > 0) {
        this.queryLanes = this.selectedLane.laneDTO.id;
        this.loadDisplayColumns(changes.inputData.currentValue.boardData.result.levelAccess[0]);
      } else {
        this.isLoading = false;
        this.boardLane = [];
        this.displayedColumns1 = [];

      }
      //this.loadTeams();
      //this.loadUsers();
    }
    if (changes.inputData.currentValue.filteredObj) {
      this.filteredObj = changes.inputData.currentValue.filteredObj;
    } else {
      this.filteredObj = {};
    }
    this.laneTaskList = [];
    this.leadListing = [];
    this.dataSource.data = [];
    this.totalRecords = 0;
    this.fetchBoardLane();
  }
  onFilterApply($event) {
    this.console.log('onFilterApply ', $event)
    this.paginator.pageIndex = 0;
    if ($event && $event['resetFilter']) {
      this.teamFilter = null;
      this.bsQuery = null;
      this.userQuery = null;
      this.teamQuery = null;
      this.closingDateQuery = null;
      this.createdDateQuery = null;
      this.accountQuery = null;
      this.plantQuery = null;
      this.mywatchlistQuery = null;
      this.w1recievedQuery = null;
      this.tagTypeQuery = null;
      this.industryQuery = null;
      this.leadSourceQuery = null;
    } else {
      if ($event && $event['teamFilter'] && $event['teamFilter'].length) {
        let tFiler = $event['teamFilter'];
        this.teamFilter = tFiler;
        this.teamQuery = $event['teamQuery']['query']['nestedWhere']["team_query"];
      } else {
        this.teamQuery = null;
      }
      if ($event && $event['bsQuery']) {
        this.bsQuery = $event['bsQuery']['query']['nestedWhere']["bs_query"];
      } else {
        this.bsQuery = null;
      }
      if ($event && $event['mywatchlist_query']) {
        this.mywatchlistQuery = $event['mywatchlist_query']['query']['nestedWhere']["mywatchlist_query"];
      } else {
        this.mywatchlistQuery = null;
      }
      if ($event && $event['dealtypeQuery']) {
        this.dealtypeQuery = $event['dealtypeQuery']['query']['nestedWhere']["dealtype_query"];
      } else {
        this.dealtypeQuery = null;
      }
      if ($event && $event['l1CategoryQuery']) {
        this.l1CategoryQuery = $event['l1CategoryQuery']['query']['nestedWhere']["l1category_query"];
      } else {
        this.l1CategoryQuery = null;
      }

      if ($event && $event['leadSourceQuery']) {
        this.leadSourceQuery = $event['leadSourceQuery']['query']['nestedWhere']["leadSourceQuery"];
      } else {
        this.leadSourceQuery = null;
      }

      if ($event && $event['potypeQuery']) {
        this.poTypeQuery = $event['potypeQuery']['query']['nestedWhere']["potype_query"];
      } else {
        this.poTypeQuery = null;
      }
      if ($event && $event['userQuery']) {
        this.userQuery = $event['userQuery']['query']['where']["user_query"];
      } else {
        this.userQuery = null;
      }
      if ($event && $event['closingDateQuery']) {
        this.closingDateQuery = $event['closingDateQuery'];
      } else {
        this.closingDateQuery = null;
      }
      if ($event && $event['createdDateQuery']) {
        this.createdDateQuery = $event['createdDateQuery'];
      } else {
        this.createdDateQuery = null;
      }
      if ($event && $event['account_query']) {
        this.accountQuery = $event['account_query']['query']['nestedWhere']["account_query"];
      } else {
        this.accountQuery = null;
      }
      if ($event && $event['plant_query']) {
        this.plantQuery = $event['plant_query']['query']['nestedWhere']["plant_query"];
      } else {
        this.plantQuery = null;
      }
      if ($event && $event['industryQuery']) {
        this.industryQuery = $event['industryQuery']['query']['nestedWhere']["industryQuery"];
      } else {
        this.industryQuery = null;
      }
      if ($event && $event['tagTypeQuery']) {
        this.tagTypeQuery = $event['tagTypeQuery']['query']['nestedWhere']["tagTypeQuery"];
      } else {
        this.tagTypeQuery = null;
      }
      if ($event && $event['selected_stages']) {
        this.stagesFilter = $event['selected_stages'];
        console.log('board lane selectedStages', this.stagesFilter)
        if (this.stagesFilter.find(s => s === "W1 Received")) {
          this.w1recievedQuery = { "data.W1_stage": { "type": "nested_exists" } };
        } else {
          this.w1recievedQuery = null;
        }
        this.stagesFilter = this.stagesFilter.filter(s => s != "W1 Received");
      } else {
        this.stagesFilter = [];
      }
      if ($event && $event['name_query']) {
        this.searchByNameQuery = $event['name_query']['query']["where"]["name_query"];
      } else {
        this.searchByNameQuery = null;
      }

      if ($event && $event['supplierTypeQuery']) {
        this.supplierTypeQuery = $event['supplierTypeQuery']['query']['nestedWhere']["supplier_type_query"];
      } else {
        this.supplierTypeQuery = null;
      }
      if ($event && $event['brandQuery']) {
        this.brandQuery = $event['brandQuery']['query']['nestedWhere']["brand_query"];
      } else {
        this.brandQuery = null;
      }
      if ($event && $event['locationQuery']) {
        this.locationQuery = $event['locationQuery']['query']['nestedWhere']["location_query"];
      } else {
        this.locationQuery = null;
      }
      if ($event && $event['l2CategoryQuery']) {
        this.l2CategoryQuery = $event['l2CategoryQuery']['query']['nestedWhere']["L2Categories_query"];
      } else {
        this.l2CategoryQuery = null;
      }
      if ($event && $event['moglixPOCQuery']) {
        this.moglixPOCQuery = $event['moglixPOCQuery']['query']['nestedWhere']["moglixPOC_query"];
      } else {
        this.moglixPOCQuery = null;
      }
      if ($event && $event['MAQuery']) {
        this.MAQuery = $event['MAQuery']['query']['nestedWhere']["ma_query"];
      } else {
        this.MAQuery = null;
      }
      if ($event && $event['targetPanelQuery']) {
        this.targetPanelQuery = $event['targetPanelQuery']['query']['nestedWhere']["targetPanel_query"];
      } else {
        this.targetPanelQuery = null;
      }

      if ($event && $event['companyTypeQuery']) {
        this.companyTypeQuery = $event['companyTypeQuery']['query']['nestedWhere']["companyTypeQuery"];
      } else {
        this.companyTypeQuery = null;
      }

      if ($event && $event['productInterstedInQuery']) {
        this.productInterestedInQuery = $event['productInterstedInQuery']['query']['nestedWhere']["selectedProductInterestedInQuery"];
      } else {
        this.productInterestedInQuery = null;
      }

      if ($event && $event['lifeCycleStageQuery']) {
        this.lifeCycleStageQuery = $event['lifeCycleStageQuery']['query']['nestedWhere']["selectedLifeCycleStageQuery"];
      } else {
        this.lifeCycleStageQuery = null;
      }

      if ($event && $event['marketQuery']) {
        this.marketQuery = $event['marketQuery']['query']['nestedWhere']["selectedMarketQuery"];
      } else {
        this.marketQuery = null;
      }


    }
    this.onScrollDown(true);
  }
  toWords(s) {
    var th = ['', 'thousand', 'million', 'billion', 'trillion'];
    // uncomment this line for English Number System
    // var th = ['','thousand','million', 'milliard','billion'];

    var dg = ['zero', 'one', 'two', 'three', 'four',
      'five', 'six', 'seven', 'eight', 'nine']; var tn =
        ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
          'seventeen', 'eighteen', 'nineteen']; var tw = ['twenty', 'thirty', 'forty', 'fifty',
            'sixty', 'seventy', 'eighty', 'ninety'];

    s = s.toString(); s =
      s.replace(/[\, ]/g, ''); if (s != parseFloat(s)) return 'not a number'; var x =
        s.indexOf('.'); if (x == -1) x = s.length; if (x > 15) return 'too big'; var n =
          s.split(''); var str = ''; var sk = 0;
    for (var i = 0; i < x; i++) {
      if ((x - i) % 3 == 2) {
        if (n[i] == '1') { str += tn[Number(n[i + 1])] + ' '; i++; sk = 1; }
        else if (n[i] != 0) { str += tw[n[i] - 2] + ' '; sk = 1; }
      } else if (n[i] != 0) {
        str +=
          dg[n[i]] + ' '; if ((x - i) % 3 == 0) str += 'hundred '; sk = 1;
      } if ((x - i) % 3 == 1) {
        if (sk)
          str += th[(x - i - 1) / 3] + ' '; sk = 0;
      }
    }
    if (x != s.length) {
      var y = s.length; str +=
        'point '; i = x + 1; str.replace(/\s+/g, ' ');
    }
    return str;

  }
  nFormatter(num, digits) {
    let negetive = false;
    if (num < 0) {
      negetive = true;
      num = -num;
    }
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
      return num >= item.value;
    });
    if (negetive) {
      if (num < 1 && num > 0) {
        return (num).toFixed(digits).replace(rx, "$1");
      } else {
        return -(item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0");
      }
    } else {
      if (num < 1 && num > 0) {
        return (num).toFixed(digits).replace(rx, "$1");
      } else {
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
      }
    }
  }
  loadTeams() {
    this.settingService.fetchTeams().subscribe(response => {
      let list = response['result'];
      list.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.teams = list;
      this.loadUsers();
    });
  }
  loadUsers() {
    let obj = {};
    obj['from'] = 0;
    obj['size'] = 100;
    obj['businessId'] = this.selectedBusinessId;
    //obj['roleId'] = ["63dcc0ff230c7d007058e926"];
    this.settingService.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      let userList = [];
      for (let i = 0; i < list['result'].length; i++) {
        let obj = list['result'][i];
        let tmp = list['result'][i].userTeams;
        let teamList = [];
        if (tmp) {
          for (let k = 0; k < tmp.length; k++) {
            teamList.push(tmp[k].id);
          }
          obj.userTeams = teamList;
          userList.push(obj)
        } else {
          obj.userTeams = [];
          userList.push(obj)
        }
      }
      userList.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.allUserList = userList;
      if (this.allUserList != null && this.teams != null) {
        if (this.boardId == "63bfa8ccade7bc466d1c780e") {
          this.passOnData = {
            "teams": this.teams,
            "users": this.allUserList,
            "isDeals": true,
            "selectedLane": this.selectedLane,
            "boardId": this.boardId,
            "selectedBusinessId": this.selectedBusinessId,
            "selectedBoardName": this.selectedBoardName
          }
        } else {
          this.passOnData = {
            "teams": this.teams,
            "users": this.allUserList,
            "isDeals": false,
            "selectedLane": this.selectedLane,
            "boardId": this.boardId,
            "selectedBusinessId": this.selectedBusinessId,
            "selectedBoardName": this.selectedBoardName
          }
        }
      }
    });
  }
  loadDisplayColumns(sLane) {
    if (sLane != null) {
      let id = sLane.laneDTO.id;
      let boardId = sLane.laneDTO.board.id;

      this._kService.getLaneBoardConfigByIdDTO(boardId).subscribe((res) => {
        if (res && res['result'] && res['result']['config']) {
          let config = res['result']['config'];
          let laneDisplayConfig = config.filter((ele) => {
            if (ele['displayWhileListing']) {
              return true
            }
          });
          laneDisplayConfig.sort(function (x, y) {
            return x.index - y.index;
          });
          if (laneDisplayConfig) {
            let tmpColumn = [];
            for (let k = 0; k < laneDisplayConfig.length; k++) {
              if (laneDisplayConfig[k].display) { tmpColumn.push(laneDisplayConfig[k].field); }
            }
            if (sLane && sLane.laneDTO && sLane.laneDTO.board.name.match(/deal/igm)) {
              tmpColumn.push("lane")

              tmpColumn.push("actionItem")
            }
            if (sLane && sLane.laneDTO && sLane.laneDTO.board.name.match(/plant/igm)) {
              tmpColumn.push("count");
              tmpColumn.push("countMeetings");
              tmpColumn.push("momCount");
              tmpColumn.push("actionItem")
            }
            if (sLane && sLane.laneDTO && sLane.laneDTO.board.name.match(/opportunity/igm)) {
              tmpColumn.push("lane");
              tmpColumn.push("actionItem")
            }
            if (sLane && sLane.laneDTO && sLane.laneDTO.board.name.match(/account/igm)) {
              tmpColumn.push("plantCount");
              tmpColumn.push("count");
              tmpColumn.push("countMeetings");
              tmpColumn.push("momCount");
              tmpColumn.push("actionItem")
            }
            if (sLane && sLane.laneDTO && sLane.laneDTO.board.name.match(/supplier/igm)) {
              tmpColumn.push("Owner");
              tmpColumn.push("Owner_Email");
              tmpColumn.push("Owner_Mobile");
              tmpColumn.push("Owner_Designation");
              tmpColumn.push("Owner_Age");
            }
            // if (boardId === Constants.boardIdForSobContacts) {
            //   tmpColumn.push("countMeetings");
            //   tmpColumn.push("lastMeeting");
            //   tmpColumn.push("nextMeeting");
            // }
            // if (boardId === Constants.boardIdForSobAccount) {
            //   tmpColumn.push("sobAccountStrength");
            //   tmpColumn.push("sobPercent");
            // }
            if (boardId === Constants.boardIdForSobPlant) {
              // tmpColumn.push("categoryAnnualSpend");
              // tmpColumn.push("sobPercent");
            }
            if (boardId === Constants.boardIdForSrmSupplier) {
              tmpColumn.push("lane")
            }
            tmpColumn.push("action");
            this.displayedColumns1 = tmpColumn;
            this.displayedColumns = laneDisplayConfig;


          }
        }
      });
    }
  }
  loadLaneStats = (query) => {
    this._kService.getDashboardStats(query).subscribe((response) => {
      if (response && response['result']) {
        this.laneStats = response['result']['termsAgg'];
      }
    });
  }
  getLaneStats(lane) {
    if (this.laneStats && this.laneStats.length > 0) {
      let tmp = this.laneStats.find((item) => {
        if (item.key == lane) {
          return true;
        }
      });
      if (tmp) {
        return {
          "count": tmp.count,
          "value": tmp.sum
        }
      } else {
        if (lane.toLowerCase() == "all") {
          let allCount = 0;
          let valueSum = 0;
          for (let k = 0; k < this.laneStats.length; k++) {
            allCount = allCount + this.laneStats[k].count;
            valueSum = valueSum + this.laneStats[k].sum;
          };
          return {
            "count": allCount,
            "value": valueSum
          }
        } else {
          return {
            "count": 0,
            "value": 0
          }
        }
      }
    } else {
      return {
        "count": 0,
        "value": 0
      }
    }
  }
  changeLaneCard = (newLane: any) => {
    this.selectedLane = newLane;
    this.isLoading = true;
    this.laneTaskList = [];
    this.leadListing = [];
    this.dataSource.data = [];
    this.totalRecords = 0;
    this.fetchBoardLane();
    if (this.boardId == "63bfa8ccade7bc466d1c780e") {
      this.passOnData = {
        "teams": this.teams,
        "users": this.allUserList,
        "isDeals": true,
        "selectedLane": this.selectedLane,
        "boardId": this.boardId,
        "selectedBusinessId": this.selectedBusinessId,
        "selectedBoardName": this.selectedBoardName
      }
    } else {
      this.passOnData = {
        "teams": this.teams,
        "users": this.allUserList,
        "isDeals": false,
        "selectedLane": this.selectedLane,
        "boardId": this.boardId,
        "selectedBusinessId": this.selectedBusinessId,
        "selectedBoardName": this.selectedBoardName
      }
    }
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }
  checkPastClosingDate(ele, field) {
    const constantsMap = {};
    for (const constant of this.constantArray) {
      constantsMap[constant.value] = constant;
    }

    if (field === 'closing-date' && constantsMap[ele.lane.id]) {
      const firstDate = new Date().setHours(0, 0, 0, 0);
      let laneData = ele['data'];
      if (laneData != null) {
        let laneField = laneData.find((ele) => {
          if (ele['field'] == field) {
            return ele;
          }
        });
        var secondDate1 = new Date(laneField.value).setHours(0, 0, 0, 0);
        var secondDate = new Date(laneField.value),
          month = '' + (secondDate.getMonth() + 1),
          day = '' + secondDate.getDate(),
          year = secondDate.getFullYear();
        if (firstDate > secondDate1) {
          return true;
        }
      }
    }
    return false;
  }
  checkPastDate(ele) {
    const constantsMap = {};
    for (const constant of this.constantArray) {
      constantsMap[constant.value] = constant;
    }

    if (ele && ele['nextActionDate'] && constantsMap[ele.lane.id]) {
      if (constantsMap[ele.lane.id]) {
        const firstDate = new Date().setHours(0, 0, 0, 0);
        var secondDate = new Date(ele['nextActionDate']),
          month = '' + (secondDate.getMonth() + 1),
          day = '' + secondDate.getDate(),
          year = secondDate.getFullYear();
        if (firstDate > secondDate.getTime()) {
          return true;
        }
      }
    }
    return false;
  }
  getFormattedDate(date) {
    if (date) {
      var secondDate = new Date(date),
        month = '' + (secondDate.getMonth() + 1),
        day = '' + secondDate.getDate(),
        year = secondDate.getFullYear();
      return day + "-" + month + "-" + year;
    }
  }
  calculateAge(ele) {
    let field = "lastStageMovementDate";
    const firstDate = new Date().getTime();
    const secondDate = new Date(ele[field]);
    if (field && ele && ele[field] && firstDate && secondDate) {
      return this.calculateDifferenceBetweenDates(firstDate, secondDate);
    }
    return "";
  }
  getFieldValue(ele, field) {
    let val = "";
    if (this.selectedLane) {
      if (field != null && (field == "createdAt")) {
        if (ele && ele[field]) {
          val = this.formatDate(ele[field]);
        }
      }

      if (field != null && field == "updatedAt") {
        const firstDate = new Date().getTime();
        const secondDate = new Date(ele[field]);
        if (field && ele && ele[field] && firstDate && secondDate) {
          val = this.calculateDifferenceBetweenDates(firstDate, secondDate);
        }
      }
      // if (field != null && (field == "closing-date")) {
      //   if (ele && ele['originalClosingDate']) {
      //     val = ele['originalClosingDate'];
      //   }
      // }

      let laneData = ele['data'];
      if (laneData != null) {

        let laneField = laneData.find((ele) => {
          if (ele['field'] == field) {
            return ele;
          }
        });

        if (laneField && (laneField.type == "string" || laneField.type == "number" || laneField.type == "email" || laneField.type == "phone" || laneField.type == "textarea" ||
          laneField.type == "currency")) {
          val = laneField.value;
        } else if (laneField && (laneField.type == "date") && laneField.value != null && laneField.value.trim() != "") {
          val = this.formatDate(laneField.value);
        } else if (laneField && (laneField.type == "select")) {
          if ((laneField.key == "string" || laneField.key == "number")) {
            val = laneField.value;
          } else {
            //let searchType = laneField.key == "user" ? "user" : "laneTask";
            val = laneField.additionalData != null ? laneField.additionalData.name : "";
          }
        }
        else if (laneField && (laneField.type == "multiSelect")) {
          if ((laneField.key == "string" || laneField.key == "number") && laneField.values) {
            val = laneField.values.toString();
          } else {
            //let searchType = laneField.key == "user" ? "user" : "laneTask";
            val = laneField.additionalData != null ? laneField.additionalData.name != null ? laneField.additionalData.name : "" : "";
          }
        } else if (laneField && (laneField.type == "belongsTo")) {
          val = laneField.additionalData != null ? laneField.additionalData.name != null ? laneField.additionalData.name.toString() : "" : "";
          if (laneField.field == "plant" && val && ele['searchData'] && ele['searchData']['plant_names']) {
            val = laneField.additionalData.name[0];
          }
          if (laneField.field == "plant-name" && val && ele['searchData'] && ele['searchData']['plantname_names']) {
            val = laneField.additionalData.name[0];
          }
        }
      }
    }
    if (!val || (val && val.trim() == "")) {
      val = "-";
    }
    return val;
  }
  getMultiSelectFirstValue(ele, field) {
    let valString = "";
    if (this.selectedLane) {

      let laneData = ele['data'];
      if (laneData != null) {

        let laneField = laneData.find((ele) => {
          if (ele['field'] == field) {
            return ele;
          }
        });
        if (laneField && (laneField.type == "multiSelect")) {
          if ((laneField.key == "string" || laneField.key == "number") && laneField.values) {
            valString = laneField.values.slice(0, 3).join(", ");
          }
        }
      }
    }
    return valString;
  }
  getMultiSelectRemainingAllValue(ele, field) {
    let val = [];
    if (this.selectedLane) {

      let laneData = ele['data'];
      if (laneData != null) {

        let laneField = laneData.find((ele) => {
          if (ele['field'] == field) {
            return ele;
          }
        });
        if (laneField && (laneField.type == "multiSelect")) {
          if ((laneField.key == "string" || laneField.key == "number") && laneField.values) {
            val = laneField.values.slice(3);
          }
        }
      }
    }
    return val;
  }
  getMultiSelecValueCount(ele, field) {
    let count = 0;

    if (this.selectedLane) {

      let laneData = ele['data'];
      if (laneData != null) {

        let laneField = laneData.find((ele) => {
          if (ele['field'] == field) {
            return ele;
          }
        });
        if (laneField && (laneField.type == "multiSelect")) {
          if ((laneField.key == "string" || laneField.key == "number") && laneField.values && laneField.values.length > 3) {
            count = laneField.values.length - 3;
          }
        }
      }
    }
    return count;
  }
  getOriginalClosingDateValue(ele, field) {
    let val = "";
    if (this.selectedLane) {
      if (field != null && field === "closing-date") {
        if (ele && ele['originalClosingDate']) {
          const parsedDate = new Date(ele['originalClosingDate']);
          // Add 5 hours and 30 minutes to the date
          parsedDate.setHours(parsedDate.getHours() + 5);
          parsedDate.setMinutes(parsedDate.getMinutes() + 30);

          val = this.datePipe.transform(parsedDate, 'dd-MM-yyyy');
        }
      }
    }
    return val;
  }
  getTotalPlantsOnSobContacts(element: any) {
    let totalPlantsOnSobContacts = [];
    if (element['searchData'] && element['searchData']['plant_names']) {
      totalPlantsOnSobContacts = element['searchData']['plant_names'];
    }
    return totalPlantsOnSobContacts;
  }
  getTotalPlantsCountOnSobContacts(element: any) {
    let totalPlantsOnSobContacts = [];
    if (element['searchData'] && element['searchData']['plant_names']) {
      totalPlantsOnSobContacts = element['searchData']['plant_names'];
    }
    if (element['searchData'] && element['searchData']['plantname_names']) {
      totalPlantsOnSobContacts = element['searchData']['plantname_names'];
    }
    return totalPlantsOnSobContacts.length;
  }
  getTotalPlantsMoreCountOnSobContacts(element: any) {
    let totalPlantsOnSobContacts = [];
    if (element['searchData'] && element['searchData']['plant_names']) {
      totalPlantsOnSobContacts = element['searchData']['plant_names'];
    }
    if (element['searchData'] && element['searchData']['plantname_names']) {
      totalPlantsOnSobContacts = element['searchData']['plantname_names'];
    }

    if (totalPlantsOnSobContacts.length > 1) {
      return totalPlantsOnSobContacts.length - 1;
    } else {
      return 0;
    }
  }
  calculateDifferenceBetweenDates(firstDate, secondDate) {
    let val = "";
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let diffDays = 0;
    diffDays = Math.round((firstDate - secondDate.getTime()) / oneDay);
    if (diffDays === 0) {
      val = " today";
    } else if (diffDays === 1) {
      val = " yesterday"
    } else {
      val = diffDays + " days ago";
    }
    return val;
  }
  fetchBoardLane(param?) {
    if (this.boardLaneResponse == null) {
      return;
    }

    //this.onScrollDown(false);
  }
  existed(event: CdkDragDrop<any>, id) {
    this.currentLaneMovedID = id;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  sortLaneTasks($event) {
    this.activeSorts = [];
    if ($event && $event['active'] && $event['direction']) {
      let index = this.activeSorts.findIndex((tmp) => {
        return tmp.field == $event['active']
      });
      if ($event['direction'].trim() == "") {
        // Do Nothing
      } else {
        let f = $event['active'].replace(/\W/g, '');
        if (!f.match(this.sortNumberFieldRegex)) {
          f = f + ".raw";
        } else if (f === 'actionItem') {
          f = "nextactiondate";
        }
        this.activeSorts.push({
          "field": "data." + f,
          "order": $event['direction']
        })
      }
    } else {
      // Do Nothing
    }
    this.paginator.firstPage();
    this.onScrollDown(false);
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }
  toLowerCase = (text) => {
    return text.toLowerCase();
  }
  getLeadStatus = (lead) => {
    let status = {
      "application_status": "",
      "lead_status": ""
    }
    if (lead.lane.name == "new-leads") {
      status['application_status'] = "Application Created";
    } else if (lead.lane.position > 1 && lead.lane.position < 5) {
      status['application_status'] = "Application Processing";
    } else if (lead.lane.position == 5) {
      status['application_status'] = "Awaiting Termsheet";
    } else if (lead.lane.position > 5) {
      status['application_status'] = "Termsheet Issued";
    }
    status['lead_status'] = lead.lane.displayName;
    return status;
  }
  callForTaskMovement(event, obj) {
    obj['coldLeadReasons'] = this.setReasonToMove
    this._kService.assignLaneTaskMovement(obj).subscribe(res => {
      if (res['status'] == 200) {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        this.fetchBoardLane();
      }
    })
  }
  applyFilter(filterField) {
    let index = this.filterFields.findIndex((tmp) => {
      return filterField['field'] == tmp['field'];
    });
    if (index == -1) {
      this.filterFields.push(filterField);
    }
    this.onScrollDown(false);
  }
  clearFilter(filterField) {
    let index = this.filterFields.findIndex((tmp) => {
      return filterField['field'] == tmp['field'];
    });
    if (index > -1) {
      this.filterFields.splice(index, 1)
    }
    this.onScrollDown(false);

  }
  getOptionList(list) {
    if (!list) {
      return [];
    }
    list.sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return list;
  }
  onScrollDown(loadStats) {
    console.log('onScrollDown........')
    this.isLoading = true;
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardId
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageSize * this.paginator.pageIndex,
      "size": this.pageSize
    }
    this.console.log('onScrollDown........inboiardaa', obj)
    if (this.selectedLane != null && this.selectedLane.laneDTO && this.selectedLane.laneDTO.id && this.selectedLane.laneDTO.id != "all") {
      obj['where']['laneId.raw'] = {
        "type": "search",
        "field": "laneId.raw",
        "operator": "or",
        "value": [
          {
            "field": "laneId.raw",
            "value": this.selectedLane.laneDTO.id
          }
        ]
      }
    } else {
      if (this.boardLane) {
        let laneQuery = [];
        for (let k = 0; k < this.boardLane.length; k++) {
          if (this.boardLane[k].laneDTO && this.boardLane[k].laneDTO.id && this.boardLane[k].laneDTO.id != "all") {
            laneQuery.push({
              "field": "laneId.raw",
              "value": this.boardLane[k].laneDTO.id
            });
          }
        }
        obj['where']['laneId.raw'] = {
          "type": "search",
          "operator": "or",
          "value": laneQuery
        }
      }
    }
    if (this.filteredObj['assignList'] != null && this.filteredObj['assignList'].length > 0) {
      obj['where']['assignee.user.id'] = {
        "type": "in",
        "value": this.filteredObj['assignList']
      }
    }
    if (this.filteredObj['tagList'] != null && this.filteredObj['tagList'].length > 0) {
      obj['where']['tags.id'] = {
        "type": "in",
        "value": this.filteredObj['tagList']
      }
    }
    if (this.stagesFilter != null && this.stagesFilter.length > 0) {
      let laneQuery = [];
      for (let k = 0; k < this.stagesFilter.length; k++) {
        laneQuery.push({
          "field": "laneId.raw",
          "value": this.stagesFilter[k]
        });
      }
      obj['where']['laneIdQuery'] = {
        "type": "search",
        "operator": "or",
        "value": laneQuery
      }
    }
    if (this.searchText && this.searchText.trim() != null) {
      let name = {
        "type": "multi_match_query",
        "value": [
          {
            "field": "displayName",
            "value": this.searchText,
            "type": "like"
          },
          {
            "field": "displaySequenceName",
            "value": this.searchText,
            "type": "like"
          }
        ]
      }
      obj['where']['multi_match_query'] = name;
    }
    if (this.teamQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["team_query"] = this.teamQuery;
    }
    if (this.accountQuery) {
      this.console.log("Account query", this.accountQuery)
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["account_query"] = this.accountQuery;
    }
    if (this.plantQuery) {
      this.console.log("Plant query", this.plantQuery)
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["plant_query"] = this.plantQuery;
    }
    if (this.searchByNameQuery) {
      obj['where']["name_query"] = this.searchByNameQuery;
    }
    if (this.industryQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["industry_query"] = this.industryQuery;
    }
    if (this.tagTypeQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["tagTypeQuery"] = this.tagTypeQuery;
    }
    if (this.bsQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["bs_query"] = this.bsQuery;
    }
    if (this.mywatchlistQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["mywatchlist_query"] = this.mywatchlistQuery;
    }
    if (this.dealtypeQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["dealtype_query"] = this.dealtypeQuery;
    }
    if (this.l1CategoryQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["l1category_query"] = this.l1CategoryQuery;
    }
    if (this.leadSourceQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["leadSourceQuery"] = this.leadSourceQuery;
    }
    if (this.poTypeQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["potype_query"] = this.poTypeQuery;
    }
    if (this.userQuery) {
      obj['where']["user_query"] = this.userQuery;
    }
    if (this.createdDateQuery) {
      obj['where']["createdAt"] = this.createdDateQuery['createdAt'];
    }
    if (this.closingDateQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']['data.closingdate'] = this.closingDateQuery['data.closingdate'];
    }
    if (this.w1recievedQuery) {
      obj['where']['data.W1_stage'] = this.w1recievedQuery['data.W1_stage']
    }
    if (this.filterFields && this.filterFields.length) {
      for (let k = 0; k < this.filterFields.length; k++) {
        let filterQuery = [];
        let field = this.filterFields[k];
        let values = field['icon'];
        if (typeof values == 'string') {
          if (values.trim() != "") {
            values = [values];
          } else {
            continue;
          }
        } else {
          if (values.length < 1) {
            continue;
          }
        }
        for (let p = 0; p < values.length; p++) {
          let tmpField = field['field'];
          tmpField = tmpField.replace(/[^a-zA-Z0-9]/, "");
          let tmpIndex = filterQuery.findIndex((tmp) => {
            return tmp['field'] == "data." + tmpField;
          })
          field = "data." + tmpField + ".raw";
          if (tmpIndex == -1) {
            filterQuery.push({
              "field": field,
              "value": values[p]
            });
          }
        }
        let q = {
          "type": "search",
          "operator": "or",
          "value": filterQuery
        }
        if (!obj['nestedWhere']) {
          obj['nestedWhere'] = {};
        }
        obj['nestedWhere'][field] = q;
      }
    }

    if (this.supplierTypeQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["supplier_type_query"] = this.supplierTypeQuery;
    }
    if (this.brandQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["brand_query"] = this.brandQuery;
    }
    if (this.locationQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["location_query"] = this.locationQuery;
    }
    if (this.l2CategoryQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["l2Category_query"] = this.l2CategoryQuery;
    }
    if (this.moglixPOCQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["moglixPOC_query"] = this.moglixPOCQuery;
    }
    if (this.MAQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["MA_query"] = this.MAQuery;
    }
    if (this.targetPanelQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["targetPanel_query"] = this.targetPanelQuery;
    }

    if (this.companyTypeQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["companyTypeQuery"] = this.companyTypeQuery;
    }

    if (this.productInterestedInQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["productInterestedInQuery"] = this.productInterestedInQuery;
    }

    if (this.lifeCycleStageQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["lifeCycleStageQuery"] = this.lifeCycleStageQuery;
    }

    if (this.marketQuery) {
      if (!obj['nestedWhere']) {
        obj['nestedWhere'] = {};
      }
      obj['nestedWhere']["marketQuery"] = this.marketQuery;
    }

    this.currentState['query'] = obj;
    this.currentState['selectedLane'] = this.selectedLane;
    localStorage.setItem("board_listing", this.currentState);
    if (loadStats && this.boardId == Constants.boardIdForCRMDeals || (this.boardId == Constants.boardIdForSobOpportunity || this.boardId === Constants.boardIdForSrmSupplier)) {
      let statsQuery = JSON.parse(JSON.stringify(obj));
      statsQuery['query'] = {};
      if (statsQuery['where']) {
        statsQuery['query']['where'] = statsQuery.where;
      }
      if (statsQuery['nestedWhere']) {
        statsQuery['query']['nestedWhere'] = statsQuery['nestedWhere'];
      }
      statsQuery['type'] = "TOP_DEALS_BY_CLOSING_DATE";
      if (statsQuery['where'] && statsQuery['where']['laneId.raw']) {
        delete statsQuery['where']['laneId.raw'];
      }
      this.loadLaneStats(statsQuery);
    }
    this.getLaneTask(obj, this.laneTaskList);
  }
  getToolTipText(text, reverse) {

    let type = "";
    if (this.boardId == "63bfa8ccade7bc466d1c780e") {
      type = " Deal";
    } else if (this.boardId == "63bfa8c3ade7bc466d1c780d") {
      type = " Account";
    } else if (this.boardId == "63bfa88dade7bc466d1c780c") {
      type = " Contact";
    }
    if (reverse) {
      return type.trim() + " " + text;
    } else {
      return text + type;
    }
  }
  getLaneTask(obj, lanetaskList?, isFromGlobalSearch?) {
    console.log('getLaneTask##############', obj);
    let name;
    if (isFromGlobalSearch) {
      this.searchText = isFromGlobalSearch;
    }
    if (this.activeSorts && this.activeSorts.length > 0) {
      obj['nested_sort'] = [{
        ...this.activeSorts[0]
      }]
      delete obj['sort'];
    } else {
      obj['sort'] = {
        "field": "updatedAt",
        "order": "desc"
      };
      delete obj['nested_sort'];
    }
    obj['size'] = this.pageSize;
    obj['from'] = this.pageSize * this.paginator.pageIndex,
      console.log('before getLaneTask', obj)
    this._kService.getLaneTask(obj).subscribe(res => {
      this.isLoading = false;
      if (res == null) {
        return;
      }
      this.pageIndex++;
      this.totalRecords = res['total'];
      this.laneTaskList = res['result'];
      this.dataSource.data = res['result'];;
      if (this.laneTaskList.length != 0) {
        this.boardLane.map(val => {
          if (isFromGlobalSearch) {
            val['taskList'] = [];
          }
          if (lanetaskList) {
            if (val.laneDTO.id == this.laneTaskList[0].laneId) {
              val['taskList'] = this.laneTaskList;
            }
          }
          else if (isFromGlobalSearch) {
            this.laneTaskList.map(data => {
              if (val.laneDTO.id == data['laneId']) {
                val['taskList'].push(data);
              }
            })
          }
          else {
            if (val.laneDTO.id == this.laneTaskList[0].laneId) {
              val['taskList'] = this.laneTaskList;
            }
            else {
              val['taskList'] = [];
            }
          }
          this.pageFrom = this.pageFrom + 1
        })
      }
      else {
        this.boardLane.map(val => {
          for (let i = 0; i < this.boardLane.length; i++) {
            if (val.laneDTO.id == this.boardLane[i]['laneDTO'].id) {
              val['taskList'] = [];
            }
          }
        })
      }
      this.getLabelCount(res)
    },
      (error) => {
        this.isLoading = false;
      }
    )
  }
  initSearch() {
    this.textServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap((text) => {

          this.searchText = text;
          this.searching = true
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredBanks1 => {
        let fieldData = {};
        this.searching = false;
        this.searchAuto(fieldData).subscribe((response) => {
          if (this.searchText) {
            this.results = response['result'].filter(bank => {
              let matchText = bank.userName ? bank.userName.toLowerCase() : bank.name.toLowerCase();
              return matchText.indexOf(this.searchText.toLowerCase()) > -1
            });
            this.filteredServerSideTexts.next(this.results);

          } else {
            this.results = response['result'].filter(bank => bank.name);
            this.filteredServerSideTexts.next(this.results);
          }
        })
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });

  }
  selectAction(data: any) {
    this.addData = "";

  }
  submitSearch() {
    if (this.searchText && this.searchText.trim() == "") {
      this.resetSearchText();
    }


    this.showListing = false;
  }
  searchAuto(fieldData) {
    this.console.log("fieldData", fieldData);
    let value = this.searchText;
    let searchType = fieldData.config.key;
    if (searchType == "user") {
      return this._kService.getKanbanUserList();
    } else if (searchType == "laneTask") {
      let relationKey = fieldData.config.relationKey;
      if (relationKey) {
        let obj = { "where": { "isDeleted": { "type": "search", "value": false }, "board.$id": { "type": "object_id_in", "value": [`${relationKey}`] } }, "sort": { "field": "createdAt", "order": "desc" }, "from": 0, "size": 5 }
        return this._kService.getLaneTask(obj)
      } else {
        let obj = { "where": { "isDeleted": { "type": "search", "value": false } }, "sort": { "field": "createdAt", "order": "desc" }, "from": 0, "size": 5 }
        return this._kService.getLaneTask(obj)
      }
    } else if (searchType == "userTeam") {
      return this.settingService.fetchTeams();
    }
  }
  getCardDetails(list) {
    window.open('/#/cardDetails/' + list.id);
    this.showListing = false;

  }
  resetSearchText() {
    this.searchText = "";
    this.showCloseIcon = false;
    this.showListing = false;
  }
  getLabelCount(task?) {
    return task.totalRecords;
  }
  onScrollHorizontal(event, length) {
    if (length < this.boardLane['totalRecords']) {
      this.isHorizontalScroll = true;
      this.lanePageFrom = this.lanePageFrom + 1
      this.lanePageSize = this.lanePageSize
      this.fetchBoardLane();
    }
  }
  getTaskRemarks(task) {
    if (task['remark']) {
      task['remark'].map(val => {
        if (val.laneId == task.laneId) {
          return val.remarks
        }
      })
    }
  }
  setAssigneename(list) {
    if (list.user != null) {
      let userName = list.user.name;
      var matches = userName.match(/\b(\w)/g);
      if (matches.length > 2) {
        matches.splice(2, matches.length)
      }
      this.userProfileName = matches.join('').toUpperCase()
      return this.userProfileName;
    }
  }
  viewLaneTask(task) {
    const dialogRef = this.dialog.open(ViewLaneComponent, {
      disableClose: false,
      width: '70%',
      panelClass: 'right-side-dialog',
      //  position : {bottom: '0px', right:'0px'} ,
      data: {
        laneTask: task
      }
    });


  }
  changeAssignee(task) {
    const dialogRef = this.dialog.open(LaneTaskUsersTabComponent, {
      disableClose: false,
      width: '30%',
      height: '50%',
      //  position : {bottom: '0px', right:'0px'} ,
      data: {
        laneTask: task
      }
    });
  }
  showHistory(task) {
    const dialogRef = this.dialog.open(HistoryTabComponent, {
      disableClose: false,
      width: '70%',
      height: '80%',
      panelClass: 'right-side-dialog',
      //  position : {bottom: '0px', right:'0px'} ,
      data: {
        laneTask: task,
        boardId: this.boardId
      }
    });
  }
  openCardDetails(task) {
    task['boardId'] = this.boardId
    this.boardLane.map(ele => {
      if (ele.laneDTO.id == task.laneId) {
        task['isEditAccess'] = ele.laneDTO.isEditAccess
      }
    })
    window.open('/#/cardDetails/' + task.id, "_blank");
  }
  openEmailDetails(task) {
    this.settingService.setEmailCommunication(task);
    const queryParams = `?tasks=${task.id}`;
    window.open('/#/email-communication/' + queryParams, "_self");
  }
  accountReject() {
    const dialogRef = this.dialog.open(AccountRejectComponent, {
      width: '600px',
      height: '248px',
      backdropClass: 'account-reject-dialog-backdrop-class',
      panelClass: 'account-reject-dialog-panel-class',

    });

  }
  isWatcherAdded(element) {
    if (element && element['searchData']['watcher']) {
      let watchers = element['searchData']['watcher'];
      let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
      if (getLocalStorage && getLocalStorage['role']) {
        let currentUserEmail = getLocalStorage['role']['userEmail'];
        return watchers.includes(currentUserEmail);
      }
    }
    return false;
  }
  updateWatcheronDeal(element) {
    this.console.log('updateWatcheronDeal', element)
    if (this.isWatcherAdded(element)) {
      //remove watcher
      this._kService.updateWatcherOnDeal(element['id'], "remove").subscribe(res => {
        console.log('updateWatcheronDeal res', res)
        if (res && res['result']) {

          this._snackBar.openFromComponent(SuccessToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'success'],
            duration: 6000,
            data: res['result']
          });


          // this.toasterService.success(res['result'])
          this.onScrollDown(false);
        }
      },
        (error) => {
          console.log('updateWatcheronDeal error', error)
        });
    } else {
      //add watcher
      this._kService.updateWatcherOnDeal(element['id'], "add").subscribe(res => {
        console.log('updateWatcheronDeal res', res)
        if (res && res['result']) {
          this._snackBar.openFromComponent(SuccessToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'success'],
            duration: 6000,
            data: res['result']
          });

          // this.toasterService.success(res['result'])
          this.onScrollDown(false);
        }
      },
        (error) => {
          console.log('updateWatcheronDeal error', error)
        });
    }
  }
  getWatcherToolTip(element) {
    if (this.isWatcherAdded(element)) {
      return "Click to remove bookmark";
    } else {
      return "Click to bookmark";
    }
  }
  isW1Recieved(element) {
    if (element && element['rfqDto'] && element['rfqDto'].length > 0) {
      return "W1 Received"
    } else return ""
  }
  downloadSingleDocument(id) {
    this._kService.downloadSingleUploadDocument(id).subscribe(res => {
      this.downloadSheetCommonMethod(res);

    })
  }
  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    let fileName = resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }
  getFormPropertyValue(element: any, property: any) {
    let val = "-";
    if (element && element['searchData'] && element['searchData']['owner_form_data'] && element['searchData']['owner_form_data']['items']) {
      let index = this.getPrimaryIndex(element['searchData']['owner_form_data']['items']);
      if (index != -1) {
        val = element['searchData']['owner_form_data']['items'][index][property];
      }
    }
    return val;
  }
  getPrimaryIndex(items: any) {
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element['primary']) {
        return index;
      }
    }
    return -1;
  }

  openMeetingDialog(element: any, dialogType: 'allMeetings' | 'momMeetings' | 'meetingsByField' | 'meetingsByFieldWithMOM') {
    element.allMeetingDialog = false;
    element.momMeetingDilaog = false;
    element.meetingsByField = false;
    element.filters = {};

    // Get the current date
    const currentDate = new Date();

    // Set the start date to the first day of the current month at 00:00:00
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);

    // Set the end date to the last day of the current month at 23:59:59
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    // Assign to element filters
    element.filters.startDate = startOfMonth.getTime();
    element.filters.endDate = endOfMonth.getTime();


    switch (dialogType) {
      case 'allMeetings':
        element.allMeetingDialog = true;
        break;
      case 'momMeetings':
        element.momMeetingDilaog = true;
        break;
      case 'meetingsByField':
        element.meetingsByField = true;
        if (this.boardId === this.boardIdForVedantaAccount) {
          element.board = this.boardIdForVedantaAccount
        }
        else if (this.boardId === this.boardIdForSobPlant) {
          element.board = this.boardIdForSobPlant
        }
        break;
      case 'meetingsByFieldWithMOM':
        element.meetingsByFieldWithMOM = true;
        if (this.boardId === this.boardIdForVedantaAccount) {
          element.board = this.boardIdForVedantaAccount
        }
        else if (this.boardId === this.boardIdForSobPlant) {
          element.board = this.boardIdForSobPlant
        }
        break;
    }

    const dialogRef = this.dialog.open(MeetingListComponent, {
      width: '580px',
      height: '530px',
      panelClass: 'right-side-dialog',
      data: element
    });
  }

}
