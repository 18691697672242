<div id="curtain" class="curtain">
  <div id="curtain-spinner" class="curtain-spinner"></div>
</div>
<!-- Tool Bar Row(First Row) -->
<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" id="mainToolbar" color="primary" *ngIf="loginDetails && selectAccount">
  <!-- Moglix Logo(Web Only) -->
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon *ngIf="!pagesService.checkMobileScreen()" (click)="onToolbarMenuToggle()" class="menuicon" id="toolbarMenu">subject</mat-icon>
    <a class="d-flex" (click)="navigateToSupplier()"><img src="../assets/images/vedanta-crm.png"
        style="width: 148px;height:31px;"></a>
  </div>
<!-- Gloabl Search (Web Only) -->
  <div  *ngIf="!pagesService.checkMobileScreen()">
    <div fxLayout="column" class="search_box">
      <div class="srchBox" fxLayout="row" fxLayoutAlign="flex-start center">
        <!-- <i class="ri-search-line" ></i> -->
        <mat-icon aria-hidden="false" aria-label="Search" fontIcon="home" matTooltip="Search"
          matTooltipClass="new-tooltip" class="material-symbols-outlined comments-icon">search</mat-icon>
        <input type="text" placeholder="Search" [(ngModel)]="searchText" (input)="onSearchChange(searchText)"
          (keyup.enter)="submitSearch()" />
        <mat-icon aria-hidden="false" aria-label="Close" fontIcon="home" matTooltip="Close"
          matTooltipClass="new-tooltip" class="material-symbols-outlined comments-icon padding-1"
          (click)="resetSearchText();" *ngIf="searchText && searchText != ''">close</mat-icon>
      </div>

      <div class="searchedListing" *ngIf="showListing && searchText.length>1">
        <div class="sk-fading-circle" *ngIf="autoSuggestloading">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>
        <div *ngIf="storeSearchResponse.length>0 && autoSuggestloading == false">
          <ul *ngFor="let list of storeSearchResponse; let i = index">
            <li (click)="getCardDetails(list)">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-icon-div">
                  <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Company Name"
                    matTooltipClass="new-tooltip"
                    class="material-symbols-outlined company-icon">corporate_fare</mat-icon>

                </div>
                <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%;">
                  <span class="table-title" fxLayout="row" fxLayoutAlign="start center" style="width: 100%;"><label
                      class="lbLabel">Name:</label> <span class="ldname">{{list.name || "N/A"}}</span></span>
                  <span class="table-title" fxLayout="row" fxLayoutAlign="start center" style="width: 100%;"><label
                      class="lbLabel">Status :</label> <span class="ldname">{{list.lane.name }}</span></span>
                </div>
              </div>

            </li>

          </ul>
          <!-- <ul  class="auto-all" (click)="submitSearch()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span class="table-title" fxLayout="row" fxLayoutAlign="start center" ><label class="lbLabel" style="width: 100%;" >View All Results for : {{searchText}} <span class="enterLabel">(Press Enter)</span></label> </span>
        </div>
      </ul> -->
        </div>
      </div>
    </div>
  </div>
<!-- Right Head Area-->
  <div class="rightheadarea" fxLayout="row" fxLayoutAlign="center center">
   <!-- FeedBack Form -->
   <!-- <div *ngIf="!pagesService.checkMobileScreen()">  
    <div class="feedback-btn">
      <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=hJUeQv-HT0KVuBv0a3DbmSmLv6EK659EsEluwQxoFqNUQUszTjdaRjNNVERFWFQ2QUkzWjU0V0pSMC4u"
        target="_blank">Help us improve better <i class="ri-external-link-line "></i></a>
    </div>
   </div> -->
   <!-- Business Drop-Down (Web Only) -->
   <div fxLayout="row" fxLayoutAlign="center center">
    <div  *ngIf="!pagesService.checkMobileScreen()">
    <mat-form-field appearance="outline" class="mog3-matselect mr-10 portal-select" *ngIf="enableBusinessDropDown">
      <mat-select panelClass="portal-selection" placeholder="Select portal" [(ngModel)]="selecedManagement">
          <mat-option *ngIf="crmUserId!=null" class="portal-option " value="SOB Management" (click)="switchManagement('SOB Management')"
          [ngClass]="{'active': isSOB}">
          <i class="ri-checkbox-circle-fill"></i>
          SOB Management</mat-option>
          <mat-option *ngIf="sobUserId!=null" class="portal-option " value="CRM Management" (click)="switchManagement('CRM Management')"
          [ngClass]="{'active': isCRM}">
          <i class="ri-checkbox-circle-fill"></i>
          Hunting Management</mat-option>
          <mat-option *ngIf="srmUserId!=null" class="portal-option " value="SRM Management" (click)="switchManagement('SRM Management')"
          [ngClass]="{'active': isSRM}">
          <i class="ri-checkbox-circle-fill"></i>
          SRM Management</mat-option>
        <!-- <mat-option *hasPermission="['CAMPAIGN']" [routerLink]="['/abm-management']" value="ABM Management"
          (click)="switchManagement('ABM Management')" class="portal-option " [ngClass]="{'active': !isCRM && !isSOB}">
          <i class="ri-checkbox-circle-fill"></i>
          ABM Management</mat-option> -->
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field appearance="outline" class="mog3-matselect mr-10 portal-select"
      *ngIf="crmUserId==null && sobUserId!=null">
      <mat-select panelClass="portal-selection" placeholder="Select portal" [(ngModel)]="selecedManagement">
        <mat-option class="portal-option " value="SOB Management" (click)="switchManagement('SOB Management')"
          [ngClass]="{'active': isSOB}">
          <i class="ri-checkbox-circle-fill"></i>
          SOB Management</mat-option>
        <mat-option *hasPermission="['CAMPAIGN']" [routerLink]="['/abm-management']" value="ABM Management"
          (click)="switchManagement('ABM Management')" class="portal-option " [ngClass]="{'active': !isCRM && !isSOB}">
          <i class="ri-checkbox-circle-fill"></i>
          ABM Management</mat-option>
      </mat-select>
    </mat-form-field> -->
    <div *ngIf="isCredlixUser" style="margin-right: 20px;">
      <mat-form-field class="buyerList">
        <mat-select style="width: 80px;" [(value)]="anchorType" (selectionChange)="changeAnchor($event.value)">
          <mat-option style="font-size: 10px;" *ngFor="let anchor of anchorList" [value]="anchor">
            {{anchor.displayName}}
          </mat-option>
        </mat-select>
        <i matSuffix class="ri-arrow-down-s-fill"></i>
      </mat-form-field>
    </div>
    </div>
    <div fxFlex="2" [matMenuTriggerFor]="menu" class="user_prf"><span>{{userProfile}}</span>
    </div>
   </div>
   <!-- Profile Icon(Web and Mobile) -->
   <mat-menu #menu="matMenu" class="menuListProfile">
      <ul class="head-user-info">
        <li class="name">{{userName}}</li>
        <li class="email">{{userEmail}}</li>
        <li class="role">
          <span *ngIf="!businessName">{{ displayName | titlecase }}</span>
        </li>
      </ul>
      <hr>
      <button mat-menu-item (click)="logout()">
        Logout
      </button>
      <hr>
      <button mat-menu-item (click)="deleteUserAccount()">
        Delete Account
      </button>
   </mat-menu>
  </div>
</mat-toolbar>
<!-- Side Navigator(Web Only) -->
<mat-sidenav-container id="sidenavContainer" fullscreen
  *ngIf="loginDetails && selectAccount && !pagesService.isSubmitted" [ngClass]="{'full': sidenavfull == true}">
  <mat-sidenav mode="side" id="sidenav" >
    <div class="sidenav_items" >
    <mat-nav-list class="dash-sidenav-list">
      <mat-icon>subject</mat-icon>
    </mat-nav-list>
    <div class="sidenav_items_new">
      <mat-nav-list class="dash-sidenav-list">
        <ng-container *ngIf="isCRM || isSOB">
        <!-- <mat-list-item class="repay" routerLink="/hunting-dashboard" routerLinkActive="active-list-item">
          <mat-icon class="material-symbols-rounded hunting-icon">dashboard</mat-icon>
          <a matLine class="side-text">Dashboard</a>
        </mat-list-item> -->
      </ng-container>
        <!-- <mat-list-item class="repay" routerLink="/hunting-projections" routerLinkActive="active-list-item" *hasPermission="['KANBAN']" >
          <mat-icon class="material-symbols-rounded hunting-icon">dashboard</mat-icon>
          <a matLine class="side-text">Projections</a>
        </mat-list-item> -->
        <ng-container *ngIf="isCRM || isSOB || isSRM">
          <mat-list-item class="repay" routerLink="/crm-board" routerLinkActive="active-list-item"
            *hasPermission="['KANBAN']">
            <mat-icon class="material-symbols-rounded hunting-icon">track_changes</mat-icon>
            <a matLine class="side-text"> {{boardName}} </a>
          </mat-list-item>
        </ng-container>
        <!-- <ng-container *ngIf="!isCRM && !isSOB"> -->
          <!-- <mat-list-item class="repay" routerLink="/abm-management" routerLinkActive="active-list-item"
            *hasPermission="['CAMPAIGN']">
            <mat-icon class="material-symbols-rounded hunting-icon">campaign</mat-icon>
            <a matLine class="side-text">Campaign</a>
          </mat-list-item> -->
          
        <!-- Meeting -->
        <mat-list-item class="repay" *ngIf="isCRM || isSOB || isSRM" routerLink="/meeting" routerLinkActive="active-list-item">
            <mat-icon class="material-symbols-rounded hunting-icon">calendar_month</mat-icon>
            <a matLine class="side-text">Meeting</a>
        </mat-list-item>

         <!-- KAM -->
        <mat-list-item class="repay" routerLink="/tracker" routerLinkActive="active-list-item">
          <i class="ri-map-pin-user-fill"></i>
          <a matLine class="side-text">Tracker</a>
        </mat-list-item>
        

<!-- 
          <mat-list-item class="repay" *ngIf="isSOB" routerLink="/meeting" routerLinkActive="active-list-item"></mat-list-item>
            <mat-icon class="material-symbols-rounded hunting-icon">user</mat-icon>
            <a matLine class="side-text">Tracker</a>
          </mat-list-item> -->
        <!-- </ng-container> -->
        <!-- <mat-list-item class="repay" routerLink="/presales" routerLinkActive="active-list-item"
        (click)="resetGlobaFiler('white_doc_cb');">
        <img src="/assets/icons/Payout_blue.svg" id="grey_doc_cb">
        <img src="/assets/icons/Payout_white.svg" id="white_doc_cb">
        <a matLine>PreSales </a>
      </mat-list-item> -->
        
        <!-- Raise Ticket -->
        <!-- <a href="https://moglilabs.freshservice.com/support/catalog/items/72" target="_blank">
          <mat-list-item class="repay" routerLinkActive="active-list-item" style="position: absolute; bottom: 52px;">
            <mat-icon class="material-symbols-rounded hunting-icon">forum</mat-icon>
            <a matLine class="side-text">Raise Ticket</a>
          </mat-list-item>
        </a> -->
      
        <!-- Settings -->
        <mat-list-item class="repay" routerLinkActive="active-list-item" *hasPermission="['MANAGE_SETTINGS']"
          routerLink="/settings/user-management" style="position: absolute; bottom: 13px;">
          <mat-icon class="material-symbols-rounded hunting-icon">settings</mat-icon>
          <a matLine class="side-text">Settings</a>
        </mat-list-item>

      </mat-nav-list>
    </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content  [ngClass]="{'dasharea': !pagesService.checkMobileScreen()}">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-sidenav-container id="sidenavContainer" fullscreen *ngIf="loginDetails && pagesService.isSubmitted"
  [ngClass]="{'full': sidenavfull == true}">
  <mat-sidenav-content class="dasharea">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="isLoading" class="overlay">
  <mat-spinner class="spinner" [diameter]="60"></mat-spinner>
</div>

<router-outlet *ngIf="!loginDetails || !selectAccount"></router-outlet>