import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill'
import { FormControl, FormControlName, FormGroup, FormBuilder } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { saveAs } from 'file-saver';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import 'quill-mention';
import {
  animate, state, style, transition, trigger
} from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingService } from 'src/app/settings/setting.service';
import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { MatSnackBar } from '@angular/material';

type PaneType = 'left' | 'right';
@Component({
  selector: 'app-comments-tab',
  templateUrl: './comments-tab.component.html',
  styleUrls: ['./comments-tab.component.scss'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300))
    ])],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CommentsTabComponent implements OnInit {
  @Input() activePane: PaneType = 'left';
  minDateForNextAction: Date = new Date();
  comments: any = [];
  dataSource = new MatTableDataSource(this.comments);
  displayedColumns: string[] = ["id"];
  displayedColumns1: ["id"];
  laneTaskIDDetail: any;
  allUsersList: any=[{id:1,value:"shashikant"},{id:1,value:"shashi"},{id:1,value:"shanky"},{id:1,value:"shashikant"},{id:1,value:"shashi"},{id:1,value:"shanky"}];
  userTitle = "";
  title = "";
  file: any;
  isProgressVisible = false;
  type: string = "";
  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent
  content = ''
  matContent = ''
  nextActionDate: any
  requestfromNewDeal:any;

  showMoretext = false;
  showCommentArea = false;
  mentionList:any=[];
  selectedIndex=null;
  modules = {
    mention: {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      mentionContainerClass:'ql-mention-list-container',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor
        insertItem(item)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user')
        this.mentionList.push(item.id);
      },
      source:  async (searchTerm, renderList) => {
        // const values = this.allUsersList

        // if (searchTerm.length === 0) {
        //   renderList(values, searchTerm)
        // } else {
        //   const matches = []

        //   values.forEach((entry) => {
        //     if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
        //       matches.push(entry)
        //     }
        //   })
        //   renderList(matches, searchTerm)
        // }
        const matchedPeople =  await this.suggestPeople(searchTerm);
        console.log('matchedPeople',matchedPeople)
        renderList(matchedPeople,searchTerm);
      }
    },
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter', context);
            return true;
          },
        },
      },
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      // ['link', 'image', 'video']                         // link and image, video
    ]
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public _kService: KanbanService, public toasterService: ToastrService, public dialogRef: MatDialogRef<CommentsTabComponent>,
  public settingService: SettingService,private _snackBar: MatSnackBar) { 

  }
  toggleText() {
    this.showMoretext = !this.showMoretext;
  }
  commentPageNumber: number = 0;
  pageSize: number = 10;
  totalComments: any ;
  getTaskComment(save?, load?) {
    console.log('type:::',this.type)

    let reqObj = {
      "from": this.commentPageNumber * this.pageSize,
      "size": 200,
      "where": { "laneTask.$id": { "type": "search", "value": this.laneTaskIDDetail.id } }
    }
    let reqFunc = null;
    if (this.type == "action-item") {
      this.title = "Action Items";
      this.userTitle = "Added";
      reqFunc = this._kService.getActionItem(reqObj);
    } else {
      this.title = "Notes";
      this.userTitle = "Added";
      reqFunc = this._kService.getTaskComment(reqObj);
    }
    reqFunc.subscribe(res => {
      let tmp = res['result'];
      this.totalComments = tmp;
      this.dataSource.data = tmp;
    })
  }
  commentUserName: string
  getCommentUserName(name) {
    if (name != null) {
      let userName = name['byUser']['name'];
      if (userName != null && userName != '') {
        var matches = userName.match(/\b(\w)/g);
        if (matches.length > 2) {
          matches.splice(2, matches.length)
        }
        this.commentUserName = matches.join('').toUpperCase()
        return this.commentUserName;
      }
    }
  }

  ngOnInit() {
    this.selectedIndex=null;
    this.mentionList=[];
    this.content='';
    if (this.data != null && this.data['laneTask'] != null) {
      this.laneTaskIDDetail = this.data['laneTask'];
    }
    if (this.data != null && this.data['userList'] != null) {
      let tmp = [];
      for (let k = 0; k < this.data['userList'].length; k++) {
        let t = this.data['userList'][k];
        t['value'] = t.userName;
        tmp.push(t);
      }
     // this.allUsersList = tmp;
    }
    if(this.data!=null && this.data["requestfromNewDeal"]!=null){
      this.requestfromNewDeal=this.data["requestfromNewDeal"];
    }
    if(this.requestfromNewDeal){
      if (this.data['type']) {
        this.type = this.data['type'];
      }
      if (this.data['title']) {
        this.title = this.data['title'];
      }
    }else{
      if (this.laneTaskIDDetail) {
        if (this.data['type']) {
          this.type = this.data['type'];
        }
        this.getTaskComment(false);
      }
    }
  }
  addComment() {
    console.log('addComment');
    let tmp = this.content;
    tmp = tmp.replace(/(<([^>]+)>)/ig, '');
    if (this.content && (this.content.trim() != "" && tmp.trim() != "") && this.nextActionDate) {
      let obj = {
        "commentId": "string",
        "message": this.content,
        "nextActionDate": this.nextActionDate,
        "mention":this.mentionList
      }
      if (this.file) {
        obj["file"]=this.file;
        obj["fileType"]="actionItemId";
      }
      this.dialogRef.close({actionData:obj});
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Next Action Date and Comments are mandatory"
      });
      // this.toasterService.error("Next Action Date and Comments are mandatory");
    }
  }
  onScrollDown() {
    console.log("Scrolling");
  }
  postComment() {
    console.log('postComment mention list',this.mentionList)
    let tmp = this.content;
    console.log('content : ',this.content)
    tmp = tmp.replace(/(<([^>]+)>)/ig, '');
    if((this.type == "action-item")){
    if (this.content && (this.content.trim() != "" && tmp.trim() != "") && this.nextActionDate) {
      let obj = {
        "commentId": "string",
        "laneTaskId": this.laneTaskIDDetail.id,
        "message": this.content,
        "nextActionDate": this.nextActionDate,
        "mention":this.mentionList
      }
      let reqFunc = null;
        this.title = "Action Items";
        this.userTitle = "Added";
        reqFunc = this._kService.addTaskActionItem(obj);
      
      reqFunc.subscribe(res => {
        if (res['status'] == 200) {
          if (this.file) {
            const uploadData = new FormData();
            uploadData.append('file', this.file, this.file.name);
            this._kService.uploadSubDocuments(uploadData, res['result'].id, (this.type == "action-item" ? "actionItemId" : "commentId")).subscribe(file => {
              if (file['status'] == 200) {
                this.isProgressVisible = false;
              }
              this.content = ''
              this.mentionList=[]
              this.getTaskComment("save");
            });
          } else {
            this.file = null;
            this.content = ''
            this.mentionList=[]
           this.getTaskComment("save");
          }
          
        }

      })
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Next Action Date and Comments are mandatory"
      });
      // this.toasterService.error("Next Action Date and Comments are mandatory");
    }
  }else{
    if (this.content && (this.content.trim() != "" && tmp.trim() != "")) {
      let obj = {
        "commentId": "string",
        "laneTaskId": this.laneTaskIDDetail.id,
        "message": this.content,
        "nextActionDate": this.nextActionDate,
        "mention":this.mentionList
      }
      let reqFunc = null;
        this.title = "Comments";
        this.userTitle = "Added";
        reqFunc = this._kService.addTaskComment(obj);
      reqFunc.subscribe(res => {
        if (res['status'] == 200) {
          if (this.file) {
            const uploadData = new FormData();
            uploadData.append('file', this.file, this.file.name);
            this._kService.uploadSubDocuments(uploadData, res['result'].id, (this.type == "action-item" ? "actionItemId" : "commentId")).subscribe(file => {
              if (file['status'] == 200) {
                this.isProgressVisible = false;
              }
              this.content = ''
              this.mentionList=[]
              this.getTaskComment("save");
            });
          } else {
            this.file = null;
            this.content = ''
            this.mentionList=[]
           this.getTaskComment("save");
          }
        }
      })
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Comments is mandatory"
      });

      // this.toasterService.error("Comments is mandatory");
    }
  }
  }
  addCommentsOnActionItemOrNote(element,id){
    console.log(' addCommentsOnActionItemOrNotemention list',this.mentionList)
    const quillEditor = document.getElementById(id) as HTMLDivElement;
    let quillInstance;
    let tmp = '';
    let content;
    if (quillEditor) {
       quillInstance = quillEditor.querySelector('.ql-editor') as HTMLDivElement;
       tmp=quillInstance.innerHTML
       content=quillInstance.innerHTML
    }
    
    console.log('addCommentsOnActionItemOrNotemention content : ',content)
    
    
    tmp = tmp.replace(/(<([^>]+)>)/ig, '');
    if (content && (content.trim() != "" && tmp.trim() != "")) {
      let obj = {
        "dataType": element.type,
        "laneTaskId": this.laneTaskIDDetail.id,
        "message": content,
        "parentId": element.id,
        "mention":this.mentionList
      }
        let reqFunc = null;
        reqFunc = this._kService.addCommentsOnActionItemOrNote(obj);
      
      reqFunc.subscribe(res => {
        if (res['status'] == 200) {
            this.file = null;
            this.mentionList=[];
           this.getTaskComment("save");
        }
      })
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please provide comment"
      });


      // this.toasterService.error("Please provide comment");
    }
  
  }
  rmeoveSelectedFile() {
    this.file = null;
  }
  fileUpload(event) {
    this.isProgressVisible = true;
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }

  }
  downloadSingleDocument(id) {
    this._kService.downloadSingleUploadDocument(id).subscribe(res => {
      this.downloadSheetCommonMethod(res);

    })
  }
  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    let fileName = resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }
  toggleCommentArea(index) {
    console.log('toggleCommentArea index ',index)
    this.selectedIndex=index;
  }
  closeCommentArea(index){
    console.log('closeCommentArea index ',index)
    this.selectedIndex=null;
  }
  async suggestPeople(searchTerm) {
    return new Promise((resolve, reject) => {
        let allPeople = [];
        let userinfo = JSON.parse(localStorage.getItem("USERINFO"))
        let businessId=userinfo.business_id;
        let obj = {
            searchKey: searchTerm,
            businessId: businessId
        };
        this.settingService.fetchUserListAutosuggest(obj).subscribe(
            (response) => {
                if (response) {
                    let alluser: any = response;
                    for (let i = 0; i < alluser.length; i++) {
                        let user = {id: alluser[i]["id"], value: alluser[i]["name"]};
                        allPeople.push(user);
                    }
                    console.log('allPeople', allPeople);
                    resolve(allPeople);
                }
            },
            (error) => {
                reject(error);
            }
        );
    });
}
}
