import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { KamDealsPopupComponent } from './kam-deals-popup/kam-deals-popup.component';
import { Constants } from 'src/app/Constants';
import { SettingService } from 'src/app/settings/setting.service';
import { KanbanService } from '../../../kanban.service';
import { MatTableDataSource } from '@angular/material';
import { DealsHistoryViewComponent } from '../deals-history-view/deals-history-view.component';
import * as moment from 'moment';
import { LastLoginHistoryComponent } from '../last-login-history/last-login-history.component';
import { DealFunnelModalComponent } from '../deal-funnel-modal/deal-funnel-modal.component';
import { Router } from '@angular/router';
import { LabelConstants } from 'src/app/LabelConstants';
import { MoreActionItemsComponent } from '../hunting-listing/more-action-items/more-action-items.component';
import { MeetingListComponent } from '../hunting-listing/meeting-list/meeting-list.component';
import { DropdownConstants } from 'src/utils/DropdownConstants';

@Component({
  selector: 'app-kam-listing',
  templateUrl: './kam-listing.component.html',
  styleUrls: ['./kam-listing.component.scss']
})
export class KamListingComponent implements OnInit {

  displayedColumns: any[] = ['name', 'login', 'business_vertical', 'region', 'total_deals', 'active_deals', 'worked_on', 'closing_date_exceeded', 'action_date_exceeded', 'dormant_deals', 'won_deals', 'action', 'status'];
  @Input() inputData;
  sobBusinessId = Constants.businessIdForSOB;
  teams: any;
  teamsSearch: any;
  selectTeams = [];
  selectedUsers: any = [];
  teamQuery: any;
  teamQueryForKam: any;
  selectedRole: any[] = [];
  roles: any = [
    {"name":"ASM","id":"vedanta_area_sales_manager"}, 
    {"name":"RM","id":"vedanta_regional_manager"}, 
    {"name":"CMO","id":"vedanta_cmo"}, 
    {"name":"National Head","id":"vedanta_national_head"}];
  isLoading: boolean = false;
  leadListing: any = [];
  dataSource = new MatTableDataSource(this.leadListing);
  totalRecords: any;
  startDate: any;
  endDate: any;
  startUpdatedDate: any;
  endUpdatedDate: any;
  startExceededDate: any;
  endExceededDate: any;
  resetDates: boolean = false;
  selectedStatus: any;
  defaultOpportunityStages = [
    { "name": "S0: Opportunity Identified", "id": Constants.S0 },
    { "name": "S1: Initial Contact made", "id": Constants.S1 },
    { "name": "S2: RFQ/RFP/LPP Data Received", "id": Constants.S2 },
    { "name": "S3: Quote/ Proposal sent", "id": Constants.S3 },
    { "name": "S4: Negotiation ongoing", "id": Constants.S4 },
    { "name": "Cold Deal", "id": Constants.coldDeal },
    { "name": "Deal Won", "id": Constants.dealWon },
    { "name": "Deal Lost", "id": Constants.lostDeal }];
  selectedStages = [];
  localCache: any = localStorage;
  w1recievedQuery: any;
  switchBusiness: any;
  selectedBusinessId: any;
  crmBusinessId = Constants.businessIdForCRM;
  searchControlRegion = '';
  teamsLabel: string = LabelConstants.teamsLabel;

  KAMLabel: string = LabelConstants.KAMLabel;
  designatedASMLabel: string = LabelConstants.DesignatedASMLabel;
  businessVerticalLabel: string = LabelConstants.businessVerticalLabel;
  allUserList: any[] = [];
  allUserListSearch: any[] = [];
  searchControlOwner = '';
  userQuery: any;
  boardId = Constants.boardIdForSobOpportunity;
  selected: any;
  displayedColumns2: any[] = ['name', 'login', 'business_vertical', 'region', 'user_head', 'total_deals', 'primary_deals', 'secondary_deals', 'active_deals', 'worked_on', 'closing_date_exceeded', 'action_date_exceeded', 'won_deals', 'action', 'status']; //,'duration'];
  displayColumns5: any[] = ['name', 'region', 'user_head', 'totalAccount','conducted_meeting', 'mom', 'upcoming_meetings', 'latest_mom'];

  defaultBusinessVertical = DropdownConstants.BusinessFilter;
  defaultBusinessVerticalSearch = DropdownConstants.BusinessFilter;
  searchControlBusinessVertical = '';
  selectedBusinessVertical = [];
  businessVerticalQuery: { query: { nestedWhere: {}; }; };
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForSobAccount,
    "nameField": false
  };
  @ViewChild('cusComComplete') cusComComplete: any;
  selectedTab: number = 1;  // Default to Tab 1, change this to show a different tab

  constructor(private dialog: MatDialog, private router: Router, private _kService: KanbanService, public settingService: SettingService) { }


  ngOnInit() {
    let userinfo = JSON.parse(localStorage.getItem("USERINFO"))
    this.selectedBusinessId=userinfo.business_id;
    // this.switchBusiness = this.settingService._ManagementData.subscribe(res => {
    //   if (res === 'SOB Management') {
    //     this.selectedBusinessId = this.sobBusinessId;
    //   }
    //   else {
    //     this.router.navigate(['/crm-board']);
    //   }
    // });
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 6);
    this.inputData = {
      startDate: startDate,
      endDate: endDate
    };
    this.setDefaultDateRange();
    this.loadTeams();
    this.loadUsers();
    // this.loadKAMList();
    this.loadKamDataVedanta();
  }
  setDefaultDateRange() {
    const startDate = moment().subtract(6, 'days').startOf('day');
    const endDate = moment().endOf('day');

    this.selected = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate()
    };
  }
  ngOnDestroy() {
    console.log('ngOnDestroy on hunter tab');
    this.selected = null;
    // this.switchBusiness.unsubscribe();
  }
  kamDealsPopup(element) {
    //   this.dialog.open(KamDealsPopupComponent, {
    //     disableClose: false,
    //     hasBackdrop: true,
    //     panelClass: "kamDealsWrap",
    // });

    const dialogRef = this.dialog.open(DealsHistoryViewComponent, {
      width: '580px',
      height: '530px',
      panelClass: 'right-side-dialog',
      data: element
    });


  }
  loadTeams() {
    this.settingService.fetchTeams().subscribe(response => {
      this.teams = response['result'];
      this.teamsSearch = this.teams;
    });
  }
  resetTeamFilterInCache() {
    this.teamQuery = null;
    this.teamQueryForKam = null;
  }
  loadKAMList() {
    console.log("in loadKAMList: ");
    const kamQuery = {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: Constants.boardIdForSobOpportunity,
            },
          ],
        },
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
      filter: {},
    };
    this.isLoading = true;
    this._kService.getKAMLaneTaskUsers(kamQuery, [], '').subscribe(res => {
      this.isLoading = false;
      let users = res['result'];
      users.sort(function (a, b) {
        if (a.user.name < b.user.name) {
          return -1;
        }
        if (a.user.name > b.user.name) {
          return 1;
        }
        return 0;
      });
      this.leadListing = users;
      this.dataSource.data = this.leadListing;
      this.totalRecords = res['total'];
    });
  }

  loadKamDataVedanta() {
    console.log("laodkam", this.selectTeams, this.selectedUsers, this.selectedRole, this.startDate, this.endDate);
    
    let userIds = [];
    if (this.selectedUsers) {
      console.log("selectedUsers", this.selectedUsers);
      userIds = this.selectedUsers.map(user => user.id);
    }
    
    let roles = [];
    if (this.selectedRole) {
      roles = this.selectedRole.map(role => role.id);
    }
    
    let regions = [];
    if (this.selectTeams) {
      regions = this.selectTeams.map(team => team.id);
    }
    
    let obj = {
      userIds: userIds.length > 0 ? userIds : undefined, 
      regions: regions.length > 0 ? regions : undefined,
      roles: roles.length > 0 ? roles : undefined,
      filter: {
        start_date: this.startDate || null,
        end_date: this.endDate || null
      }
    };
    

    this.isLoading = true;
    this._kService.getKAMLaneTaskUsersVedanta(obj).subscribe({
      next: (res) => {
        this.isLoading = false;

        const users = res['result'].sort((a, b) => a.user.name.localeCompare(b.user.name));

        this.leadListing = users;
        this.dataSource.data = users;
        this.totalRecords = res['total'];
      },
      error: () => {
        this.isLoading = false;
        console.error('Failed to load KAM data');
      }
    });
  }


  onApplyDates(event) {
    console.log('onApplyDates on kam', event)
    this.startDate = Number(moment(event.start_date).format('x'));
    this.endDate = Number(moment(event.end_date).format('x'));
    this.startUpdatedDate = moment(event.start_date);
    this.endUpdatedDate = moment(event.end_date);
    this.startExceededDate = moment(event.start_date).startOf('day').toISOString();
    this.endExceededDate = moment(event.end_date).startOf('day').toISOString();
    this.resetDates = false;
    // this.onScrollDown(false);
    this.loadKamDataVedanta();
  }
  getAllDealQueryOpportunity() {
    const opportunityConstants = [
      Constants.S0,
      Constants.S1,
      Constants.S2,
      Constants.S3,
      Constants.S4,
      Constants.dealWon,
      Constants.coldDeal,
      Constants.lostDeal
    ];

    return opportunityConstants.map(value => ({
      "field": "laneId.raw",
      "value": value
    }));
  }
  onApply = () => {
    this.loadKamDataVedanta();
    // this.onScrollDown(false);
  }
  onScrollDown(reset) {
    this.isLoading = true;
    console.log('onScrollDown team', this.teamQueryForKam)
    console.log('onScrollDown role value', this.selectedRole)
    let query = {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: Constants.boardIdForSobOpportunity,
            },
          ],
        },
        laneIdQuery: {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "laneId.raw",
              "value": this.getAllDealQueryOpportunity()
            }
          ]
        },
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
      filter: {},
    };
    if (this.teamQueryForKam) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      query['nestedWhere']["team_query"] = this.teamQueryForKam["team_query"];
      query['regions'] = this.selectTeams.map(team => team.id);
    }
    if (this.userQuery) {
      query['where']["user_query"] = this.userQuery['query']['where']["user_query"];
      query['userIds'] = this.selectedUsers.map(user => user.id);
    }
    if (this.businessVerticalQuery) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      console.log("businessVerticalQuery", this.businessVerticalQuery);
      query['nestedWhere']["bs_query"] = this.businessVerticalQuery['query']['nestedWhere']["bs_query"];
      query['businessVerticals'] = this.selectedBusinessVertical;
    }
    if (this.accountQuery) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      query['nestedWhere']["account_query"] = this.accountQuery['query']['nestedWhere']["account_query"];
      query['accounts'] = this.customSelectWithMultipleAccounts;
    }
    if (this.selectedStages.length > 0) {
      console.log("this.selectedStages", this.selectedStages);
      let laneQuery = [];
      for (let k = 0; k < this.selectedStages.length; k++) {
        laneQuery.push({
          "field": "laneId.raw",
          "value": this.selectedStages[k]
        });
      }
      query['where']['laneIdQuery'] = {
        "type": "search",
        "operator": "or",
        "value": laneQuery
      }
    }
    else {
      delete query['where']['laneIdQuery'];
    }
    query['filter'] = {
      "start_date": this.startDate,
      "end_date": this.endDate
    }
    this._kService.getKAMLaneTaskUsers(query, this.selectedRole, this.selectedStatus).subscribe(res => {
      this.isLoading = false;
      let users = res['result'];
      users.sort(function (a, b) {
        if (a.user.name < b.user.name) {
          return -1;
        }
        if (a.user.name > b.user.name) {
          return 1;
        }
        return 0;
      });
      this.leadListing = users;
      this.dataSource.data = this.leadListing;
      this.totalRecords = res['total'];
    });
  }
  resetStageFilterFromCache() {
    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["selectedStages"] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }
  }
  filterOptionsTeams(event) {
    this.teamsSearch = this.teams;
    const filterValue = event.toLowerCase();
    this.teamsSearch = this.teams.filter(option => (option.name).toLowerCase().includes(filterValue));
    console.log('filterOptionsTeams', this.teamsSearch)
  }
  clearAll() {
    this.resetDates = true;
    this.startDate = null;
    this.endDate = null;
    this.teamQuery = null;
    this.teamQueryForKam = null;
    this.customSelectWithMultipleAccounts = [];
    if (this.cusComComplete) {
      this.cusComComplete.clearSelections();
    }
    this.selectedBusinessVertical = [];
    this.businessVerticalQuery = null;
    this.searchControlBusinessVertical = '';
    this.selectTeams = [];
    this.selectedRole = [];
    this.startUpdatedDate = null;
    this.endUpdatedDate = null;
    this.startExceededDate = null;
    this.endExceededDate = null;
    this.selectedStatus = null;
    this.selectedStages = [];
    this.selectedUsers = [];
    this.searchControlOwner = '';
    this.searchControlRegion = '';
    this.userQuery = null;
    this.accountQuery = null;
    // this.onScrollDown(false);
    this.loadKamDataVedanta();
  }
  lastLoginDialog(element: any) {
    this.settingService.setUserId(element);
    const dialogRef = this.dialog.open(LastLoginHistoryComponent, {
      width: '308px',
      panelClass: 'right-side-dialog-small',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selected = null;
      console.log(result);
    });
  }
  openDealDialog(element, column) {
    let count: any = 0;
    let sum: any = 0;
    this.inputData['lane'] = column
    this.inputData['label'] = column
    this.inputData['count'] = count
    this.inputData['sum'] = sum
    this.inputData['userQuery'] = null;
    this.inputData['primaryUserQuery'] = null;
    this.inputData['secondaryUserQuery'] = null;
    this.inputData['lastUpdatedDateQuery'] = null;
    this.inputData['teamQueryForFunner'] = null;
    this.inputData['teamFilter'] = null;
    this.inputData['closingDateQuery'] = null;
    this.inputData['nextActionDateQuery'] = null;
    this.inputData['dormantQuery'] = null;
    if (this.selectTeams && this.selectTeams.length) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["team_query"] = {};
      query['query']['nestedWhere']["team_query"]["type"] = "search";
      query['query']['nestedWhere']["team_query"]["operator"] = "or";
      let tQuery = [];
      for (let k = 0; k < this.selectTeams.length; k++) {
        tQuery.push({
          "field": "data.regionteam.raw",
          "value": this.selectTeams[k].id
        })
      }
      query['query']['nestedWhere']["team_query"]["value"] = tQuery;
      this.teamQuery = query;
      this.inputData['teamQuery'] = this.teamQuery;
      this.inputData['teamFilter'] = this.selectTeams;
    }
    if (column === 'totaldeal') {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["user_query"] = {};
      query['query']['where']["user_query"]["type"] = "search";
      query['query']['where']["user_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["user_query"]["value"] = tQuery;
      this.inputData['userQuery'] = query;
      this.inputData['label'] = "Total Deal";
    }
    else if (column === 'primary') {
      this.inputData['primaryUserQuery'] = {
        query: {
          where: {
            primary_query: {
              type: "search",
              operator: "or",
              value: element.user.id
            }
          }
        }
      };
    } else if (column === 'secondary') {
      let query = {
        "query": {
          "where": {},
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["secondary_query"] = {};
      query['query']['nestedWhere']["secondary_query"]["type"] = "search";
      query['query']['nestedWhere']["secondary_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "data.secondaryowner.raw",
        "value": element.user.id
      });
      query['query']['nestedWhere']["secondary_query"]["value"] = tQuery;
      this.inputData['secondaryUserQuery'] = query;
    } else if (column === 'activedeal') {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;
      this.inputData['sum'] = element.activeDealSum;
      this.inputData['label'] = "Active Deal";

    } else if (column === 'modifieddeal') {

      let query = {
        "query": {
          "where": {}
        }
      };

      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";
      this.setUpdatedDate();
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;
      this.inputData['sum'] = element.updatedCount;
      this.inputData['label'] = "Modified Deal";

    } else if (column === 'closingDeal') {
      let query = {
        "query": {
          "where": {},
          "nestedWhere": {}
        }
      };

      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";

      query["data.closingdate"] = {};
      query["data.closingdate"]["type"] = "range";
      query["data.closingdate"]["field"] = "data.closingdate";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;

      if (this.startExceededDate) {
        query["data.closingdate"]["start_value"] = moment(this.startExceededDate).startOf('day').toISOString();
      } else {
        query["data.closingdate"]["start_value"] = moment().subtract(6, 'days').startOf('day').toISOString();
        // query["data.closingdate"]["start_value"] = moment('2015-01-01').startOf('day').toISOString(); // enable in case you want data from starting of moglix year 
      }
      if (this.endExceededDate) {
        query["data.closingdate"]["end_value"] = moment(this.endExceededDate).startOf('day').subtract(10, 'seconds').toISOString();
      } else {
        query["data.closingdate"]["end_value"] = moment().startOf('day').subtract(10, 'seconds').toISOString();
      }
      this.inputData['closingDateQuery'] = query;
      this.inputData['sum'] = element.closingDateExceeded;
      this.inputData['label'] = "Closing Deal";

    } else if (column === 'nextActionDateDeal') {
      let query = {
        "query": {
          "where": {},
          "nestedWhere": {}
        }
      };

      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";

      query["data.nextactiondate"] = {};
      query["data.nextactiondate"]["type"] = "range";
      query["data.nextactiondate"]["field"] = "data.nextactiondate";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;

      if (this.startExceededDate) {
        query["data.nextactiondate"]["start_value"] = moment(this.startExceededDate).startOf('day').toISOString();
      } else {
        query["data.nextactiondate"]["start_value"] = moment().subtract(6, 'days').startOf('day').toISOString();
      }
      if (this.endExceededDate) {
        query["data.nextactiondate"]["end_value"] = moment(this.endExceededDate).startOf('day').subtract(10, 'seconds').toISOString();
      } else {
        query["data.nextactiondate"]["end_value"] = moment().startOf('day').subtract(10, 'seconds').toISOString();
      }
      this.inputData['nextActionDateQuery'] = query;
      this.inputData['sum'] = element.nextActionDateExceeded;
      this.inputData['label'] = "Next Action Deal";

    } else if (column === 'wondeal') {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;
      this.inputData['sum'] = element.wonDealSum;
      this.inputData['label'] = "Won Deal";

    } else if (column === 'Dormant') {
      this.inputData['dormantQuery'] = {
        updatedAt: {
          field: 'updatedAt',
          type: 'range',
          start_value: '2020-04-22T18:30:00.000Z',
          end_value: `${moment().subtract(21, 'days').toISOString()}`
        }
      };
      this.inputData['primaryUserQuery'] = {
        query: {
          where: {
            primary_query: {
              type: "search",
              operator: "or",
              value: [{
                field: "assigneeId.raw",
                value: element.user.id
              }]
            }
          }
        }
      };

    }
    this.inputData.boardId = Constants.boardIdForSobOpportunity;
    const dialog = this.dialog.open(DealFunnelModalComponent, {
      width: '650px',
      height: '530px',
      panelClass: 'right-side-dialog',
      data: this.inputData
    });
  }
  setUpdatedDate() {
    let query1 = {
      "query": {
        "where": {}
      }
    };
    query1["updatedAt"] = {};
    query1["updatedAt"]["type"] = "range";
    query1["updatedAt"]["field"] = "updatedAt";
    if (this.startUpdatedDate) {
      query1["updatedAt"]["start_value"] = this.startUpdatedDate;
    } else {
      query1["updatedAt"]["start_value"] = moment().subtract(6, 'days').toISOString();
    }
    if (this.endUpdatedDate) {
      query1["updatedAt"]["end_value"] = this.endUpdatedDate;
    } else {
      query1["updatedAt"]["end_value"] = moment().toISOString();
    }
    this.inputData['lastUpdatedDateQuery'] = query1;
  }
  sortLaneTasks(sort) {
    const data = this.leadListing.slice();
    if (!sort.active || sort.direction === '') {
      this.leadListing = data;
      return;
    }

    this.leadListing = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'login': return this.compare(a.lastLoginCount, b.lastLoginCount, isAsc);
        case 'deal_size': return this.compare(a.totalSum, b.totalSum, isAsc);
        case 'total_deals': return this.compare(a.totalCount, b.totalCount, isAsc);
        case 'worked_on': return this.compare(a.updatedCount, b.updatedCount, isAsc);
        case 'primary_deals': return this.compare(a.primaryDeal, b.primaryDeal, isAsc);
        case 'secondary_deals': return this.compare(a.secondaryDeal, b.secondaryDeal, isAsc);
        case 'active_deals': return this.compare(a.activeDeal, b.activeDeal, isAsc);
        case 'closing_date_exceeded': return this.compare(a.closingDateExceeded, b.closingDateExceeded, isAsc);
        case 'action_date_exceeded': return this.compare(a.nextActionDateExceeded, b.nextActionDateExceeded, isAsc);
        case 'won_deals': return this.compare(a.wonDeal, b.wonDeal, isAsc);
        default: return 0;
      }
    });
    this.dataSource.data = this.leadListing;
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  onRegionSelectionChange(selection: any) {
    this.selectTeams = selection;
    this.searchControlRegion = '';
    this.onApply();
  }
  loadUsers() {
    const obj = {
      from: 0,
      size: 100,
      businessId: this.selectedBusinessId
    };

    this.settingService.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      console.log("list", list);

      const userList = list['result'].map(user => {
        const teamList = user.userTeams ? user.userTeams.map(team => team.id) : [];
        return { ...user, userTeams: teamList };
      });

      userList.sort((a, b) => a.name.localeCompare(b.name));

      this.allUserList = userList;
      this.allUserListSearch = this.allUserList;
    });
  }
  onKAMSelectionChange(selection: any) {
    this.selectedUsers = selection;
    this.onApply();
  }
  filterOptions(event) {
    this.allUserListSearch = this.allUserList;
    const filterValue = event.toLowerCase();
    this.allUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  moreActionItemsDialog(element) {
    element.filters = {};
    element.momListDialog = true;
    const hunterIds = this.selectedUsers.map(user => user.id);
    if (hunterIds.length > 0) {
      element.filters.hunterIds = hunterIds;
    }
    if (this.startDate) {
      element.filters.startDate = this.startDate;
    } else {
      const lastSevenDays = new Date();
      lastSevenDays.setHours(0, 0, 0, 0); // Set time to 00:00:00
      lastSevenDays.setDate(lastSevenDays.getDate() - 6); // Subtract 7 days
      element.filters.startDate = lastSevenDays.getTime();
    }
    if (this.endDate) {
      element.filters.endDate = this.endDate;
    } else {
      element.filters.endDate = Date.now();  // Or new Date().getTime()
    }
    const regions = this.selectTeams.map(team => team.id);
    if (regions.length > 0) {
      element.filters.regions = regions;
    }
    // const bv = this.selectedBusinessVertical;
    // if (bv.length > 0) {
    //   element.filters.businessVerticals = bv;
    // }
    const dialogRef = this.dialog.open(MoreActionItemsComponent, {
      width: '580px',
      height: '530px',
      panelClass: ['right-side-dialog', 'more-acion-list-modal'],
      data: element
    });
  }

  moreMOMDialog(element) {
    element.filters = {};
    element.momListDialog = true;
    const hunterIds = this.selectedUsers.map(user => user.id);
    if (hunterIds.length > 0) {
      element.filters.hunterIds = hunterIds;
    }
    if (this.startDate) {
      element.filters.startDate = this.startDate;
    } else {
      const lastSevenDays = new Date();
      lastSevenDays.setHours(0, 0, 0, 0); // Set time to 00:00:00
      lastSevenDays.setDate(lastSevenDays.getDate() - 6); // Subtract 7 days
      element.filters.startDate = lastSevenDays.getTime();
    }
    if (this.endDate) {
      element.filters.endDate = this.endDate;
    } else {
      element.filters.endDate = Date.now();  // Or new Date().getTime()
    }
    const regions = this.selectTeams.map(team => team.id);
    if (regions.length > 0) {
      element.filters.regions = regions;
    }
    // const bv = this.selectedBusinessVertical;
    // if (bv.length > 0) {
    //   element.filters.businessVerticals = bv;
    // }
    const dialogRef = this.dialog.open(MoreActionItemsComponent, {
      width: '580px',
      height: '530px',
      panelClass: ['right-side-dialog', 'more-acion-list-modal'],
      data: element
    });
  }

  openDialog(element) {

    const dialogRef = this.dialog.open(DealsHistoryViewComponent, {

      width: '580px',

      height: '530px',

      panelClass: 'right-side-dialog',

      data: element
    });
  }

  openMeetingDialog(element: any, dialogType: 'momList' | 'upcomingList' | 'conductedMeeting') {
    element.momListDialog = false;
    element.upcomingListDialog = false;
    element.conductedMeetingDialog = false;
    element.filters = {};

    const hunterIds = this.selectedUsers.map(user => user.id);
    if (hunterIds.length > 0) {
      element.filters.hunterIds = hunterIds;
    }

    if (this.startDate) {
      element.filters.startDate = this.startDate;
    } else {
      const lastSevenDays = new Date();
      lastSevenDays.setHours(0, 0, 0, 0); // Set time to 00:00:00
      lastSevenDays.setDate(lastSevenDays.getDate() - 6); // Subtract 7 days
      element.filters.startDate = lastSevenDays.getTime();
    }

    if (this.endDate) {
      element.filters.endDate = this.endDate;
    } else {
      element.filters.endDate = Date.now();  // Or new Date().getTime()
    }

    const regions = this.selectTeams.map(team => team.id);
    if (regions.length > 0) {
      element.filters.regions = regions;
    }

    // const bv = this.selectedBusinessVertical;
    // if (bv.length > 0) {
    //   element.filters.businessVerticals = bv;
    // }


    switch (dialogType) {
      case 'momList':
        element.momListDialog = true;
        break;
      case 'upcomingList':
        element.upcomingListDialog = true;
        break;
      case 'conductedMeeting':
        element.conductedMeetingDialog = true;
        break;
    }

    const dialogRef = this.dialog.open(MeetingListComponent, {
      width: '580px',
      height: '530px',
      panelClass: 'right-side-dialog',
      data: element
    });
  }
  openUserAccount(element : any){
    console.log('openUserAccount');
  }
  stringToList(text) {
    if (!text) {
      return [];  // Return empty list for empty string
    }
    return text.trim().split(","); // Remove leading/trailing whitespace and split by comma
  }

  onBUSelectionChange(selection: string[]) {
    console.log('onBuselectionChange', selection)
    this.selectedBusinessVertical = selection;
    this.onApply();
  }
  filterOptionsVertical(event) {
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical.filter(option => (option).toLowerCase().includes(event.toLowerCase()));
  }
  accountQuery: any;
  customSelectWithMultipleAccounts: any[] = [];

  handleApplySelections(selectedValues: any, field: string = 'accountname.raw'): void {
    console.log('Selected values:', selectedValues);
    this.customSelectWithMultipleAccounts = selectedValues.map(item => item.id);
    this.onMultipleSelectFilter(this.customSelectWithMultipleAccounts, field);
  }

  onMultipleSelectFilter(ids: string[], field: string): void {
    console.log('onMultipleSelectFilter in chart filter ids', ids)
    if (!ids || ids.length === 0) {
      this.accountQuery = null;
    } else {
      const accountQueries = ids.map(value => {
        return {
          field: `data.${field}`,
          value: value
        };
      });
      let query = {
        "query": {
          nestedWhere: {
            account_query: {
              type: 'search',
              operator: 'or',
              value: accountQueries
            }
          },
        }
      };
      this.accountQuery = query;
    }
    console.log('onMultipleSelectFilter in chart filter acc query', this.accountQuery);
    this.onApply();
  }

}

export interface Transaction {
  sheetId: any;
  customerName: any;
  totalEffectiveCl: any;
  timeStamp: any;
  createdBy: any;
  status: any;
  dealsModified: any;
  closingDate: any;
  actionDate: any;
  dormantDeals: any;
  action: any;
}



