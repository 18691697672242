import { Component, Inject, Input, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDatepicker, MatDialogRef, MatSnackBar } from '@angular/material';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';
import { SettingService } from 'src/app/settings/setting.service';
import { Constants } from 'src/app/Constants';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { InfoToastComponent } from 'src/app/shared-files/info-toast/info-toast.component';
import { tap } from 'rxjs/operators';
import { MY_FORMATS } from 'src/app/component/credlix-board/sop-management/sop-management.component';
import { saveAs } from 'file-saver';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class MeetingListComponent implements OnInit {
  dateWiseMap: any = new Map();
  meetingDetail: any;
  @Input() inputData;
  meetingContacts: any;
  currentTime: Date = new Date();
  selectedMeeting: any;
  selectedPlatform: string;
  selectedPlantsForMeeting: any[];
  plantOptions: any;
  selectedAccountsForMOM: any[];
  boardIdForSobOpportunity = Constants.boardIdForSobOpportunity;
  sobUserId: string;
  huntingUserId: string;
  srmUserId: string;
  selectedBusinessId: string;
  switchBusiness: any;
  sobBusinessId: string = Constants.businessIdForSOB;
  crmBusinessId: string = Constants.businessIdForCRM;
  srmBusinessID: string = Constants.businessIdForSRM;
  isLoading: boolean;
  opportunityList: any;
  selectedOpportunity: any;
  selectedSupplier: any;
  supplierOptions: any;
  plants: any;
  plantOptionsSelected: any;
  plantOptionsAllIds: any;
  accountIdsList: any;
  accountOptions: any;
  accountOptionsSelected: any;
  supplierIdsList: any;
  supplierList: any;
  fetchedMOMPlants: any[];
  fetchedMOMAccounts: any[];
  fetchedMOMPlantsString: string;
  fetchedMOMContent: string;
  fetchedMOMAttachement: any[];
  addMomDisplayText: string;
  fetchedMOMCreationdate: any;
  fetchedMOMNextActiondate: any;
  fetchedMOMValue: string;
  isRefresh = false;
  selected: { startDate: Date; endDate: Date; };
  startDate: any;
  endDate: any;
  startUpdatedDate: any;
  endUpdatedDate: any;
  startExceededDate: any;
  endExceededDate: any;

  constructor(private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<MeetingListComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public _kService: KanbanService, public settingService: SettingService) {
    if (data) {
      this.data = data;
      console.log(this.data);
    }
  }

  ngOnInit() {
    this.sobUserId = localStorage.getItem('sobUserId');
    this.huntingUserId = localStorage.getItem('crmUserId');
    this.srmUserId = localStorage.getItem('srmUserId');
    this.selectedBusinessId = localStorage.getItem('selectedBusinessId');

    this.switchBusiness = this.settingService._ManagementData.subscribe(res => {
      this.handleBusinessSelection(res);
    });

    this.setDefaultDateRange();
    const filters = this.data.filters;

    // Convert the timestamps to moment objects
    const startDate = moment(filters.startDate);
    const endDate = moment(filters.endDate);

    // Set the selected dates
    this.selected = {
      startDate: startDate.startOf('day').toDate(),
      endDate: endDate.endOf('day').toDate()
    };

    this.inputData = {
      startDate: startDate,
      endDate: endDate
    };

    let obj = {
      'userIds': this.data.user ? [this.data.user.id] : [],
      'userEmail': this.data.userEmail ? [this.data.user.email] : [],
      'from': 0,
      'size': 50,
      'businessVerticals': filters.businessVerticals ? filters.businessVerticals : [],
      'regions': filters.regions ? filters.regions : [],
      'startDate': filters.startDate,
      'endDate': filters.endDate,
      'accounts': filters.accounts
    };

    if (this.data.conductedMeetingDialog) {
      obj['pastMeeting'] = true;
      obj['futureMeeting'] = false;
      this.getCalendarByUser(obj);
    }
    else if (this.data.upcomingListDialog) {
      obj['pastMeeting'] = false;
      obj['futureMeeting'] = true;
      this.getCalendarByUser(obj);
    } else if (this.data.attendeeMeetingDialog) {
      obj['pastMeeting'] = true;
      obj['futureMeeting'] = false;
      obj['asAttendee'] = true;
      this.getCalendarByUser(obj);
    } else if (this.data.allMeetingDialog) {
      obj['pastMeeting'] = true;
      obj['supplierIds'] = [this.data.id];
      this.getCalendarByUser(obj);
    } else if (this.data.momListDialog) {
      this.getMOMByUser(obj);
    } else if (this.data.meetingsByField) {
      let obj = {
        'start': { "time": filters.startDate },
        'end': { "time": filters.endDate }
      };

      if (this.data.board === Constants.boardIdForVedantaAccount) {
        obj['accountList'] = [this.data.id];
      }
      else if (this.data.board === Constants.boardIdForSobPlant) {
        obj['plantIdList'] = [this.data.id];
      }

      this.getCalendarByfield(obj);
    }
    else if (this.data.meetingsByFieldWithMOM) {
      let obj = {
        'start': { "time": filters.startDate },
        'end': { "time": filters.endDate },
        'associatedContent':"MOM"
      };

      if (this.data.board === Constants.boardIdForVedantaAccount) {
        obj['accountList'] = [this.data.id];
      }
      else if (this.data.board === Constants.boardIdForSobPlant) {
        obj['plantIdList'] = [this.data.id];
      }

      this.getCalendarByfield(obj);
    }
    else {
      this.getMOMByUser(obj);
    }
  }

  setDefaultDateRange() {
    const startDate = moment().subtract(6, 'days').startOf('day');
    const endDate = moment().endOf('day');

    this.selected = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate()
    };
  }

  handleBusinessSelection(res) {
    const selectedBusiness = localStorage.getItem('selected_business');

    if (res && res.length > 0) {
      this.updateSelectedBusiness(res, selectedBusiness);
    } else {
      this.clearBusinessSelection();
    }

    this.setBusinessDetails(res);
  }

  updateSelectedBusiness(res, selectedBusiness) {
    if (selectedBusiness != null && selectedBusiness != res) {
      this.clearLaneSelection();
    } else if (selectedBusiness == null) {
      this.clearLaneSelection();
    }
    localStorage.setItem('selected_business', res);
  }

  clearBusinessSelection() {
    this.clearLaneSelection();
    localStorage.removeItem('selected_business');
  }

  clearLaneSelection() {
    localStorage.removeItem('lane_board');
    localStorage.removeItem('selected_lane');
  }
  setBusinessDetails(res) {
    let businessId, businessName;

    switch (res) {
      case 'SOB Management':
        businessId = this.sobBusinessId;
        businessName = 'SOB Management';
        break;
      case 'CRM Management':
        businessId = this.crmBusinessId;
        businessName = 'CRM Management';
        break;
      case 'SRM Management':
        businessId = this.srmBusinessID;
        businessName = 'SRM Management';
        break;
      default:
        return;
    }

    this.selectedBusinessId = businessId;
    localStorage.setItem('selectedBusinessId', businessId);
    localStorage.setItem('selected_business', businessName);
  }

  date = new FormControl(moment());
  startDateSelected: any = null;
  endDateSelected: any = null;
  selectedTab = '';

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    console.log("date", this.date);
  }
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const selectedDate = this.date.value.clone();
    selectedDate.month(normalizedMonth.month());

    const startDate = selectedDate.clone().startOf('month').valueOf();
    this.startDateSelected = startDate;

    const endDate = selectedDate.clone().endOf('month').valueOf();
    this.endDateSelected = endDate;

    this.date.setValue(selectedDate);
    datepicker.close();
    const filters = this.data.filters;
    let obj = {
      'userIds': [this.data.user.id],
      'userEmail': [this.data.user.email],
      'from': 0,
      'size': 10,
      'businessVerticals': filters.businessVerticals,
      'regions': filters.regions,
      'startDate': this.startDateSelected,
      'endDate': this.endDateSelected
    };

    if (this.data.conductedMeetingDialog) {
      obj['pastMeeting'] = true;
      obj['futureMeeting'] = false;
      this.getCalendarByUser(obj);
    }
    else if (this.data.upcomingListDialog) {
      obj['pastMeeting'] = false;
      obj['futureMeeting'] = true;
      this.getCalendarByUser(obj);
    }
    else {
      this.getMOMByUser(obj);
    }

  }


  getCalendarByUser(obj) {
    this.settingService.getCalendarByUser(obj).subscribe((res) => {
      console.log("getMeetingsFromCalendar() ", res);
      this.dateWiseMap = new Map(Object.entries(res["result"]));
      this.meetingDetail = this.dateWiseMap.size > 0 ? this.dateWiseMap : null;
      this.meetingContacts = this.dateWiseMap.size > 0 ? this.dateWiseMap : [];
      this.isRefresh = true;

      const reversedEntries = Array.from(this.dateWiseMap.entries()).reverse();
      let isNavigationClicked = false;
      reversedEntries.forEach(([key, value]: [any, any]) => {
        if (!isNavigationClicked) {
          const keyDate = new Date(key);
          console.log("keyDate: ", keyDate)
          if (keyDate.getDate() > this.currentTime.getDate() - 1) {
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              const meetingStartTime = new Date(element.start.dateTime);
              meetingStartTime.setHours(meetingStartTime.getHours() + 5); // Subtract 5 hours
              meetingStartTime.setMinutes(meetingStartTime.getMinutes() + 30); // Subtract 30 minutes
              if (meetingStartTime > this.currentTime) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
              else if (keyDate.getDate() > this.currentTime.getDate()) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
            }
          }
        }
      });
    });
  }

  getCalendarByfield(obj) {
    this.settingService.getCalendarByFieldFilter(obj).subscribe((res) => {
      this.dateWiseMap = new Map(Object.entries(res["result"]));
      this.meetingDetail = this.dateWiseMap.size > 0 ? this.dateWiseMap : null;
      this.meetingContacts = this.dateWiseMap.size > 0 ? this.dateWiseMap : [];
      this.isRefresh = true;

      const reversedEntries = Array.from(this.dateWiseMap.entries()).reverse();
      let isNavigationClicked = false;
      reversedEntries.forEach(([key, value]: [any, any]) => {
        if (!isNavigationClicked) {
          const keyDate = new Date(key);
          console.log("keyDate: ", keyDate)
          if (keyDate.getDate() > this.currentTime.getDate() - 1) {
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              const meetingStartTime = new Date(element.start.dateTime);
              meetingStartTime.setHours(meetingStartTime.getHours() + 5); // Subtract 5 hours
              meetingStartTime.setMinutes(meetingStartTime.getMinutes() + 30); // Subtract 30 minutes
              if (meetingStartTime > this.currentTime) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
              else if (keyDate.getDate() > this.currentTime.getDate()) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
            }
          }
        }
      });
    })
  }

  getMOMByUser(obj) {
    this.settingService.getMOMByUser(obj).subscribe((res) => {
      console.log("getMOMByUser() ", res);
      this.dateWiseMap = new Map(Object.entries(res["result"]));
      this.meetingDetail = this.dateWiseMap.size > 0 ? this.dateWiseMap : null;
      this.meetingContacts = this.dateWiseMap.size > 0 ? this.dateWiseMap : [];
      this.isRefresh = true;

      const reversedEntries = Array.from(this.dateWiseMap.entries()).reverse();
      let isNavigationClicked = false;
      reversedEntries.forEach(([key, value]: [any, any]) => {
        if (!isNavigationClicked) {
          const keyDate = new Date(key);
          console.log("keyDate: ", keyDate)
          if (keyDate.getDate() > this.currentTime.getDate() - 1) {
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              const meetingStartTime = new Date(element.start.dateTime);
              meetingStartTime.setHours(meetingStartTime.getHours() + 5); // Subtract 5 hours
              meetingStartTime.setMinutes(meetingStartTime.getMinutes() + 30); // Subtract 30 minutes
              if (meetingStartTime > this.currentTime) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
              else if (keyDate.getDate() > this.currentTime.getDate()) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
            }
          }
        }
      });
    });
  }


  onClickNavigation(selectedSection: any) {
    this.isRefresh = false;
    selectedSection.isSelected = true;
    this.dateWiseMap.forEach((value: any, key: any) => {
      value.forEach((l: any) => {
        if (l.id != selectedSection.id) {
          l.isSelected = false;
        }
      })
    });
    this.selectedMeeting = selectedSection;
    this.loadMeetingDetail(selectedSection);
    this.loadMeetingContacts(selectedSection);
    this.page = this.defaultPage;
    this.pageSize = this.defaultPageSize;
    this.fetchMom();
  }
  loadMeetingContacts(selectedSection) {
    this.meetingContacts = [];
    let attendees = selectedSection.attendees;
    let organizer = selectedSection.organizer.emailAddress.address;
    attendees.forEach((c: any) => {
      let contact: any = {};
      contact.name = c.emailAddress.name;
      contact.email = c.emailAddress.address;
      if (organizer === c.emailAddress.address) { contact.organiser = true; }
      this.meetingContacts.push(contact);
    });
  }

  defaultPage = 0; // Number of records per page Do not change
  defaultPageSize = 10; // Number of records per page Do not change

  page = this.defaultPage;
  pageSize = this.defaultPageSize;
  momList: any = [];

  fetchMom() {
    this.fetchedMOMPlants = [];
    this.fetchedMOMAccounts = [];
    this.fetchedMOMPlantsString = "";
    this.fetchedMOMContent = "";
    this.fetchedMOMAttachement = [];
    this.addMomDisplayText = "ADD MOM";

    let obj = {
      meetingId: this.selectedMeeting.id,
      from: this.page,
      size: this.pageSize,
    }

    this._kService.fetchAllMOM(obj).subscribe(res => {
      let response = res['result'];
      if (response) {
        this.addMomDisplayText = "UPDATE MOM";
        this.fetchedMOMContent = response.message;
        this.fetchedMOMCreationdate = response.createdAt;
        this.fetchedMOMNextActiondate = response.nextActionDate;
        this.fetchedMOMValue = (response.valueDelivery) ? "YES" : "NO";
        this.fetchPlatformData(response, this.selectedMeeting.selectedPlatform);
        this.fetchedMOMAttachement = response.attachments;

        this.momList = response;
        this.page++;

      }
    });
  }
  fetchPlatformData(response: any, selectedPlatform: string): void {
    const platformMappings = {
      'SOB': { key: 'plants', targetArray: 'fetchedMOMPlants', targetString: 'fetchedMOMPlantsString' },
      'Hunting': { key: 'accounts', targetArray: 'fetchedMOMAccounts', targetString: 'fetchedMOMAccountsString' },
      'SRM': { key: 'suppliers', targetArray: 'fetchedMOMSuppliers', targetString: 'fetchedMOMSuppliersString' },
    };

    const platformData = platformMappings[selectedPlatform];
    if (platformData && response[platformData.key] && response[platformData.key].length > 0) {
      this[platformData.targetArray] = response[platformData.key].map((val: any) => val.name);
      this[platformData.targetString] = this[platformData.targetArray].join(', ');
    }
  }

  getNames(list: any[]): string {
    return list.map((ele: any) => ele.name).join(', ');
  }
  getEmailFromUserDTOEmail(list: any[]): string {
    if (list && list.length > 0)
      return list.map((ele: any) => ele.email).join(', ');
  }

  loadMeetingDetail(selectedSection) {
    console.log("asassasasa")
    this.meetingDetail = {};
    this.meetingDetail.subject = selectedSection.subject;
    this.meetingDetail.starttime = selectedSection.start.time;
    this.meetingDetail.endtime = selectedSection.end.time;
    this.meetingDetail.body = selectedSection.body.content;
    this.meetingDetail.meetingCategory = selectedSection.meetingCategory;
    this.meetingDetail.accounts = selectedSection.accounts;
    this.meetingDetail.plants = selectedSection.plants;

    if (selectedSection.selectedPlatform === 'SOB') {
      this.selectedPlatform = 'SOB';
      this.selectedPlantsForMeeting = [];
      let plantIdList = selectedSection.plantIdList;

      this.loadPlants().subscribe(() => {
        if (plantIdList != null && plantIdList.length > 0) {
          this.selectedPlantsForMeeting = this.plantOptions.filter(element => plantIdList.includes(element.id));
          this.loadOpportunitysFromPlants();
        }
      });
    }
    else if (selectedSection.selectedPlatform === 'Hunting') {
      this.selectedPlatform = 'Hunting';
      this.selectedAccountsForMOM = [];
      let accountList = selectedSection.accountList;

      this.loadAccounts().subscribe(() => {
        if (accountList && accountList.length > 0) {
          this.selectedAccountsForListing(accountList);
        }
      });
    }
    else if (selectedSection.selectedPlatform === 'SRM') {
      this.selectedPlatform = 'SRM';
      this.selectedSupplier = [];
      let supplierList = selectedSection.supplierList;

      this.loadSupplier().subscribe(() => {
        if (supplierList && supplierList.length > 0) {
          this.selectedSupplier = this.supplierOptions.filter(element => supplierList.includes(element.id));
        }
      });
    }

  }
  loadOpportunitysFromPlants() {
    if (this.selectedPlantsForMeeting != null && this.selectedPlantsForMeeting.length > 0) {
      const plantsIdList = this.selectedPlantsForMeeting.map((plant) => plant.id);

      const query = {
        where: {
          boardId: {
            type: 'search',
            operator: 'or',
            value: [{ field: 'boardId.raw', value: this.boardIdForSobOpportunity }],
          },
        },
        nestedWhere: {
          plant_query: {
            type: 'search',
            operator: 'or',
            value: plantsIdList.map((id) => ({ field: 'data.plantname.raw', value: id })),
          },
        },
        sort: { field: 'updatedAt', order: 'desc' },
        from: 0,
        size: 20,
        userId: this.sobUserId
      };
      this.isLoading = true;
      this._kService.getLaneTaskByUserId(query).subscribe(task => {
        this.isLoading = false;
        if (task['result'].length > 0) {
          this.opportunityList = task['result'];
          let opp = this.selectedMeeting.opportunityList;
          if (opp != null && opp.length > 0) {
            this.selectedOpportunity = this.opportunityList.filter((e) => opp.includes(e.id));
          }
        } else {
          this.opportunityList = null;
          setTimeout(() => {
            this._snackBar.openFromComponent(InfoToastComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['atom-toast', 'information'],
              duration: 3500,
              data: "No opportunities for selected plants"
            });
          }, 0);
        }
      });
    }
  }

  loadPlants() {
    this.isLoading = true;
    return this.settingService.fetchPlantByUserId(this.sobUserId).pipe(
      tap(response => {
        this.isLoading = false;
        this.plants = response['result'];
        this.plantOptions = this.plants;
        this.plantOptions.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        this.plantOptionsSelected = this.plantOptions.slice(0, 100);
        this.plantOptionsAllIds = this.plantOptions.map(element => element.id);
      })
    );
  }

  loadAccounts() {
    this.isLoading = true;
    return this.settingService.fetchAccountsByUserId(this.huntingUserId).pipe(
      tap(response => {
        this.isLoading = false;
        this.accountIdsList = response['result'];
        this.accountOptions = this.accountIdsList;
        this.accountOptions.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        this.accountOptionsSelected = this.accountOptions;
        this.accountOptionsSelected = this.accountOptionsSelected.slice(0, 100);
      })
    );
  }
  loadSupplier() {
    this.isLoading = true;
    return this.settingService.fetchRecordsByBoardId(Constants.boardIdForSrmSupplier).pipe(
      tap(response => {
        this.isLoading = false;
        this.supplierIdsList = response['result'];
        this.supplierOptions = this.supplierIdsList;
        this.supplierOptions.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        this.supplierList = this.supplierOptions.slice(0, 100);
      })
    );
  }

  selectedAccountsForListing(accountList: any) {
    this.selectedAccountsForMOM = this.accountOptions.filter(element => accountList.includes(element.id));
  }

  downloadSingleDocument(id) {
    this._kService.downloadSingleUploadDocument(id).subscribe(res => {
      this.downloadSheetCommonMethod(res);

    })
  }

  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    let fileName = resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }
  resetDates: boolean = false;

  onApplyDates(event) {
    console.log('onApplyDates on meeting-listing', event)
    this.startDate = Number(moment(event.start_date).format('x'));
    this.endDate = Number(moment(event.end_date).format('x'));
    this.startUpdatedDate = moment(event.start_date);
    this.endUpdatedDate = moment(event.end_date);
    this.startExceededDate = moment(event.start_date).startOf('day').toISOString();
    this.endExceededDate = moment(event.end_date).startOf('day').toISOString();
    this.resetDates = false;
    this.onScrollDown();
  }

  onScrollDown() {
    const filters = this.data.filters;
    let obj = {
      'userIds': [],
      'userEmail': [],
      'from': 0,
      'size': 10,
      'businessVerticals': filters.businessVerticals,
      'regions': filters.regions,
      'startDate': this.startDate,
      'endDate': this.endDate
    };

    // Assign values only if user data exists
    if (this.data && this.data.user) {
      obj.userIds = [this.data.user.id];
      obj.userEmail = [this.data.user.email];
    }

    if (this.data.conductedMeetingDialog) {
      obj['pastMeeting'] = true;
      obj['futureMeeting'] = false;
      this.getCalendarByUser(obj);
    }
    else if (this.data.upcomingListDialog) {
      obj['pastMeeting'] = false;
      obj['futureMeeting'] = true;
      this.getCalendarByUser(obj);
    }
    else if (this.data.meetingsByField) {
      let obj = {
        'start': { "time": this.startDate },
        'end': { "time": this.endDate }
      };
      if (this.data.board === Constants.boardIdForVedantaAccount) {
        obj['accountList'] = [this.data.id];
      }
      else if (this.data.board === Constants.boardIdForSobPlant) {
        obj['plantIdList'] = [this.data.id];
      }

      this.getCalendarByfield(obj);
    }
    else if (this.data.meetingsByFieldWithMOM) {
      let obj = {
        'start': { "time": this.startDate },
        'end': { "time": this.endDate },
        'associatedContent':"MOM"
      };

      if (this.data.board === Constants.boardIdForVedantaAccount) {
        obj['accountList'] = [this.data.id];
      }
      else if (this.data.board === Constants.boardIdForSobPlant) {
        obj['plantIdList'] = [this.data.id];
      }

      this.getCalendarByfield(obj);
    }
    else {
      this.getMOMByUser(obj);
    }
  }

}
