import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { KanbanService } from '../kanban.service';
import { LanesListComponent } from './lanes-list/lanes-list.component';
import { KBoardFiltersComponent } from './k-board-filters/k-board-filters.component';
import { AddNewLeadComponent } from './add-new-lead/add-new-lead.component';
import { Router } from '@angular/router';
import { UploadPanjivaDialogComponent } from './board-lane/upload-panjiva-dialog/upload-panjiva-dialog.component';
import { BoardLaneComponent } from './board-lane/board-lane.component';
import { BulkGtDialogComponent } from './bulk-gt-dialog/bulk-gt-dialog.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { CreateLaneTaskComponent } from './board-lane/create-lane-task/create-lane-task.component';
import { NavigationService } from './navigation-service.service';
import { MatTabGroup } from '@angular/material';
import * as lodash from 'lodash';
import { SettingService } from '../../../settings/setting.service';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { Constants } from 'src/app/Constants';
@Component({
  selector: 'app-k-board',
  templateUrl: './k-board-v1.component.html',
  styleUrls: ['./k-board.component.scss']
})
export class KBoardComponent implements OnInit {
  boardId: any;
  sendFilteredReqObj = {};
  searchText: string;
  selectedBoard : any;
  showCloseIcon: boolean = false;
  isLoading: boolean = false;
  laneAccessOn: boolean = false;
  breakpoint: number;
  _: any = lodash;
  currentUser : any = {};
  boardStats: any;
  currentState: any = {};
  passOnData: any;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(BoardLaneComponent) boardLaneComp: BoardLaneComponent;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  switchBusiness:any;
  crmBusinessId = Constants.businessIdForCRM;
  sobBusinessId = Constants.businessIdForSOB;
  srmBusinessID = Constants.businessIdForSRM;
  selectedBusinessId:any;
  selectedBusinessName:any;
  defaultBusinessId:any;
  defaultBusinessName:any;
  getTabIndex: number;
  huntersListSelected: boolean = false;
  KamBoardSelected: boolean = false;
  MeetingBoardSelected: boolean = false;
  boardIdForSobContacts: string = Constants.boardIdForSobContacts;
  boardList = [];
  boardLane: any = [];
  dashboard = [];
  selectedDashItem: any;
  selectedBoardName: string;
  fetchLaneConfigById;
  boardLaneResponse;
  storeSearchResponse = [];
  showListing: boolean = false;
  autoSuggestloading: boolean = false
  animal: string;
  name: string;
  constructor(public _kService: KanbanService, public router: Router, public dialog: MatDialog, public navigation: NavigationService, public settingService: SettingService) {
    this.navigation.startSaveHistory();
  }
  ngOnInit() {
  this.boardId=null;
  this.currentUser = JSON.parse(localStorage.getItem("USERINFO"))
  this.setBreakPoint();
  this.defaultBusinessId=localStorage.getItem('defaultBusinessId');
  this.defaultBusinessName=localStorage.getItem('defaultBusinessName');
  //this.getBoardList(this.crmBusinessId);
  // this.switchBusiness=this.settingService._ManagementData.subscribe(res => {
  //   console.log(" ngOnInit res", res);
  //   this.huntersListSelected=false;
  //   this.MeetingBoardSelected=false;
  //   this.KamBoardSelected=false;
  //  //remove board id from local storage on switching business
  //   if(res && res.length>0){
  //   if(localStorage.getItem('selected_business')!=null){
  //     if(localStorage.getItem('selected_business')!=res){
  //       localStorage.removeItem('lane_board');
  //       localStorage.removeItem('selected_lane');
  //     }
  //   }else{
  //     localStorage.removeItem('lane_board');
  //     localStorage.removeItem('selected_lane');
  //   }
  //   this.selectedBusinessName=res;
  //   localStorage.setItem('selected_business',this.selectedBusinessName);
  // }else{
  //   res=localStorage.getItem('selected_business');
  //   localStorage.removeItem('lane_board');
  //   localStorage.removeItem('selected_lane');
  //   localStorage.removeItem('selected_business');
  // }
  //   this.selectedDashItem=null;
  //   if(res==='SOB Management'){
  //     console.log("in sob management");
  //     this.selectedBusinessId=this.sobBusinessId;
  //     this.selectedBusinessName="SOB Management";
  //     localStorage.setItem('selectedBusinessId',this.selectedBusinessId);
  //     localStorage.setItem('selected_business',this.selectedBusinessName);
  //     this.getBoardList(this.sobBusinessId);
  //   }else if(res==='CRM Management'){
  //     console.log("in crm management");
  //     this.selectedBusinessId=this.crmBusinessId;
  //     this.selectedBusinessName="CRM Management";
  //     localStorage.setItem('selectedBusinessId',this.selectedBusinessId);
  //     localStorage.setItem('selected_business',this.selectedBusinessName);
  //     this.getBoardList(this.crmBusinessId);
  //   }else if(res==='SRM Management'){
  //     console.log("in srm management");
  //     this.selectedBusinessId=this.srmBusinessID;
  //     this.selectedBusinessName="SRM Management";
  //     localStorage.setItem('selectedBusinessId',this.selectedBusinessId);
  //     localStorage.setItem('selected_business',this.selectedBusinessName);
  //     this.getBoardList(this.selectedBusinessId);
  //   }
  // });
  let userinfo = JSON.parse(localStorage.getItem("USERINFO"))
  this.selectedBusinessId=userinfo.business_id;
  this.getBoardList(this.selectedBusinessId);
  }
  ngOnDestroy(){
    console.log('ngOnDestroy')
    // this.switchBusiness.unsubscribe();
  }
  onResize(event) {
    //this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
    this.setBreakPoint();
  }
  getSingularName(name) {
    if (name) {
      if (name.endsWith("s")) {
        return name.substring(0, name.length - 1);
      }
    }
    return name;
  }
  setBreakPoint() {
    if ((window.innerWidth <= 400)) {
      this.breakpoint = 1
    } else if ((window.innerWidth > 400) && (window.innerWidth <= 768)) {
      this.breakpoint = 3
    } else if ((window.innerWidth > 768) && (window.innerWidth <= 1390)) {
      this.breakpoint = 5;
    } else if ((window.innerWidth > 1390)) {
      this.breakpoint = 7;
    }
  }
  selectLaneCard = (dashItem, index) => {
    if (this.selectedDashItem == null) {
      if (localStorage.getItem('selected_lane') != null) {
        let tmpBoard = JSON.parse(localStorage.getItem('selected_lane'));
        if (tmpBoard) {
          this.selectedDashItem = tmpBoard;
        } else {
          this.selectedDashItem = dashItem;
        }
      }
    } else {
      this.selectedDashItem = dashItem;
    }

    //this.isLoading = !this.isLoading;
    this.passOnData = {
      "boardId": this.boardId,
      "selectedLane": this.selectedDashItem,
      "boardData": this.boardLaneResponse,
      "selectedLaneIndex": index,
      "accessLanes": this.boardLane
    }
    this.currentState = this.passOnData;
    localStorage.setItem("selected_lane" , JSON.stringify(this.selectedDashItem));
    // localStorage.setItem("lane_board" , this.currentState);
    //this.forceUpdate();
  }
  getBoardStats() {
    let tmpQueryLanes = [];
    if (this.boardLaneResponse != null && (this.boardStats == null || this.boardStats[this.boardId] == null)) {
      for (let i = 0; i < this.boardLaneResponse['result']['levelAccess'].length; i++) {
        tmpQueryLanes.push(this.boardLaneResponse['result']['levelAccess'][i]['laneDTO'].id);
      }
      tmpQueryLanes;
      let obj = {
        "where": {
          "lane.$id": {
            "type": "objectid_in",
            "value": tmpQueryLanes
          },
          "isDeleted": {
            "type": "search",
            "value": false
          }
        }
      }
      this._kService.getBoardStats(obj).subscribe(res => {
        if (this.boardStats == null) {
          this.boardStats = {};
        }
        if (res && res['result'] && res['result'].length > 0) {
          let responseFormat = {};
          let total = 0;
          for (let k = 0; k < res['result'].length; k++) {
            responseFormat[res['result'][k]['_id'].id] = res['result'][k]['taskCount'];
            total = total + res['result'][k]['taskCount'];
          }
          responseFormat['total'] = total;
          this.boardStats[this.boardId] = responseFormat;
        }
      });
    }
  }
  getBoardList(businessId) {
    this.boardId=null;
    this._kService.getBoardListByBusinessId(businessId).subscribe(res => {
      let tmpList = res['result'];
      if(businessId !== this.sobBusinessId){
        tmpList.unshift(tmpList[tmpList.length - 1]);
        tmpList.splice(tmpList.length - 1);
      }
      this.boardList = tmpList;
      console.log("this.boardList",this.boardList)
      if (this.boardList != null && this.boardList.length > 0) {
        if (localStorage.getItem('lane_board') != null) {
          let tmpBoard = localStorage.getItem('lane_board');
          if (tmpBoard) {
            this.boardId = tmpBoard;
          }
        }
        if (this.boardId == null) {
          this.boardId = this.boardList[0].id;
          this.selectedBoardName = this.boardList[0].name;
          this.selectedBoard= this.boardList[0];
        }
        if (this.boardId != null && (this.selectedBoardName == null || this.selectedBoardName.trim() != '' )) {
          let selBoardId = this.boardId;
          let tmp = this.boardList.find(function(item) {
            return item.id == selBoardId;
          })
          if (tmp) {
            this.selectedBoardName = tmp.name;
            this.selectedBoard= tmp;
          }
        }
        this.fetchBoardLane(this.boardId);
      }
    })
  }
  togglekBoardUserManagement() {
    this.laneAccessOn = !this.laneAccessOn;
    if (this.laneAccessOn) {
      this.fetchBoardLane(null);
    }
  }
  openKBoardUserManagement() {
    this.router.navigate(['/KboardUsermanagement/' + this.boardId]);
  }
  getLaneList() {
    const dialogRef = this.dialog.open(LanesListComponent, {
      width: '373px',
      height: '100%',
      panelClass: 'eximSetting'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  boardFiltersOpen() {
    const dialogRef = this.dialog.open(KBoardFiltersComponent, {
      width: '373px',
      height: '100%',
      data: {
        boardId: this.boardId,
        reqObj: this.sendFilteredReqObj
      },
      panelClass: 'eximSetting'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.sendFilteredReqObj = result
      if (!result.close)
        this.getBoardList(this.selectedBusinessId)
    });
  }
  fetchBoardLane(boardIdTmp: string) {
    let tmp1: any = boardIdTmp != null ? boardIdTmp : this.boardId;
    this._kService.getLaneWithUserAccess(tmp1, '').subscribe(res => {
      this.boardId = tmp1;
      this.boardLaneResponse = res;
      let tmp = [];
      for (let i = 0; i < res['result']['levelAccess'].length; i++) {
        let t = res['result']['levelAccess'][i].laneDTO;
        t['class'] = "data-pending";
        if (res['result']['levelAccess'][i].laneDTO.position == 1) {
          this.fetchLaneConfigById = res['result']['levelAccess'][i].laneDTO.id;
        }
        tmp.push(t);
      }
      this.boardLane = tmp;
      if (this.selectedDashItem == null) {
        if (localStorage.getItem('selected_lane') != null && localStorage.getItem('selected_lane').toString() != 'undefined') {
          let tmpBoard = JSON.parse(localStorage.getItem('selected_lane'));
          if (tmpBoard) {
            this.selectedDashItem = tmpBoard;
          }
        } else {
          this.selectedDashItem = this.boardLane[0];
        }
      }
      this.getTabIndex = 0;
      this.passOnData = {
        "boardId": this.boardId,
        "selectedLane": this.selectedDashItem,
        "boardData": res,
        "accessLanes": this.boardLane,
        "loadStats" : false,
        "selectedBusinessId":this.selectedBusinessId,
        "selectedBoardName":this.selectedBoardName
      }
      this.currentState = this.passOnData;
      localStorage.setItem("lane_board", this.boardId);
      localStorage.setItem("selected_lane" , JSON.stringify(this.selectedDashItem));
    });
  }
  onLaneChange(lane) {
  }
  addNewLead() {
    // let url = "lane-task/add-new/" + this.selectedDashItem.id;
    // window.open("/#/lane-task/add-new/" + this.selectedDashItem.id, "_blank");
    console.log('board lane before',this.boardLane)
    this.boardLane.sort(function (a, b) {
      return a.position - b.position;
    });
    console.log('board lane after',this.boardLane)
    console.log('board lane selectedBoardName',this.selectedBoardName);
    if (this.selectedBoardName && /deal/igm.test(this.selectedBoardName)) {
      window.open("/#/lane-task/add-new/" + this.boardId + "/"+ this.boardLane[0].id, '_self');
    }
    else if (this.selectedBoardName && /opportunity/igm.test(this.selectedBoardName)) {
      window.open("/#/lane-task/add-new/" + this.boardId + "/"+ this.boardLane[0].id, '_self');
    }
    else if (this.selectedBoardName && /supplier/igm.test(this.selectedBoardName)) {
      window.open("/#/lane-task/add-new/" + this.boardId + "/"+ this.boardLane[0].id, '_self');
    }
    else {
      const dialogRef = this.dialog.open(CreateLaneTaskComponent, {
        disableClose: true,
        width: '90%',
        maxWidth: '90vw',
        height: '100vh',
        position: { bottom: '0px', right: '0px' },
        data: {
          "boardId": this.boardId,
          "boardName": this.selectedBoardName,
          "selectedLane": this.boardLane[0]
        },
        panelClass: 'addnewleadoverlay'
      });
      dialogRef.afterClosed().subscribe(result => {
        // if (result == true) {
        //   this.getBoardList(this.selectedBusinessId);
        // }
        window.location.reload();
      });
    }

  }
 selectHunterLane() {
  this.huntersListSelected = true;
  this.KamBoardSelected=false;
  this.MeetingBoardSelected = false;
  this.boardId = 4;
 }
 selectMeetingLane() {
  this.huntersListSelected = false;
  this.MeetingBoardSelected = true;
  this.KamBoardSelected = false;
  this.boardId = 6;
 }
 selectKAMLane(){
  this.MeetingBoardSelected = false;
  this.KamBoardSelected = true;
  this.boardId = 5;
 }
  getBoardTabChange(index) {
    this.huntersListSelected = false;
    this.MeetingBoardSelected=false;
    this.KamBoardSelected = false;
    if (this.getTabIndex == null) {
      if (localStorage.getItem('lane_board_index') != null) {
        let tmpBoard = localStorage.getItem('lane_board_index');
        if (tmpBoard) {
          this.getTabIndex = parseInt(tmpBoard);
       //  this.tabGroup.selectedIndex = this.getTabIndex;
        }
      }
    } else {
      this.getTabIndex = index
    }
   // this.tabGroup.selectedIndex = this.getTabIndex;
    this.searchText = ''
    this.showCloseIcon = false;
    this.boardLane = [];
    let i = this.getTabIndex != null ? this.getTabIndex : index;
    if (i < this.boardList.length) {
      this.boardId = this.boardList[i].id;
      this.selectedBoard= this.boardList[i];
      this.selectedBoardName = this.boardList[i].name;
      localStorage.setItem('lane_board', this.boardId);
      localStorage.setItem('lane_board_index', this.getTabIndex != null ? this.getTabIndex.toString() : null);
      this.selectedBoardName = this.boardList[i].name;
      this.fetchBoardLane(this.boardList[i].id)
    }
  }
  uploadPanjivaFile(status) {
    const dialogRef = this.dialog.open(UploadPanjivaDialogComponent, {
      disableClose: true,
      width: '450px',
      height: '240px',
      data: status,
      panelClass: 'addnewleadoverlay'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.ngOnInit();
      }
    });
  }
  submitSearch() {
    console.log("Submit Search ");
    if (this.searchText && this.searchText.trim() == "") {
      this.resetSearchText();
    }
   // this.selectedDashItem = this.boardLane[0];
    this.sendFilteredReqObj = {
      "searchText": this.searchText
    }
    this.showListing = false;
  }
  onSearchChange(value) {
    this.autoSuggestloading = true
    this.showCloseIcon = true;

    if (value) {
      if (value.trim().length > 1) {
        let tmpQueryLanes = [];
        if (this.boardLaneResponse && this.boardLaneResponse['result'] && this.boardLaneResponse['result']['levelAccess'] && this.boardLaneResponse['result']['levelAccess'].length > 0) {
          for (let i = 0; i < this.boardLaneResponse['result']['levelAccess'].length; i++) {
            tmpQueryLanes.push(this.boardLaneResponse['result']['levelAccess'][i]['laneDTO'].id);
          }
        }
        let obj = {
          "where":
          {
            "isDeleted": { "type": "search", "value": false },
            "lane.$id": {
              "type": "objectid_in",
              "value": tmpQueryLanes
            },
            "multi_match_query": {
              "type": "multi_match_query",
              "value": [
                {
                  "field": "displayName",
                  "value": this.searchText,
                  "type": "like"
                },
                {
                  "field": "displaySequenceName",
                  "value": this.searchText,
                  "type": "like"
                }
              ]
            }

          },
          "sort": { "field": "createdAt", "order": "desc" },
          "from": 0, "size": 5
        }
        setTimeout(function () {
          this.autoSuggestloading = false;
        }.bind(this), 500);
        this._kService.getLaneTask(obj).subscribe(res => {
          this.autoSuggestloading = false;
          this.storeSearchResponse = res['result'];
          this.showListing = true;

        })
        //this.boardLaneComp.getLaneTask(obj);
      }
    }
    else {
      this.showCloseIcon = false;
      this.boardLaneComp.ngAfterViewInit();
    }
  }
  getCardDetails(list) {
    window.open('/#/cardDetails/' + list.id, "_blank");
    this.showListing = false;

  }
  resetSearchText() {
    this.searchText = "";
    this.showCloseIcon = false;
    this.showListing = false;
    //this.boardLaneComp.ngAfterViewInit();
  }
  openDialog(): void {
    this.router.navigate(['/bulkListing/' + this.boardId]);
    // const dialogRef = this.dialog.open(BulkGtDialogComponent, {
    //   width: '516px',
    //   height: '600px',
    //   data: this.boardId
    // });
  }
  bulkUploadDialog() {

    const dialogRef = this.dialog.open(BulkUploadComponent,{

    width: '580px',

    height: '350px',

    backdropClass: 'custom-dialog-backdrop-class',

    panelClass: 'custom-dialog-panel-class',

    });

    }
}
