import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { QuillEditorComponent } from 'ngx-quill';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { SettingService } from 'src/app/settings/setting.service';
import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';

@Component({
  selector: 'app-more-action-items',
  templateUrl: './more-action-items.component.html',
  styleUrls: ['./more-action-items.component.scss']
})
export class MoreActionItemsComponent implements OnInit {

  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent
  selectedIndex: any;
  file: null;
  title: string;
  userTitle: string;
  totalComments: any;
  mentionList: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _settingService: SettingService, public _kService: KanbanService, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<MoreActionItemsComponent>) {
    if (data) {
      this.data = data;
      console.log(" data :: ", this.data);
    }
  }
  actionItemList: any = [];
  modules = {
    mention: {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      mentionContainerClass: 'ql-mention-list-container',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor
        insertItem(item)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user')
        this.mentionList.push(item.id);
      },
      source: async (searchTerm, renderList) => {
        // const values = this.allUsersList

        // if (searchTerm.length === 0) {
        //   renderList(values, searchTerm)
        // } else {
        //   const matches = []

        //   values.forEach((entry) => {
        //     if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
        //       matches.push(entry)
        //     }
        //   })
        //   renderList(matches, searchTerm)
        // }
        const matchedPeople = await this.suggestPeople(searchTerm);
        console.log('matchedPeople', matchedPeople)
        renderList(matchedPeople, searchTerm);
      }
    },
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter', context);
            return true;
          },
        },
      },
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      // ['link', 'image', 'video']                         // link and image, video
    ]
  }
  showMoretext = false;

  ngOnInit() {
    this.actionItemList = [];

    const filters = this.data.filters;
    let obj = {
      'userIds': [this.data.user.id],
      'userEmail': [this.data.user.email],
      'from': 0,
      'size': 10,
      'businessVerticals': filters.businessVerticals,
      'regions': filters.regions,
      'startDate': filters.startDate,
      'endDate': filters.endDate
    };
    this.getActionItemByUser(obj);
  }

  getActionItemByUser(obj) {
    console.log("getActionItemByUser :: ");
    this._settingService.getActionItemByUser(obj).subscribe(data => {
      console.log("getActionItemByUser :: ", data);
      if (data['result']) {
        this.actionItemList = data['result'];
      }
      else {
        this.actionItemList = [];
      }
    });
  }
  toggleText() {
    this.showMoretext = !this.showMoretext;
  }
  toggleCommentArea(index) {
    this.selectedIndex = index;
  }

  addCommentsOnActionItemOrNote(element, id) {
    console.log(' addCommentsOnActionItemOrNotemention list', this.actionItemList)
    const quillEditor = document.getElementById(id) as HTMLDivElement;
    let quillInstance;
    let tmp = '';
    let content;
    if (quillEditor) {
      quillInstance = quillEditor.querySelector('.ql-editor') as HTMLDivElement;
      tmp = quillInstance.innerHTML
      content = quillInstance.innerHTML
    }

    console.log('addCommentsOnActionItemOrNotemention content : ', content)

    tmp = tmp.replace(/(<([^>]+)>)/ig, '');
    if (content && (content.trim() != "" && tmp.trim() != "")) {
      let obj = {
        "dataType": element.type,
        "laneTaskId": element.laneTaskId,
        "message": content,
        "parentId": element.id,
        "mention": this.mentionList
      }
      let reqFunc = null;
      reqFunc = this._kService.addCommentsOnActionItemOrNote(obj);

      reqFunc.subscribe(res => {
        if (res['status'] == 200) {
          this.file = null;
          if (quillInstance) {
            quillInstance.innerHTML = '';
          }          
          this.closeCommentArea(id);
          this.getTaskComment("save", res['result'].laneTaskId, element.id);
        }
      })
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please provide comment"
      });
    }

  }
  commentPageNumber: number = 0;
  pageSize: number = 10;

  getTaskComment(save?, laneTaskId?, id?: any) {

    let reqObj = {
      "from": this.commentPageNumber * this.pageSize,
      "size": 200,
      "where": { "laneTask.$id": { "type": "search", "value": laneTaskId } }
    }
    let reqFunc = null;
    this.title = "Action Items";
    this.userTitle = "Added";
    reqFunc = this._kService.getActionItem(reqObj).subscribe(res => {
      let tmp = res['result'];
      console.log("result: ", res)
      this.totalComments = tmp;
      console.log("new : ", tmp);
      console.log("old: ", this.actionItemList);
      tmp.forEach(tmpItem => {
        let correspondingItem = this.actionItemList.find(item => item.id === tmpItem.id);    
        if (correspondingItem) {
            correspondingItem.comments = tmpItem.comments;
        }
    });
    })
  }

  async suggestPeople(searchTerm) {
    return new Promise((resolve, reject) => {
      let allPeople = [];
      let userinfo = JSON.parse(localStorage.getItem("USERINFO"))
      let businessId=userinfo.business_id;
      let obj = {
        searchKey: searchTerm,
        businessId: businessId
      };
      this._settingService.fetchUserListAutosuggest(obj).subscribe(
        (response) => {
          if (response) {
            let alluser: any = response;
            for (let i = 0; i < alluser.length; i++) {
              let user = { id: alluser[i]["id"], value: alluser[i]["name"] };
              allPeople.push(user);
            }
            console.log('allPeople', allPeople);
            resolve(allPeople);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  closeCommentArea(index) {
    console.log('closeCommentArea index ', index)
    this.selectedIndex = null;
  }
}
