<ng-container>
  <div fxLayout="row" class="board-dashboard border-top-1 lane-card-section overflow-1" *ngIf="boardLane != null && boardLane.length > 1 && this.boardId!=boardIDForSOBAccount">

  <ul class="lane-card-list">
    <li *ngFor="let dashItem of boardLane; let i = index;" (click)="changeLaneCard(dashItem);" [matTooltip]="toWords(getLaneStats(dashItem.laneDTO.displayName).value) + 'Cr'" [matTooltipClass]="tooltip" [ngClass]="{'selected-lane': dashItem.laneDTO.name == selectedLane.laneDTO.name}">
      <!-- <ng-template #mytmp let-stats="getLaneStats(dashItem.laneDTO.displayName);"> -->
      <button  type="button"
      [ngClass]="{'trans': dashItem.laneDTO.name == selectedLane.laneDTO.name}">
      {{dashItem.laneDTO.displayName}} <span> {{getLaneStats(dashItem.laneDTO.displayName).count}} </span>
    </button>
    <span *ngIf="showAmountOnLanes" class="amount">{{nFormatter(getLaneStats(dashItem.laneDTO.displayName).value, 3)}} Cr</span>
  <!-- </ng-template> -->
    </li>
  </ul>

    <!-- <mat-grid-list [cols]="breakpoint" rowHeight="4:1" (window:resize)="onResize($event)" gutterSize="0.5rem" class="list-view">
      <mat-grid-tile class="lane-grid" *ngFor="let dashItem of boardLane; let i = index"
        (click)="changeLaneCard(dashItem);"
        [ngClass]="{'selected-lane': dashItem.laneDTO.name == selectedLane.laneDTO.name}">
        <button class="dash-button dash-button-font"
          [ngClass]="{'trans': dashItem.laneDTO.name == selectedLane.laneDTO.name}">
          {{dashItem.laneDTO.displayName}} - ({{getLaneStats(dashItem.laneDTO.displayName).count}})
        </button>
      </mat-grid-tile>
    </mat-grid-list> -->
  </div>
  <div fxLayout="row" class="board-dashboard border-top-1"  *ngIf="boardLane != null && boardLane.length > 1">
    <app-chart-filters  #child [inputData]="passOnData" (filterApplied)="onFilterApply($event)" style="width: 100%;"></app-chart-filters>
  </div>
  <div fxLayout="row" class="board-dashboard border-top-1"  *ngIf="boardLane != null && boardLane.length == 1">
    <app-chart-filters  #child [inputData]="passOnData" (filterApplied)="onFilterApply($event)" style="width: 100%;"></app-chart-filters>
  </div>
  <div fxLayout="column" style="position: relative;">
    <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%;">
      <div fxLayout="row"  class="section-width ">
        <div class="table-overflow mog3_msnsearchtable_sticky" [ngClass]="{'deal-table-overflow-height' : boardId == '63bfa8ccade7bc466d1c780e', 'table-overflow-height-1': boardId != '63bfa8ccade7bc466d1c780e', 'opportunity-table-height': boardId == boardIdForSobOpportunity }"  >
          <table mat-table matSort [dataSource]="dataSource" class="table-1 m" cdkDropList [ngClass]="{'deal-table' : boardId == '63bfa8ccade7bc466d1c780e'}"
            (cdkDropListDropped)="drop($event)" cdkDropListData="dataSource" [cdkDropListDisabled]="dragDisabled"
            *ngIf="displayedColumns && displayedColumns.length > 0" (matSortChange)="sortLaneTasks($event)" [@.disabled]="true">

            <ng-container>
            <!-- tag Column -->
            <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
              <ng-container *ngIf="disCol.display">
                <ng-container *ngIf="disCol.type != 'date' && disCol.type != 'currency' && disCol.type != 'number'">
                  <th mat-header-cell *matHeaderCellDef class="table-header" >
                    <span class="col-name"> {{disCol.displayName}}</span>
                  </th>
                </ng-container>
                <ng-container *ngIf="disCol.type == 'date' || disCol.type == 'currency' ||  disCol.type == 'number'">
                  <th mat-header-cell *matHeaderCellDef class="table-header">
                    <span class="col-name"> {{disCol.displayName}}</span>
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;"
                  (click)="openCardDetails(element)">
                  <div fxLayout="column">
                    <div *ngIf="disCol.type !== 'multiSelect' && disCol.field !== 'closing-date' && disCol.field !== 'active-total-deals' && disCol.field !== 'contact' && disCol.field !== 'Status' 
                          " fxLayout="row" class="field-value">
                      <span [ngClass]="{'table-title': (colIndex == 0)}"> {{ getFieldValue(element, disCol.field) }} </span>
                    </div>
                    <div *ngIf="(disCol.field === 'plant' && boardId===boardIdForSobContact) || (disCol.field === 'plant-name' &&  boardId===boardIdForSobOpportunity)" class="field-value app">
                      <span class="d-flex" [ngClass]="{'table-title': (colIndex == 0)}"> {{ getFieldValue(element, disCol.field) }} </span>
                      <a *ngIf="getTotalPlantsMoreCountOnSobContacts(element)>0" class="blue-anchor-2 more-details-menu-button" (click)="$event.stopPropagation();" 
                         [matMenuTriggerFor]="menu">+{{getTotalPlantsMoreCountOnSobContacts(element)}} more</a>
                          <mat-menu class="more-details-menu" #menu="matMenu">
                            <p>{{getTotalPlantsCountOnSobContacts(element)}} plant assigned</p>
                            <ul *ngIf="element['searchData']['plant_names']">
                              <li *ngFor="let dashItem of element['searchData']['plant_names'];">
                                <button mat-menu-item>{{dashItem}}</button>
                              </li>
                            </ul>
                            <ul *ngIf="element['searchData']['plantname_names']">
                              <li *ngFor="let dashItem of element['searchData']['plantname_names'];">
                                <button mat-menu-item>{{dashItem}}</button>
                              </li>
                            </ul>
                          </mat-menu>
                    </div>
                    <div *ngIf="disCol.type === 'multiSelect'" class="field-value app">
                      <span class="d-flex" [ngClass]="{'table-title': (colIndex == 0)}"> {{ getMultiSelectFirstValue(element, disCol.field) }} </span>
                      <a *ngIf="getMultiSelecValueCount(element,disCol.field)>0" class="blue-anchor-2 more-details-menu-button" (click)="$event.stopPropagation();" 
                        [matMenuTriggerFor]="menu">+{{getMultiSelecValueCount(element,disCol.field)}} more</a>
                          <mat-menu class="more-details-menu" #menu="matMenu">
                            <p>{{getMultiSelecValueCount(element,disCol.field)}} other values</p>
                            <ul>
                              <li *ngFor="let dashItem of getMultiSelectRemainingAllValue(element,disCol.field);">
                                <button mat-menu-item>{{dashItem}}</button></li>
                            </ul>
                          </mat-menu>
                    </div>
                    <!-- <div *ngIf="disCol.field === 'plant-name' && boardId===boardIdForSobOpportunity" class="field-value app">
                      <span class="d-flex" [ngClass]="{'table-title': (colIndex == 0)}"> {{ getFieldValue(element, disCol.field) }} </span>
                      <a *ngIf="getTotalPlantsMoreCountOnSobContacts(element)>0" class="blue-anchor-2 more-details-menu-button" (click)="$event.stopPropagation();" mat-button [matMenuTriggerFor]="menu">+{{getTotalPlantsMoreCountOnSobContacts(element)}} more</a>
                          <mat-menu class="more-details-menu" #menu="matMenu">
                            <p>{{getTotalPlantsCountOnSobContacts(element)}} plant assigned</p>
                            <ul>
                              <li *ngFor="let dashItem of element['searchData']['plant_names'];">
                                <button mat-menu-item>{{dashItem}}</button></li>
                            </ul>
                          </mat-menu>
                    </div> -->

                    <div *ngIf="disCol.field === 'closing-date'" fxLayout="row" class="field-value">
                      <div><span [ngClass]="{'past-closing-date': checkPastClosingDate(element, disCol.field)}"> {{ getFieldValue(element, disCol.field) }} </span></div>
                    </div>
                    <div fxLayout="column" *ngIf="disCol.field == 'closing-date'" fxLayoutAlign ="center">
                      <p class="m-0 cell-sub-text"><span class="time-label"> Original Date: </span> {{ getOriginalClosingDateValue(element, disCol.field) }} </p>
                    </div>

                    <div fxLayout="column" *ngIf="disCol.field == 'account-name'" fxLayoutAlign ="center">
                      <p class="m-0 cell-sub-text"><span class="time-label"></span> {{element.searchData.tagType}} </p>
                    </div>

                    <div fxLayout="column" *ngIf="disCol.field == 'deal-name'" fxLayoutAlign ="center">
                      <p class="m-0 cell-sub-text"><span class="time-label"> Created</span> {{ getFieldValue(element, 'createdAt') }}</p>
                      <p class="m-0 cell-sub-text"><span class="time-label"> Modified</span> {{ getFieldValue(element, 'updatedAt') }}</p>
                    </div>
                    <div fxLayout="column" *ngIf="disCol.field == 'deal-owner'" fxLayoutAlign ="center">
                      <p class="m-0 cell-sub-text">{{ getFieldValue(element, 'secondary-owner') }}</p>
                    </div>
                    <div fxLayout="column" *ngIf="disCol.field === 'name' && boardId===boardIdForSobContact" fxLayoutAlign ="center">
                      <p class="m-0 cell-sub-text">{{ getFieldValue(element, 'designation') }}</p>
                    </div>
                    <div fxLayout="column" *ngIf="disCol.field === 'role' && boardId===boardIdForSobContact" fxLayoutAlign ="center">
                      <p class="m-0 cell-sub-text">{{ getFieldValue(element, 'role-type') }}</p>
                    </div>
                    <!-- <div fxLayout="row" *ngIf="disCol.field === 'active-total-deals'" class="field-value">
                      <span class="table-title">{{ getFieldValue(element, disCol.field) }}</span>
                      <span *ngIf="getFieldValue(element, disCol.field) != '-'" class="table-title">/28</span>
                    </div>
                    <div fxLayout="row" *ngIf="disCol.field == 'contact'" class="field-value">
                      <span class="table-title">{{ getFieldValue(element, disCol.field) }}</span>
                    </div>
                    <div fxLayout="column" *ngIf="disCol.field == 'Status'" class="field-value">
                      <div [ngClass]="{'activeLabel': getFieldValue(element, disCol.field) == 'Active','rejectedLabel' : getFieldValue(element, disCol.field) == 'Rejected','underApprovalLabel' : getFieldValue(element, disCol.field) == 'Under Approval'}">{{ getFieldValue(element, disCol.field) }}</div>
                      <div class="statusMsg" *ngIf="getFieldValue(element, disCol.field) == 'Rejected'">Duplicate account</div>
                    </div>   -->
                  </div>
                </td>
              </ng-container>
            </ng-container>
            <ng-container matColumnDef="lane">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column; display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Stage </span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                <div><span> <strong> {{ element.lane?.displayName }}</strong></span></div>
                <div fxLayout="column" fxLayoutAlign ="center">
                  <p class="m-0 cell-sub-text">{{ calculateAge(element)}}</p>
                </div>
                <div fxLayout="column" fxLayoutAlign ="center">
                  <p class="m-0 cell-sub-text">{{ isW1Recieved(element)}}</p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="countMeetings">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Total Meetings</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.totalMeetings}}
              </td>
            </ng-container>
            <ng-container matColumnDef="lastMeeting">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Last Meeting Date</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.lastMeetingDate}}
              </td>
            </ng-container>
            <ng-container matColumnDef="nextMeeting">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Next Planned Meeting</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.nextMeetingDate}}
              </td>
            </ng-container>
            
            <ng-container matColumnDef="plantCount">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Total Plants</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.totalPlants}}
              </td>
            </ng-container>

            <ng-container matColumnDef="count">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Total Contacts</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.totalContacts}}
              </td>
            </ng-container>

            <ng-container matColumnDef="momCount">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Total MOM</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.totalMom}}
              </td>
            </ng-container>

            <ng-container matColumnDef="sobAccountStrength">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Strength </span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.sobAccountStrength }}
              </td>
            </ng-container>
            <ng-container matColumnDef="sobPercent">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> SOB % </span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.sobPercent}}
              </td>
            </ng-container>
            <ng-container matColumnDef="categoryAnnualSpend">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Category Annual Spend (in Cr)</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{element.categoryAnnualSpend}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Owner">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Owner</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{getFormPropertyValue(element,'name')}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Owner_Email">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Owner Email</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{getFormPropertyValue(element,'email-id')}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Owner_Mobile">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Mobile</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{getFormPropertyValue(element,'mobile-no')}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Owner_Designation">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Designation</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{getFormPropertyValue(element,'designation')}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Owner_Age">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name"> Age</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                {{getFormPropertyValue(element,'age')}}
              </td>
            </ng-container>

            <ng-container matColumnDef="actionItem">
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>
                <div style=" flex-direction: column;
                display: flex; width: 100%; height: 100%;">
                 <span class="col-name" *ngIf="boardId!=boardIdForSobPlant && boardId !== boardIdForVedantaAccount"> Next Action Item</span>
                 <span class="col-name" *ngIf="boardId==boardIdForSobPlant || boardId === boardIdForVedantaAccount"> Minute of Meeting</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
                <div  *ngIf="element.nextActionItem != null">

                  <p style="width: 200px;" [ngClass]="{'limitTextHeight': isReadMore}" [innerHtml]="element.nextActionItem.message" class="comment-message overflow-auto"></p>

                  <button type="button" class="read-more-cta" *ngIf="element.nextActionItem.message.length > 50" (click)="isReadMore=!isReadMore">
                    {{ isReadMore ? 'Read More': 'Read Less' }}
                </button>
               
                <div fxLayout="column" fxLayoutAlign ="center">
                  <ng-container *ngIf="element.nextActionItem.attachments && element.nextActionItem.attachments.length">
                    <div class="file-upload-section">
               
                        <div class="file-uploading-status d-flex justify-content-between">
                          <a  (click)="downloadSingleDocument(element.nextActionItem.attachments[0].id)">
                          <span class="file-name"  >
                            {{element.nextActionItem.attachments[0].name}}
                            <mat-icon class="material-icons">download</mat-icon>
                          </span>
                        </a>
                        </div>
                    </div>
                  </ng-container>
                </div>
                <div fxLayout="column" fxLayoutAlign ="center">
                  <div><span [ngClass]="{'past-closing-date': checkPastDate(element)}"> {{ getFormattedDate(element.nextActionDate) }} </span></div>
                  <!-- <p class="m-0 cell-sub-text" [ngClass]="{'past-closing-date': checkPastDate(element)}">{{ getFormattedDate(element.nextActionDate) }}</p> -->
                </div>
                </div>
                <div  *ngIf="element.nextActionItem == null">

                  <span class="comment-message">N/A</span>
                  <div fxLayout="column" fxLayoutAlign ="center">
                    <p class="m-0 cell-sub-text">{{ getFormattedDate(element.nextActionDate) }}</p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <div>
                 <span class="col-name">
                Action
              </span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <!-- <div class="approval-action " *ngIf="dataSource.filteredData[0].laneId == '63ce5630a5c09f3f171460b2'">
                  <button mat-flat-button class="approval-btn action-btn-2 mr-10" >
                   Approval
                  </button>
                  <button mat-flat-button class="reject-btn action-btn-2" (click)="accountReject();">
                    Reject
                   </button>
                </div> -->
                <div class="actions-list">
                  <!-- <i *ngIf="(boardId===boardIdForSobContact || boardId===boardIdForCrmContact )&& (roleName==='Top Management' || roleName==='Master Admin')" class="ri-mail-line"
                  (click)="openEmailDetails(element)" [matTooltip]="getToolTipText('Email Communication', false)" matTooltipClass="new-tooltip"></i> -->

                  <i class="ri-edit-box-line" [matTooltip]="getToolTipText('Edit', false)" matTooltipClass="new-tooltip"
                  (click)="openCardDetails(element)" *ngIf="element.canEdit"  ></i>

                  <i class="ri-eye-line"  [matTooltip]="getToolTipText('Summary',true)"
                  matTooltipClass="new-tooltip" (click)="viewLaneTask(element)"></i>
                  <!-- <img src="../../../../../assets/icons/eye-black.svg" matTooltip="View Lead"
                    matTooltipClass="new-tooltip" (click)="viewLaneTask(element)" /> -->


                  <!-- <img src="../../../../../assets/icons/edit-black.svg" matTooltip="Edit Lead" matTooltipClass="new-tooltip"
                    (click)="openCardDetails(element)"  /> -->
                  <!-- <img src="../../../../../assets/icons/dark-calender.svg" matTooltip="Mark Disposition"
                    matTooltipClass="new-tooltip" (click)="downloadInvoice(element)" />
                  <img src="../../../../../assets/icons/add-repayment.svg"
                    matTooltip="Change Assignee" matTooltipClass="new-tooltip" (click)="changeAssignee(element)"/> -->
                    <i class="ri-history-line" [matTooltip]="getToolTipText('History', true)" matTooltipClass="new-tooltip" (click)="showHistory(element)"></i>
                  <!-- <img (click)="showHistory(element)" src="../../../../../assets/icons/history-black.svg"
                    matTooltip="Check Lead history" matTooltipClass="new-tooltip" /> -->
                    <i *ngIf="boardId == '63bfa8ccade7bc466d1c780e'" [ngClass]="{'ri-bookmark-fill':isWatcherAdded(element),'ri-bookmark-line':!isWatcherAdded(element)}"  [matTooltip]="getWatcherToolTip(element)"
                    matTooltipClass="new-tooltip" (click)="updateWatcheronDeal(element)"></i>


                </div>
              </td>

            </ng-container>
          </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true">

            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1; let i = index"
              [ngClass]="{ 'active-row': i === selectedTrIndex }">

            </tr>
          </table>

        </div>
      </div>
      <ng-container *ngIf="((laneTaskList != null && laneTaskList.length < 1)) && !isLoading" style="background: #ffffff;">
        <div  fxLayout="row"  fxLayoutAlign="center center">
          No Document to Show.
        </div>
    </ng-container>
      <div fxLayout="row" class="section-width  divider">
        <mat-divider></mat-divider>
      </div>
      <!-- <div *ngIf="isLoading" fxLayout="row" style="position: absolute; top: 10%" fxLayoutAlign="center center">
        <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
      </div> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" class="section-width ">
      <div fxLayout="row">
        <div fxLayout="row" class="pagination-wrapper" >
          <mat-paginator #paginator [length]="totalRecords" class="custom-pagintor" [pageSize]="pageSize"
            (page)="onScrollDown(false)" showFirstLastButtons>
            <!--  (page)="onScrollDown($event, lane?.laneDTO.id, lane['taskList'].length, lane?.totalRecords, lane['taskList'])" -->
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div *ngIf="isLoading" class="overlay">
  <mat-spinner class="spinner" [diameter]="60"></mat-spinner>
</div>