<div class="fixedHeightContainer">
    <div class="d-flex-rw summary-dialog">
       <div class="pb-15 dialog-heading light-grey-bg meeting-list-header" aria-label="heading">
          <h1 *ngIf="data.momListDialog" class="modal-heading-2 text-capitalize mb-0">{{data.user.name}}’s Meetings having MOM </h1>
          <h1 *ngIf="data.conductedMeetingDialog" class="modal-heading-2 text-capitalize mb-0">{{data.user.name}}’s Conducted Meetings </h1>
          <h1 *ngIf="data.upcomingListDialog" class="modal-heading-2 text-capitalize mb-0">{{data.user.name}}’s Upcoming Meetings </h1>
          <button mat-mini-fab class="modal-close-btn" (click)="dialogRef.close();"><i class="ri-close-circle-fill"></i></button>
    </div>
    </div>
    <div fxLayout="row" class="border-top">

        <div fxFlex="30" class="right-border-1 fullHeight">
           <div class="month-strip">
            
            <!-- <mat-form-field class="mog3_matinput meeting_month_picker" appearance="outline">
              <input matInput [matDatepicker]="dp" [formControl]="date" (click)="dp.open()" readonly>
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker"></mat-datepicker>
            </mat-form-field> -->

            <div class="w-p215 mr-10 mb-10">
              <div class="input-group">
                <app-date-picker-custom [selectDefault]="selected" [resetDates]="resetDates" [inputData]="inputData" 
                [placeholder]="'Time Duration'" (filterApplied)="onApplyDates($event)"></app-date-picker-custom>
              </div>
            </div>

          </div>
          
          

          <div class="meeting-tabs scrollbar-1">
            <div *ngFor="let data of dateWiseMap | keyvalue">
              <div class="meeting-date">{{ data.key }}</div>
              <ul class="meeting-list" *ngFor="let listdata of data.value">
                <li [ngClass]="{ active: listdata.isSelected === true }" (click)="onClickNavigation(listdata)">
                  <div class="adhoc-batch hunting mr-10" *ngIf="huntingUserId && sobUserId && listdata.selectedPlatform?.toUpperCase() === 'HUNTING'">{{ listdata.selectedPlatform || 'Hunting/SOB' }}</div>
                  <div class="adhoc-batch sob mr-10" *ngIf="huntingUserId && sobUserId && listdata.selectedPlatform?.toUpperCase() === 'SOB'">{{ listdata.selectedPlatform || 'Hunting/SOB' }}</div>
                  <div class="adhoc-batch hunting mr-10" *ngIf="huntingUserId && !sobUserId">{{ listdata.selectedPlatform || 'Hunting' }}</div>
                  <div class="adhoc-batch hunting mr-10" *ngIf="listdata.selectedPlatform === 'SRM'">{{ listdata.selectedPlatform || 'SRM' }}</div>
                  <div class="adhoc-batch sob mr-10" *ngIf="!huntingUserId && sobUserId">{{ listdata.selectedPlatform || 'SOB' }}</div>
                  <div class="adhoc-batch ad-hock mr-10" *ngIf="listdata.meetingCreatedFromCRM === true">Ad-hock Meeting</div>
                  <h2>{{ listdata.subject }}</h2>
                  <h5>Organiser: {{ listdata.organizer.emailAddress.address }}</h5>
                  <h4>Time: {{ listdata.start.time | date: 'short' }} to {{ listdata.end.time | date: 'short' }}</h4>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
        <div class="border-right-1 overflowContainer" fxFlex="70">
          <div class="meeting-detail-group" *ngIf="!isRefresh" >
            <div *ngIf="meetingDetail">
              <h1>{{ meetingDetail.subject }}</h1>
              <h3><span>Time: {{ meetingDetail.starttime | date: 'short' }} to {{ meetingDetail.endtime | date: 'short' }}</span></h3>
              <div class="meeting-detail"><div></div></div>
              <hr>
               <!-- Meeting Body -->
                <div class="meeting-detail">
                  <div [innerHtml]="meetingDetail.body"></div>
                </div>
              
                <!-- Meeting Category -->
                <p>
                  <strong class="bold-text"> Category : </strong>
                  <span class="grey-text">{{ meetingDetail.meetingCategory }}</span> 
                </p>
              
                <!-- Meeting Account -->
                <p>
                  <strong class="bold-text"> Account : </strong>
                  <span class="grey-text">{{ meetingDetail.accounts }}</span> 
                </p>
              
                <!-- Meeting Plant -->
                <p>
                  <strong class="bold-text"> Plant : </strong>
                  <span class="grey-text">{{ meetingDetail.plants }}</span> 
                </p>
              
              <div class="mom">

                <div class="mom-content" *ngFor="let mom of momList">
                 
                  <p>
                    <strong class="bold-text">MOM - Details : </strong> 
                    <span class="grey-text">{{ mom.createdAt | date: "short" }}</span>
                  </p>
                  <p>
                    <strong class="bold-text">Attendees: </strong> 
                    <span class="grey-text">{{ getEmailFromUserDTOEmail(mom.attendees) }}</span>
                  </p>
                  <p>
                    <strong class="bold-text">Message: </strong> 
                    <span [innerHTML]="mom.message"></span>
                  </p>
                  
                  <p>
                    <strong class="bold-text">Action date: </strong> 
                    <span class="grey-text">{{ mom.nextActionDate | date: "short" }}</span>
                  </p>
                          
                  <!-- MOM Attachment -->
                  <ng-container
                    *ngIf="mom.attachments && mom.attachments.length">
                    <div class="file-upload-section">
                      <div
                        class="file-uploading-status d-flex justify-content-between">
                        <a
                          (click)="
                            downloadSingleDocument(mom.attachments[0].id)">
                          <span class="file-name">
                            {{ mom.attachments[0].name }}
                            <mat-icon class="material-icons">download</mat-icon>
                          </span>
                        </a>
                      </div>
                    </div>
                  </ng-container>
                <!-- </div> -->
                </div>

              </div>
              <!-- <div class="mom">
                <div class="mom-content">
                  <div class="d-flex justify-content-between"><p><strong class="bold-text">MOM - </strong><span class="grey-text">{{ fetchedMOMCreationdate | date: 'short' }}</span></p></div>
                  <p *ngIf="selectedPlatform === 'SOB'"><strong class="bold-text">Plant: </strong><span class="grey-text">{{ fetchedMOMPlantsString }}</span></p>
                  <p *ngIf="selectedPlatform === 'Hunting'"><strong class="bold-text">Account: </strong><span class="grey-text">{{ fetchedMOMAccountsString }}</span></p>
                  <p *ngIf="selectedPlatform === 'SRM'"><strong class="bold-text">Supplier: </strong><span class="grey-text">{{ fetchedMOMSuppliersString }}</span></p>
                  <p><strong class="bold-text">Value delivery: </strong><span class="grey-text">{{ fetchedMOMValue }}</span></p>
                  <div [innerHTML]="fetchedMOMContent"></div>
                  <p><strong class="bold-text">Action date: </strong><span class="grey-text">{{ fetchedMOMNextActiondate | date: 'short' }}</span></p>
                  <ng-container *ngIf="fetchedMOMAttachement && fetchedMOMAttachement.length">
                    <div class="file-upload-section">
                      <div class="file-uploading-status d-flex justify-content-between">
                        <a (click)="downloadSingleDocument(fetchedMOMAttachement[0].id)">
                          <span class="file-name">{{ fetchedMOMAttachement[0].name }}<mat-icon class="material-icons">download</mat-icon></span>
                        </a>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div> -->
            </div>
            
          </div>
          <div class="no-meeting-found-full" *ngIf="dateWiseMap.size < 1 && data.momListDialog">No MoM Present, please change date to see MoM result</div>
          <div class="no-meeting-found-full" *ngIf="dateWiseMap.size < 1 && !data.momListDialog">No Meeting Present, please change date to see Meeting result</div>
    
        </div>
        
        
      </div>
    </div>