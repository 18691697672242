export const LabelConstants = {
    tagTypeLabel: 'Tag Type',
    KAMLabel: 'KAM',
    DesignatedASMLabel: 'Designated ASM',
    accountLabel: 'Account',
    businessVerticalLabel: 'Business Vertical',
    industryLabel: 'Industry',
    industryLabelPlant: 'Applicabel Industry',
    teamsLabel: 'Region',
    huntersLabel:'Hunters',
    dealTypeLabel: 'Deal Type',
    L1CategoriesLabel: 'L1 Categories',
    stagesLabel:'Stages',
    supplierTypeLabel:'Supplier Type',
    brandsLabel:'Brands',
    locationLabel:'Location',
    L2CategoryLabel:'L2 Categories',
    MoglixPOCLabel:'Moglix POC',
    MALabel:'M & A',
    TargetPanelLabel:'Target Panel',
    LeadSourceLabel:'Lead Source',
    SRMLabel:'Supplier',
    attendeeLabel:'Attendees',
    VedantaLabel:"Vedanta"
}