import { E } from "@angular/cdk/keycodes";
import { ElementRef, HostListener, Output, ViewChild } from "@angular/core";

import {
  Component, Input, OnInit, SimpleChanges, ChangeDetectorRef, OnChanges
  , ChangeDetectionStrategy, EventEmitter,
} from '@angular/core';

import { Router, ActivatedRoute } from "@angular/router";
import { Constants, generateQuery } from "src/app/Constants";
import { SettingService } from 'src/app/settings/setting.service';
import { KanbanService } from "../../../kanban.service";
import { MatOption, MatSelect, MatSelectChange } from "@angular/material";
import { FormControl } from "@angular/forms";
import { LabelConstants } from "src/app/LabelConstants";
import { DropdownConstants } from "src/utils/DropdownConstants";


@Component({
  selector: 'app-chart-filters',
  templateUrl: './chart-filters.component.html',
  styleUrls: ['./chart-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChartFiltersComponent implements OnChanges {
  @Input() inputData;
  @Output() filterApplied = new EventEmitter<string>();
  teams: any;
  teamsSearch: any;
  selectTeams = [];
  resetDates: boolean = false;
  selectedUsers = [];
  userList: any;
  userListSearch: any;
  isDeals: any = false;
  isAccount: any = false;
  isContact: any = false;
  isSOBDeals: any = false;
  isSOBAccount: any = false;
  isSOBContact: any = false;
  isSOBOpportunity: any = false;
  isSRMSupplier: any = false;
  selectedBusinessVertical = [];
  selectedDealTypes = [];
  selectedL1Categories = [];
  selectedPoTypes = [];
  closingDateQuery: any;
  createdDateQuery: any;
  industryQuery: any;
  searchText: any;
  showListing: any;
  onSearchChange: any;
  submitSearch: any;
  accountQuery: any;
  plantQuery: any;
  searchByNameQuery: any;
  selectedLane: any;
  routeFilter: any;
  localCache: any = localStorage;
  selectedStages = [];
  searchControlOwner = '';
  searchControlRegion = '';
  searchControlIndustry = '';
  searchControlTagType = '';
  searchControlBusinessVertical = '';
  searchControlDealType = '';
  searchControlL1Categories = '';
  laneTaskConfig: any;
  boardIdForSobOpportunity = Constants.boardIdForSobOpportunity;
  boardIdForSobAccount = Constants.boardIdForSobAccount;
  boardIdForCRMAccount = Constants.boardIdForCRMAccount;
  boardIdForSRMSupplier = Constants.boardIdForSrmSupplier;
  businessIdForSob = Constants.businessIdForSOB;
  businessIdForSrm = Constants.businessIdForSRM;
  defaultStages = [
    { name: "C0: Connect Established", id: Constants.C0 },
    { name: "C1: Met Decision Maker", id: Constants.C1 },
    { name: "C2: Data Received", id: Constants.C2 },
    { name: "C3: Negotiation", id: Constants.C3 },
    { name: "C4: MSA Signed", id: Constants.C4 },
    { name: "PO Received/ ADP", id: Constants.poRecievedLaneId },
    { name: "Cold Deals", id: Constants.coldDealLaneId },
    { name: "Lost Deals", id: Constants.lostDealLaneId },
    { name: "KAM HandOver", id: Constants.kamHandoverLaneId },
    { name: "W1 Received", id: "W1 Received" }
  ];
  defaultOpportunityStages = [
    { "name": "S0: Opportunity Identified", "id": Constants.S0 },
    { "name": "S1: Initial Contact made", "id": Constants.S1 },
    { "name": "S2: RFQ/RFP/LPP Data Received", "id": Constants.S2 },
    { "name": "S3: Quote/ Proposal sent", "id": Constants.S3 },
    { "name": "S4: Negotiation ongoing", "id": Constants.S4 },
    { "name": "Deal Won", "id": Constants.dealWon },
    { "name": "Cold Deal", "id": Constants.coldDeal },
    { "name": "Lost Deal", "id": Constants.lostDeal }];

  defaultSRMStages = [
    { name: "S0: Supplier Identify", id: Constants.SRM_S0 },
    { name: "S1: Initial Contract Made", id: Constants.SRM_S1 },
    { name: "S2: RFQ, ARC, TOD recieved", id: Constants.SRM_S2 },
    { name: "S3: PO recieved", id: Constants.SRM_S3 },
    { name: "S4: ARC, TOD signed", id: Constants.SRM_S4 },
  ]

  defaultSRMStagesSearch = this.defaultSRMStages;

  defaultBusinessVertical = DropdownConstants.BusinessFilter;
  defaultBusinessVerticalSearch = DropdownConstants.BusinessFilter;
  defaultDealTypes = DropdownConstants.DealTypeFilter;
  defaultDealTypesOpportunity = DropdownConstants.OpportunityTypeFilter;
  defaultDealTypesOpportunitySearch = DropdownConstants.OpportunityTypeFilter
  defaultIndustries = DropdownConstants.IndustryFilterVedanta;
  defaultIndustriesSearch = DropdownConstants.IndustryFilterVedanta;
  defaultTaygeType = DropdownConstants.TagFilter;
  defaultTagTypeSearch = DropdownConstants.TagFilter;
  defaultL1CategoriesList: any = [];

  defaultPoTypes = [
    "Repeat RFQ",
    "One Time RFQ",
    "ARC"
  ];


  selectedIndustries = [];
  selectedTagType = [];
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8c3ade7bc466d1c780d",
    "nameField": false
  };

  accountConfigOpportunity = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForSobAccount,
    "nameField": false
  };

  accountConfigVedanta = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForVedantaAccount,
    "nameField": false
  };

  plantConfigOpportunity = {
    "key": "laneTask",
    "field": "plant-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Plant Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForSobPlant,
    "nameField": false
  };

  searchByNameConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8ccade7bc466d1c780e",
    "nameField": false,
    "isClear": false
  };
  sobAccountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForSobAccount,
    "nameField": false
  };
  crmBusinessId = Constants.businessIdForCRM;
  sobBusinessId = Constants.businessIdForSOB;

  selectedBusinessId: any = this.crmBusinessId;
  mywatchlist: any = false;

  selectedAccountIds: string[] = [];
  customSelectWithMultipleAccounts: any[] = [];
  pageIndex = -1;
  pageSize = 10;
  pageFrom = 0;
  accountIdsList: any = [];
  accountIdsListSearch: any = [];
  @ViewChild('sl') s1: MatSelect;

  searchControl = new FormControl();
  searchResults: any[] = [];
  loading = false; // Indicates if a request is in progress
  tagTypeLabel: string = LabelConstants.tagTypeLabel;
  KAMLabel: string = LabelConstants.KAMLabel;
  designatedASMLabel: string = LabelConstants.DesignatedASMLabel;
  accountLabel: string = LabelConstants.accountLabel;
  businessVerticalLabel: string = LabelConstants.businessVerticalLabel;
  industryLabel: string = LabelConstants.industryLabel;
  industryLabelPlant: string = LabelConstants.industryLabelPlant;
  teamsLabel: string = LabelConstants.teamsLabel;
  huntersLabel: string = LabelConstants.huntersLabel;
  dealTypeLabel: string = LabelConstants.dealTypeLabel;
  L1CategoriesLabel: string = LabelConstants.L1CategoriesLabel;
  stagesLabel: string = LabelConstants.stagesLabel;
  supplierTypeLabel: string = LabelConstants.supplierTypeLabel;
  brandsLabel: string = LabelConstants.brandsLabel;
  locationLabel: string = LabelConstants.locationLabel;
  L2CategoryLabel: string = LabelConstants.L2CategoryLabel;
  MoglixPOCLabel: string = LabelConstants.MoglixPOCLabel;
  MALabel: string = LabelConstants.MALabel;
  TargetPanelLabel: String = LabelConstants.TargetPanelLabel;
  LeadSourceLabel: String = LabelConstants.LeadSourceLabel;

  isTagTypeSelected: boolean = false;
  searchControlAccount: "";
  inputAutoField = {
    'config': this.searchByNameConfig,
    'isFilter': true
  }
  @ViewChild('customSelectRef') customSelectRef: any;
  @ViewChild('autocompleteComponent') autocompleteComponent: any;
  @ViewChild('autoComComponent') autoComComponent: any;
  @ViewChild('cusComComplete') cusComComplete: any;
  defaultL1CategoriesListSearch: any[] = [];

  defaultSupplierTypeList: any[] = [];
  defaultSupplierTypeListSearch: any[] = [];
  selectedSupplierType: any[] = [];
  searchControlSupplierType = '';

  defaultBrandList: any[] = [];
  defaultBrandListSearch: any[] = [];
  selectedBrand = [];
  searchControlBrand = '';

  defaultLocationList: any[] = [];
  defaultLocationListSearch: any[] = [];
  selectedLocation = [];
  searchControlLocation = '';

  defaultL2CategoriesList: any[] = [];
  defaultL2CategoriesListSearch: any[] = [];
  selectedL2Categories = [];
  searchControlL2Categories = '';

  selectedSRMStages = [];

  defaultMoglixPOCList: any[] = [];
  defaultMoglixPOCListSearch: any[] = [];
  selectedMoglixPOC = [];
  searchControlMoglixPOC = '';

  defaultMAList: any[] = [];
  defaultMAListSearch: any[] = [];
  selectedMA = [];
  searchControlMA = '';

  defaultTargetpanelList: any[] = [];
  defaultTargetpanelListSearch: any[] = [];
  selectedTargetPanel = [];
  searchControlTargetpanel = '';

  defaultLeadSourceList = DropdownConstants.LeadSourceList;
  leadSourceAvailable: any[] = this.defaultLeadSourceList;
  selectedLeadSource = [];
  searchControlLeadSource = '';

  defaultCompanyTypeList = DropdownConstants.CompanyTypeVedanta;
  companyTypeListAvailable: any[] = this.defaultCompanyTypeList;
  selectedCompanyType = [];
  searchControlCompanyType = '';

  sobAccountBoardId: string = '66c8384e8535f255a8b12efd';
  sobPlantBoardId: string = '6494a60df92a8255c71e39b5';
  sobContactBoardId: string = '64934c1b7dc1e549e1262268';

  isSobVedantaAccount: boolean = false;
  isSobVedantaPlant: boolean = false;
  isSobVedantaContact: boolean = false;

  constructor(public cRef: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, public settingService: SettingService, public _kService: KanbanService) {

  }

  ngOnInit() {
    this.loadL1Categories();
  }


  ngAfterViewInit() {
    this.localCache = localStorage;
    setTimeout(() => {
      this.cRef.detectChanges(); // Manually trigger change detection
    }, 0);
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('ngon change in chart filter changes....', changes)
    this.loadTeams();
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.selectedBusinessId != null &&
      changes.inputData.previousValue != null && changes.inputData.currentValue.selectedBusinessId != changes.inputData.previousValue.selectedBusinessId) {
      this.isAccount = false;
      this.isContact = false;
      this.isDeals = false;
      this.isSOBAccount = false;
      this.isSOBContact = false;
      this.isSOBDeals = false;
      this.isSOBOpportunity = false;
      this.clearAllonBoardOrBusinessSwitch();
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.boardId != null &&
      changes.inputData.previousValue != null && changes.inputData.currentValue.boardId != changes.inputData.previousValue.boardId) {
      this.isAccount = false;
      this.isContact = false;
      this.isDeals = false;
      this.isSOBAccount = false;
      this.isSOBContact = false;
      this.isSOBDeals = false;
      this.isSOBOpportunity = false;
      this.isSRMSupplier = false;
      this.clearAllonBoardOrBusinessSwitch();
    }
    else if (!changes.inputData.previousValue) {
      this.isAccount = false;
      this.isContact = false;
      this.isDeals = false;
      this.isSOBAccount = false;
      this.isSOBContact = false;
      this.isSOBDeals = false;
      this.isSOBOpportunity = false;
      this.isSRMSupplier = false;
      //this.clearAllonBoardOrBusinessSwitch();

    }
    if (changes.inputData.currentValue != null && changes.inputData.currentValue.selectedBusinessId != null) {
      this.selectedBusinessId = changes.inputData.currentValue.selectedBusinessId;
    }
    if (this.localCache == null) {
      this.localCache = localStorage;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.teams) {
      this.teams = changes.inputData.currentValue.teams;
      this.teamsSearch = this.teams;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.users) {
      this.userList = changes.inputData.currentValue.users;
      this.userListSearch = this.userList;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.selectedLane != null) {
      this.selectedLane = changes.inputData.currentValue.selectedLane;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && this.selectedBusinessId === Constants.businessIdForCRM) {
      console.log("changes.inputData.currentValue.boardId crm", changes.inputData.currentValue.boardId)
      if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.boardId != null) {
        if (changes.inputData.currentValue.boardId == Constants.boardIdForCRMAccount) {
          this.isAccount = true;
          this.isDeals = false;
          this.isContact = false;
        }
        else if (changes.inputData.currentValue.boardId == Constants.boardIdForCRMContacts) {
          this.isContact = true;
          this.isDeals = false;
          this.isAccount = false;
          this.isSOBOpportunity = false;
        }

        else if (changes.inputData.currentValue.boardId === this.boardIdForSobOpportunity) {
          this.isSOBOpportunity = true;
        }
        else if (changes.inputData.currentValue.boardId === Constants.boardIdForCRMDeals) {
          this.isDeals = true;
          this.isContact = false;
          this.isAccount = false;
          this.isSOBOpportunity = false;
        }
      } else {
        return;
      }
    }
    if (changes.inputData != null && changes.inputData.currentValue != null) {
      console.log("changes.inputData.currentValue.boardId sob", changes.inputData.currentValue.boardId)
      if (changes.inputData.currentValue.boardId != null) {
        if (changes.inputData.currentValue.boardId === this.boardIdForSobOpportunity) {
          this.isSOBOpportunity = true;
        }
        if (changes.inputData.currentValue.boardId === Constants.boardIdForSobPlant) {
          this.isSOBDeals = false;
          this.isSOBContact = false;
          this.isSOBAccount = false;
        }
        if (changes.inputData.currentValue.boardId == Constants.boardIdForSobContacts) {
          this.isSOBContact = true;
          this.isSOBDeals = false;
          this.isSOBAccount = false;

        }
        if (changes.inputData.currentValue.boardId == Constants.boardIdForSobAccount) {
          this.isSOBAccount = true;
          this.isSOBDeals = false;
          this.isSOBContact = false;
        }

        if (changes.inputData.currentValue.boardId == this.sobAccountBoardId) {
          this.isSobVedantaAccount = true;
          this.isSobVedantaPlant = false;
          this.isSOBAccount = false;
          this.isSobVedantaContact = false;
        }
        if (changes.inputData.currentValue.boardId == this.sobPlantBoardId) {
          this.isSobVedantaAccount = false;
          this.isSobVedantaPlant = true;
          this.isSobVedantaContact = false;
        }
        if (changes.inputData.currentValue.boardId == this.sobContactBoardId) {
          this.isSobVedantaAccount = false;
          this.isSobVedantaPlant = false;
          this.isSOBContact = false;
          this.isSobVedantaContact = true;
        }
      }
      else {
        return;
      }
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && this.selectedBusinessId === this.businessIdForSrm) {
      if (changes.inputData.currentValue.boardId != null) {
        if (changes.inputData.currentValue.boardId === this.boardIdForSRMSupplier) {
          this.isSRMSupplier = true;
          this.loadL1Categories();
        }
      }
      else {
        return;
      }
    }
    console.log('ngonchange selected board id ', changes.inputData.currentValue.boardId);
    this.searchByNameConfig.relationKey = changes.inputData.currentValue.boardId;
    this.searchByNameConfig.displayName = changes.inputData.currentValue.selectedBoardName;
    this.inputAutoField = {
      'config': this.searchByNameConfig,
      'isFilter': true
    }
    this.inputData = changes.inputData;
    let cachedFilter = this.localCache.getItem('appliedFilters');

    cachedFilter = JSON.parse(cachedFilter);
    if (cachedFilter) {
      if (cachedFilter['selectedUsers'] != null) {
        this.selectedUsers = cachedFilter['selectedUsers'];
      }
      if (cachedFilter['selectedStages'] != null && this.isDeals) {
        this.selectedStages = cachedFilter['selectedStages'];
      }
      if (cachedFilter['selectedStages'] != null && this.isSRMSupplier) {
        const cachedStages = cachedFilter['selectedStages'];
        this.selectedSRMStages = this.defaultSRMStages.filter(stage => cachedStages.includes(stage.id));
      }
      if (cachedFilter['selectedBS'] != null && this.isDeals) {
        this.selectedBusinessVertical = cachedFilter['selectedBS'];
      }
      if (cachedFilter['selectedDealTypes'] != null && (this.isDeals || this.isSOBOpportunity)) {
        this.selectedDealTypes = cachedFilter['selectedDealTypes'];
      }
      if (cachedFilter['selectedL1Categories'] != null && (this.isSOBOpportunity)) {
        this.selectedL1Categories = cachedFilter['selectedL1Categories'];
      }
      if (cachedFilter['selectedPoTypes'] != null && this.isSOBOpportunity) {
        this.selectedPoTypes = cachedFilter['selectedPoTypes'];
      }
      if (cachedFilter['selectedIndustries'] != null && this.isAccount) {
        this.selectedIndustries = cachedFilter['selectedIndustries'];
      }
      if (cachedFilter['selectedTagType'] != null && this.isAccount) {
        this.selectedTagType = cachedFilter['selectedTagType'];
      }
      if (cachedFilter['accountConfig'] != null) {
        this.accountConfig = cachedFilter['accountConfig'];
        this.accountQuery = cachedFilter['accountQuery'];
      }
      if (cachedFilter['plantConfig'] != null) {
        this.plantConfigOpportunity = cachedFilter['plantConfig'];
        this.plantQuery = cachedFilter['plantQuery'];
      }
      if (cachedFilter['selectTeams'] != null && this.isDeals) {
        this.selectTeams = cachedFilter['selectTeams'];
      }
      if (cachedFilter['selectedCreatedDate'] != null && (!this.isSRMSupplier)) {
        this.createdDateQuery = cachedFilter["selectedCreatedDate"];
      }
      if (cachedFilter['selectedClosingDate'] != null && (this.isDeals || this.isSOBOpportunity)) {
        this.closingDateQuery = cachedFilter["selectedClosingDate"];
      }
      if (cachedFilter['mywatchlist'] != null) {
        this.mywatchlist = cachedFilter['mywatchlist'];
      }
      if (cachedFilter.selectedBrand) {
        this.selectedBrand = cachedFilter.selectedBrand;
      } if (cachedFilter.selectedL1Categories) {
        this.selectedL1Categories = cachedFilter.selectedL1Categories;
      } if (cachedFilter.selectedL2Categories) {
        this.selectedL2Categories = cachedFilter.selectedL2Categories
      } if (cachedFilter.selectedLocation) {
        this.selectedLocation = cachedFilter.selectedLocation
      } if (cachedFilter.selectisSobVedantaAccountedMA) {
        this.selectedMA = cachedFilter.selectedMA
      } if (cachedFilter.selectedSupplierType) {
        this.selectedSupplierType = cachedFilter.selectedSupplierType
      } if (cachedFilter.selectedTargetPanel) {
        this.selectedTargetPanel = cachedFilter.selectedTargetPanel
      }
      this.onApply();
    }
    else { this.onApply(); }

    console.log('is deals ', this.isDeals);
    console.log('is account ', this.isAccount);
    console.log('is contacts ', this.isContact);
    console.log('is sobdeals ', this.isSOBDeals);
    console.log('is sobaccount ', this.isSOBAccount);
    console.log('is sobcontacts ', this.isSOBContact);
    console.log('is sobOpportunity ', this.isSOBOpportunity);
    console.log('is srmSupplier ', this.isSRMSupplier);
    console.log("is isSobVedantaAccount", this.isSobVedantaAccount);
    console.log("is isSobVedantaContact", this.isSobVedantaContact);
    console.log("is isSobVedantaPlant", this.isSobVedantaPlant);
    
  }

  filterOptions(event) {
    this.userListSearch = this.userList;
    const filterValue = event.toLowerCase();
    this.userListSearch = this.userList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }


  filterOptionsAccount(event) {
    this.accountIdsListSearch = this.accountIdsList;
    const filterValue = event.target.value.toLowerCase();
    this.accountIdsListSearch = this.accountIdsList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  filterOptionsTeams(event) {
    this.teamsSearch = this.teams;
    const filterValue = event.toLowerCase();
    this.teamsSearch = this.teams.filter(option => (option.name).toLowerCase().includes(filterValue));
    console.log('filterOptionsTeams', this.teamsSearch)
  }

  filterOptionsIndustry(event) {
    this.defaultIndustriesSearch = this.defaultIndustries;
    const filterValue = event.toLowerCase();
    this.defaultIndustriesSearch = this.defaultIndustries.filter(option => (option).toLowerCase().includes(filterValue));
  }

  filterOptionsLeadSource(event) {
    this.leadSourceAvailable = this.defaultLeadSourceList.filter(option => (option).toLowerCase().includes(event.toLowerCase()));
  }

  filterOptionsCompanyType(event) {
    this.companyTypeListAvailable = this.defaultCompanyTypeList.filter(option => (option).toLowerCase().includes(event.toLowerCase()));
  }

  filterOptionsTagType(event) {
    this.defaultTagTypeSearch = this.defaultTaygeType;
    const filterValue = event.target.value.toLowerCase();
    this.defaultTagTypeSearch = this.defaultTaygeType.filter(option => (option).toLowerCase().includes(filterValue));
  }

  filterOptionsVertical(event) {
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical;
    const filterValue = event.toLowerCase();
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical.filter(option => (option).toLowerCase().includes(filterValue));
  }

  filterOptionsDealType(event) {
    this.defaultDealTypesOpportunitySearch = this.defaultDealTypesOpportunity;
    const filterValue = event.toLowerCase();
    this.defaultDealTypesOpportunitySearch = this.defaultDealTypesOpportunity.filter(option => (option).toLowerCase().includes(filterValue));
  }

  filterOptionsL1Categories(event) {
    this.defaultL1CategoriesListSearch = this.defaultL1CategoriesList;
    const filterValue = event.toLowerCase();
    this.defaultL1CategoriesListSearch = this.defaultL1CategoriesList.filter(option => (option).toLowerCase().includes(filterValue));
  }

  onApplyDates($event) {
    this.resetDates = false;
    // if ($event['dateFilterQuery'] && $event['dateFilterQuery']['query'] && (this.isDeals || this.isSOBOpportunity)) {
    //   this.closingDateQuery = $event['dateFilterQuery']['query']['nestedWhere'];
    // } else {
    //   this.closingDateQuery = null;
    // }
    // if ($event['createdDateQuery'] && $event['createdDateQuery']['query']) {
    //   this.createdDateQuery = $event['createdDateQuery']['query']['where'];
    // } else {
    //   this.createdDateQuery = null;
    // }
    const createdDate = localStorage.getItem("createdDateFilter");
    const closingDate = localStorage.getItem("closingDateFilter");
    let createdDateObject;
    let closingDateObject;
    if (createdDate) {
      createdDateObject = JSON.parse(createdDate);
    }
    if (closingDate) {
      closingDateObject = JSON.parse(closingDate);
    }
    let query = {
      "query": {
      }
    };
    if (closingDate) {
      query['query']['nestedWhere'] = {};
      query['query']['nestedWhere']["data.closingdate"] = {
        type: "range",
        start_value: closingDateObject['startDate'],
        end_value: closingDateObject['endDate']
      };
      this.closingDateQuery = query.query['nestedWhere'];
    } else {
      this.closingDateQuery = null;
    }
    if (createdDate) {
      query['query']['where'] = {};
      query['query']['where']['createdAt'] = {
        field: "createdAt",
        type: "range",
        start_value: createdDateObject['startDate'],
        end_value: createdDateObject['endDate']
      }
      this.createdDateQuery = query.query['where'];
    } else {
      this.createdDateQuery = null;
    }
    this.onApply();
  }
  onRelationFilter(config) {
    console.log('onRelationFilter in chart filter config', config)
    if (!config || !config.value || config.value.trim() == "") {
      this.accountQuery = null;
    } else {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["account_query"] = {};
      query['query']['nestedWhere']["account_query"]["type"] = "search";
      query['query']['nestedWhere']["account_query"]["operator"] = "or";
      let tQuery = [];

      if (this.isContact || this.isSOBContact || this.isSOBDeals) {
        tQuery.push({
          "field": "data.account.raw",
          "value": config.value
        })
      } else {
        tQuery.push({
          "field": "data.accountname.raw",
          "value": config.value
        })
      }
      query['query']['nestedWhere']["account_query"]["value"] = tQuery;
      this.accountQuery = query;
    }
    console.log('onRelationFilter in chart filter acc query', this.accountQuery)
    this.onApply();
  }

  onSelectionChange(event: MatSelectChange): void {
    const selectedOptions = event.source.selected as MatOption[];
    console.log("selectedOptions", selectedOptions)
    this.selectedAccountIds = selectedOptions.map(option => option.value);
  }

  handleApplySelections(selectedValues: any, field: string = 'accountname.raw'): void {
    console.log('Selected values:', selectedValues);
    this.customSelectWithMultipleAccounts = selectedValues.map(item => item.id);
    if(this.isSobVedantaContact || this.isSobVedantaPlant){
      field = 'account.raw';
    }
    this.onMultipleSelectFilter(this.customSelectWithMultipleAccounts, field);
  }

  onMultipleSelectFilter(ids: string[], field: string): void {
    console.log('onMultipleSelectFilter in chart filter ids', ids)
    if (!ids || ids.length === 0) {
      this.accountQuery = null;
    } else {
      const accountQueries = ids.map(value => {
        return {
          field: `data.${field}`,
          value: value
        };
      });
      let query = {
        "query": {
          nestedWhere: {
            account_query: {
              type: 'search',
              operator: 'or',
              value: accountQueries
            }
          },
        }
      };
      this.accountQuery = query;
    }
    console.log('onMultipleSelectFilter in chart filter acc query', this.accountQuery);
    this.onApply();
  }


  onPlantRelationFilter(config) {
    console.log('onPlantRelationFilter in chart filter config', config)
    if (!config || !config.value || config.value.trim() == "") {
      this.plantQuery = null;
    } else {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["plant_query"] = {};
      query['query']['nestedWhere']["plant_query"]["type"] = "search";
      query['query']['nestedWhere']["plant_query"]["operator"] = "or";
      let tQuery = [];

      if (this.isSOBOpportunity) {
        tQuery.push({
          "field": "data.plantname",
          "value": config.value
        })
      } else {
        tQuery.push({
          "field": "data.plantname",
          "value": config.value
        })
      }
      query['query']['nestedWhere']["plant_query"]["value"] = tQuery;
      this.plantQuery = query;
    }
    console.log('onPlantRelationFilter in chart filter acc query', this.plantQuery)
    this.onApply();
  }

  onCustomSelectApplied(config) {
    console.log('onCustomSelectApplied', config);
  }

  onRelationFilterByName(config) {
    console.log('onRelationFilterByName in chart filter config', config)
    if (!config || !config.value || config.value.trim() == "") {
      this.searchByNameQuery = null;
    } else {
      this.searchByNameConfig.isClear = false;
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']["where"]["name_query"] = {};
      query['query']["where"]["name_query"]["type"] = "search";
      query['query']["where"]["name_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "id",
        "value": config.value,
      })

      query['query']["where"]["name_query"]["value"] = tQuery;
      this.searchByNameQuery = query;
    }
    console.log('onRelationFilterByName in chart filter acc query', this.searchByNameQuery)
    this.onApply();
  }
  onBUSelectionChange(selection: string[]) {
    this.selectedBusinessVertical = selection;
    this.onApply();
  }

  onKAMSelectionChange(selection: any) {
    this.selectedUsers = selection;
    this.onApply();
  }

  onRegionSelectionChange(selection: any) {
    this.selectTeams = selection;
    this.onApply();
  }

  onIndustrySelectionChange(selection: string[]) {
    this.selectedIndustries = selection;
    this.onApply();
  }

  onLeadSourceSelectionChange(selection: string[]) {
    this.selectedLeadSource = selection;
    this.onApply();
  }

  onCompanyTypeSelectionChange(selection: string[]) {
    console.log("Company", selection);
    this.selectedCompanyType = selection;
    this.onApply();
  }

  onDealTypeSelectionChange(selection: string[]) {
    this.selectedDealTypes = selection;
    this.onApply();
  }

  onL1CategoriesSelectionChange(selection: string[]) {
    this.selectedL1Categories = selection;
    this.onApply();
  }


  onTagTypeSelectionChange(selection: string[]) {
    this.isTagTypeSelected = selection.length > 0;
    this.selectedTagType = selection;
    this.onApply();
  }


  onAccountSelectionChange(selection: string[]) {
    console.log("onAccountSelectionChange", this.isTagTypeSelected, selection);
    if (this.isTagTypeSelected && selection.length > 0) {
      this.handleApplySelections(selection);
    }
    else {
      console.log("accountIdsList", this.accountIdsList)
      this.handleApplySelections(this.accountIdsList);
    }
  }

  filterOptionsAccountForDealsListing(event) {
    console.log("filterOptionsAccountForDealsListing", event)
    this.accountIdsListSearch = this.accountIdsList;
    const filterValue = event.toLowerCase();
    this.accountIdsListSearch = this.accountIdsList.filter(option => (option.name).toLowerCase().includes(filterValue));

  }

  filterOptionsTagTypeForDealsListing(event) {
    console.log("filterOptionsTagTypeForDealsListing")
    this.defaultTagTypeSearch = this.defaultTaygeType;
    const filterValue = event.toLowerCase();
    this.defaultTagTypeSearch = this.defaultTaygeType.filter(option => (option).toLowerCase().includes(filterValue));
  }

  onApply = () => {
    console.log('onApply in chart filter acc query', this.accountQuery)
    console.log('onApply in chart filter toggle', this.mywatchlist)
    console.log('onApply in chart filter this.closing date query', this.closingDateQuery)
    if (this.mywatchlist && this.isDeals) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      let accountEmail = '';
      let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
      if (getLocalStorage && getLocalStorage['role']) {
        accountEmail = getLocalStorage['role']['userEmail'];
      }
      query['query']['nestedWhere']["mywatchlist_query"] = {};
      query['query']['nestedWhere']["mywatchlist_query"]["type"] = "search";
      query['query']['nestedWhere']["mywatchlist_query"]["operator"] = "or";
      query['query']['nestedWhere']["mywatchlist_query"]["value"] = [{ "field": "data.watcher.raw", "value": accountEmail }];
      this.inputData['mywatchlist_query'] = query;
    }
    else {
      this.inputData['mywatchlist_query'] = null;
    }
    if (this.closingDateQuery) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere'] = this.closingDateQuery;
      this.inputData['closingDateQuery'] = this.closingDateQuery;
    } else {
      this.inputData['closingDateQuery'] = null;
    }
    if (this.createdDateQuery) {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where'] = this.createdDateQuery;
      this.inputData['createdDateQuery'] = this.createdDateQuery;
    } else {
      this.inputData['createdDateQuery'] = null;
    }
    if (this.selectTeams && this.selectTeams.length && (this.isDeals || this.isSOBDeals || this.isSOBOpportunity || this.isSRMSupplier || this.isSobVedantaAccount || this.isSobVedantaPlant || this.isSobVedantaContact)) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["team_query"] = {};
      query['query']['nestedWhere']["team_query"]["type"] = "search";
      query['query']['nestedWhere']["team_query"]["operator"] = "or";
      let tQuery = [];
      for (let k = 0; k < this.selectTeams.length; k++) {
        tQuery.push({
          "field": "data.regionteam.raw",
          "value": this.selectTeams[k].id
        })
      }
      query['query']['nestedWhere']["team_query"]["value"] = tQuery;
      this.inputData['teamFilter'] = this.selectTeams;
      this.inputData['teamQuery'] = query;
    } else {
      this.inputData['teamFilter'] = [];
      this.inputData['teamQuery'] = null;
    }

    if (this.selectedBusinessVertical && this.selectedBusinessVertical.length && (this.isDeals || this.isSOBOpportunity)) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["bs_query"] = {};
      query['query']['nestedWhere']["bs_query"]["type"] = "search";
      query['query']['nestedWhere']["bs_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedBusinessVertical.length; k++) {
        tQuery.push({
          "field": "data.businessvertical.raw",
          "value": this.selectedBusinessVertical[k]
        })
      }

      query['query']['nestedWhere']["bs_query"]["value"] = tQuery;
      this.inputData['bsQuery'] = query;
    } else {
      this.inputData['bsQuery'] = null;
    }

    if (this.selectedDealTypes && this.selectedDealTypes.length && (this.isDeals || this.isSOBOpportunity)) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["dealtype_query"] = {};
      query['query']['nestedWhere']["dealtype_query"]["type"] = "search";
      query['query']['nestedWhere']["dealtype_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedDealTypes.length; k++) {
        tQuery.push({
          "field": "data.dealtype",
          "value": this.selectedDealTypes[k]
        })
      }

      query['query']['nestedWhere']["dealtype_query"]["value"] = tQuery;
      this.inputData['dealtypeQuery'] = query;
    } else {
      this.inputData['dealtypeQuery'] = null;
    }
    if (this.selectedL1Categories && this.selectedL1Categories.length && this.isSOBOpportunity) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["l1category_query"] = {};
      query['query']['nestedWhere']["l1category_query"]["type"] = "search";
      query['query']['nestedWhere']["l1category_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedL1Categories.length; k++) {
        tQuery.push({
          "field": "data.newcategories",
          "value": this.selectedL1Categories[k]
        });
      }
      query['query']['nestedWhere']["l1category_query"]["value"] = tQuery;
      this.inputData['l1CategoryQuery'] = query;
    } else {
      this.inputData['l1CategoryQuery'] = null;
    }

    if (this.selectedPoTypes && this.selectedPoTypes.length && this.isSOBOpportunity) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["potype_query"] = {};
      query['query']['nestedWhere']["potype_query"]["type"] = "search";
      query['query']['nestedWhere']["potype_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedPoTypes.length; k++) {
        tQuery.push({
          "field": "data.potype",
          "value": this.selectedPoTypes[k]
        })
      }

      query['query']['nestedWhere']["potype_query"]["value"] = tQuery;
      this.inputData['potypeQuery'] = query;
    } else {
      this.inputData['potypeQuery'] = null;
    }

    if (this.selectedIndustries && this.selectedIndustries.length && (this.isAccount || this.isDeals || this.isSOBAccount || this.isSobVedantaAccount || this.isSobVedantaPlant)) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["industryQuery"] = {};
      query['query']['nestedWhere']["industryQuery"]["type"] = "search";
      query['query']['nestedWhere']["industryQuery"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedIndustries.length; k++) {
        tQuery.push({
          "field": "data.industry.raw",
          "value": this.selectedIndustries[k]
        })
      }
      if(this.isSobVedantaPlant){
        tQuery = [];
        for (let k = 0; k < this.selectedIndustries.length; k++) {
          tQuery.push({
            "field": "data.applicabelindustry.raw",
            "value": this.selectedIndustries[k]
          })
        }
      }

      query['query']['nestedWhere']["industryQuery"]["value"] = tQuery;
      this.inputData['industryQuery'] = query;
    } else {
      this.inputData['industryQuery'] = null;
    }

    if (this.selectedTagType && this.selectedTagType.length && (this.isAccount || this.isDeals)) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["tagTypeQuery"] = {};
      query['query']['nestedWhere']["tagTypeQuery"]["type"] = "search";
      query['query']['nestedWhere']["tagTypeQuery"]["operator"] = "or";
      let tQuery = [];
      if (this.isDeals) {
        for (let k = 0; k < this.selectedTagType.length; k++) {
          tQuery.push({
            "field": "data.tagType",
            "value": this.selectedTagType[k]
          })
        }
      } else if (this.isAccount) {
        for (let k = 0; k < this.selectedTagType.length; k++) {
          tQuery.push({
            "field": "data.tagtype",
            "value": this.selectedTagType[k]
          })
        }
      }

      query['query']['nestedWhere']["tagTypeQuery"]["value"] = tQuery;
      this.inputData['tagTypeQuery'] = query;
    } else {
      this.inputData['tagTypeQuery'] = null;
    }

    if (this.selectedUsers && this.selectedUsers.length) {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["user_query"] = {};
      query['query']['where']["user_query"]["type"] = "search";
      query['query']['where']["user_query"]["operator"] = "or";
      let tQuery = [];
      for (let k = 0; k < this.selectedUsers.length; k++) {
        tQuery.push({
          "field": "assigneeId.raw",
          "value": this.selectedUsers[k].id
        })
      }
      query['query']['where']["user_query"]["value"] = tQuery;
      this.inputData['userFilter'] = this.selectedUsers;
      this.inputData['userQuery'] = query;
    } else {
      this.inputData['userFilter'] = [];
      this.inputData['userQuery'] = null;
    }
    if (this.accountQuery) {
      this.inputData['account_query'] = this.accountQuery;
    } else {
      this.inputData['account_query'] = null;
    }
    if (this.plantQuery) {
      this.inputData['plant_query'] = this.plantQuery;
    } else {
      this.inputData['plant_query'] = null;
    }
    if (this.searchByNameQuery) {
      this.inputData['name_query'] = this.searchByNameQuery;
    } else {
      this.inputData['name_query'] = null;
    }

    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
    } else {
      filterCachedObj = {};
    }

    this.inputData['selected_stages'] = this.selectedStages;
    if (this.selectedSRMStages && this.selectedSRMStages.length && this.isSRMSupplier) {
      const selectedSRMStages = this.selectedSRMStages.map(stage => stage.id);
      this.inputData['selected_stages'] = selectedSRMStages;
    }

    this.inputData['resetFilter'] = false;

    // SRM Filters  
    if (this.selectedSupplierType && this.selectedSupplierType.length && this.isSRMSupplier) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["supplier_type_query"] = {};
      query['query']['nestedWhere']["supplier_type_query"]["type"] = "search";
      query['query']['nestedWhere']["supplier_type_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedSupplierType.length; k++) {
        tQuery.push({
          "field": "data.suppliertype",
          "value": this.selectedSupplierType[k]
        });
      }
      query['query']['nestedWhere']["supplier_type_query"]["value"] = tQuery;
      this.inputData['supplierTypeQuery'] = query;
      filterCachedObj["selectedSupplierType"] = this.selectedSupplierType
    } else {
      this.inputData['supplierTypeQuery'] = null;
    }
    if (this.selectedBrand && this.selectedBrand.length && this.isSRMSupplier) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["brand_query"] = {};
      query['query']['nestedWhere']["brand_query"]["type"] = "search";
      query['query']['nestedWhere']["brand_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedBrand.length; k++) {
        tQuery.push({
          "field": "data.brands",
          "value": this.selectedBrand[k]
        });
      }
      query['query']['nestedWhere']["brand_query"]["value"] = tQuery;
      this.inputData['brandQuery'] = query;
      filterCachedObj["selectedBrand"] = this.selectedBrand
    } else {
      this.inputData['brandQuery'] = null;
    }
    if (this.selectedLocation && this.selectedLocation.length && this.isSRMSupplier) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["location_query"] = {};
      query['query']['nestedWhere']["location_query"]["type"] = "search";
      query['query']['nestedWhere']["location_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedLocation.length; k++) {
        tQuery.push({
          "field": "data.locations",
          "value": this.selectedLocation[k]
        });
      }
      query['query']['nestedWhere']["location_query"]["value"] = tQuery;
      this.inputData['locationQuery'] = query;
      filterCachedObj["selectedLocation"] = this.selectedLocation

    } else {
      this.inputData['locationQuery'] = null;
    }
    if (this.selectedL1Categories && this.selectedL1Categories.length && this.isSRMSupplier) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["l1category_query"] = {};
      query['query']['nestedWhere']["l1category_query"]["type"] = "search";
      query['query']['nestedWhere']["l1category_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedL1Categories.length; k++) {
        tQuery.push({
          "field": "data.l1category",
          "value": this.selectedL1Categories[k]
        });
      }
      query['query']['nestedWhere']["l1category_query"]["value"] = tQuery;
      this.inputData['l1CategoryQuery'] = query;
      filterCachedObj["l1CategoryQuery"] = this.selectedL1Categories
    } else {
      this.inputData['l1CategoryQuery'] = null;
    }
    if (this.selectedL2Categories && this.selectedL2Categories.length && this.isSRMSupplier) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["L2Categories_query"] = {};
      query['query']['nestedWhere']["L2Categories_query"]["type"] = "search";
      query['query']['nestedWhere']["L2Categories_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedL2Categories.length; k++) {
        tQuery.push({
          "field": "data.l2category",
          "value": this.selectedL2Categories[k]
        });
      }
      query['query']['nestedWhere']["L2Categories_query"]["value"] = tQuery;
      this.inputData['l2CategoryQuery'] = query;
      filterCachedObj["selectedL2Categories"] = this.selectedL2Categories
    } else {
      this.inputData['l2CategoryQuery'] = null;
    }
    if (this.selectedMoglixPOC && this.selectedMoglixPOC.length && this.isSRMSupplier) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["moglixPOC_query"] = {};
      query['query']['nestedWhere']["moglixPOC_query"]["type"] = "search";
      query['query']['nestedWhere']["moglixPOC_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedMoglixPOC.length; k++) {
        tQuery.push({
          "field": "data.moglixpoc",
          "value": this.selectedMoglixPOC[k]
        });
      }
      query['query']['nestedWhere']["moglixPOC_query"]["value"] = tQuery;
      this.inputData['moglixPOCQuery'] = query;
      filterCachedObj["selectedMoglixPOC"] = this.selectedMoglixPOC
    } else {
      this.inputData['moglixPOCQuery'] = null;
    }
    console.log("selectedMA", this.selectedMA);
    if (this.selectedMA && this.selectedMA.length && this.isSRMSupplier) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["ma_query"] = {};
      query['query']['nestedWhere']["ma_query"]["type"] = "search";
      query['query']['nestedWhere']["ma_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedMA.length; k++) {
        tQuery.push({
          "field": "data.ma",
          "value": this.selectedMA[k]
        });
      }
      query['query']['nestedWhere']["ma_query"]["value"] = tQuery;
      this.inputData['MAQuery'] = query;
      filterCachedObj["selectedMA"] = this.selectedMA
    } else {
      this.inputData['MAQuery'] = null;
    }
    if (this.selectedTargetPanel && this.selectedTargetPanel.length && this.isSRMSupplier) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["targetPanel_query"] = {};
      query['query']['nestedWhere']["targetPanel_query"]["type"] = "search";
      query['query']['nestedWhere']["targetPanel_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedTargetPanel.length; k++) {
        tQuery.push({
          "field": "data.targetpanel",
          "value": this.selectedTargetPanel[k]
        });
      }
      query['query']['nestedWhere']["targetPanel_query"]["value"] = tQuery;
      this.inputData['targetPanelQuery'] = query;
      filterCachedObj["selectedTargetPanel"] = this.selectedTargetPanel

    } else {
      this.inputData['targetPanelQuery'] = null;
    }

    if (this.selectedLeadSource && this.selectedLeadSource.length && (this.isAccount || this.isContact || this.isDeals || this.isSobVedantaAccount)) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["leadSourceQuery"] = {};
      query['query']['nestedWhere']["leadSourceQuery"]["type"] = "search";
      query['query']['nestedWhere']["leadSourceQuery"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedLeadSource.length; k++) {
        tQuery.push({
          "field": "data.leadsource",
          "value": this.selectedLeadSource[k]
        })
      }

      query['query']['nestedWhere']["leadSourceQuery"]["value"] = tQuery;
      this.inputData['leadSourceQuery'] = query;

      this.inputData['leadSourceQuery'] = query;
      filterCachedObj["selectedLeadSource"] = this.selectedLeadSource
    } else {
      this.inputData['leadSourceQuery'] = null;
    }

    if (this.selectedCompanyType && this.selectedCompanyType.length > 0 && (this.isSobVedantaPlant || this.isSobVedantaAccount)) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["companyTypeQuery"] = {};
      query['query']['nestedWhere']["companyTypeQuery"]["type"] = "search";
      query['query']['nestedWhere']["companyTypeQuery"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedCompanyType.length; k++) {
        tQuery.push({
          "field": "data.companytype",
          "value": this.selectedCompanyType[k]
        })
      }

      query['query']['nestedWhere']["companyTypeQuery"]["value"] = tQuery;
      this.inputData['companyTypeQuery'] = query;

      this.inputData['companyTypeQuery'] = query;
      filterCachedObj["selectedCompanyType"] = this.selectedCompanyType
      console.log("Qeeeeyrrttt", query);
    } else {
      this.inputData['companyTypeQuery'] = null;
    }

    this.filterApplied.emit(this.inputData);
    if (this.selectTeams && this.selectTeams.length > 0) {
      filterCachedObj["selectTeams"] = this.selectTeams;
    }
    if (this.selectedUsers && this.selectedUsers.length > 0) {
      filterCachedObj["selectedUsers"] = this.selectedUsers;
    }
    if (this.selectedBusinessVertical && this.selectedBusinessVertical.length > 0) {
      filterCachedObj["selectedBS"] = this.selectedBusinessVertical
    }
    if (this.selectedDealTypes && this.selectedDealTypes.length > 0) {
      filterCachedObj["selectedDealTypes"] = this.selectedDealTypes
    }
    if (this.selectedL1Categories && this.selectedL1Categories.length > 0) {
      filterCachedObj["selectedL1Categories"] = this.selectedL1Categories
    }
    if (this.selectedPoTypes && this.selectedPoTypes.length > 0) {
      filterCachedObj["selectedPoTypes"] = this.selectedPoTypes;
    }
    if (this.createdDateQuery) {
      filterCachedObj["selectedCreatedDate"] = this.createdDateQuery;
    }
    if (this.selectedStages && this.selectedStages.length > 0) {
      filterCachedObj["selectedStages"] = this.selectedStages;
    }
    if (this.selectedSRMStages && this.selectedSRMStages.length > 0) {
      filterCachedObj["selectedStages"] = this.selectedSRMStages.map(stage => stage.id);
    }
    if (this.selectedIndustries && this.selectedIndustries.length > 0) {
      filterCachedObj["selectedIndustries"] = this.selectedIndustries;
    }
    if (this.selectedTagType && this.selectedTagType.length > 0) {
      filterCachedObj["selectedTagType"] = this.selectedTagType;
    }
    if (this.closingDateQuery) {
      filterCachedObj["selectedClosingDate"] = this.closingDateQuery;
    }
    if (this.accountConfig && this.accountConfig['value'] && this.accountConfig.value.trim() != "") {
      filterCachedObj["accountConfig"] = this.accountConfig;
    }
    if (this.accountQuery) {
      filterCachedObj["accountQuery"] = this.accountQuery
    }
    if (this.plantQuery) {
      filterCachedObj["plantQuery"] = this.plantQuery
    }
    if (this.searchByNameQuery) {
      filterCachedObj["nameQuery"] = this.searchByNameQuery
    }

    filterCachedObj['mywatchlist'] = this.mywatchlist;
    this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
  }
  resetStageFilterFromCache() {
    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["selectedStages"] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }
  }
  resetBSInCache() {
    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["selectedBS"] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }
  }
  resetAccountIndustry() {
    this.searchControlIndustry = '';
    this.defaultIndustriesSearch = this.defaultIndustries;
  }
  resetTagType() {
    this.searchControlTagType = '';
    this.defaultTagTypeSearch = this.defaultTaygeType;
  }
  resetDealTypesInCache() {
    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["selectedDealTypes"] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }
  }
  resetL1CategoriesInCache() {
    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["selectedL1Categories"] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }
  }

  resetPoTypesInCache() {
    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["selectedPoTypes"] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }
  }


  resetUserFilterInCache() {
    this.searchControlOwner = '';
    this.userListSearch = this.userList;

    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["selectedUsers"] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }
  }
  resetTeamFilterInCache() {
    this.searchControlRegion = '';
    this.teamsSearch = this.teams;

    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["selectTeams"] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }

  }
  resetFilterInCache(filterKey) {
    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedFilters')) {
      filterCachedObj = this.localCache.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj[filterKey] = [];
      this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }

  }

  clearFilter() {

    this.filterApplied.emit(this.inputData);
  }
  clearAll() {
    this.resetDates = true;
    this.selectedUsers = [];
    this.resetUserFilterInCache();
    this.selectTeams = [];
    this.resetTeamFilterInCache();
    this.selectedStages = [];
    this.resetStageFilterFromCache();
    this.selectedIndustries = [];
    this.selectedTagType = [];
    this.selectedBusinessVertical = [];
    this.resetBSInCache();
    this.resetAccountIndustry();
    this.resetTagType();
    this.selectedDealTypes = [];
    this.resetDealTypesInCache();
    this.selectedPoTypes = [];
    this.resetPoTypesInCache();
    this.closingDateQuery = null;
    this.searchByNameQuery = null;
    this.searchByNameConfig.value = null;
    this.searchByNameConfig.isClear = true;
    this.resetFilterInCache("selectedClosingDate");
    this.createdDateQuery = null;
    this.resetFilterInCache("selectedCreatedDate");
    //this.filterApplied.emit(this.inputData);
    this.accountConfig.value = null;
    //this.onRelationFilter(this.accountConfig);
    this.accountQuery = null;
    this.plantQuery = null;
    this.selectedL1Categories = [];
    this.selectedAccountIds = [];
    this.customSelectWithMultipleAccounts = [];
    this.isTagTypeSelected = false;
    if (this.customSelectRef) {
      this.customSelectRef.clearSelections();
    }
    if (this.autocompleteComponent) {
      this.autocompleteComponent.clearFilter();
    }
    if (this.autoComComponent) {
      this.autoComComponent.clearFilter();
    }
    if (this.cusComComplete) {
      this.cusComComplete.clearSelections();
    }

    this.selectedSupplierType = [];
    this.resetFilterInCache('selectedSupplierType');
    this.selectedBrand = [];
    this.resetFilterInCache('selectedBrand');
    this.selectedLocation = [];
    this.resetFilterInCache('selectedLocation');
    this.selectedL2Categories = [];
    this.resetFilterInCache('selectedL2Categories');
    this.selectedSRMStages = [];
    this.resetFilterInCache('selectedStages');
    this.selectedMoglixPOC = [];
    this.resetFilterInCache('selectedMoglixPOC');
    this.selectedMA = [];
    this.resetFilterInCache('selectedMA');
    this.selectedTargetPanel = [];
    this.resetFilterInCache('selectedTargetPanel');
    this.selectedLeadSource = [];
    this.resetFilterInCache('selectedLeadSource');
    this.selectedCompanyType = [];
    this.resetFilterInCache('selectedCompanyType');
    this.onApply();
  }
  clearAllonBoardOrBusinessSwitch() {
    console.log('clearAllonBoardOrBusinessSwitch start acc query ', this.accountQuery)
    this.resetDates = true;
    this.selectedUsers = [];
    this.resetUserFilterInCache();
    this.selectTeams = [];
    this.resetTeamFilterInCache();
    this.selectedStages = [];
    this.resetStageFilterFromCache();
    this.selectedIndustries = [];
    this.selectedBusinessVertical = [];
    this.resetBSInCache();
    this.resetAccountIndustry();
    this.resetTagType();
    this.selectedDealTypes = [];
    this.resetDealTypesInCache();
    this.closingDateQuery = null;
    this.searchByNameQuery = null;
    this.resetFilterInCache("selectedClosingDate");
    this.createdDateQuery = null;
    this.resetFilterInCache("selectedCreatedDate");
    //this.filterApplied.emit(this.inputData);
    this.accountConfig.value = null;
    //this.onRelationFilter(this.accountConfig);
    this.accountQuery = null;
    this.plantQuery = null;

    this.selectedSupplierType = [];
    this.resetFilterInCache('selectedSupplierType');
    this.selectedBrand = [];
    this.resetFilterInCache('selectedBrand');
    this.selectedLocation = [];
    this.resetFilterInCache('selectedLocation');
    this.selectedL2Categories = [];
    this.resetFilterInCache('selectedL2Categories');
    this.selectedSRMStages = [];
    this.resetFilterInCache('selectedStages');
    this.selectedMoglixPOC = [];
    this.resetFilterInCache('selectedMoglixPOC');
    this.selectedMA = [];
    this.resetFilterInCache('selectedMA');
    this.selectedTargetPanel = [];
    this.resetFilterInCache('selectedTargetPanel');

    if (this.localCache) {
      this.localCache.removeItem('appliedFilters');
    }
    if (localStorage) {
      localStorage.removeItem('appliedFilters');
    }
    console.log('clearAllonBoardOrBusinessSwitch end acc query ', this.accountQuery)
  }
  loadTeams() {
    this.settingService.fetchTeams().subscribe(response => {
      let list = response['result'];
      list.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.teams = list;
      this.teamsSearch = this.teams;
      this.loadUsers();
    });
  }
  loadUsers() {
    let obj = {};
    obj['from'] = 0;
    obj['size'] = 100;
    obj['businessId'] = this.selectedBusinessId;
    //obj['roleId'] = ["63dcc0ff230c7d007058e926"];
    this.settingService.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      let userList = [];
      for (let i = 0; i < list['result'].length; i++) {
        let obj = list['result'][i];
        let tmp = list['result'][i].userTeams;
        let teamList = [];
        if (tmp) {
          for (let k = 0; k < tmp.length; k++) {
            teamList.push(tmp[k].id);
          }
          obj.userTeams = teamList;
          userList.push(obj)
        } else {
          obj.userTeams = [];
          userList.push(obj)
        }
      }
      userList.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.userList = userList;
      this.userListSearch = this.userList;
    });
  }
  onToggle($eveet) {
    console.log('onToggle', $eveet)
    this.mywatchlist = !this.mywatchlist;
    console.log('onToggle mywatchlist', this.mywatchlist)
    this.onApply();
  }
  loadL1Categories() {

    let boardId = this.boardIdForSobOpportunity;
    if (this.isSRMSupplier) {
      boardId = this.boardIdForSRMSupplier;
    }
    this._kService.getLaneBoardConfigById(boardId).subscribe(res => {
      console.log("result: getLaneBoardConfigById ", res);
      let result = res['result'];
      result.sort(function (a, b) {
        return a.index - b.index;
      });
      this.laneTaskConfig = result;
      for (let index = 0; index < this.laneTaskConfig.length; index++) {
        const element = this.laneTaskConfig[index];
        if (element.field === 'new-categories') {
          this.defaultL1CategoriesList = element.dataList;
          this.defaultL1CategoriesListSearch = this.defaultL1CategoriesList.length > 100 ? this.defaultL1CategoriesList.slice(0, 100) : this.defaultL1CategoriesList;
        }

        if (this.isSRMSupplier) {
          if (element.field === 'l1-category') {
            this.defaultL1CategoriesList = element.dataList;
            this.defaultL1CategoriesListSearch = this.defaultL1CategoriesList.length > 100 ? this.defaultL1CategoriesList.slice(0, 100) : this.defaultL1CategoriesList;
          }
          else if (element.field === 'supplier-type') {
            this.defaultSupplierTypeList = element.dataList;
            this.defaultSupplierTypeListSearch = this.defaultSupplierTypeList.length > 100 ? this.defaultSupplierTypeList.slice(0, 100) : this.defaultSupplierTypeList;
          }
          else if (element.field === 'locations') {
            this.defaultLocationList = element.dataList;
            this.defaultLocationListSearch = this.defaultLocationList.length > 100 ? this.defaultLocationList.slice(0, 100) : this.defaultLocationList;
          }
          else if (element.field === 'brands') {
            this.defaultBrandList = element.dataList;
            this.defaultBrandListSearch = this.defaultBrandList.length > 100 ? this.defaultBrandList.slice(0, 100) : this.defaultBrandList;
          }
          else if (element.field === 'l2-category') {
            this.defaultL2CategoriesList = element.dataList;
            this.defaultL2CategoriesListSearch = this.defaultL2CategoriesList.length > 100 ? this.defaultL2CategoriesList.slice(0, 100) : this.defaultL2CategoriesList;
          }
          else if (element.field === 'm-a') {
            this.defaultMAList = element.dataList;
            this.defaultMAListSearch = this.defaultMAList.length > 100 ? this.defaultMAList.slice(0, 100) : this.defaultMAList;
          }
          else if (element.field === 'target-panel') {
            this.defaultTargetpanelList = element.dataList;
            this.defaultTargetpanelListSearch = this.defaultTargetpanelList.length > 100 ? this.defaultTargetpanelList.slice(0, 100) : this.defaultTargetpanelList;
          }
        }
      }
    });
  }
  getFilterOptionsSupplierType(event: string) {
    console.log("supplierType", event);
    this.defaultSupplierTypeListSearch = this.defaultSupplierTypeList.filter(option =>
      (option).toLowerCase().includes(event.toLowerCase()));
  }

  getSelectionChangeSupplierType(selection: string[]) {
    this.selectedSupplierType = selection;
    this.onApply();
  }

  getFilterOptionsLocation(event: string) {
    this.defaultLocationListSearch = this.defaultLocationList.filter(option =>
      (option).toLowerCase().includes(event.toLowerCase()));
    if (this.defaultLocationListSearch.length > 100) {
      this.defaultLocationListSearch = this.defaultLocationListSearch.slice(0, 100);
    }
  }
  getSelectionChangeLocation(selection: string[]) {
    this.selectedLocation = selection;
    this.onApply();
  }

  getFilterOptionsBrand(event: string) {
    this.defaultBrandListSearch = this.defaultBrandList.filter(option =>
      option.toLowerCase().includes(event.toLowerCase())
    );
    if (this.defaultBrandListSearch.length > 100) {
      this.defaultBrandListSearch = this.defaultBrandListSearch.slice(0, 100);
    }
  }
  getSelectionChangeBrand(selection: string[]) {
    this.selectedBrand = selection;
    this.onApply();
  }
  getFilterOptionsStages(event: string) {
    this.defaultSRMStagesSearch = this.defaultSRMStages.filter(option =>
      (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  getSelectionChangeStages(selection: string[]) {
    this.selectedSRMStages = selection;
    this.onApply();
  }
  getFilterOptionsMoglixPOC(event: string) {
    this.defaultMoglixPOCListSearch = this.defaultMoglixPOCList.filter(option =>
      (option).toLowerCase().includes(event.toLowerCase()));
    this.defaultMoglixPOCListSearch = this.defaultMoglixPOCListSearch.slice(0, 100);

  }
  getSelectionChangeMoglixPOC(selection: string[]) {
    this.selectedMoglixPOC = selection;
    this.onApply();
  }
  getFilterOptionsMA(event: string) {
    this.defaultMAListSearch = this.defaultMAList.filter(option =>
      (option).toLowerCase().includes(event.toLowerCase()));
    if (this.defaultMAListSearch.length > 100) {
      this.defaultMAListSearch = this.defaultMAListSearch.slice(0, 100);
    }
  }
  getSelectionChangeMA(selection: string[]) {
    this.selectedMA = selection;
    this.onApply();
  }

  getFilterOptionsTargetPanel(event: string) {
    this.defaultTargetpanelListSearch = this.defaultTargetpanelList.filter(option =>
      (option).toLowerCase().includes(event.toLowerCase()));
    if (this.defaultTargetpanelListSearch.length > 100) {
      this.defaultTargetpanelListSearch = this.defaultTargetpanelListSearch.slice(0, 100);
    }
  }
  getSelectionChangeTargetPanel(selection: string[]) {
    this.selectedTargetPanel = selection;
    this.onApply();
  }
  getFilterOptionsL2Categories(event: string) {
    this.defaultL2CategoriesListSearch = this.defaultL2CategoriesList.filter(option =>
      (option).toLowerCase().includes(event.toLowerCase()));

    if (this.defaultL2CategoriesListSearch.length > 100) {
      this.defaultL2CategoriesListSearch = this.defaultL2CategoriesListSearch.slice(0, 100);
    }
  }
  getSelectionChangeL2Categories(selection: string[]) {
    this.selectedL2Categories = selection;
    this.onApply();
  }
}
