import { Injectable } from '@angular/core';
import { params } from '../param';
import { urls } from '../url';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { environment } from '.././../environments/environment';
import { U } from '@angular/cdk/keycodes';
import { Constants } from '../Constants';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  isLeadsPage: boolean = false;
  crmBusinessId = Constants.businessIdForCRM;
  sobBusinessId = Constants.businessIdForSOB;
  srmBusinessId = Constants.businessIdForSRM
  constructor(private http: HttpClient) {
  }
  readonly BaseUrl = environment.BASEURL;
  readonly escrowUrl = environment.ESCROWURL;

  getUserIdElement: any[] = [];

  getUserIdElementForLoginDetails() {
    return this.getUserIdElement;
  }

  setUserId(element: any) {
    this.getUserIdElement = element;
  }

  isLoading = new Subject<boolean>();
  show() {
    this.isLoading.next(true);
  }
  hide() {
    this.isLoading.next(false);
  }
  offSpinner() {
    const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
    const curtain = <HTMLDivElement>document.getElementById('curtain');
    spinner.style.display = 'none';
    curtain.style.display = 'none';
  }

  emailDetails: any[] = [];
  getEmailCommunication() {
    return this.emailDetails;
  }
  setEmailCommunication(emailDetails: any) {
    this.emailDetails = emailDetails;
  }

  selectAccount = new Subject();

  _countSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');

  _matcountSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');

  _payoutElementSubjectBehv: BehaviorSubject<any> = new BehaviorSubject({});

  _EscrowFieldID: BehaviorSubject<any> = new BehaviorSubject('');

  _btnClickSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');

  _invoicePaymentSubjectBehv: BehaviorSubject<any> = new BehaviorSubject([]);
  _supplierPaymentSubjectBehv: BehaviorSubject<any> = new BehaviorSubject([]);
  _paymentReviewSubjectBehv: BehaviorSubject<any> = new BehaviorSubject([]);
  _isFilterClickSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');
  _reviewTabIndexSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');
  _getTotalPayment: BehaviorSubject<any> = new BehaviorSubject('');
  _ManagementData: BehaviorSubject<any> = new BehaviorSubject({});

  isFilterClicked(event) {
    this._isFilterClickSubjectBehv.next(event);
  }
  storeManagementData(event) {
    this._ManagementData.next(event);
  }

  getBtnClickEvent(event) {
    this._btnClickSubjectBehv.next(event);
  }

  getTotalPaymentTrans(event) {
    this._getTotalPayment.next(event);
  }

  getSupplierPayment(event) {
    this._supplierPaymentSubjectBehv.next(event);
  }
  getPaymentReview(event) {
    this._paymentReviewSubjectBehv.next(event);
  }

  getReviewTabIndex(event) {
    this._reviewTabIndexSubjectBehv.next(event);
  }
  getInvoicePayment(event) {
    this._invoicePaymentSubjectBehv.next(event);
  }

  getStepperCount(event) {
    this._countSubjectBehv.next(event);
  }
  getMatStepperCount(event) {
    this._matcountSubjectBehv.next(event);
  }
  getPayoutElement(event) {
    this._payoutElementSubjectBehv.next(event);
  }

  getEscrowFieldId(event) {
    this._EscrowFieldID.next(event);
  }

  _GSTINSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');

  _GSTINOnBoardData: BehaviorSubject<any> = new BehaviorSubject({});

  setBusinessAcountId(event) {
    this._AccountIdSubjectBehv.next(event);
  }

  _AccountIdSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');


  storeValidGSTIN(event) {
    this._GSTINSubjectBehv.next(event);
  }

  storeOnBoardData(event) {
    this._GSTINOnBoardData.next(event);
  }
  fetchAllRoles(selectedBusinessId) {
    let url = this.BaseUrl + urls.getAllRoles + "?businessId=" + selectedBusinessId;
    return this.http.get(url);
  }
  fetchTeams() {
    let businessId = localStorage.getItem('selectedBusinessId');
    let userID;
    let url = this.BaseUrl + urls.getTeams;
    if (businessId === this.crmBusinessId) {
      userID = localStorage.getItem('crmUserId');
    } else if (businessId === this.sobBusinessId) {
      userID = localStorage.getItem('sobUserId');
    } else if(businessId === this.srmBusinessId) {
      userID = localStorage.getItem('srmUserId')
    }
    if (userID != null && userID != '') {
      url = url + "?userId=" + userID;
    }
    return this.http.get(url);
  }

  fetchSentEmailDetails(userId, email) {
    let url = this.BaseUrl + urls.getSentEmail + "?email=" + email + "&userId=" + userId;
    return this.http.get(url);
  }

  fetchReceivedEmailDetails(userId, email) {
    let url = this.BaseUrl + urls.getReceivedEmail + "?email=" + email + "&userId=" + userId;
    return this.http.get(url);
  }

  fetchMeetings(obj) {
    let url = this.BaseUrl + urls.getAllMeetings;
    return this.http.post(url, obj);
  }
  fetchMOMMeetings(obj) {
    let url = this.BaseUrl + urls.getMOMMeetings;
    return this.http.post(url, obj);
  }
  fetchCampaignList(obj, fromIndex, range) {
    let url = this.BaseUrl + urls.getRecentcampaignsfromDb;
    url = url + "?fromindex=" + fromIndex + "&" + "range=" + range;
    if (obj) {
      url = url + "&" + "campaignName=" + obj;
    } else {
      url = url + "&" + "campaignName=" + " ";
    }
    return this.http.get(url);
  }
  fetchCampaignListV1(obj) {
    let url = this.BaseUrl + urls.getRecentcampaignsfromDbV1;
    return this.http.post(url, obj);
  }
  reloadCampaignList(obj, fromIndex, range) {
    let url = this.BaseUrl + urls.reloadRecentcampaignsfromDb;
    url = url + "?fromindex=" + fromIndex + "&" + "range=" + range;
    if (obj) {
      url = url + "&" + "campaignName=" + obj;
    } else {
      url = url + "&" + "campaignName=" + " ";
    }
    return this.http.get(url);
  }
  reloadCampaignListV1(obj) {
    let url = this.BaseUrl + urls.reloadRecentcampaignsfromDbV1;
    return this.http.post(url, obj);
  }
  fetchAccountWiseCanpaignReport(campaignKey) {
    let url = this.BaseUrl + urls.getAccountWiseReport + "/" + campaignKey;
    return this.http.get(url);
  }
  fetchAccountWiseCampaignContacts(campaignKey, page, pagesize) {
    let url = this.BaseUrl + urls.getAccountWiseContact + "/" + campaignKey + '?pageNo=' + page + '&pageSize=' + pagesize;
    return this.http.get(url);
  }
  reloadAccountWiseCanpaignReport(campaignKey) {
    let url = this.BaseUrl + urls.reloadAccountWiseReport + "/" + campaignKey;
    return this.http.get(url);
  }
  addPlantsToMeetings(obj) {
    let url = this.BaseUrl + urls.addPlantsToMeetings;
    return this.http.post(url, obj);
  }
  addOpportunityToMeetings(obj) {
    let url = this.BaseUrl + urls.addOpportunityToMeetings;
    return this.http.post(url, obj);
  }

  associateEntitiesToMeetings(obj) {
    let url = this.BaseUrl + urls.associateEntitiesToMeetings;
    return this.http.post(url, obj);
  }

  createTeamMeetingByUserId(obj, userId) {
    let url = this.BaseUrl + urls.createTeamMeetings + "?userId=" + userId;
    return this.http.post(url, obj);
  }

  getCalendarForTracker(obj) {
    let url = this.BaseUrl + urls.calendarMeetingTracker + '?page=' + obj.from + '&pageSize=' + obj.size;
    return this.http.post(url, obj);
  }

  getCalendarByUser(obj) {
    let url = this.BaseUrl + urls.calendarMeetingByUser + '?page=' + obj.from + '&pageSize=' + obj.size;
    return this.http.post(url, obj);
  }

  getMeetingListByUser() {
    let url = this.BaseUrl + urls.calendarMeetingIdsByUser;
    return this.http.get(url);
  }
  
  getCalendarByFieldFilter(obj) {
    let url = this.BaseUrl + urls.calendarMeetingByFieldFilter + '?page=0'+'&pageSize=20';
    return this.http.post(url, obj);
  }
  
  getMOMByUser(obj) {
    let url = this.BaseUrl + urls.momMeetingByUser + '?page=' + obj.from + '&pageSize=' + obj.size;
    return this.http.post(url, obj);
  }
  getActionItemByUser(obj) {
    let url = this.BaseUrl + urls.actionItemByUser + '?page=' + obj.from + '&pageSize=' + obj.size;
    return this.http.post(url, obj);
  }



  fetchPlantByUserId(userId) {
    let url = this.BaseUrl + urls.getPlantsByUserId + "?userId=" + userId;
    return this.http.get(url);
  }
  fetchAccountsByUserId(userId) {
    let url = this.BaseUrl + urls.getAccountsByUserId + "?userId=" + userId;
    return this.http.get(url);
  }

  fetchRecordsByBoardId(boardId) {
    let url = this.BaseUrl + urls.getRecordsByBoardId + "?boardId=" + boardId;
    return this.http.get(url);
  }

  fetchAllTeams() {
    let url = this.BaseUrl + urls.getTeams + "?all=true";
    return this.http.get(url);
  }
  fetchAllPermissions() {
    let url = this.BaseUrl + urls.getAllPermissions;
    return this.http.get(url);
  }
  fetchAllPlants() {
    let url = this.BaseUrl + urls.getAllPlants
    return this.http.get(url);
  }
  addUser(obj) {
    let url = this.BaseUrl + urls.adduser
    return this.http.post(url, obj);
  }

  getUserDetails(obj) {
    let url = this.BaseUrl + urls.userDetails + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    if (obj.roleId)
      url = url + '&roleId=' + obj.roleId;
    if (obj.businessId)
      url = url + '&businessId=' + obj.businessId;
    return this.http.get(url);
  }

  getLoginHistoryDetailsByUserId(obj) {
    let url = this.BaseUrl + urls.userLoginHistoryDetails + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    if (obj.userEmail) { url = url + '&userEmail=' + obj.userEmail; }
    if (obj.startDate) { url = url + '&startDate=' + obj.startDate; }
    if (obj.endDate) { url = url + '&endDate=' + obj.endDate; }
    return this.http.get(url);
  }

  getUserDetailsForOwnerDropDown(obj) {
    let businessId = localStorage.getItem('selectedBusinessId');
    let url = this.BaseUrl + urls.userDetailsForOwnerDropDown + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    if (obj['businessId']) {
      businessId = obj['businessId'];
    }
    url = url + '&businessId=' + businessId;
    return this.http.get(url);
  }
  assignTeam(obj) {
    let url = this.BaseUrl + urls.userAssignTeam
    let businessId = localStorage.getItem('selectedBusinessId');
    if (businessId) {
      obj['businessId'] = businessId;
    }
    return this.http.post(url, obj);
  }
  assignHunter(obj) {
    let url = this.BaseUrl + urls.userAssignHunter
    return this.http.post(url, obj);
  }
  assignRoles(obj) {
    let url = this.BaseUrl + urls.userAssignRoles
    return this.http.post(url, obj);
  }

  assignUserActiveInActive(obj) {
    let url = this.BaseUrl + urls.userActiveInActive
    return this.http.post(url, obj);
  }

  assignUserOwnerDropDown(obj) {
    let url = this.BaseUrl + urls.userOwnerDropDown
    return this.http.post(url, obj);
  }

  getStates() {
    let url = this.BaseUrl + urls.getAllStates
    return this.http.get(url);
  }

  addNewPlant(obj) {
    let url = this.BaseUrl + urls.getAllPlants
    return this.http.post(url, obj);
  }

  deleteRole(obj) {
    let url = this.BaseUrl + urls.deleteUserRole + "/" + obj.roleID
    return this.http.delete(url);
  }
  deleteReassignedRole(obj) {
    let url = this.BaseUrl + urls.deleteUserRole + "/" + obj.roleID + '?replacementRoleId=' + obj.replacementRoleId
    return this.http.delete(url);
  }
  createUserRole(obj) {
    let url = this.BaseUrl + urls.createUserRole
    return this.http.post(url, obj);
  }

  bulkUserUploaded(obj) {
    let url = this.BaseUrl + urls.bulkUserAdded
    return this.http.post(url, obj);
  }
  changeUserStatus(obj) {
    let url = this.BaseUrl + urls.userStatusUpdate + '?email=' + obj.email + '&status=' + obj.status + '&roleId=' + obj.customUserRoleId
    return this.http.get(url);
  }
  fetchProfileDetails() {
    let url = this.BaseUrl + urls.getUserProfile
    return this.http.get(url);
  }
  addSubUserDetails(obj) {
    let url = this.BaseUrl + urls.getUserProfile;
    return this.http.post(url, obj);
  }
  deleteSubUserDetails(id) {
    let url = this.BaseUrl + urls.getUserProfile + "/" + id;
    return this.http.delete(url);
  }

  fetchUserListAutosuggest(obj) {
    let url = this.BaseUrl + urls.userAutosuggest + '?searchKey=' + obj.searchKey
    if (obj.businessId) {
      url = url + '&businessId=' + obj.businessId
    }
    return this.http.get(url);
  }

  isRoleAssigned(obj) {
    let url = this.BaseUrl + urls.checkRoleID + "/" + obj.roleID
    return this.http.get(url);

  }

  // allRoles: any;
  // getAllRoles() {
  //   this.fetchAllRoles().subscribe(list => {
  //     console.log(list);
  //     this.allRoles = list['result']

  //   });
  // }


  fetchCompanyDetails(gstin) {
    let url = this.BaseUrl + urls.companyDetails + "/" + gstin
    return this.http.get(url);
  }

  saveCompany(obj) {
    let url = this.BaseUrl + urls.companyDetails
    return this.http.post(url, obj);
  }
  saveDirectorDetails(obj) {
    let url = this.BaseUrl + urls.directorDetails
    return this.http.post(url, obj);
  }
  fetchDirectorDetails(gstin, id?) {
    let url = this.BaseUrl + urls.directorDetails + "/" + gstin
    if (id)
      url = url + "?businessAccountId=" + id;
    return this.http.get(url);
  }
  uploadDocuments(obj, file) {
    let url = this.BaseUrl + urls.uploadDocuments + "/" + obj.gstin + "/" + obj.type;
    if (obj.businessAccountId)
      url = url + "?businessAccountId=" + obj.businessAccountId;
    if (obj.docName)
      url = url + "&docName=" + obj.docName;
    return this.http.post(url, file);
  }
  fetchverifyDetails(gstin) {
    let url = this.BaseUrl + urls.verifyDetails + "/" + gstin
    return this.http.get(url);
  }
  checkGSTINExist(id?) {
    let url = this.BaseUrl + urls.checkOnBoardGSTIN;
    if (id) {
      url = url + "?businessAccountId=" + id;
    }
    return this.http.get(url);
  }

  submitOnBoardDetails(obj) {
    let url = this.BaseUrl + urls.submitDetails + "/" + obj.gstin
    if (obj.businessAccountId)
      url = url + "?businessAccountId=" + obj.businessAccountId;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "&anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.post(url, {});
  }
  uploadDocumentsDownload(obj) {
    let url = this.BaseUrl + urls.downloadDocuments + '?docType=' + obj.docType + '&gstin=' + obj.gstin;
    if (obj.businessAccountId)
      url = url + "&businessAccountId=" + obj.businessAccountId;
    if (obj.docName)
      url = url + "&docName=" + obj.docName;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  onBoardingOn(obj) {
    let url = this.BaseUrl + urls.onBoarding;
    return this.http.put(url, obj);
  }

  verifyInfo(id) {
    let url = this.BaseUrl + urls.verifyInfo + "?businessAccountId=" + id;
    return this.http.put(url, {});
  }

  downloadZip(id) {
    let url = this.BaseUrl + urls.downloadZip + "?businessAccountId=" + id;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  deleteDocuments(id, businessAccID?) {
    let url = this.BaseUrl + urls.deleteDocument + "?documentId=" + id;
    if (businessAccID)
      url = url + "&businessAccountId=" + businessAccID;
    return this.http.put(url, {});
  }

  addToLeads(businessIDs) {
    let url = this.BaseUrl + urls.addToLead + "?isLead=" + true;
    return this.http.put(url, businessIDs);
  }

  getWhatsappContent(obj, element) {
    console.log(element)
    if (element.value == 'EpInvoice') {
      let url = this.BaseUrl + urls.whatsappInvoiceMessageContent
      return this.http.post(url, obj);
    }

    else {
      let url = this.BaseUrl + urls.whatsappMessageContent
      return this.http.post(url, obj);

    }
  }

  fetchWhatsappUserList(id, value, type) {

    let url = '';
    if (type != null) {
      url = '&type=' + type;
    }
    if (value != null) {
      url = url + '&isWhatsapp=' + value
    }
    url = this.BaseUrl + urls.whatsappSubUserList + "?businessAccountId=" + id + url;

    return this.http.get(url);
  }

  fetchUserList(id) {
    let url = this.BaseUrl + urls.getUserList + "?businessAccountId=" + id;
    return this.http.get(url);
  }

  addWhatsappContact(obj) {
    let url = this.BaseUrl + urls.getUserProfile;
    return this.http.post(url, obj);
  }

  setWhatsappNumber(obj) {
    let url = this.BaseUrl + urls.markWhatsappNumber;
    return this.http.put(url, obj);
  }
  sendWhatsappMsg(obj) {
    let url = this.BaseUrl + urls.sendWhatsappMsg;
    return this.http.post(url, obj);
  }
  sendEmailMsg(obj) {
    let url = this.BaseUrl + urls.sendEmailMsg;
    return this.http.post(url, obj);
  }
  checkWhatsappUserExist() {
    let url = this.BaseUrl + urls.checkWhatsapplogged;
    return this.http.get(url);
  }

  getCiData(obj) {
    let url = this.BaseUrl + urls.getCiData + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    return this.http.get(url);
  }
  getCiDetails(obj) {
    let url = this.BaseUrl + urls.getCiData + "/" + obj.id + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    return this.http.get(url);
  }
  invoiceMarkDelayed(obj) {
    let url = this.BaseUrl + urls.EPMarkDelayed;
    return this.http.put(url, obj);
  }
  cancelInvoiceMarked(obj) {
    let url = this.BaseUrl + urls.cancelEPInvoice;
    return this.http.put(url, obj);
  }

  getEscrowPayments(obj) {
    let url = this.BaseUrl + urls.getEscrowPayments;
    return this.http.post(url, obj);
  }

  getEscrowDetailByID(id) {
    let URl = this.BaseUrl + urls.getEscrowDetail + "?escrowId=" + id
    return this.http.get(URl);
  }
  //For Borrower
  getAllStats(isLead) {
    let url = this.BaseUrl + urls.getAllBorrowerStats;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "?anchorMasterAccountId=" + localStorage.getItem('credlixUserID');
    }
    if (isLead) {
      url = url + "?anchorMasterAccountId=" + "6228a749a2cee5063fd7573c";
    }
    return this.http.get(url);
  }

  numDifferentiation(value) {
    let val = Math.abs(value);
    let result = null;
    if (val >= 10000000) {
      result = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      result = (val / 100000).toFixed(2) + ' Lac';
    } else if (val >= 1000) {
      result = (val / 1000).toFixed(2) + ' K';
    }
    return result || val;
  }
  discountPopup(obj) {
    let URl = this.BaseUrl + urls.discountPopup
    return this.http.post(URl, obj)
  }

  getSupplierMetrics(obj) {
    let url = this.BaseUrl + urls.getMetrics + "?businessAccountId=" + obj.businessAccountId + "&timePeriod=" + obj.timePeriod;
    return this.http.get(url);
  }

  //escrow Account
  getEscrowPayouts(obj) {
    let URl = this.BaseUrl + urls.getPayouts

    // if (localStorage.getItem('credlixUserID')) {
    //   URl = URl + "&masterAccountId=" + localStorage.getItem('credlixUserID');
    // }
    return this.http.post(URl, obj);
  }
  uploadAnchorFile(obj) {
    let URl = this.BaseUrl + urls.uploadPayoutFile
    return this.http.post(URl, obj);
  }
  scheduleJobUploadFile(id) {
    let url = this.BaseUrl + urls.uploadedJobSchedule + "?uniqueId=" + id
    return this.http.get(url);

  }
  getInvoicesPayout(obj) {
    //  let fileId= localStorage.getItem('escrowFieldID');
    let URl = this.BaseUrl + urls.invoicesPayout;
    return this.http.post(URl, obj)
  }
  getSupplierPayout(obj) {
    let URl = this.BaseUrl + urls.supplierPayouts + "?fileId=" + obj.escrowId + '&pageNo=' + obj.from + '&pageSize=' + obj.size;
    if (obj.status != undefined) {
      URl = URl + '&status=' + obj.status
    }
    if (obj.id != undefined) {
      URl = URl + '&supplierId=' + obj.id
    }
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "&masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl)
  }
  verifyAndAcceptPayment(obj) {
    let URl = this.BaseUrl + urls.verifyTransactions;
    return this.http.post(URl, obj);
  }

  getFilePaymentTransaction(obj) {
    let URl = this.BaseUrl + urls.fileTransaction;
    return this.http.post(URl, obj)
  }

  getOTPPayment(obj) {
    let URl = this.BaseUrl + urls.getEscrowOTP + "?fileId=" + obj.fileId
    return this.http.get(URl, obj)
  }
  sendEscrowPaymentWithOTP(obj) {
    let URl = this.BaseUrl + urls.sendPaymentOTP;
    return this.http.post(URl, obj);
  }

  unMapPayoutTransactions(obj) {
    let URl = this.BaseUrl + urls.unmapTransaction
    return this.http.post(URl, obj)
  }
  mapPayoutTransactions(obj) {
    let URl = this.BaseUrl + urls.mapTransaction
    return this.http.post(URl, obj)
  }

  reviewPayoutTransaction(id, obj) {
    let URl = this.BaseUrl + urls.reviewPayoutFile + "?fileId=" + id
    return this.http.post(URl, obj)
  }

  getEscrowSystemStats() {
    let URl = this.BaseUrl + urls.escrowSystemStats
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl);
  }

  downloadPayoutFile(id) {
    let URl = this.BaseUrl + urls.downloadUploadedFile + "?fileId=" + id
    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getTransactionEntries(id) {
    let URl = this.BaseUrl + urls.getPayouts + "/" + id
    return this.http.get(URl)
  }

  rejectUploadedFile(obj) {
    let URl = this.BaseUrl + urls.rejectUploadedFile
    return this.http.post(URl, obj)
  }

  downloadPayoutSheet(obj) {
    let URl = this.BaseUrl + urls.downloadPayoutSheet
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' });
  }

  downloadPayoutTransactionSheet(id, reportType, obj) {
    let URl = this.BaseUrl + urls.downloadPayoutTransactionSheet + "?fileId=" + id + "&reportType=" + reportType
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "&masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getPayoutBalance() {
    let URl = this.BaseUrl + urls.payoutBalance
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl)
  }

  downloadBalanceSheet(obj) {
    let URl = this.BaseUrl + urls.balanceSheetDownload
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' })

  }
  downloadSupplierInvoices() {
    let URl = this.BaseUrl + urls.supplierInvoicesSheet
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' })
  }

  downloadUnMappedBalance() {
    let URl = this.BaseUrl + urls.unmappedBalanceSheet
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' })
  }

  manualcreditForm(obj) {
    let URl = this.escrowUrl + urls.manualCreditForm
    return this.http.post(URl, obj)
  }
  getPayoutReport() {
    let URl = this.BaseUrl + urls.payoutReport
    return this.http.get(URl)
  }

  multipleAccount(obj) {
    let URl = this.BaseUrl + urls.multipleAccount
    return this.http.post(URl, obj)
  }
  setBydefaultuser(id) {
    let URl = this.BaseUrl + urls.bydefaultUserRole + "?roleId=" + id
    return this.http.get(URl)
  }
  addGstDetails(obj) {
    let url = this.BaseUrl + urls.getGstPdf + "?gstin=" + obj.gstin;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  getEmailTemplateValue(id) {
    let URl = this.BaseUrl + urls.requestEPtemplateValues + "?notificationId=" + id
    return this.http.get(URl)
  }

  OTPVerificationEP(obj) {
    let URl = this.BaseUrl + urls.requestEPOTPVerify
    return this.http.post(URl, obj)
  }
}
