import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { urls } from '../../url'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AuthService } from 'src/app/auth/auth.service';
import { Constants } from 'src/app/Constants';
@Injectable({
  providedIn: 'root'
})
export class KanbanService {
  readonly BaseUrl;
  crmBusinessId="6492b7cc46b1b677c408d556";
  sobBusinessId="6492cd17a056ce1c2860f874";
  constructor(private http: HttpClient, public authService: AuthService) {
    if(this.authService.isKanbanUser()){
       this.BaseUrl = environment.MOGLIXKANBAN;
    }
    else{
      this.BaseUrl= environment.CREDLIXKANBAN
    }
   }
  readonly BaseCredlixUrl = environment.BASEURL;
  //readonly EximURL = environment.EXIMAUTOSALES;

  ids: any;

  getIds(){
    return this.ids;
  }

  setIds(id: any, laneId: any) {
    let obj = {
      'accountId': id,
      'laneTaskId': laneId
    };
    this.ids = obj;
  }



  getBoardList() {
    let url = this.BaseUrl + urls.boardList+"?businessId=6492b7cc46b1b677c408d556"
    return this.http.get(url);
  }
  getBoardListByBusinessId(businessId) {
    let url;
    if(businessId){
       url = this.BaseUrl + urls.boardList+"?businessId="+businessId
    }else{
       url = this.BaseUrl + urls.boardList
    }
    return this.http.get(url);
  }
  getBoardLane(obj) {
    let url = this.BaseUrl + urls.searchLane
    return this.http.post(url, obj);
  }

  getBoardStats(obj) {
    let businessId = localStorage.getItem('selectedBusinessId');
    let userID;
    let url = this.BaseUrl + urls.laneTaskStats
    if (businessId === this.crmBusinessId) {
      userID = localStorage.getItem('crmUserId');
    } else if (businessId === this.sobBusinessId) {
      userID = localStorage.getItem('sobUserId');
    }
    obj['userId'] = userID;
    console.log("objgetBoardStats: ",obj);
    return this.http.post(url, obj);
  }
  getDashboardStats(obj) {
    let url = this.BaseUrl + urls.laneTaskStats
    return this.http.post(url, obj);
  }
  getLaneWithUserAccess(id, userID, pageSize?, pageFrom?) {
    let url = this.BaseUrl + urls.fetchLaneWithAccess + "/" + id;
    let businessId=localStorage.getItem('selectedBusinessId');
    if (pageSize != null && pageFrom != null) {
      url = url + "?pageNo=" + pageFrom + '&pageSize=' + pageSize
    }
    return this.http.get(url);
  }
  createLaneTask(obj){
    let url = this.BaseUrl + urls.createLanetask
    let businessId=localStorage.getItem('selectedBusinessId');
    let userID;
    if(businessId===this.crmBusinessId){
      userID=localStorage.getItem('crmUserId');
    }else if(businessId===this.sobBusinessId){
      userID=localStorage.getItem('sobUserId');
    }
    if (userID != null && userID != '') {
      obj['createdUserId']=userID;
    }
    return this.http.post(url, obj);
  }
  getLaneTask(obj){
    let url = this.BaseUrl + urls.laneTask
    return this.http.post(url, obj);
  }
  getLaneTaskWithoutAccess(obj){
    let url = this.BaseUrl + urls.laneTaskWithoutAccess
    return this.http.post(url, obj);
  }
  getLaneTaskByUserId(obj){
    let url = this.BaseUrl + urls.laneTask
    return this.http.post(url, obj);
  }
  loadHuntingVelocityData(obj){
    let businessId=localStorage.getItem('selectedBusinessId');
    let userID;
    if(businessId===this.crmBusinessId){
      userID=localStorage.getItem('crmUserId');
    }else if(businessId===this.sobBusinessId){
      userID=localStorage.getItem('sobUserId');
    }
    if (userID != null && userID != '') {
      obj['userId']=userID;
    }
    let url=this.BaseUrl+urls.getHuntingVelocity;
    return this.http.post(url,obj);
  }
  updateWatcherOnDeal(lanetask:any,action:any){
    console.log('updateWatcherOnDeal')
    let businessId=localStorage.getItem('selectedBusinessId');
    let userID;
    let url = this.BaseUrl + urls.updateWatcherOnDeal
    if(businessId===this.crmBusinessId){
      userID=localStorage.getItem('crmUserId');
    }else if(businessId===this.sobBusinessId){
      userID=localStorage.getItem('sobUserId');
    }
    if (userID != null && userID != '') {
      url=url+"?userId="+userID;
    }
    if(lanetask){
      url=url+"&laneTask="+lanetask;
    }
    if(action){
      url=url+"&action="+action;
    }
    console.log('updateWatcherOnDeal url',url)
    return this.http.post(url, {});
  }

  getLaneTaskContactDetails(obj, laneId){
    let businessId=localStorage.getItem('selectedBusinessId');
    let userID;
    if(businessId===this.crmBusinessId){
      userID=localStorage.getItem('crmUserId');
    }else if(businessId===this.sobBusinessId){
      userID=localStorage.getItem('sobUserId');
    }
    if (userID != null && userID != '') {
      obj['userId']=userID;
    }
    let url = this.BaseUrl + urls.laneTaskContactDetails
    if (laneId !== null) {
      url = url + (url.includes('?') ? '&' : '?') + 'laneTaskId=' + laneId;
    }
    return this.http.post(url, obj);
  }
  getLaneTaskUsers(obj,roleName,selectedStatus){

    let url = this.BaseUrl + urls.laneTaskUsers ;
    if(roleName){
      url=url+"?roleName="+roleName
      url=url+"&status="+selectedStatus;
    }else{
      url=url+"?status="+selectedStatus;
    }
    return this.http.post(url, obj);
  }

  getKAMLaneTaskUsers(obj: any, roleName: string[], selectedStatus: string){

    let url = this.BaseUrl + urls.laneTaskUsersKam ;
    if(roleName){
      url=url+"?roleName="+roleName
      url=url+"&status="+selectedStatus;
    }else{
      url=url+"?status="+selectedStatus;
    }
    return this.http.post(url, obj);
  }

  getKAMLaneTaskUsersVedanta(obj: any){
    let url = this.BaseUrl + urls.laneTaskUsersVedanta ;
    return this.http.post(url, obj);
  }

  assignLaneTaskMovement(obj){
    let url = this.BaseUrl + urls.assignLaneTask
    return this.http.post(url, obj);
  }
  removeAssignee(obj){
    let url = this.BaseUrl + urls.removeAssignee + '?laneTaskId=' + obj.laneTaskId + '&assigneeIds=' + obj.assigneeIds
    return this.http.delete(url, obj);
  }
  removeWatchers(obj){
    let url = this.BaseUrl + urls.removeWatchers + '?laneTaskId=' + obj.laneTaskId + '&watcherIds=' + obj.watcherIds
    return this.http.delete(url, obj);
  }

  removeAssignedtags(tagId,id){
    let url = this.BaseUrl + urls.removeTags + '/lane-task/' + id + '/tag/' + tagId
    return this.http.delete(url);
  }
  updateLaneTask(obj) {
    let url = this.BaseUrl + urls.createLanetask
    let businessId=localStorage.getItem('selectedBusinessId');
    let userID;
    if(businessId===this.crmBusinessId){
      userID=localStorage.getItem('crmUserId');
    }else if(businessId===this.sobBusinessId){
      userID=localStorage.getItem('sobUserId');
    }
    if (userID != null && userID != '') {
      obj['createdUserId']=userID;
    }
    return this.http.put(url, obj);
  }

  detailsByLaneId(id){
    let businessId=localStorage.getItem('selectedBusinessId');
    let userID;
    let url = this.BaseUrl + urls.createLanetask + "/" + id
    if(businessId===this.crmBusinessId){
      userID=localStorage.getItem('crmUserId');
    }else if(businessId===this.sobBusinessId){
      userID=localStorage.getItem('sobUserId');
    }
    if (userID != null && userID != '') {
      url=url+"?userId="+userID;
    }
    return this.http.get(url);
  }

  getUserList(obj) {
    let url = this.BaseCredlixUrl + urls.userDetails + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    return this.http.get(url);
  }
  addAssigneeToLaneTask(obj, id) {
    let url = this.BaseUrl + urls.addAssigneToLaneTask + '?laneTaskId=' + id
    return this.http.post(url, obj);
  }
  addWatchersToLaneTask(obj, id) {
    let url = this.BaseUrl + urls.addWatchers + '?laneTaskId=' + id
    return this.http.post(url, obj);
  }
  checkLaneMovementValidation(obj) {
    let url = this.BaseUrl + urls.laneMovementValidate
    return this.http.post(url, obj);
  }

  getAllTags(id) {
    let url = this.BaseUrl + urls.getTags + '?boardId=' + id
    return this.http.get(url);
  }

  getLaneConfigById(id) {
    let url = this.BaseUrl + urls.laneConfig + "/" + id
    return this.http.get(url);
  }
  getLaneBoardConfigById(id) {
    let url = this.BaseUrl + urls.boadLaneConfig + "/" + id
    return this.http.get(url);
  }
  getSubdivisionLaneBoardConfigById(id) {
    let url = this.BaseUrl + urls.boardSubdivisionLaneConfig + "/" + id
    return this.http.get(url);
  }
  // Define a method to make the HTTP request
  updateTableLaneTask(file: File, boardId: string, laneId: string, laneTaskId: string, subLaneId: string, tableName: string) {
  // Prepare request parameters
  const formData = new FormData();
  formData.append('file', file);
  formData.append('boardId', boardId);
  formData.append('laneId', laneId);
  formData.append('laneTaskId', laneTaskId);
  formData.append('subLaneId', subLaneId);
  formData.append('tableName', tableName);

  // Make the HTTP POST request
  const url = this.BaseUrl + urls.tableUploadLaneTask;
  return this.http.post(url, formData);
}
  
  tableUploadLaneTask
  getLaneBoardConfigByIdDTO(id) {
    let url = this.BaseUrl + urls.boadLaneConfigDTO + "/" + id
    return this.http.get(url);
  }
  saveLaneConfigById(data) {
    let url = this.BaseUrl + urls.laneConfig
    return this.http.post(url, data);
  }

  uploadTaskDocuments(obj, id) {
    let url = this.BaseUrl + urls.laneTaskDocumentsUpload + '?laneTaskId=' + id + '&remarks=' + 'sampleRemarks'
    return this.http.post(url, obj);
  }
  deleteUploadedDocuments(id, fieldName, laneID, dataType) {
    let url = this.BaseUrl + urls.deleteTaskDocuments + '?documentId=' + id
    if (fieldName != null && laneID != null) {
      url = url + "&fieldName=" + fieldName + '&laneTaskId=' + laneID
    }
    if (dataType != null) {
      url = url + "&dataType=" + dataType
    }
    return this.http.delete(url);
  }

  downloadZipDocumentsByLaneId(id) {
    let url = this.BaseUrl + urls.downloadZipTaskDocuments + '?laneTaskId=' + id
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  downloadSingleUploadDocument(id) {
    let url = this.BaseUrl + urls.downloadSingleDocument + '?documentId=' + id
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  searchUploadedTaskDocuments(obj) {
    let url = this.BaseUrl + urls.uploadDocumentSearch
    return this.http.post(url, obj);
  }

  getKanbanUserList() {
    let url = this.BaseCredlixUrl + urls.getUserManagement;
    return this.http.get(url);
  }

  getLaneTaskHistory(id, obj) {
    let url = this.BaseUrl + urls.laneTaskHistory + '?laneTaskId=' + id + '&pageNo=' + obj.from + '&pageSize=' + obj.size
    return this.http.get(url);
  }
  getLanetaskHistoryByUser(id, obj, accName) {
    let url = this.BaseUrl + urls.laneTaskHistoryByUser + '?userId=' + id + '&pageNo=' + obj.from + '&pageSize=' + obj.size + '&accountName=' + accName
    return this.http.get(url);
  }

  getL2Categories(l1Value: string) {
    const url = this.BaseUrl + urls.laneTaskl2category;
    const params = new HttpParams().set('categoryName', l1Value);

    return this.http.get(url, { params });
  }
  getRoleTypeByRoleName(roleName: string) {
    const url = this.BaseUrl + urls.getRoleTypeByRoleName;
    const params = new HttpParams().set('roleName', roleName);
    return this.http.get(url,{ params });
  }
  addTaskComment(obj) {
    let url = this.BaseUrl + urls.addComment;
    return this.http.post(url, obj)
  }
  addTaskActionItem(obj) {
    let url = this.BaseUrl + urls.addActionItem;
    return this.http.post(url, obj)
  }
  addCommentsOnActionItemOrNote(obj) {
    let url = this.BaseUrl + urls.addCommentsOnActionOrNote;
    return this.http.post(url, obj)
  }
  addTask(obj){
    let url=this.BaseUrl+ urls.addTask;
    return this.http.post(url, obj)
  }
  getTaskByMeetingId(meetingId){
    let url=this.BaseUrl+ urls.addTask+urls.getTaskByMeeting+"?meetingId="+meetingId;
    return this.http.get(url)
  }
  getAllTaskByMeetingId(obj){
    let url=this.BaseUrl+ urls.addTask+urls.getAllTaskByMeeting+"?meetingId="+obj.meetingId+ '&pageNo=' + obj.from + '&pageSize=' + obj.size;
    return this.http.get(url)
  }
  addMOM(obj) {
    let url = this.BaseUrl + urls.addMOM;
    return this.http.post(url, obj)
  }
  fetchMOM(meetingId) {
    let url = this.BaseUrl + urls.addMOM+"?meetingId="+meetingId;
    return this.http.get(url)
  }
  fetchAllMOM(obj) {
    let url = this.BaseUrl + urls.getAllMOM+"?meetingId="+ obj.meetingId + '&pageNo=' + obj.from + '&pageSize=' + obj.size;
    return this.http.get(url)
  }
  fetchDraftMOM(meetingId) {
    let url = this.BaseUrl + urls.addMOM+"/get-draft-mom?meetingId="+meetingId;
    return this.http.get(url)
  }
  getActionItem(obj) {
    let url = this.BaseUrl + urls.actionItemSearch;
    return this.http.post(url, obj)

  }
  getTaskComment(obj) {
    let url = this.BaseUrl + urls.commentTaskSearch;
    return this.http.post(url, obj)
  }
  uploadSubDocuments(obj, id, entityType) {
    let url = this.BaseUrl + urls.laneTaskDocumentsUpload + `?${entityType}=` + id
    return this.http.post(url, obj);
  }
  uploadSubDocumentsForConfig(obj, actionItemId,commentId,laneTaskId,remarks,dataType,momId) {
    let url = this.BaseUrl + urls.laneTaskDocumentsUpload;
      url=url+`?actionItemId=` + actionItemId
      url=url+`&commentId=` + commentId
      url=url+`&laneTaskId=` + laneTaskId
      url=url+`&remarks=` + remarks
      url=url+`&momId=` + momId
      url=url+`&dataType=` + dataType
      return this.http.post(url, obj);
  }
  saveAuthority(obj) {
    let url = this.BaseUrl + urls.saveAuthority;
    return this.http.post(url, obj)
  }

  uploadCofigDocuments(obj) {
    let url = this.BaseUrl + urls.configDocumentUpload;
    return this.http.post(url, obj)
  }
  uploadBuyerDocumentsById(obj,buyerID, type ) {
    let url = this.BaseUrl + urls.uploadBuyerDocs + '?buyerDataId=' + buyerID + '&type=' + type
    return this.http.post(url, obj)
  }

  getConfigCountryList(){
    let url = this.BaseCredlixUrl + urls.getConfigCountry;
    return this.http.get(url)
  }

  readBuyerFileData(obj){
    let url = this.BaseUrl + urls.readBuyerDataFile + '?documentId=' + obj.documentId + '&limit=' + obj.limit
    return this.http.post(url, {})
  }

  generateTermSheetPDF(obj,id){
    let url = this.BaseUrl + urls.termSheetGenerate + '?lanetaskId=' + id
    return this.http.post(url, obj, {responseType: 'blob' as 'blob'})
  }
  ValidateTermSheetPDF(obj,id){
    let url = this.BaseUrl + urls.validateTermsheet + '?lanetaskId=' + id
    return this.http.post(url, obj)
  }
  saveTermSheetData(obj,id){
    let url = this.BaseUrl + urls.termSheetSave + '?lanetaskId=' + id
    return this.http.post(url, obj)
  }
  generateRPAPDF(obj,id){
    let url = this.BaseUrl + urls.rpaSheetGenerate + '?lanetaskId=' + id
    return this.http.post(url, obj, {responseType: 'blob' as 'blob'})
  }
  getRPASheetData(id){
    let url = this.BaseUrl + urls.getRPAData + '?laneTaskId=' + id
    return this.http.get(url)
  }
  getPrepareTermSheetData(id){
    let url = this.BaseUrl + urls.getTermSheetData + '?laneTaskId=' + id
    return this.http.get(url)
  }

  updateBuyerData(id, obj){
    let url = this.BaseUrl + urls.buyerData + '?laneTaskId=' + id
    return this.http.put(url, obj)
  }
  getBuyerData(id, tabId){
    let url = this.BaseUrl + urls.buyerData + '?laneTaskId=' + id + "&laneId=" + tabId;
    return this.http.get(url)
  }

  uploadPanjivaFile(obj){
    let url = this.BaseCredlixUrl + urls.uploadMultipleLead + '?leads=' + 'credlix'
    return this.http.post(url, obj)
  }

  getCountryList(){
    let url = this.BaseCredlixUrl + urls.getCountryList;
    return this.http.get(url)
  }
  CountryList(){
    let url = this.BaseCredlixUrl + urls.country;
    return this.http.get(url)
  }
  deleteTermSheet(id){
    let url = this.BaseUrl + urls.deleteTermsheet + '/'  + id
    return this.http.delete(url);
  }
  deleteBuyer(buyerId, docId){
    let url = this.BaseUrl + urls.deleteBuyerData + '?buyerdataId=' + buyerId + "&documentIdList=" + docId;
    return this.http.delete(url);
  }

  saveContactDetails(obj, id){
    let url = this.BaseUrl + urls.contactDetails + '?laneTaskId=' + id
    return this.http.post(url, obj)
  }
  getContactDetails( id){
    let url = this.BaseUrl + urls.contactDetails + '?laneTaskId=' + id
    return this.http.get(url)
  }

  updateContactDetails(laneTaskId , contactIdList){
    let url = this.BaseUrl + urls.contactDetailsUpdate + '?laneTaskId=' + laneTaskId
    return this.http.post(url, contactIdList)
  }
  createBuyerDataRow(obj,id){
    let url = this.BaseUrl + urls.buyerData + '?laneTaskId=' + id
    return this.http.post(url, obj)
  }
  deleteContactDetails( id, contactId){
    let url = this.BaseUrl + urls.deleteContactDetails + '?contacts=' + contactId  + "&laneTaskId=" + id;
    return this.http.delete(url)
  }
  deleteBuyerData( id, buyerID){
    let url = this.BaseUrl + urls.buyerData + '?buyerDataIds=' + buyerID  + "&laneTaskId=" + id;
    return this.http.delete(url)
  }
  getDropdowndata(){
    let url = "https://credlix-public.s3.ap-south-1.amazonaws.com/DropdownData.json";
    return this.http.get(url);
  }
  generateCAMReport(gstin){
    let url = this.BaseCredlixUrl + urls.getGstPdf + '?gstin=' + gstin
    return this.http.get(url,{ observe: 'response', responseType: 'blob' as 'json' })
  }


  buyerConfigUpdate(obj,id){
    let url = this.BaseUrl + urls.updateBuyerDataConfig + '?boardId=' + id
    return this.http.post(url, obj)
  }
  changeAccessLaneType(obj){
    let url = this.BaseUrl + urls.laneAccessType
    return this.http.post(url, obj)
  }
  getAccessType(id,boardId){
    let url = this.BaseUrl + urls.getAccessByBoardId + '?userId=' + id + '&boardId=' +boardId
    return this.http.get(url)
  }
  uploadSupplierBulkTermsheet(obj, id) {
 let getMasterId = localStorage.getItem("USERINFO")
    let url = this.BaseUrl + urls.supplierBulkTermsheet  + '?boardId=' + id
    return this.http.post(url, obj);
  }

  uploadBuyerBulkTermsheet(obj, id) {
    let getMasterId = localStorage.getItem("USERINFO")
       let url = this.BaseUrl + urls.buyerBulkTermsheet  + '?boardId=' + id
       return this.http.post(url, obj);
     }

  getSupplierTermsheetListing(obj){
    let url = this.BaseUrl + urls.supplierTermsheetData + '?pageNo=' + obj.from + '&size=' + obj.size
    return this.http.get(url)
  }
  downloadSingleLead(id){
    let url = this.BaseUrl + urls.downloadBulkLead + '?bulkUploadId=' + id;
    return this.http.get(url,{ observe: 'response', responseType: 'blob' as 'json' })
  }
  downloadZipLead(id){
    let url = this.BaseUrl + urls.downloadZipBulkLead + '?bulkUploadId=' + id;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' })
  }
  bulkUploadLaneTask(obj,boardId){
    let url=this.BaseUrl+urls.bulkUpload;
    if(boardId){
      url=url+'?boardId='+boardId;
    }
    return this.http.post(url, obj);
  }
  downloadContactUploadTemplate(url){
    //return this.http.get(url);
    window.location.assign(url);
  }

  downloadTableData(laneTaskId: any, field: string, subLaneId: any){
    let url = this.BaseUrl + urls.tableDownloadLaneTask + '?laneTaskId=' + laneTaskId + '&tableName=' + field + '&subLaneId=' + subLaneId;
    return this.http.get(url);
  }

  checkMobileScreen(){
    // Use the window reference: this.windowRef
    var ms = window.matchMedia("(max-width: 767px)");
    if (ms.matches) { // If media query matches
    console.log("mobile")
        return true;
      
    }else{
      console.log("desktop")
      return false;
    
    }
  }
  getFormattedDate(dateLong:any): string {
    const date = new Date(dateLong); // Convert timestamp to Date object
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`; // Format as 'dd-MM-yyyy'
  }
}



