<div fxLayout="row" class="main_content" style="background: #F7F7F7 0% 0% no-repeat padding-box;">
  <div class="content-sec" fxLayout="column" style="width: 100%;">
    <ul class="list-tab">
      <li *ngFor="let tab of boardList; let index = index"
        [ngClass]="{'active': boardId === tab.id && !huntersListSelected && !MeetingBoardSelected && !KamBoardSelected}"
        (click)="getBoardTabChange(index)">
        {{ tab.name }}
      </li>
      <li [ngClass]="{'active': huntersListSelected === true}" *ngIf="selectedBusinessId === crmBusinessId && currentUser.role != null && (
          currentUser.role.roleName === 'HEAD' ||
          currentUser.role.roleName === 'MASTER_ADMIN' ||
          currentUser.role.roleName === 'REGIONAL_ADMIN' ||
          currentUser.role.roleName === 'CITY_ADMIN' ||
          currentUser.role.roleName === 'LEAD_HUNTER'
        )" (click)="selectHunterLane()">
        Hunters
      </li>
      <!-- <li [ngClass]="{'active': KamBoardSelected === true}" *ngIf="selectedBusinessId === sobBusinessId"
        (click)="selectKAMLane()">
        KAM
      </li> -->
      <!-- <li [ngClass]="{'active': MeetingBoardSelected === true}" *ngIf="selectedBusinessId === sobBusinessId || selectedBusinessId === crmBusinessId"
        (click)="selectMeetingLane()">
        Meeting
      </li> -->
    </ul>
    <ng-container *ngIf="passOnData != null && !huntersListSelected && !MeetingBoardSelected && !KamBoardSelected">
      <app-board-lane #child [inputData]="passOnData">
      </app-board-lane>
    </ng-container>
    <ng-container *ngIf="passOnData != null && huntersListSelected">
      <app-hunting-listing #child [inputData]="passOnData" >
      </app-hunting-listing>
    </ng-container>
    <ng-container *ngIf="KamBoardSelected">
      <app-kam-listing #child [inputData]="passOnData"></app-kam-listing>
    </ng-container>
    <ng-container *ngIf="passOnData != null && MeetingBoardSelected">
      <app-sop-management #child [inputData]="passOnData">
      </app-sop-management>
    </ng-container>
  </div>
  <div fxLayout="row" class="tab-overflow-btn mr-30">
    <!-- <button mat-flat-button class="mog3_primary_flat_btn add-deal-btn mr-20"
      *ngIf="boardId===boardIdForSobContacts && !huntersListSelected && !MeetingBoardSelected"
      (click)="bulkUploadDialog()">
      <i class="ri-upload-2-line"></i>
      <span>
        Bulk Upload</span>
    </button> -->
    <div fxLayout="row" fxLayoutAlign="end center"
      *ngIf="selectedBoard != null && selectedBoard.canEdit && !huntersListSelected && !MeetingBoardSelected && !KamBoardSelected">
      <div fxLayout="row" *hasPermission="['CREATE_TASK']">
        <button mat-flat-button class="mog3_primary_flat_btn add-deal-btn" (click)="addNewLead()">
          <i class="ri-add-line"></i>
          <span *ngIf="selectedBoardName != null && selectedBoardName.trim() != ''">
            Add {{getSingularName(selectedBoardName)}} </span>
        </button>
      </div>
    </div>

  </div>
</div>