import { Component, Injectable, NgZone, OnInit } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDialog, MatSnackBar, MatDatepicker } from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ToastrService } from "ngx-toastr";
import { Constants } from "src/app/Constants";
import { PagesService } from "src/app/pages/pages.service";
import { SettingService } from "src/app/settings/setting.service";
import { KanbanService } from "../kanban.service";
import { tap } from 'rxjs/operators';
import { LabelConstants } from "src/app/LabelConstants";
import { FormControl } from "@angular/forms";
import moment, { Moment } from "moment";
import { NewMeetingComponent } from "../new-meeting/new-meeting.component";
import { SuccessToastComponent } from "src/app/shared-files/success-toast/success-toast.component";
import { ErrorToastComponent } from "src/app/shared-files/error-toast/error-toast.component";

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    monthDayLabel: 'MMM YYYY', // Adding a new format for month and day only
  },
};

@Component({
  selector: 'app-sop-management',
  templateUrl: './sop-management.component.html',
  styleUrls: ['./sop-management.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})

@Injectable({
  providedIn: 'root'
})
export class SopManagementComponent implements OnInit {

  sobUserId: any;
  huntingUserId: any;
  srmUserId: string;

  selectedBusinessId: string;
  switchBusiness: any;

  minDate: Date = new Date();
  minEndDate: Date = new Date();
  maxDate: Date = new Date();
  currentTime: Date = new Date();

  sobBusinessId: string = Constants.businessIdForSOB;
  crmBusinessId: string = Constants.businessIdForCRM;
  srmBusinessID: string = Constants.businessIdForSRM;

  huntersLabel: string = LabelConstants.huntersLabel;
  KAMLabel: string = LabelConstants.KAMLabel;
  SRMLabel: string = LabelConstants.SRMLabel;
  vedantaLabel: string= LabelConstants.VedantaLabel;
  attendeeLabel: string = this.vedantaLabel;

  isLoading: boolean = false;

  accountDetails: any[] = [];
  accountDetailsTrimmed: any[] = [];

  plantDetails: any[] = [];
  plantDetailsTrimmed: any[] = [];
  selectedPlantsForMeeting: any[] = [];
  searchControlPlants = '';

  datewisemap = new Map<any, any>();
  meetingDetail: any = [];
  meetingContacts: any = [];

  isRefresh = false;

  selectedMeeting: any;
  selectedValue: any;
  file: any = null;
  content = '';

  momAttendees: any = [];

  allUserList: any = [];
  allUserListSearch: any = [];
  allTaskOwnerUserListSearch: any = [];
  allTaskAssigneeUserListSearch: any = [];
  allTaskCollaboratorUserListSearch: any = [];

  taskDetails: any = {
    id:null,
    addMomDisplayText: "ADD MOM & Task",
    fetchedTaskTitle: "",
    fetchedTaskDescription: "",
    fetchedTaskCreationDate: "",
    fetchedTaskDueDate: "",
    fetchedTaskPlantsValue: "",
    fetchedTaskAccountsValue: "",
    fetchedTaskOwnerValue: "",
    fetchedTaskAssigneeValue: "",
    fetchedTaskCollaboratorValue: ""
  };
  
  momDetails: any = {
    id:null,
    momType:null,
    addMomDisplayText: "ADD MOM",
    fetchedMOMContent: "",
    fetchedMOMCreationDate: "",
    fetchedMOMNextActionDate: "",
    fetchedMOMValue: "",
    fetchedMOMAttachment: []
  };
  draftMomDetails: any = {
    id:null,
    momType:null,
    addMomDisplayText: "ADD MOM",
    fetchedMOMContent: "",
    fetchedMOMCreationDate: "",
    fetchedMOMNextActionDate: "",
    fetchedMOMValue: "",
    fetchedMOMAttachment: [],
    fetchedAttendees:[]
  };
  date = new FormControl(moment());

  selectedTab: string = 'ALL';
  startDateSelected: any = null;
  endDateSelected: any = null;

  selectedAccountsForMOM: any[] = [];
  accountOptionsSelected: any[] = [];
  searchControlAccounts = '';

  nextActionDate: any = new Date();
  taskDueDate: any = new Date();
  selectedAttendees: any = [];
  selectedTaskOwners: any = [];
  selectedTaskAssignees: any = [];
  selectedTaskCollaborators: any = [];
  taskTitle: string;
  taskDescription:string;
  isHidden = true;

  sendMomMail: boolean = false; // Set initial state (false for disabled)
  sobAccountBoardId: string = '66c8384e8535f255a8b12efd';
  sobPlantBoardId: string = '6494a60df92a8255c71e39b5';
  enableAddMomButton: boolean = false;
  constructor(private _snackBar: MatSnackBar, public pagesService: PagesService, public dialog: MatDialog, public settingService: SettingService, public _kService: KanbanService, public toasterService: ToastrService, private _ngZone: NgZone) {
    this.minDate;
    this.minDate.setDate(this.minDate.getDate() - 2);

    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minEndDate.getDate() - 2);

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 2);

  }

  ngOnInit() {
    this.sobUserId = localStorage.getItem('sobUserId');
    this.huntingUserId = localStorage.getItem('crmUserId');
    this.srmUserId = localStorage.getItem('srmUserId');

    this.selectedBusinessId = localStorage.getItem('selectedBusinessId');

    this.switchBusiness = this.settingService._ManagementData.subscribe(res => {
      this.handleBusinessSelection(res);
    });

    this.loadAccounts().subscribe();
    this.loadPlants().subscribe();
    this.loadMeetings({});
  }

  handleBusinessSelection(res) {
    const selectedBusiness = localStorage.getItem('selected_business');

    if (res && res.length > 0) {
      this.updateSelectedBusiness(res, selectedBusiness);
    } else {
      this.clearBusinessSelection();
    }

    this.setBusinessDetails(res);
  }

  setBusinessDetails(res) {
    let businessId, businessName;

    switch (res) {
      case 'SOB Management':
        businessId = this.sobBusinessId;
        businessName = 'SOB Management';
        break;
      case 'CRM Management':
        businessId = this.crmBusinessId;
        businessName = 'CRM Management';
        break;
      case 'SRM Management':
        businessId = this.srmBusinessID;
        businessName = 'SRM Management';
        break;
      default:
        return;
    }

    this.selectedBusinessId = businessId;
    localStorage.setItem('selectedBusinessId', businessId);
    localStorage.setItem('selected_business', businessName);
  }

  updateSelectedBusiness(res, selectedBusiness) {
    if (selectedBusiness != null && selectedBusiness != res) {
      this.clearLaneSelection();
    } else if (selectedBusiness == null) {
      this.clearLaneSelection();
    }
    localStorage.setItem('selected_business', res);
  }

  clearBusinessSelection() {
    this.clearLaneSelection();
    localStorage.removeItem('selected_business');
  }

  clearLaneSelection() {
    localStorage.removeItem('lane_board');
    localStorage.removeItem('selected_lane');
  }

  getQuery(boardId: any) {
    return {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: boardId,
            },
          ],
        },
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
    };
  }

  loadAccounts() {
    this.isLoading = true;
    const query = this.getQuery(this.sobAccountBoardId);

    return this.settingService.fetchAccountsByUserId(this.huntingUserId).pipe(
      tap(response => {
        this.isLoading = false;

        this.accountDetails = response['result'];

        this.accountDetails.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );

        this.accountDetailsTrimmed = this.accountDetails.slice(0, 100);
      })
    );
  }

  loadPlants() {
    this.isLoading = true;
    const accountIds = this.selectedAccountsForMOM.map(accounts => accounts.id);
    const query = this.getPlantsQuery(this.sobPlantBoardId, accountIds);
    return this._kService.getLaneTask(query).pipe(
      tap(response => {
        this.isLoading = false;

        this.plantDetails = response['result'];

        this.plantDetails.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );

        this.plantDetailsTrimmed = this.plantDetails.slice(0, 100);
      })
    );
  }

  loadMeetings(obj) {
    this.isLoading = true;
    this.settingService.fetchMeetings(obj).subscribe(response => {
      this.isLoading = false;
      if (response["result"]) {
        this.datewisemap = new Map(Object.entries(response["result"]));
        this.meetingDetail = this.datewisemap.size > 0 ? this.datewisemap : null;
        this.meetingContacts = this.datewisemap.size > 0 ? this.datewisemap : [];
        this.isRefresh = true;

        const reversedEntries = Array.from(this.datewisemap.entries()).reverse();

        let isNavigationClicked = false;
        reversedEntries.forEach(([key, value]: [any, any]) => {
          if (!isNavigationClicked) {
            const keyDate = new Date(key);
            if (keyDate.getDate() > this.currentTime.getDate() - 1) {
              for (let index = 0; index < value.length; index++) {
                const element = value[index];
                const meetingStartTime = new Date(element.start.dateTime);
                meetingStartTime.setHours(meetingStartTime.getHours() + 5); // Subtract 5 hours
                meetingStartTime.setMinutes(meetingStartTime.getMinutes() + 30); // Subtract 30 minutes
                if (meetingStartTime > this.currentTime) {
                  this.onClickNavigation(value[index]);
                  isNavigationClicked = true;
                  break;
                }
                else if (keyDate.getDate() > this.currentTime.getDate()) {
                  this.onClickNavigation(value[index]);
                  isNavigationClicked = true;
                  break;
                }
              }
            }
          }
        });
      }
      else {
        this.datewisemap = null;
        this.meetingDetail = null;
        this.meetingContacts = null;
      }
    });
  }

  onClickNavigation(selectedSection: any) {
    this.isRefresh = false;

    selectedSection.isSelected = true;
    this.datewisemap.forEach((value: any, key: any) => {
      value.forEach((l: any) => {
        if (l.id != selectedSection.id) {
          l.isSelected = false;
        }
      })
    });
    this.selectedMeeting = selectedSection;
    this.loadMeetingDetail(selectedSection);
    this.loadMeetingContacts(selectedSection);
    this.loadMoglixAttendees(selectedSection);
    this.fetchMOMDetails(selectedSection);
    this.fetchDraftMOMDetails(selectedSection);
    this.fetchTaskDetails(selectedSection);
  }

  loadMeetingDetail(selectedSection) {
    console.log("selectedSection", selectedSection)
    this.meetingDetail = {};

    this.meetingDetail.subject = selectedSection.subject;
    this.meetingDetail.starttime = selectedSection.start.time;
    this.meetingDetail.endtime = selectedSection.end.time;
    this.meetingDetail.body = selectedSection.body.content;
    
    if(new Date().getTime()>this.meetingDetail.endtime){
      this.enableAddMomButton=true;
    }else{
      this.enableAddMomButton=false;
    }
    let plantIdList = selectedSection.plantIdList;
    if (plantIdList != null && plantIdList.length > 0) {
      this.selectedPlantsForMeeting = this.plantDetails.filter(element => plantIdList.includes(element.id));
    }

    let accountIdList = selectedSection.accountList;
    console.log("selection", selectedSection)
    if (accountIdList != null && accountIdList.length > 0) {
      this.selectedAccountsForMOM = this.accountDetails.filter(element => accountIdList.includes(element.id));
    }

    this.momAttendees = [];
    this.momAttendees = selectedSection.moglixAttendees;

    this.loadUsers(this.sobBusinessId);
    this.attendeeLabel = this.vedantaLabel;
  }

  loadUsers(businessId: string) {
    const obj = {
      from: 0,
      size: 100,
      businessId: businessId
    };

    this.settingService.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      const userList = list['result'].map(user => {
        const teamList = user.userTeams ? user.userTeams.map(team => team.id) : [];
        return { ...user, userTeams: teamList };
      });
      userList.sort((a, b) => a.name.localeCompare(b.name));
      if (businessId === Constants.businessIdForCRM) {
        const filteredUserList = userList.filter(user => user.id !== this.huntingUserId);
        this.allUserList = filteredUserList;
      }
      if (businessId === Constants.businessIdForSOB) {
        const filteredUserList = userList.filter(user => user.id !== this.sobUserId);
        this.allUserList = filteredUserList;
      }
      if (businessId === Constants.businessIdForSRM) {
        const filteredUserList = userList.filter(user => user.id !== this.srmUserId);
        this.allUserList = filteredUserList;
      }
      this.allUserListSearch = this.allUserList;
      this.allTaskOwnerUserListSearch = this.allUserList;
      this.allTaskAssigneeUserListSearch = this.allUserList;
      this.allTaskCollaboratorUserListSearch = this.allUserList;
    });
  }

  loadMeetingContacts(selectedSection) {
    this.meetingContacts = [];
    let attendees = selectedSection.attendees;
    let organizer = selectedSection.organizer.emailAddress.address;
    attendees.forEach((c: any) => {
      let contact: any = {};
      contact.name = c.emailAddress.name;
      contact.email = c.emailAddress.address;
      if (organizer === c.emailAddress.address) { contact.organiser = true; }
      this.meetingContacts.push(contact);
    });
  }

  loadMoglixAttendees(selectedSection) {
    this.momAttendees = [];
    let moglixAttendees = selectedSection.collaboratorDetails;
    if (moglixAttendees) {
      moglixAttendees.forEach((c: any) => {
        let contact: any = {};
        contact.name = c.emailAddress.name;
        contact.email = c.emailAddress.address;
        this.momAttendees.push(contact);
      });
    }
  }

  fetchTaskDetails(selectedMeeting: any): void {
    this._kService.getTaskByMeetingId(selectedMeeting.id).subscribe({
      next: (res) => {
        console.log("fetchTaskDetails Task Details", res);
        let response;
        if(res && res['result']){
          response = res['result'];
        }
        if (response) {
          this.updateTaskDetails(response);
        } else {
          this.taskDetails = {
            addMomDisplayText: "Add MOM & Task"
          };
        }
      },
      error: (err) => {
        console.error("Error fetching task details:", err);
  
        // Handle API errors here
        this.taskDetails = {
          addMomDisplayText: "Add MOM & Task"
        };
      }
    });
  
    // Log taskDetails after the async response, just for debugging purposes
    console.log("taskDetail", this.taskDetails);
  }
  

  fetchMOMDetails(selectedMeeting: any): void {
    this._kService.fetchMOM(selectedMeeting.id).subscribe(res => {
      console.log(" fetchMOMDetails MOM Details", res);
      const response = res['result'];
      if (response) {
        this.updateMOMDetails(response, selectedMeeting.selectedPlatform);
      } else {
        this.momDetails = {
          addMomDisplayText: "Add MOM & Task"
        };
      }
    });
    console.log("meetingDetail", this.meetingDetail);
  }

  fetchDraftMOMDetails(selectedMeeting: any): void {
    this._kService.fetchDraftMOM(selectedMeeting.id).subscribe(res => {
      console.log("fetchDraftMOM MOM Details", res);
      const response = res['result'];
      if (response) {
        this.updateDraftMOMDetails(response);
      }else{
        this.draftMomDetails={
          id:null,
          momType:null,
          fetchedMOMContent: "",
          fetchedMOMCreationDate: "",
          fetchedMOMNextActionDate: "",
          fetchedMOMValue: "",
          fetchedMOMAttachment: [],
          fetchedAttendees:[]
        };
        if(this.draftMomDetails){
          this.isHidden=true;
          this.selectedValue=this.draftMomDetails.fetchedMOMValue;
          let attendeeIds=this.draftMomDetails.fetchedAttendees;
          let fetchedUserList = this.allUserList.filter(option => attendeeIds.includes(option.id));
          this.selectedAttendees=fetchedUserList;
          this.content=this.draftMomDetails.fetchedMOMContent;
          this.nextActionDate=this.draftMomDetails.fetchedMOMNextActionDate;
        }
      }
    });
    console.log("fetchDraftMOM", this.meetingDetail);
  }

  private updateTaskDetails(response: any): void {
    this.taskDetails.id=response.id;
    this.taskDetails.addMomDisplayText = "UPDATE MOM & Task";
    this.taskDetails.fetchedTaskTitle = response.title;
    this.taskDetails.fetchedTaskDescription = response.description;
    this.taskDetails.fetchedTaskCreationDate = response.createdAt;
    this.taskDetails.fetchedTaskDueDate = this._kService.getFormattedDate(response.dueDate);
    
    if (response.plants && Array.isArray(response.plants)) {
      this.taskDetails.fetchedTaskPlantsValue = response.plants.map((plant: any) => plant.name).join(', ');
    }
    if (response.accounts && Array.isArray(response.accounts)) {
      this.taskDetails.fetchedTaskAccountsValue = response.accounts.map((account: any) => account.name).join(', ');
    }
    if (response.owners && Array.isArray(response.owners)) {
      this.taskDetails.fetchedTaskOwnerValue = response.owners.map((owner: any) => owner.name).join(', ');
    }
    if (response.assignees && Array.isArray(response.assignees)) {
      this.taskDetails.fetchedTaskAssigneeValue = response.assignees.map((owner: any) => owner.name).join(', ');
    }
    if (response.collaborators && Array.isArray(response.collaborators)) {
      this.taskDetails.fetchedTaskCollaboratorValue = response.collaborators.map((owner: any) => owner.name).join(', ');
    }
    console.log("updateTaskDetails task details", this.taskDetails)
  }
  
  private updateMOMDetails(response: any, selectedPlatform: any): void {
    this.momDetails.momType=response.momType;
    this.momDetails.addMomDisplayText = "UPDATE MOM";
    this.momDetails.fetchedMOMContent = response.message;
    this.momDetails.fetchedMOMCreationDate = response.createdAt;
    this.momDetails.fetchedMOMNextActionDate = response.nextActionDate;
    this.momDetails.fetchedMOMValue = response.valueDelivery ? "YES" : "NO";
    this.momDetails.fetchedMOMAttachment = response.attachments;
    if (response.plants && Array.isArray(response.plants)) {
      this.momDetails.fetchedMOMPlantsString = response.plants.map((plant: any) => plant.name).join(', ');
    }
    if (response.accounts && Array.isArray(response.accounts)) {
      this.momDetails.fetchedMOMAccountsString = response.accounts.map((account: any) => account.name).join(', ');
    }
    console.log("momDetails", this.momDetails)
  }

  private updateDraftMOMDetails(response: any): void {
    this.draftMomDetails.momType=response.momType;
    this.draftMomDetails.id=response.id;
    this.draftMomDetails.fetchedMOMContent = response.message;
    this.draftMomDetails.fetchedMOMCreationDate = response.createdAt;
    this.draftMomDetails.fetchedMOMNextActionDate = response.nextActionDate;
    this.draftMomDetails.fetchedMOMValue = response.valueDelivery ? "YES" : "NO";
    this.draftMomDetails.fetchedMOMAttachment = response.attachments;
    this.draftMomDetails.fetchedAttendees=response.attendees;
    if(this.draftMomDetails){
      this.momDetails.addMomDisplayText = "Resume Draft MOM";
      this.isHidden=false;
      this.selectedValue=this.draftMomDetails.fetchedMOMValue;
      let attendeeIds=this.draftMomDetails.fetchedAttendees;
      let fetchedUserList = this.allUserList.filter(option => attendeeIds.includes(option.id));
      this.selectedAttendees=fetchedUserList;
      this.content=this.draftMomDetails.fetchedMOMContent;
      this.nextActionDate=this.draftMomDetails.fetchedMOMNextActionDate;
    }
    console.log("draftmomDetails", this.draftMomDetails)
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const selectedDate = this.date.value.clone(); // Clone to avoid mutating the original value
    selectedDate.month(normalizedMonth.month());

    // Set the start date to the beginning of the month as a Unix timestamp
    const startDate = selectedDate.clone().startOf('month').valueOf();
    this.startDateSelected = startDate;

    // Set the end date to the end of the month as a Unix timestamp
    const endDate = selectedDate.clone().endOf('month').valueOf();
    this.endDateSelected = endDate;
    const obj = {
      "startDate": this.startDateSelected,
      "endDate": this.endDateSelected
    };

    this.date.setValue(selectedDate); // Set the updated date back to the form control
    datepicker.close();
    if (this.selectedTab === 'MOM') {
      this.loadMOMMeetings(obj);
    } else {
      this.loadMeetings(obj);
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  loadMOMMeetings(obj) {
    this.isLoading = true;
    this.settingService.fetchMOMMeetings(obj).subscribe(response => {
      this.isLoading = false;
      this.datewisemap = new Map(Object.entries(response["result"]));
      this.meetingDetail = this.datewisemap.size > 0 ? this.datewisemap : null;
      this.meetingContacts = this.datewisemap.size > 0 ? this.datewisemap : [];
      this.isRefresh = true;
      const reversedEntries = Array.from(this.datewisemap.entries()).reverse();

      let isNavigationClicked = false;
      reversedEntries.forEach(([key, value]: [any, any]) => {
        if (!isNavigationClicked) {
          const keyDate = new Date(key);
          if (keyDate.getDate() > this.currentTime.getDate() - 1) {
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              const meetingStartTime = new Date(element.start.dateTime);
              meetingStartTime.setHours(meetingStartTime.getHours() + 5); // Subtract 5 hours
              meetingStartTime.setMinutes(meetingStartTime.getMinutes() + 30); // Subtract 30 minutes
              if (meetingStartTime > this.currentTime) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
              else if (keyDate.getDate() > this.currentTime.getDate()) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
            }
          }
        }
      });

    });
  }

  newMeetingDialog() {
    const dialogRef = this.dialog.open(NewMeetingComponent, {
      width: '50%',
      height: '100%',
      position: { bottom: '0px', right: '0px' },

    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.selectedTab === 'MOM') {
        this.loadMOMMeetings({});
      } else {
        this.loadMeetings({});
      }
    });
  }

  refreshMeetingFromAzure() {
    const selectedDate = this.date.value.clone(); // Clone to avoid mutating the original value
    const startDate = selectedDate.clone().startOf('month').valueOf();
    this.startDateSelected = startDate;
    const endDate = selectedDate.clone().endOf('month').valueOf();
    this.endDateSelected = endDate;

    const obj = {
      "startDate": this.startDateSelected,
      "endDate": this.endDateSelected,
      "pullFromAzure": true
    };

    this.date.setValue(selectedDate); // Set the updated date back to the form control
    if (this.selectedTab === 'MOM') {
      this.loadMOMMeetings(obj);
    } else {
      this.loadMeetings(obj);
    }
    // this.loadPlants();
    this.isRefresh = true;
  }

  filterOptionsAccounts(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.accountDetailsTrimmed = this.accountDetails.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );

    if (this.selectedAccountsForMOM && this.selectedAccountsForMOM.length > 0) {
      this.selectedAccountsForMOM.forEach(item => {
        if (!this.accountDetailsTrimmed.includes(item)) {
          this.accountDetailsTrimmed.push(item);
        }
      });
    }

    this.accountDetailsTrimmed = this.accountDetailsTrimmed.slice(0, 100);
  }

  onApplyAccounts() {
    this.searchControlAccounts = "";
    this.accountDetailsTrimmed = this.accountDetails;
    this.accountDetailsTrimmed = this.accountDetailsTrimmed.slice(0, 100);
    if (this.selectedAccountsForMOM) {
      this.selectedAccountsForMOM.forEach(item => {
        if (this.accountDetails.includes(item) && !this.accountDetailsTrimmed.includes(item)) {
          this.accountDetailsTrimmed.push(item);
        }
      });
    }
    if (this.selectedMeeting) {
      this.updateMeetingentity();
      this.loadPlantsOfAccounts();
    }
  }

  updateMeetingentity() {
    let selectedIds = this.selectedAccountsForMOM.map(item => item.id);
    this.selectedMeeting.accountList = selectedIds;
    const obj = {
      id: this.selectedMeeting.id,
      accountList: selectedIds,
    };
    this.isLoading = true;
    this.updateAssociateMeeting(obj, "Account");
  }

  updateAssociateMeeting(obj: any, portal: string) {
    this.settingService.associateEntitiesToMeetings(obj).subscribe((res) => {
      this.isLoading = false;
      if (res['status'] === 200) {
        const message = portal + " Updated successfully";
        this.datewisemap.forEach((value: any, key: any) => {
          value.forEach((l: any) => {
            if (l.id == res['result'].id) {
              l.selectedPlatform = res['result'].selectedPlatform;
            }
          })
        });
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 3500,
          data: message
        });
      }
      else {
        const errorMessage = "Error while updating " + portal + " " + res['status']
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 3500,
          data: errorMessage
        });
      }
    });
  }

  clearAccounts() {
    this.searchControlAccounts = '';
    this.accountDetailsTrimmed = this.accountDetails.map(a => ({ ...a }));
    this.accountDetailsTrimmed = this.accountDetailsTrimmed.slice(0, 100);
    this.selectedAccountsForMOM = [];
    this.onApplyAccounts();
  }

  getPlantsQuery(boardId: any, accountIds: any) {
    const accountQueries = accountIds.map(value => {
      return {
        field: 'data.account.raw',
        value: value
      };
    });

    return {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: boardId,
            },
          ],
        },
      },
      nestedWhere: {
        account_query: {
          type: 'search',
          operator: 'or',
          value: accountQueries
        }
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
    };
  }

  loadPlantsOfAccounts() {
    this.isLoading = true;
    const accountIds = this.selectedAccountsForMOM.map(account => account.id);
    const query = this.getPlantsQuery(this.sobPlantBoardId, accountIds);
    this._kService.getLaneTask(query).subscribe(response => {
      this.isLoading = false;
      this.plantDetails = response['result'];
      this.selectedPlantsForMeeting = [];
      this.plantDetails.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );
      this.plantDetailsTrimmed = this.plantDetails.slice(0, 100);
    });
  }


  onApplyPlants() {
    this.searchControlPlants = "";
    this.plantDetailsTrimmed = this.plantDetails;
    this.plantDetailsTrimmed = this.plantDetailsTrimmed.slice(0, 100);
    if (this.selectedPlantsForMeeting) {
      this.selectedPlantsForMeeting.forEach(item => {
        if (this.plantDetails.includes(item) && !this.plantDetailsTrimmed.includes(item)) {
          this.plantDetailsTrimmed.push(item);
        }
      });
    }
    const selectedPlantsList = this.selectedPlantsForMeeting.map(item => item.id);
    const accountIds = this.selectedAccountsForMOM.map(account => account.id);
    this.updateMeetingEntitiesForSob(selectedPlantsList, accountIds);
  }

  clearPlants(){
    this.selectedPlantsForMeeting = []
    this.onApplyPlants();
  }

  filterOptionsPlants(event) {
    const filterValue = event.target.value.toLowerCase();
    this.plantDetailsTrimmed = this.plantDetails.filter(option => (option.name).toLowerCase().includes(filterValue));
    if (this.selectedPlantsForMeeting) {
      this.selectedPlantsForMeeting.forEach(item => {
        if (this.plantDetails.includes(item) && !this.plantDetailsTrimmed.includes(item)) {
          this.plantDetailsTrimmed.push(item);
        }
      });
    }
    this.plantDetailsTrimmed = this.plantDetailsTrimmed.slice(0, 100);
  }

  cancelMOM() {
    this.file = null;
    this.content = ''
    this.selectedPlantsForMeeting = [];
    this.selectedValue = null;
    this.nextActionDate = null;
    this.toggleVisibility();
  }
  cancelTask() {
    this.taskTitle = null;
    this.taskDescription = ''
    this.taskDueDate = null;
    this.selectedTaskAssignees=[];
    this.selectedTaskOwners=[];
    this.selectedTaskCollaborators=[];
    this.toggleVisibility();
  }

  toggleVisibility() {
    // if (!this.selectedAccountsForMOM) {
    //   this._snackBar.openFromComponent(ErrorToastComponent, {
    //     horizontalPosition: 'center',
    //     verticalPosition: 'top',
    //     panelClass: ['atom-toast', 'error'],
    //     duration: 3500,
    //     data: "Please select Account"
    //   });
    // }
    this.attendeeLabel = this.vedantaLabel;
    //this.selectedAttendees = [];
    this.isHidden = !this.isHidden;

  }
  postTask() {
    let errorMessage = "";
    let tmpTaskDescription = this.taskDescription;
    if (this.taskDescription) {
      tmpTaskDescription = tmpTaskDescription.replace(/(<([^>]+)>)/ig, '');
    }
    let tmpTaskTitle = this.taskTitle;
    if (this.taskTitle) {
      tmpTaskTitle = tmpTaskTitle.replace(/(<([^>]+)>)/ig, '');
    }
    if (!this.taskTitle || !this.taskTitle.trim()) {
      errorMessage += "Task Title";
    }
    if (!this.taskDescription || !this.taskDescription.trim()) {
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Task Description";
    }
    if (!this.taskDueDate) {
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Due Date";
    }
    console.log('selectedTaskOwners', this.selectedTaskOwners)
    if(!this.selectedTaskOwners || this.selectedTaskOwners.length==0){
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Task Owner";
    }
    if(!this.selectedTaskAssignees  || this.selectedTaskAssignees.length==0){
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Task Assignee";
    }    
    if (errorMessage) {
      const lastCommaIndex = errorMessage.lastIndexOf(", ");
      if (lastCommaIndex !== -1) {
        errorMessage = errorMessage.substring(0, lastCommaIndex) + " and" + errorMessage.substring(lastCommaIndex + 1);
      }
    }
    if (!errorMessage || !errorMessage.trim) {
      this.addTask(this._kService);
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 3500,
        data: errorMessage + " are mandatory"
      });
    }
  }

  private addTask(service:any) {
    let selectedTaskOwnerValue;
    let selectedTaskAssigneValue;
    let selectedTaskCollaboratorValue;
    if (this.selectedTaskOwners) {
      selectedTaskOwnerValue = this.selectedTaskOwners.map(attendee => ({"id":attendee.id}));
    }
    if (this.selectedTaskAssignees) {
      selectedTaskAssigneValue = this.selectedTaskAssignees.map(attendee => ({"id":attendee.id}));
    }
    if (this.selectedTaskCollaborators) {
      selectedTaskCollaboratorValue = this.selectedTaskCollaborators.map(attendee => ({"id":attendee.id}));
    }
    let taskDueDate:Date;
    if (!(this.taskDueDate instanceof Date)) {
      taskDueDate = new Date(this.taskDueDate); // Convert to Date if it's not already
    }
    let obj = {
      "meeting": {"id":this.selectedMeeting.id},
      "title": this.taskTitle,
      "description": this.taskDescription,
      "dueDate": taskDueDate.getTime(),
      "owners":selectedTaskOwnerValue,
      "assignees":selectedTaskAssigneValue,
      "collaborators":selectedTaskCollaboratorValue
    }
    let reqFunc = null;
    reqFunc = service.addTask(obj);

    reqFunc.subscribe(res => {
      this.sendMomMail = false;
      if (res['status'] == 200) {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 3500,
          data: "Task added successfully"
        });
        this.isHidden = true;
        this.cancelTask();
        console.log("selected", this.selectedMeeting);
        this.fetchTaskDetails(this.selectedMeeting);
      }
    });
  }

  postMOM(momtype:string) {
    let tmp = this.content;
    if (this.content) {
      tmp = tmp.replace(/(<([^>]+)>)/ig, '');
    }

    let errorMessage = "";

    if (!this.content || !this.content.trim()) {
      errorMessage += "Comment";
    }
    // if (!this.nextActionDate) {
    //   if (errorMessage) {
    //     errorMessage += ", ";
    //   }
    //   errorMessage += "Next Action Date";
    // }
    if (!this.selectedValue) {
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Value Delivery";
    }

    if (errorMessage) {
      const lastCommaIndex = errorMessage.lastIndexOf(", ");
      if (lastCommaIndex !== -1) {
        errorMessage = errorMessage.substring(0, lastCommaIndex) + " and" + errorMessage.substring(lastCommaIndex + 1);
      }
    }
    if (this.content && (this.content.trim() != "" && tmp.trim() != "") && this.selectedValue) {
      this.addMOM(this.selectedAccountsForMOM, this.selectedPlantsForMeeting, this.sobUserId, this._kService,momtype);
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 3500,
        data: errorMessage + " are mandatory"
      });
    }
  }

  private addMOM(selectedAccountsForMOM: any[], selectedPlantsForMeeting: any[], userId: string, service: any,momtype:any) {
    console.log("query", selectedAccountsForMOM, selectedPlantsForMeeting);
    // if (selectedAccountsForMOM.length < 1) {
    //   return this._snackBar.openFromComponent(ErrorToastComponent, {
    //     horizontalPosition: 'center',
    //     verticalPosition: 'top',
    //     panelClass: ['atom-toast', 'error'],
    //     duration: 3500,
    //     data: `Please select Account first to Add MOM`
    //   });
    // }
    let valueDelivery = false;
    if (this.selectedValue === "YES") {
      valueDelivery = true;
    }
    let attendees;
    if (this.selectedAttendees) {
      attendees = this.selectedAttendees.map(attendee => attendee.id);
    }
    let obj = {
      "commentId": "string",
      "attendees": attendees,
      "meetingId": this.selectedMeeting.id,
      "message": this.content,
      "nextActionDate": this.nextActionDate,
      "valueDelivery": valueDelivery,
      "sendMomMail": this.sendMomMail,
      // "accounts": selectedAccountsForMOM,
      // "plants": selectedPlantsForMeeting,
      "createdById": userId,
      "momType":momtype,
      "id":this.draftMomDetails.id
    }
    let reqFunc = null;
    reqFunc = service.addMOM(obj);

    reqFunc.subscribe(res => {
      this.sendMomMail = false;
      if (res['status'] == 200) {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 3500,
          data: "MOM added successfully"
        });
        this.isHidden = true;
        if (this.file) {
          const uploadData = new FormData();
          uploadData.append('file', this.file, this.file.name);
          service.uploadSubDocuments(uploadData, res['result'].id, ("momId")).subscribe(file => {
            if (file['status'] == 200) {
              this._snackBar.openFromComponent(SuccessToastComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['atom-toast', 'success'],
                duration: 3500,
                data: "Attachment uploaded successfully"
              });
            }
            else {
              this._snackBar.openFromComponent(ErrorToastComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['atom-toast', 'error'],
                duration: 3500,
                data: "Error while uploading attachment. Please try again." + file
              });
            }
            this.clearForm();
            console.log("res.meetingId", res);
            this.fetchMOMDetails(res['result'].meetingId);
          });
        } else {
          this.clearForm();
        }
        console.log("selected", this.selectedMeeting);
        this.fetchMOMDetails(this.selectedMeeting);
      }
    });
  }
  fileUpload(event) {
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }
  }
  rmeoveSelectedFile() {
    this.file = null;
  }

  clearForm() {
    this.file = null;
    this.content = '';
    this.selectedValue = null;
    this.nextActionDate = null;
  }

  sendMail(event) {
    this.sendMomMail = event;
  }

  updateMeetingEntitiesForSob(plantIdList: string[] = [], accountIds: any[] = []) {
    this.selectedMeeting.plantIdList = plantIdList;
    this.selectedMeeting.accountIds = accountIds;
    const obj = {
      id: this.selectedMeeting.id,
      plantIdList: plantIdList,
      accountList: accountIds,
      selectedPlatform: 'SOB'
    };
    this.settingService.associateEntitiesToMeetings(obj).subscribe((res) => {
      const successMessage = accountIds != null && accountIds.length > 0 ? "Plants Updated Successfully" : "Accounts Updated Successfully";
      const errorMessage = accountIds != null && accountIds.length > 0 ? "Error while updating Plants" : "Error while updating Accounts";
      if (res['status'] === 200) {
        this.datewisemap.forEach((value: any, key: any) => {
          value.forEach((l: any) => {
            if (l.id == res['result'].id) {
              l.selectedPlatform = res['result'].selectedPlatform;
            }
          })
        });

        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 3500,
          data: successMessage
        });
      } else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 3500,
          data: errorMessage + res['status']
        });
      }
    });
  }

  onAttendeeSelectionChange(selection: any) {
    this.selectedAttendees = selection;
    console.log('selectedAttendees',this.selectedAttendees)
  }
  onTaskOwnerSelectionChange(selection: any) {
    this.selectedTaskOwners = selection;
    console.log('selectedTaskOwners',this.selectedTaskOwners)
  }
  onTaskAssigneeSelectionChange(selection: any) {
    this.selectedTaskAssignees = selection;
    console.log('selectedTaskAssignees',this.selectedTaskAssignees)
  }
  onTaskCollaboratorsSelectionChange(selection: any) {
    this.selectedTaskCollaborators = selection;
    console.log('selectedTaskCollaborators',this.selectedTaskCollaborators)
  }

  filterOptionsAttendees(event) {
    this.allUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  filterOptionsTaskOwner(event) {
    this.allTaskOwnerUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  filterOptionsTaskAsshignee(event) {
    this.allTaskAssigneeUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  filterOptionsTaskCollaborator(event) {
    this.allTaskCollaboratorUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }

  meetingTabSelection(selectedTab: string) {
    let obj = {};
    if (this.startDateSelected !== null && this.endDateSelected !== null) {
      obj = {
        "startDate": this.startDateSelected,
        "endDate": this.endDateSelected,
      };
    }
    this.selectedTab = selectedTab;
    if (this.selectedTab === "MOM") {
      this.loadMOMMeetings(obj);
    }
    else {
      this.loadMeetings(obj);
    }
  }

}